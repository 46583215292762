import { BillingSummary, BillingPaged } from '../models/Poco';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { BillingService } from '../Service/billing.service';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';

// https://blog.angular-university.io/angular-material-data-table/

export class BillingDataSource implements DataSource<BillingSummary> {

    private billingSubject = new BehaviorSubject<BillingPaged>(new BillingPaged());
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    totalRecords = 0;

    constructor(private billingService: BillingService) { }

    connect(collectionViewer: CollectionViewer): Observable<BillingSummary[]> {
        return this.billingSubject.asObservable()
            .pipe(map((x) => {
                // console.log(this.totalRecords); 
                this.totalRecords = x.TotalRecords; return x.Summary;
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.billingSubject.complete();
        this.loadingSubject.complete();
    }

    loadBilling(pageIndex: number, pageSize: number, sortKey = 'FromDate', sortDir = 'asc', custName: string, toBePaidOnly: boolean) {
        this.loadingSubject.next(true);
        this.billingService.listBilling(pageIndex, pageSize, sortKey, sortDir, custName, toBePaidOnly)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((billingPaged: BillingPaged) => {
                this.totalRecords = billingPaged.TotalRecords;
                this.billingSubject.next(billingPaged);
            }
            );
    }
}
