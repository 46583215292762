import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MyShopSalesPurchase, MyShopSale, MyShopPurchase } from '../models/Poco';
import { MatTable } from '@angular/material/table';
import { Util } from '../models/Util';

@Component({
  selector: 'app-misc-bill-activity',
  templateUrl: './misc-bill-activity.component.html',
  styleUrls: ['./misc-bill-activity.component.css']
})
export class MiscBillActivityComponent implements OnInit {

  sales: MyShopSale[];
  purchases: MyShopPurchase[];
  salePurchaseDate: Date;
  @Input() stickyFlag = false;

  displayedSalesColumns = ['Product', 'ConcessionSubTypeDisplayName', 'SoldQty', 'SoldAmount'];
  displayedPurchaseColumns = ['Product', 'PurchasedQty', 'PurchasedAmount'];

  constructor(private http: HttpClient, private util: Util) { }

  ngOnInit() {
    this.loadSalesAndPurchase();
  }

  loadSalesAndPurchase() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    return this.http.get<MyShopSalesPurchase>(
      this.util.GetServerUrl() + '/myshopsalespurchase', { headers, observe: 'response' }).subscribe(res => {
        this.sales = res.body.Sales;
        this.purchases = res.body.Purchases;
        this.salePurchaseDate = res.body.SalePurchaseDate;
      }
      );
  }

  getTotalSaleQty() {
    const total = this.sales != null && this.sales.length > 0 ?
    this.sales.map(x=> x.SoldQty).reduce((x, y) => x + y) : 0;
    return total;
  }
  
  getMaxSaleQtyPres() {
    if (this.sales.length === 0) {
      return 3;
    }
    return this.sales.map(x => x.QtyPres).reduce( (x, y) => x > y ? x : y);
  }

  getTotalSaleAmount() {
    const total = this.sales != null && this.sales.length > 0 ?
    this.sales.reduce(( sum, current) => sum + current.SoldAmount, 0) : 0;
    return total;
  }

  getTotalPurchaseQty() {
    const total = this.purchases != null && this.purchases.length > 0 ?
    this.purchases.reduce(( sum, current) => sum + current.PurchasedQty, 0) : 0;
    return total;
  }

  getMaxPurchaseQtyPres() {
    if (this.purchases == null || this.purchases.length === 0) {
      return 3;
    }
    return this.purchases.map(x => x.QtyPres).reduce( (x, y) => x > y ? x : y);
  }

  getTotalPurchaseAmount() {
    const total = this.purchases != null && this.purchases.length > 0 ?
    this.purchases.reduce(( sum, current) => sum + current.PurchasedAmount, 0) : 0;
    return total;
  }
}

