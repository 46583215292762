import { Component, OnInit, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Util } from '../models/Util';
import { BillDetailForReturn, BillReturn } from '../models/Poco';
import { WindowRef } from '../service/window-ref';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-bill-return',
  templateUrl: './bill-return.component.html',
  styleUrls: ['./bill-return.component.css'],
  providers: [WindowRef]
})
export class BillReturnComponent implements OnInit {

  displayedColumns: string[] = ['ProductName', 'BillQty', 'Returned', 'Rate', 'Qty', 'Amount'];
  BillSummaryId: number;
  BillDetails: BillDetailForReturn[];
  RoundValue: number;
  constructor(private dialogRef: MatDialogRef<BillReturnComponent>, @Inject(MAT_DIALOG_DATA) data: number,
              private http: HttpClient, private util: Util, private dialog: MatDialog, private winref: WindowRef) {
    this.BillSummaryId = data;
  }

  ngOnInit() {
    setTimeout(() => {
      this.showDetail();
    }, 800);
  }

  showDetail() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<BillDetailForReturn[]>(
      this.util.GetServerUrl() + '/billreturn/' + this.BillSummaryId, { headers, observe: 'response' })
      .subscribe(res => {
        this.BillDetails = res.body;
      }
      );
  }

  SaveReturn() {

    // debugger;
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    for (const element of this.BillDetails) {
      if ((1.0 * element.Returned) + (1.0 * element.Qty) > (1.0 * element.BillQty)) {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Please check the quantity', null);
        return;
      }
    }

    const obj = new BillReturn();
    obj.BillSummaryId = this.BillSummaryId;
    obj.ReturnDetails = this.BillDetails;
    this.http.post(
      this.util.GetServerUrl() + '/makereturn', obj, { headers, observe: 'response' }).subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
          this.dialogRef.close(true);
        });
      },
        err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
      );
  }
  Close() {
    this.dialogRef.close();
  }
}
