import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
   name: 'indianNumber'
})
export class IndianNumberPipe implements PipeTransform {
   transform(val: number, dec: number): string {
      if (val == 0 && dec > 0) {
         return '0.'+'0'.padStart(dec, '0');
      }
      if (!val) {
         return '';
      }
      if (dec) {
         const powerVal = Math.round(val * Math.pow(10, dec)).toString();
         return powerVal.substring(0,powerVal.length-dec)+'.'+ powerVal.substring(powerVal.length-dec);
         //return (val.toFixed(dec)).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
      } else {
         if (val % 1 !== 0) {
            return (val + '').replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
         } else {
            const dotText = (val + '.').replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
            return dotText.substr(0, dotText.length - 1);
         }

         // return (val + '').replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
         // return (val.toFixed(1)).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
      }
   }
}
