import { Component, OnInit, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ShopCategory, ShopProductUpdate, ShopCategoryUpdate } from '../models/Poco';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-shop-category',
  templateUrl: './shop-category.component.html',
  styleUrls: ['./shop-category.component.css'],
  providers: [WindowRef]
})
export class ShopCategoryComponent implements OnInit {

  ShopId: number;
  ShopCategories: ShopCategory[];

  displayedColumns: string[] = ['Included', 'CategoryName'];
  totalColumns: string[] = [];

  constructor(private dialogRef: MatDialogRef<ShopCategoryComponent>, @Inject(MAT_DIALOG_DATA) data: any,
              private http: HttpClient, private util: Util, private dialog: MatDialog,
              private winref: WindowRef) {
    this.ShopId = data as number;
  }

  ngOnInit() {
    this.loadShopCategories(this.ShopId);
  }

  doSave() {

    const excluded = this.ShopCategories.filter(x => !x.Included && x.ShopCategoryId != null)
      .map(x => x.ShopCategoryId);
    const updated = this.ShopCategories.filter(x => x.Included);
    const updateObj = new ShopCategoryUpdate();
    updateObj.ShopId = this.ShopId;
    updateObj.UpdateCategories = updated;
    updateObj.DeleteShopCategoryIds = excluded;

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.post(
      this.util.GetServerUrl() + '/updateshopcategories', updateObj, { headers, observe: 'response' }).subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Updated', (x) => {
          this.dialogRef.close(true);
        });
      },
        err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
      );
  }

  doCancel() {
    this.dialogRef.close(false);
  }

  loadShopCategories(shopId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ShopCategory[]>(
      this.util.GetServerUrl() + '/shopcategories/' + shopId, { headers, observe: 'response' })
      .subscribe(res => {
        this.ShopCategories = res.body;
      }
      );
  }

  includeChanged(change: MatCheckboxChange, shopCategory: ShopCategory) {
    if (!change.checked) {
    }
  }

}
