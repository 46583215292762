import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Util } from '../models/Util';
import { OpenCloseStatusResponse, ShopNozzleQty } from '../models/Poco';
import { WindowRef } from '../service/window-ref';
import { CashSalesComponent } from '../cash-sales/cash-sales.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BulkBoatBillHistoryComponent } from '../bulk-boat-bill-history/bulk-boat-bill-history.component';

@Component({
  selector: 'app-billing-status',
  templateUrl: './billing-status.component.html',
  styleUrls: ['./billing-status.component.css'],
  providers: [
    WindowRef,
  ]
})
export class BillingStatusComponent implements OnInit {

  @Input() Mode: string;

  TransactionDate: Date;
  IsBillingOpen = false;
  IsBillingClosed = false;
  EntryFound = false;
  BoatBulkBilling = false;
  ShopPumpNozzleQty: ShopNozzleQty[];

  @Output() StatusChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(private router: Router, private http: HttpClient, private util: Util,
              private dialog: MatDialog, private winref: WindowRef) { }

  ngOnInit() {
    this.loadBillingStatus();
  }

  NonValidityProdCheckAndNewEntry() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<boolean>(
      this.util.GetServerUrl() + '/nonvalidityprod',
      { headers, observe: 'response' }).subscribe(res => {
        if (res.body) {
          this.util.ShowMessageBox(this.dialog, this.winref, 'Ok', null,
          'Convert the inactive qutota items to active quota to proceed', null);
          return;
        } else {
          if (this.Mode === 'Billing') {
            this.router.navigate(['../BillingDetail', 0]);
          } else if (this.Mode === 'Purchase') {
            this.router.navigate(['../PurchaseDetail', 0]);
          } else if (this.Mode === 'Adjustment') {
            this.router.navigate(['../AdjustmentDetail', 0]);
          } else if (this.Mode === 'Stktransfer') {
            this.router.navigate(['../StktransferDetail', 0]);
          }
        }
      }
      );
  }

  loadBillingStatus() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const dateInddMMyyyy = this.util.GetddMMMyyyyDate(new Date());

    this.http.get<OpenCloseStatusResponse>(
      this.util.GetServerUrl() + '/GetOpenCloseStatus?StatusDate=' + dateInddMMyyyy,
      { headers, observe: 'response' }).subscribe(res => {
        this.TransactionDate = new Date(res.body.TransactionDate);
        this.IsBillingOpen = res.body.EntryFound && res.body.OpenTime != null && res.body.CloseTime === null;
        this.IsBillingClosed = res.body.EntryFound && res.body.CloseTime != null;
        this.BoatBulkBilling = res.body.BoatBulkBilling;
        this.EntryFound = res.body.EntryFound;
        if (this.util.GetToday() > this.TransactionDate) {
          const isdone = sessionStorage.getItem('dateViolationPromptDone');
          if ( isdone == null || isdone === '0') {
            if (this.Mode != 'Settlement') {
              sessionStorage.setItem('dateViolationPromptDone', '1');
              this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Please review the transaction date', null);
            }
          }
        }
      }
      );
  }

  openBilling(): void {

    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Do you want to open?', x => {
      if (x === 1) {

        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });

        const dateInddMMyyyy = this.util.GetddMMMyyyyDate(this.TransactionDate);

        this.http.post<OpenCloseStatusResponse>(
          this.util.GetServerUrl() + '/OpenDatewiseTransaction?StatusDate=' + dateInddMMyyyy, null,
          { headers, observe: 'response' }).subscribe(res => {
            this.StatusChanged.emit('Opened');
            this.loadBillingStatus();
          }, err => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
          });
      }
    }
    );
  }


  closeBilling() {
    debugger;
    const dialogRef = this.dialog.open(CashSalesComponent, {
        data: null,
        disableClose: true,
        // height: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
        if (result != null) {
          this.ShopPumpNozzleQty = result as ShopNozzleQty[];
          this._closeBilling();
        }
    });
  }

  _closeBilling() {
    //debugger;
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const dateInddMMyyyy = this.util.GetddMMMyyyyDate(this.TransactionDate);
    this.http.post<OpenCloseStatusResponse>(
      this.util.GetServerUrl() + '/CloseDatewiseTransaction?StatusDate=' + dateInddMMyyyy, this.ShopPumpNozzleQty,
      { headers, observe: 'response' }).subscribe(res => {
        this.StatusChanged.emit('Closed');
        this.loadBillingStatus();
      }, err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
      });
  }

  showBulkHistoryRequest() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.TransactionDate;
    this.dialog.open(BulkBoatBillHistoryComponent, dialogConfig);
  }

}
