import { Component, OnInit, Inject } from '@angular/core';
import { Util } from '../models/Util';
import { ServiceProvider } from '../models/Poco';
import { WindowRef } from '../service/window-ref';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-new-service-provider',
  templateUrl: './new-service-provider.component.html',
  styleUrls: ['./new-service-provider.component.css'],
  providers: [WindowRef]
})
export class NewServiceProviderComponent implements OnInit {

  ServiceProviderId: number;
  GstNumber: string;
  PanNumber: string;
  PartyName: string;
  constructor(@Inject(MAT_DIALOG_DATA) data: number, private dialogRef: MatDialogRef<NewServiceProviderComponent>,
              private util: Util, private dialog: MatDialog, private winref: WindowRef,
              private http: HttpClient) {
    this.ServiceProviderId = data;
   }


  ngOnInit() {

  }

  saveOrUpdate() {

    if (this.PartyName == null || this.PartyName.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the PartyName', null);
      return;
    }

    const pattern =
    '^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$';

    if (this.GstNumber != null && this.GstNumber.length > 0) {
      const exp = new RegExp(pattern);
      if (!exp.test(this.GstNumber)) {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the valid GST number', null);
        return;
      }
    }

    if (this.PartyName == null || this.PartyName.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the party number', null);
      return;
    }

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const serviceProvider = new ServiceProvider();
    serviceProvider.GstNumber = this.GstNumber;
    serviceProvider.PartyName = this.PartyName;
    serviceProvider.PanNumber = this.PanNumber;

    this.http.post(
      this.util.GetServerUrl() + '/recordServiceProvider', serviceProvider, { headers, observe: 'response' }).subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
          this.dialogRef.close(true);
        });
      }, err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
      }
      );
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
