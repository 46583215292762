import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ParentProduct } from '../models/Poco';
import { Util } from '../models/Util';
import { ProductCategoryAssociationComponent } from '../product-catagory-association/product-catagory-association.component';

@Component({
  selector: 'app-product-catagory',
  templateUrl: './product-catagory.component.html',
  styleUrls: ['./product-catagory.component.css']
})
export class ProductCatagoryComponent implements OnInit {

  displayedColumns = ['ParentProductName', 'Categories', 'Uom', 'RateEditable', 'Action'];
  ParentProducts: ParentProduct[] = [];
  @ViewChild('details', { static: true }) details: MatTable<ParentProduct>;
  dataSource = new MatTableDataSource<ParentProduct>(this.ParentProducts);

  constructor(private http: HttpClient, private util: Util, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.loadParentProducts();
  }

  loadParentProducts(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ParentProduct[]>(
      this.util.GetServerUrl() + '/allparentproducts',
      { headers, observe: 'response' }).subscribe(res => {
        this.ParentProducts = res.body;
        this.dataSource = new MatTableDataSource<ParentProduct>(this.ParentProducts);
      }
      );
  }

  productCategories(parentProductId: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = parentProductId;
    const dialogRef = this.dialog.open(ProductCategoryAssociationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadParentProducts();
      }
    }
    );
  }
}
