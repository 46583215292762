import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { merge, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { WindowRef } from '../service/window-ref';
import { Util } from '../models/Util';
import { UserService } from '../service/user.service';
import { UserDataSource } from '../datasource/user.datasource';
import { ContextService } from '../service/context-change';
import { BankReceiptsComponent } from '../bank-receipts/bank-receipts.component';
import { NewUserComponent } from '../new-user/new-user.component';
import { UserShopComponent } from '../user-shop/user-shop.component';
import { UserSessionsComponent } from '../user-sessions/user-sessions.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserSummary } from '../models/Poco';

@Component({
  selector: 'app-user-list',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  providers: [UserService],
})
export class UserComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['UserName', 'FullName', 'RoleName', 'EmailId', 'MobileNo', 'SessionCount', 'LastLoggedIn', 'Action'];
  pageSize = 10;
  totalRecords: number;
  dataSource: UserDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  contextSubscriber: Subscription;

  constructor(private userService: UserService, private contextChange: ContextService,
    private http: HttpClient, private util: Util,
    public router: Router, private dialog: MatDialog, private winref: WindowRef) {
    this.contextSubscriber = contextChange.getObservable().subscribe(x => {
      this.dataSource.loadUsers(0, 10, 'UserName', 'asc');
    });
  }

  ngOnInit() {
    this.dataSource = new UserDataSource(this.userService);
    this.totalRecords = this.dataSource.totalRecords;
    this.dataSource.loadUsers(0, 10, 'UserName', 'asc');
  }

  saveEmail(element: UserSummary) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.post<number>(
      this.util.GetServerUrl() + '/updateemail', { UserId: element.UserId, EmailId: element.NewEmailId }, { headers, observe: 'response' }).subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
          element.EmailId = element.NewEmailId;
          element.EditEmailId = false;
        });
      }
      );
  }

  cancelEmail(element: UserSummary) {
    element.NewEmailId = element.EmailId;
    element.EditEmailId = false;
  }

  switch(element: UserSummary) {
    element.NewEmailId = element.EmailId;
    element.EditEmailId = true;
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadUsers())
      )
      .subscribe();
  }

  loadUsers() {
    this.dataSource.loadUsers(this.paginator.pageIndex, this.paginator.pageSize);
  }

  newUser() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(NewUserComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadUsers();
    }
    );
  }

  editShops(userId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = userId;
    const dialogRef = this.dialog.open(UserShopComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
    }
    );
  }

  resetPassword(userId: number, userName: string) {

    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Do you want to reset the password for profile \''
      + userName + '\'?',
      x => {
        if (x === 1) {

          const token = sessionStorage.getItem('Token') ?? '';
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            token
          });

          this.http.post(
            this.util.GetServerUrl() + '/resetpwd/' + userId, null, { headers, observe: 'response' }).subscribe(res => {
              this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Password has been reset', x => {
                this.loadUsers();
              });
            }, err => {
              this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
            }
            );
        }
      });
  }

  ShowSessions(userId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = userId;
    this.dialog.open(UserSessionsComponent, dialogConfig);
  }

  setActive(userId: number, isActive: boolean) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Do you want to ' + (isActive ? 'activate' : 'deactivate') + '?',
      x => {
        if (x === 1) {
          this.http.post(
            this.util.GetServerUrl() + '/setprofileactive/' + userId + '/' + (isActive ? 'true' : 'false'),
            null, { headers, observe: 'response' }).subscribe(res => {
              this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null,
                (isActive ? 'Profile has been activated' : 'Profile has been de-activated'), x => {
                  this.loadUsers();
                });
            }, err => {
              this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
            }
            );
        }
      }
    );
  }

}
