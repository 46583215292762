import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LkType, MasterProduct } from '../models/Poco';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css']
})
export class EditProductComponent implements OnInit {

  ParentProductId: number;
  ParentProductName: string;
  FillTypes: LkType[];
  UomTypes: LkType[];
  SelFillType: LkType;
  SelUomType: LkType;
  IncludingTax: boolean;
  StockCheck: boolean;
  DailyRate: boolean;
  SGst: number;
  CGst: number;
  IGst: number;

  constructor(@Inject(MAT_DIALOG_DATA) data: number, private http: HttpClient, private util: Util,
              private dialogRef: MatDialogRef<EditProductComponent>, private winref: WindowRef,
              private dialog: MatDialog) {
    this.ParentProductId = data;
  }

  ngOnInit(): void {
    this.loadFillType();
  }

  loadFillType(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/AllFillTypes',
      { headers, observe: 'response' }).subscribe(res => {
        this.FillTypes = res.body;
        const defSelFillType = this.FillTypes.filter(x => x.TypeName === 'AllInOne');
        if ( (this.ParentProductId == null || this.ParentProductId === 0) && defSelFillType.length > 0)
        {
          this.SelFillType = defSelFillType[0];
        }
        this.loadUomType();
      }
      );
  }

  loadUomType(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/AllUomTypes',
      { headers, observe: 'response' }).subscribe(res => {
        this.UomTypes = res.body;
        if (this.ParentProductId > 0) {
          this.showDetail(this.ParentProductId);
        } else {
          this.StockCheck = true;
        }
      }
      );
  }

  showDetail(parentProductId: number): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<MasterProduct>(
      this.util.GetServerUrl() + '/parentproduct/' + parentProductId, { headers, observe: 'response' })
      .subscribe(res => {
        const product = res.body as MasterProduct;
        this.ParentProductName = product.ParentProductName;
        const fillTypes = this.FillTypes.filter(x => x.TypeId === product.FillTypeId);
        if (fillTypes.length > 0) {
          this.SelFillType = fillTypes[0];
        }
        const uomTypes = this.UomTypes.filter(x => x.TypeId === product.UomTypeId);
        if (uomTypes.length > 0) {
          this.SelUomType = uomTypes[0];
        }
        this.SGst = product.SGst;
        this.CGst = product.CGst;
        this.IGst = product.IGst;
        this.IncludingTax = product.IncludingTax;
        this.StockCheck = product.StockCheck;
        this.DailyRate = product.DailyRate;
      }
      );
  }

  SaveOrUpdate(request: boolean): void {

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const expense = new MasterProduct(this.ParentProductId, this.ParentProductName, this.SelUomType.TypeId,
      this.SelFillType.TypeId, this.SGst, this.CGst, this.IGst, this.IncludingTax, this.StockCheck, this.DailyRate);
    if (this.ParentProductId > 0) {
      this.http.post(
        this.util.GetServerUrl() + '/updateproduct', expense, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.dialogRef.close(true);
          });
        }
        );
    } else {
      this.http.post<number>(
        this.util.GetServerUrl() + '/addproduct', expense, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            expense.ParentProductId = res.body;
            this.dialogRef.close(true);
          });
        }, err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
        );
    }
  }

  doCancel(): void {
    this.dialogRef.close(false);
  }

}
