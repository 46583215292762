import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Product, ShopQuota, ShopQuotaUpdate, ShopQuotaFilter, Quota, LkType } from '../models/Poco';
import { Util } from '../models/Util';
import { Subject } from 'rxjs';
import { QuotaService } from '../service/quota.service';
import { WindowRef } from '../service/window-ref';
import { MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-shop-quota',
  templateUrl: './shop-quota.component.html',
  styleUrls: ['./shop-quota.component.css'],
  providers: [
    QuotaService,
    WindowRef
  ]
})
export class ShopQuotaComponent implements OnInit {

  displayedColumns: string[] = ['ShopName', 'Qty'];

  // ctrlMonthDate: MatInput;

  monthDate: Date;
  quotas: Quota[];
  products: Product[];
  boatTypes: LkType[];

  Quota: Quota;
  Product: Product;
  BoatType: LkType;

  quotaListFound = false;
  QuotaList: ShopQuota[];
  @ViewChild('details', { static: true }) details: MatTable<ShopQuota>;

  alertVisible = false;
  alertType = 'success';
  alertMessage = '';
  private _success = new Subject<string>();
  staticAlertClosed = false;

  constructor(private http: HttpClient, private util: Util, private dialog: MatDialog, private winref: WindowRef) { }

  ngOnInit() {
    this.loadQuota();
    this.loadProduct();
    this.loadBoatType();
  }

  loadQuota() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<Quota[]>(
      this.util.GetServerUrl() + '/GetAllActiveQuota', { headers, observe: 'response' }).subscribe(res => {
        this.quotas = res.body;
      }
      );
  }

  loadProduct() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<Product[]>(
      this.util.GetServerUrl() + '/allproducts', { headers, observe: 'response' }).subscribe(res => {
        this.products = res.body.filter(x => !x.IsDummy);
      }
      );
  }

  loadBoatType() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/AllBoatTypes',
      { headers, observe: 'response' }).subscribe(res => {
        // console.log(res.body);
        this.boatTypes = res.body;
      }
      );
  }

  loadQuotas(quota: Quota, product: Product, boatType: LkType) {
    if (quota == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the quota', null);
      return;
    }
    if (product == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the product', null);
      return;
    }
    if (boatType == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the boat type', null);
      return;
    }

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<ShopQuota[]>(
      this.util.GetServerUrl() + '/getshopquota?QuotaId=' + quota.QuotaId + '&BoatTypeId=' + boatType.TypeId
      + '&ProductId=' + product.ProductId
      , { headers, observe: 'response' }).subscribe(res => {
        this.QuotaList = res.body;
        this.quotaListFound = (this.QuotaList.length > 0);
      }
      );
  }

  updateQuotas(quota: Quota, product: Product, boatType: LkType) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const update = new ShopQuotaUpdate(
      new ShopQuotaFilter(quota.QuotaId, boatType.TypeId, product.ProductId),
      this.QuotaList);

    this.http.post(
      this.util.GetServerUrl() + '/updateshopquota', update, { headers, observe: 'response' }).subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Updated', (x) => {
          this.loadQuotas(this.Quota, this.Product, this.BoatType);
        });
      },
      err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
      }

      );
  }
}
