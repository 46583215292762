import { SalesPurchaseHistory, SalesPurchasePaged, StockChangeHistory, StockHistoryPaged } from '../models/Poco';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { StockHistoryService } from '../service/stock-history.service';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';


// https://blog.angular-university.io/angular-material-data-table/

export class StockHistoryDataSource implements DataSource<StockChangeHistory> {

    private billingSubject = new BehaviorSubject<StockHistoryPaged>(new StockHistoryPaged());
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    public totalRecords = 0;

    constructor(private stockHistoryService: StockHistoryService) { }

    connect(collectionViewer: CollectionViewer): Observable<StockChangeHistory[]> {
        return this.billingSubject.asObservable()
            .pipe(map((x) => {
                console.log(this.totalRecords);
                this.totalRecords = x.TotalRecords; return x.Summary;
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.billingSubject.complete();
        this.loadingSubject.complete();
    }

    loadHistory(shopId: number, fromDate: Date, toDate: Date, productId: number, quotaId: number,
                pageIndex: number, pageSize: number) {
        this.loadingSubject.next(true);
        this.stockHistoryService.listHistory(shopId, fromDate, toDate, productId, quotaId, pageIndex, pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((stockHistoryPaged: StockHistoryPaged) => {
                this.totalRecords = stockHistoryPaged.TotalRecords;
                this.billingSubject.next(stockHistoryPaged);
            }
            );
    }
}
