import { Component, OnInit, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ProductCategory, ProductCategoryUpdate } from '../models/Poco';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-product-catagory-association',
  templateUrl: './product-catagory-association.component.html',
  styleUrls: ['./product-catagory-association.component.css'],
  providers: [WindowRef]
})
export class ProductCategoryAssociationComponent implements OnInit {

  ProductId: number;
  ProductCategories: ProductCategory[];

  displayedColumns: string[] = ['Included', 'CategoryName'];
  totalColumns: string[] = [];

  constructor(private dialogRef: MatDialogRef<ProductCategoryAssociationComponent>, @Inject(MAT_DIALOG_DATA) data: any,
              private http: HttpClient, private util: Util, private dialog: MatDialog,
              private winref: WindowRef) {
    this.ProductId = data as number;
  }

  ngOnInit(): void {
    this.loadProductCategories(this.ProductId);
  }

  doSave(): void {

    const excluded = this.ProductCategories.filter(x => !x.Included && x.ProductCategoryId != null)
      .map(x => x.ProductCategoryId);
    const updated = this.ProductCategories.filter(x => x.Included);
    const updateObj = new ProductCategoryUpdate();
    updateObj.ProductId = this.ProductId;
    updateObj.UpdateCategories = updated;
    updateObj.DeleteProductCategoryIds = excluded;

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.post(
      this.util.GetServerUrl() + '/updateProductcategories', updateObj, { headers, observe: 'response' }).subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Updated', (x) => {
          this.dialogRef.close(true);
        });
      },
        err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
      );
  }

  doCancel(): void {
    this.dialogRef.close(false);
  }

  loadProductCategories(ProductId: number): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ProductCategory[]>(
      this.util.GetServerUrl() + '/Productcategories/' + ProductId, { headers, observe: 'response' })
      .subscribe(res => {
        this.ProductCategories = res.body;
      }
      );
  }

  includeChanged(change: MatCheckboxChange, productCategory: ProductCategory): void {
    if (!change.checked) {
    }
  }

}
