import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TempLedgerEntryDataSource } from '../datasource/temp-ledger-entry.datasource';
import { MiscEntry, TempLedgerEntrySummary } from '../models/Poco';
import { TempLedgerEntryService } from '../service/temp-ledger-entry.service';
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MiscBankLedgerEntryComponent } from '../misc-bank-ledger-entry/misc-bank-ledger-entry.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';

@Component({
  selector: 'app-temp-ledger-entry',
  templateUrl: './temp-ledger-entry.component.html',
  styleUrls: ['./temp-ledger-entry.component.css'],
  providers: [ TempLedgerEntryService ]
})
export class TempLedgerEntryComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['ReferenceDate', 'LedgerTypeName', 'Amount', 'Description', 'Action'];
  IsAllSelected = false;
  pageSize = 10;
  totalRecords: number;
  dataSource: TempLedgerEntryDataSource;
  CurrentLedgerList: TempLedgerEntrySummary[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  SelectedTaskIds: { UserTaskId: number, TaskStatus: string }[] = [];

  constructor(private ledgerService: TempLedgerEntryService, private util: Util,
    private http: HttpClient, private dialog: MatDialog, private winref: WindowRef,
    @Inject(MAT_DIALOG_DATA) private bankAccountId: number,
    private dialogRef: MatDialogRef<TempLedgerEntryComponent>) {  }

  ngOnInit(): void {
    this.dataSource = new TempLedgerEntryDataSource(this.ledgerService);
    this.dataSource.subscribeForList().subscribe(res => {
      this.CurrentLedgerList = res.Summary;
      let allSelected: boolean = null;
      this.IsAllSelected = allSelected;
    }, err => {
    });
    this.totalRecords = this.dataSource.totalRecords;
    this.dataSource.loadEntries(0, 10, 'CreatedAt', 'asc');
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.loadTasks();
        })
      )
      .subscribe();
  }

  loadTasks() {
    this.dataSource.loadEntries(this.paginator.pageIndex, this.paginator.pageSize);
  }

  acceptTempLedgerEntry(tempLedgerEntryId: number){
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<TempLedgerEntrySummary>(
      this.util.GetServerUrl() + '/getTempLedgerEntry/' + tempLedgerEntryId,
      { headers, observe: 'response' }).subscribe(res => {
        const entry = new MiscEntry(this.bankAccountId, null, res.body.Description, res.body.LedegerTypeId, res.body.Amount, res.body.TempLedgerEntryId, res.body.ReferenceDate);
        this._acceptTempLedgerEntry(entry);
      }
      );
  }
  _acceptTempLedgerEntry(miscEntry: MiscEntry) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = miscEntry;
    const dialogRef = this.dialog.open(MiscBankLedgerEntryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      const newentry = result as MiscEntry;
      if (result != null) {
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });
        this.http.post<boolean>(
          this.util.GetServerUrl() + '/makeMiscEntry', newentry, { headers, observe: 'response' }).subscribe(res => {
            this.dataSource.loadEntries(0, 10, 'CreatedAt', 'asc');
          });
      }
    }
    );
  }

  deleteTempLedgerEntry(tempLedgerEntryId: number) {
    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Are you sure?', (x: number) => {
      if (x === 1) {
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });
        this.http.post<boolean>(
          this.util.GetServerUrl() + '/deleteTempLedgerEntry/'+tempLedgerEntryId, null, { headers, observe: 'response' }).subscribe(res => {
            this.dataSource.loadEntries(0, 10, 'CreatedAt', 'asc');
          }, err=> {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
          });
      }
    });
  }

  doCancel() {
    this.dialogRef.close(null);
  }

}
