import { BoatCustomerPaged, BoatCustomer } from '../models/Poco';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { QuotaService } from '../service/quota.service';
import { BoatCustomerService } from '../service/boat-customer.service';

// https://blog.angular-university.io/angular-material-data-table/

export class BoatCustomerDataSource implements DataSource<BoatCustomer> {

    private quotaSubject = new BehaviorSubject<BoatCustomerPaged>(new BoatCustomerPaged());
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    totalRecords = 0;

    constructor(private customerService: BoatCustomerService) { }

    connect(collectionViewer: CollectionViewer): Observable<BoatCustomer[]> {
        return this.quotaSubject.asObservable()
            .pipe(map((x) => {
                // console.log(this.totalRecords);
                this.totalRecords = x.TotalRecords;
                return x.Summary;
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.quotaSubject.complete();
        this.loadingSubject.complete();
    }

    loadCustomer(pageIndex: number, pageSize: number, sortKey = 'FromDate', sortDir = 'asc') {
        this.loadingSubject.next(true);
        this.customerService.listCustomer(pageIndex, pageSize, sortKey, sortDir)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((customerPaged: BoatCustomerPaged) => {
                this.totalRecords = customerPaged.TotalRecords;
                this.quotaSubject.next(customerPaged);
            }
            );
    }
}
