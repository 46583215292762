import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Product, PurchaseDetail, PurchaseSummary, Purchase } from '../models/Poco';


import { Util } from '../models/Util';
import { MatTable } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';

@Component({
  templateUrl: './view-purchase.component.html',
  styleUrls: ['./view-purchase.component.css']
})
export class ViewPurchaseComponent implements OnInit {

  PurchaseSummaryId: number;
  PurchaseDate?: Date;
  InvoiceNo?: string;
  InvoiceAmount?: number;
  TotalQty?: number;
  TotalAmount?: number;

  products?: Product[];
  selProduct?: Product;
  purchaseDetails: PurchaseDetail[] = new Array();

  displayedColumns: string[] = ['ProductName', 'ConcessionTypeName', 'PurchaseRate', 'Qty', 'Amount'];

  @ViewChild('ctrlFromDate', { static: true }) ctrlFromDate: MatInput;
  @ViewChild('ctrlToDate', { static: true }) ctrlToDate: MatInput;
  @ViewChild('ctrlTotalAmount', { static: true }) ctrlTotalAmount: MatInput;
  @ViewChild('details', { static: true }) details: MatTable<PurchaseDetail>;

  constructor(@Inject(MAT_DIALOG_DATA) public purchase: any,
    public dialogRef: MatDialogRef<ViewPurchaseComponent>, private http: HttpClient, private util: Util) {
    if (purchase != null) {
      const _purchase = purchase as Purchase;
      if (_purchase.Summary != null) {
        this.PurchaseSummaryId = _purchase.Summary.PurchaseSummaryId;
        this.loadPurchase(_purchase);
      } else {
        this.PurchaseSummaryId = 0;
      }
    } else {
      this.PurchaseSummaryId = 0;
    }
  }

  ngOnInit() {
    this.loadProduct();
  }

  loadProduct() {
    const token = sessionStorage.getItem('Token') ?? '' ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<Product[]>(
      this.util.GetServerUrl() + '/allproducts', { headers, observe: 'response' }).subscribe(res => {
        this.products = res.body;
      }
      );
  }

  addPurchaseDetail(product: Product, purchaseAmount: number, qty: number, sgst: number, cgst: number,
                    igst: number, vat: number, tcs: number, amount: number, sellingRate: number, quotaId: number, quotaName: string) {
    this.purchaseDetails.push(new PurchaseDetail(product.ProductId, product.ProductDisplayName,
      qty, sgst, cgst, igst, vat, tcs, amount, sellingRate, quotaId, quotaName));
    this.details.renderRows();
  }

  calculateTotal() {

  }

  loadPurchase(purchase: Purchase) {
    this.purchaseDetails = purchase.PurchaseDetails;
    this.PurchaseDate = new Date(purchase.Summary.PurchaseDate);
    this.InvoiceNo = purchase.Summary.InvoiceNo;
    this.InvoiceAmount = purchase.Summary.InvoiceAmount;
  }



}
