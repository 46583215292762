import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BillingComponent } from './billing/billing.component';
import { NewBillingComponent } from './new-billing/new-billing.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { NewPurchaseComponent } from './new-purchase/new-purchase.component';
import { ShopQuotaComponent as ShopQuotaComponent } from './shop-quota/shop-quota.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HomeComponent } from './home/home.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule, MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Util } from './models/Util';

import { ProductRateComponent } from './product-rate/product-rate.component';

import { MonthlyReportComponent } from './monthly-report/monthly-report.component';
import { GstReportComponent } from './gst-report/gst-report.component';

import { SalesChartComponent } from './sales-chart/sales-chart.component';
import { SalePurchaseChartComponent } from './sale-purchase-chart/sale-purchase-chart.component';

import { SettlementComponent } from './settlement/settlement.component';
import { QuotaBalanceComponent } from './quota-balance/quota-balance.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FdcInterceptor } from './fdc-interceptor';
import { SaleDailyChartComponent } from './sale-daily-chart/sale-daily-chart.component';
import { MiscBillActivityComponent } from './misc-bill-activity/misc-bill-activity.component';
import { MessageBoxComponent } from './message-box/message-box.component';
import { ViewPurchaseComponent } from './view-purchase/view-purchase.component';
import { QuotaMasterComponent } from './quota-master/quota-master.component';
import { BillingStatusComponent } from './billing-status/billing-status.component';
import { BoatInfoComponent } from './boat-info/boat-info.component';
import { ConfirmBoatComponent } from './confirm-boat/confirm-boat.component';
import { BoatCustomerListComponent } from './boat-customer-list/boat-customer-list.component';
import { BoatListComponent } from './boat-list/boat-list.component';
import { BillPaymentComponent } from './bill-payment/bill-payment.component';
import { BoatQuotaComponent } from './boat-quota/boat-quota.component';
import { StockReportComponent } from './stock-report/stock-report.component';
import { ShopSelectionComponent } from './shop-selection/shop-selection.component';
import { DenominationPageComponent } from './settlement/denomination-page/denomination-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { CashSalesComponent } from './cash-sales/cash-sales.component';
import { PaymentCommentComponent } from './payment-comment/payment-comment.component';
import { SettlementListComponent } from './settlement-list/settlement-list.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ExpenseComponent } from './expense/expense.component';
import { EditExpenseComponent } from './edit-expense/edit-expense.component';
import { AuditEntryComponent } from './audit-entry/audit-entry.component';

import { NgxPrintModule} from 'ngx-print';
import { BillPrintComponent } from './bill-print/bill-print.component';
import { EditBoatCustomerComponent } from './edit-boat-customer/edit-boat-customer.component';
import { BankReceiptsComponent } from './bank-receipts/bank-receipts.component';

import { MiscBankLedgerEntryComponent } from './misc-bank-ledger-entry/misc-bank-ledger-entry.component';
import { BankLedgerComponent } from './bank-ledger/bank-ledger.component';

import { ProductMasterComponent } from './product-master/product-master.component';

import { UserComponent } from './user/user.component';
import { EditShopComponent } from './edit-shop/edit-shop.component';
import { ShopComponent } from './shop/shop.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { ShopProductComponent } from './shop-product/shop-product.component';
import { NewUserComponent } from './new-user/new-user.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { BumpListComponent } from './bump-list/bump-list.component';
import { ClearShopComponent } from './clear-shop/clear-shop.component';
import { NozzleListComponent } from './nozzle-list/nozzle-list.component';
import { EditNozzleComponent } from './edit-nozzle/edit-nozzle.component';
import { EditBumpComponent } from './edit-bump/edit-bump.component';
import { EditBoatComponent } from './edit-boat/edit-boat.component';
import { EditQuotaComponent } from './edit-quota/edit-quota.component';
import { UserTaskComponent } from './user-task/user-task.component';
import { EditTaskComponent } from './edit-task/edit-task.component';
import { SingleBillPrintComponent } from './single-bill-print/single-bill-print.component';
import { TaskActionComponent } from './task-action/task-action.component';
import { PdfViewComponent } from './pdf-view/pdf-view.component';
import { NewProductComponent } from './new-product/new-product.component';
import { AdjustmentComponent } from './adjustment/adjustment.component';
import { NewAdjustmentComponent } from './new-adjustment/new-adjustment.component';
import { NewServiceProviderComponent } from './new-service-provider/new-service-provider.component';
import { CurrentStockComponent } from './current-stock/current-stock.component';
import { BankAccountComponent } from './bank-account/bank-account.component';
import { EditBankAccountComponent } from './edit-bank-account/edit-bank-account.component';
import { BillReturnComponent } from './bill-return/bill-return.component';
import { SupplierComponent } from './supplier/supplier.component';
import { EditSupplierComponent } from './edit-supplier/edit-supplier.component';
import { EditDirectCustomerComponent } from './edit-direct-customer/edit-direct-customer.component';
import { DirectCustomerComponent } from './direct-customer/direct-customer.component';
import { ShopCategoryComponent } from './shop-category/shop-category.component';
import { ProductCategoryAssociationComponent } from './product-catagory-association/product-catagory-association.component';
import { UserShopComponent } from './user-shop/user-shop.component';
import { SupplierShopComponent } from './supplier-shop/supplier-shop.component';
import { UserSessionsComponent } from './user-sessions/user-sessions.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExceptionComponent } from './exception/exception.component';
import { IndianCurrencyPipe } from './pipes/indian-currency-pipe';
import { IndianNumberPipe } from './pipes/indian-number-pipe';
import { ReportsModule } from './reports/reports.module';
import { AuthGuard } from './auth.guard';
import { ResoldDetailComponent } from './resold-detail/resold-detail.component';
import { WindowRef } from './service/window-ref';
import { InformationBoxComponent } from './information-box/information-box.component';
import { ResaleAllPendingComponent } from './resale-all-pending/resale-all-pending.component';
import { ShopCodeAccountFilter } from './filter/shop-code-account-filter';
import { InchargeConsolidatedComponent } from './incharge-consolidated/incharge-consolidated.component';
import { StktransferComponent } from './stktransfer/stktransfer.component';
import { NewStktransferComponent } from './new-stktransfer/new-stktransfer.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { DateAdapter, MatOptionModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxEchartsModule } from 'ngx-echarts';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserSearchService } from './service/user-search.service';
import { ProductBulkRateComponent } from './product-bulk-rate/product-bulk-rate.component';
import { ProductCatagoryComponent } from './product-catagory/product-catagory.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { StockHistoryComponent } from './stock-history/stock-history.component';
import { DepositEntryConfirmComponent } from './deposit-entry-confirm/deposit-entry-confirm.component';
import { SingleFileUploadComponent } from './single-file-upload/single-file-upload.component';
import { BulkCustomerListPreviewComponent } from './bulk-customer-list-preview/bulk-customer-list-preview.component';
import { MatDividerModule } from '@angular/material/divider';
import { BulkBoatBillHistoryComponent } from './bulk-boat-bill-history/bulk-boat-bill-history.component';
import { CustomPropertyComponent } from './custom-property/custom-property.component';
import { EditCustomPropertyComponent } from './edit-custom-property/edit-custom-property.component';
import { AcceptAmountComponent } from './accept-amount/accept-amount.component';
import { TempLedgerEntryComponent } from './temp-ledger-entry/temp-ledger-entry.component';
import { InchargeDashboardComponent } from './incharge-dashboard/incharge-dashboard.component';
import { ShopDocmentUploadComponent } from './shop-docment-upload/shop-docment-upload.component';
import { ShopDocmentFilesComponent } from './shop-docment-files/shop-docment-files.component';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

const appRoutes: Routes = [
  {
    path: '', component: HomeComponent
  },
  {
    path: 'Home', component: HomeComponent
  },
  {
    path: 'Dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Billing', 'Incharge', 'Mgmt'] }, children: [
      {
        path: '',
        component: QuotaBalanceComponent,
        canActivate: [AuthGuard],
        data: { expectedRoles: ['Billing', 'Incharge', 'Mgmt'] }
      },
      {
        path: 'QuotaBalance',
        component: QuotaBalanceComponent,
        canActivate: [AuthGuard],
        data: { expectedRoles: ['Billing', 'Incharge', 'Mgmt'] }
      },
      {
        path: 'StockReport',
        component: StockReportComponent,
        canActivate: [AuthGuard],
        data: { expectedRoles: ['Billing', 'Incharge'] }
      },
      {
        path: 'StockHistory',
        component: StockHistoryComponent,
        canActivate: [AuthGuard],
        data: { expectedRoles: ['Billing', 'Incharge'] }
      },
      {
        path: 'Sales',
        component: SalesChartComponent,
        canActivate: [AuthGuard],
        data: { expectedRoles: ['Billing', 'Incharge'] }
      },
      {
        path: 'SalesPurchase',
        component: SalePurchaseChartComponent,
        canActivate: [AuthGuard],
        data: { expectedRoles: ['Billing', 'Incharge'] }
      },
      {
        path: 'SalesDaily',
        component: SaleDailyChartComponent,
        canActivate: [AuthGuard],
        data: { expectedRoles: ['Billing', 'Incharge'] }
      },
      {
        path: 'PendingSaleBill',
        component: ResaleAllPendingComponent,
        canActivate: [AuthGuard],
        data: { expectedRoles: ['Billing', 'Incharge'] }
      },
      {
        path: 'InchargeConsolidated',
        component: InchargeConsolidatedComponent,
        canActivate: [AuthGuard],
        data: { expectedRoles: ['Incharge', 'Mgmt'] }
      }
    ]
  },
  {
    path: 'Billing',
    component: BillingComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Billing', 'Incharge'] }
  },
  {
    path: 'BillingDetail/:Id',
    component: NewBillingComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Billing', 'Incharge'] }
  },
  {
    path: 'DirectCustomer',
    component: DirectCustomerComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Billing', 'Incharge'] }
  },
  {
    path: 'Adjustment',
    component: AdjustmentComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Billing', 'Incharge'] }
  },
  {
    path: 'AdjustmentDetail/:Id',
    component: NewAdjustmentComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Billing', 'Incharge'] }
  },
  {
    path: 'Stktransfer',
    component: StktransferComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Billing', 'Incharge'] }
  },
  {
    path: 'StktransferDetail/:Id',
    component: NewStktransferComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Billing', 'Incharge'] }
  },
  {
    path: 'Audit',
    component: AuditEntryComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Incharge'] }
  },
  {
    path: 'Suppliers',
    component: SupplierComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Incharge'] }
  },
  {
    path: 'EditSupplier/:Id',
    component: EditSupplierComponent,
    canActivate: [AuthGuard], data: { expectedRoles: ['Incharge'] }
  },
  {
    path: 'Purchase',
    component: PurchaseComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Billing', 'Incharge'] }
  },
  {
    path: 'PurchaseDetail/:Id',
    component: NewPurchaseComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Billing', 'Incharge'] }
  },
  {
    path: 'Ledger',
    component: BankLedgerComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Billing', 'Incharge'] }
  },
  {
    path: 'AppExceptions',
    component: ExceptionComponent,
    canActivate: [AuthGuard], data: { expectedRoles: ['SysAdmin'] }
  },
  {
    path: 'Expense',
    component: ExpenseComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Billing', 'Incharge', 'Advisor'] }
  },
  {
    path: 'UserTask',
    component: UserTaskComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Billing', 'Incharge', 'Mgmt', 'SysAdmin', 'Advisor'] }
  },
  {
    path: 'ShopQuota',
    component: ShopQuotaComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Mgmt'] }
  },
  {
    path: 'BoatQuota',
    component: BoatQuotaComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Mgmt'] }
  },
  {
    path: 'Quotas',
    component: QuotaMasterComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Mgmt'] }
  },
  {
    path: 'BoatCustomers',
    component: BoatCustomerListComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Mgmt'] }
  },
  {
    path: 'BoatCustomerDetail/:Id',
    component: EditBoatCustomerComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Mgmt'] }
  },
  {
    path: 'Shops',
    component: ShopComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['SysAdmin'] }
  },
  {
    path: 'BankAccount',
    component: BankAccountComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['SysAdmin'] }
  },
  {
    path: 'EditShop/:Id',
    component: EditShopComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['SysAdmin'] }
  },
  {
    path: 'Boats',
    component: BoatListComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Mgmt'] }
  },
  {
    path: 'Users',
    component: UserComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['SysAdmin'] }
  },
  {
    path: 'MasterProduct',
    component: ProductMasterComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['SysAdmin'] }
  },
  {
    path: 'BillMisc',
    component: MiscBillActivityComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Billing'] }
  },
  {
    path: 'Payment',
    component: SettlementComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Billing'] }
  },
  {
    path: 'Settlements',
    component: SettlementListComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Billing', 'Incharge'] }
  },
  {
    path: 'ProductCatagory',
    component: ProductCatagoryComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['SysAdmin'] }
  },
  {
    path: 'NewProduct',
    component: NewProductComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Incharge'] }
  },
  {
    path: 'ProductRate',
    component: ProductRateComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Billing'] }
  },
  {
    path: 'ProductBulkRate',
    component: ProductBulkRateComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Billing'] }
  },
  {
    path: 'InchargeDashboard',
    component: InchargeDashboardComponent,
    canActivate: [AuthGuard],
    data: { expectedRoles: ['Incharge'] }
  },
  {
    path: 'Reports', loadChildren: './reports/reports.module#ReportsModule'
  },
  {
    path: 'NotFound', component: NotFoundComponent
  } /* ,
  {
    path: '**', redirectTo: '/NotFound'
  }*/
];

@NgModule({
  declarations: [
    DashboardComponent,
    BillingComponent,
    PurchaseComponent,
    ShopQuotaComponent,
    ProductRateComponent,
    ProductBulkRateComponent,
    NewBillingComponent,
    NewPurchaseComponent,
    ViewPurchaseComponent,
    MonthlyReportComponent,
    GstReportComponent,
    SalesChartComponent,
    SaleDailyChartComponent,
    SalePurchaseChartComponent,
    SettlementComponent,
    QuotaBalanceComponent,
    MiscBillActivityComponent,
    MessageBoxComponent,
    QuotaMasterComponent,
    BillingStatusComponent,
    BoatInfoComponent,
    ConfirmBoatComponent,
    BoatCustomerListComponent,
    BoatListComponent,
    BillPaymentComponent,
    BoatQuotaComponent,
    StockHistoryComponent,
    StockReportComponent,
    ShopSelectionComponent,
    DenominationPageComponent,
    CashSalesComponent,
    PaymentCommentComponent,
    SettlementListComponent,
    ExpenseComponent,
    EditExpenseComponent,
    AuditEntryComponent,
    BillPrintComponent,
    SingleBillPrintComponent,
    EditBoatCustomerComponent,
    BankReceiptsComponent,
    MiscBankLedgerEntryComponent,
    BankLedgerComponent,
    UserComponent,
    EditShopComponent,
    ShopComponent,
    UpdateProfileComponent,
    ShopProductComponent,
    NewUserComponent,
    UserProfileComponent,
    BumpListComponent,
    ClearShopComponent,
    NozzleListComponent,
    EditNozzleComponent,
    EditBumpComponent,
    EditBoatComponent,
    EditQuotaComponent,
    UserTaskComponent,
    EditTaskComponent,
    TaskActionComponent,
    PdfViewComponent,
    NewProductComponent,
    AdjustmentComponent,
    NewAdjustmentComponent,
    StktransferComponent,
    NewStktransferComponent,
    NewServiceProviderComponent,
    CurrentStockComponent,
    BankAccountComponent,
    EditBankAccountComponent,
    BillReturnComponent,
    SupplierComponent,
    EditSupplierComponent,
    DirectCustomerComponent,
    EditDirectCustomerComponent,
    ShopCategoryComponent,
    UserShopComponent,
    SupplierShopComponent,
    UserSessionsComponent,
    ExceptionComponent,
    IndianCurrencyPipe,
    IndianNumberPipe,
    ResoldDetailComponent,
    InformationBoxComponent,
    ResaleAllPendingComponent,
    ShopCodeAccountFilter,
    InchargeConsolidatedComponent,
    ProductMasterComponent,
    ProductCategoryAssociationComponent,
    ProductCatagoryComponent,
    EditProductComponent,
    DepositEntryConfirmComponent,
    SingleFileUploadComponent,
    BulkCustomerListPreviewComponent,
    BulkBoatBillHistoryComponent,
    CustomPropertyComponent,
    EditCustomPropertyComponent,
    AcceptAmountComponent,
    TempLedgerEntryComponent,
    InchargeDashboardComponent,
    ShopDocmentUploadComponent,
    ShopDocmentFilesComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes),
    TranslateModule.forRoot(),
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTabsModule,
    MatChipsModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatInputModule,
    NgxPrintModule,
    MatSnackBarModule,
    MatRadioModule,
    MatAutocompleteModule,
    NgbModule,
    MatListModule,
    MatDividerModule,
    NgxEchartsModule,
    /*
    .forRoot({
      echarts: () => import('echarts')
    }),
    */
    PdfViewerModule
  ],
  providers: [
    Util,
    UserSearchService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FdcInterceptor,
      multi: true
    },
    AuthGuard,
    WindowRef,
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS},
    {provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, useValue: {autoActiveFirstOption: true}}
  ],
  exports: [
    RouterModule,
    TranslateModule
  ],
  entryComponents: [NewBillingComponent, NewPurchaseComponent, ViewPurchaseComponent, MessageBoxComponent,
    ConfirmBoatComponent, BillPaymentComponent, ShopSelectionComponent, DenominationPageComponent, CashSalesComponent,
    PaymentCommentComponent, BillPrintComponent, SingleBillPrintComponent, BankReceiptsComponent, MiscBankLedgerEntryComponent,
    ShopProductComponent, NewUserComponent, UserProfileComponent, BumpListComponent, ClearShopComponent, NozzleListComponent, InformationBoxComponent,
    EditNozzleComponent, EditBumpComponent, EditExpenseComponent, EditBoatCustomerComponent, NewServiceProviderComponent,
    EditBoatComponent, EditQuotaComponent, EditTaskComponent, TaskActionComponent, PdfViewComponent, ResoldDetailComponent,
    CurrentStockComponent, EditBankAccountComponent, BillReturnComponent, EditSupplierComponent, UserSessionsComponent,
    EditDirectCustomerComponent, ShopCategoryComponent, ProductCategoryAssociationComponent, UserShopComponent, SupplierShopComponent,
    NewStktransferComponent, EditProductComponent, DepositEntryConfirmComponent, SingleFileUploadComponent,
    BulkCustomerListPreviewComponent, BulkBoatBillHistoryComponent, CustomPropertyComponent, EditCustomPropertyComponent,
    AcceptAmountComponent, TempLedgerEntryComponent,
    ShopDocmentUploadComponent,
    ShopDocmentFilesComponent
  ]
})
export class AppRoutingModule { }
