import { Component, OnInit, Inject } from '@angular/core';
import { ResoldHistory, ResalePending, ResoldSummary } from '../models/Poco';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Util } from '../models/Util';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-resold-detail',
  templateUrl: './resold-detail.component.html',
  styleUrls: ['./resold-detail.component.css']
})
export class ResoldDetailComponent implements OnInit {

  BillSummaryId: number;
  PendingStatus: ResalePending[];
  Summaries: ResoldSummary[];

  displayedColumns: string[] = ['ProductName', 'Purchased', 'Returned', 'Sold', 'Balance'];
  summaryColumns: string[] = ['EnteredAt', 'EnteredBy', 'Details'];

  constructor(private http: HttpClient, private util: Util, private dialogRef: MatDialogRef<ResoldDetailComponent>,
    @Inject(MAT_DIALOG_DATA) private data: number) {
    this.BillSummaryId = data;
  }

  ngOnInit() {
    this.loadResoldItems(this.BillSummaryId);
  }

  loadResoldItems(summaryId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<ResoldHistory>(
      this.util.GetServerUrl() + '/getresolditems/' + summaryId,
      { headers, observe: 'response' }).subscribe(res => {
        this.PendingStatus = res.body.PendingStatus;
        this.Summaries = res.body.Summaries;
      }
      );
  }

  doCancel() {
    this.dialogRef.close(false);
  }

}
