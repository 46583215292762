import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Moment } from 'moment';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';

@Component({
  selector: 'app-clear-shop',
  templateUrl: './clear-shop.component.html',
  styleUrls: ['./clear-shop.component.css']
})
export class ClearShopComponent implements OnInit {

  ShopId: number;
  clearDate: Moment;
  constructor(@Inject(MAT_DIALOG_DATA) data: number, private dialogRef: MatDialogRef<ClearShopComponent>, private util: Util,
    private dialog: MatDialog, private winref: WindowRef, private http: HttpClient) { 
      this.ShopId = data;
    }

  ngOnInit(): void {
  }

  doCancel() {
    this.dialogRef.close(false);
  }

  doClear() {

    if (this.clearDate == null || this.clearDate == undefined) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the active date to reset', null);  
      return;
    }

    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Do you want to clear the shop?', (x: number) => {
      if (x === 1) {
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });

        const obj = { ShopId: this.ShopId, ActiveOpenDate : this.clearDate.utcOffset(0, true).toDate() } ;
debugger;
        this.http.post(
          this.util.GetServerUrl() + '/clearShop', obj, { headers, observe: 'response' }).subscribe(res => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Cleared the shop data', () => {
              this.dialogRef.close(false);
            });
          }, err => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
          }
          );

      }
    });

  }

}
