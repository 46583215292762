import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpHeaders, HttpClient, HttpEventType } from '@angular/common/http';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';
import { BoatCustomer } from '../models/Poco';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-boat-customer',
  templateUrl: './edit-boat-customer.component.html',
  styleUrls: ['./edit-boat-customer.component.css'],
  providers: [WindowRef]
})
export class EditBoatCustomerComponent implements OnInit {

  BoatCustomerId: number;
  CustomerName: string;
  FatherOrHusbandName: string;
  Address: string;
  MobileNo: string;
  AadhaarNo: string;
  SocietyName: string;
  SocietyMemberNo: string;
  BankIfscCode: string;
  BankAccNo: string;
  PhotoFileUniqueId: string;

  SelecetdFile: File;

  // SelCustomer: Customer;

  constructor(private route: ActivatedRoute, private router: Router, private util: Util,
              private http: HttpClient, private dialog: MatDialog, private winref: WindowRef,
              @Inject(MAT_DIALOG_DATA) data: number, private dialogRef: MatDialogRef<EditBoatCustomerComponent>) {
                this.BoatCustomerId = data;
  }

  ngOnInit() {
    if (this.BoatCustomerId > 0) {
      this.loadCustomer(this.BoatCustomerId);
    }

  }

  loadCustomer(custmomerId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const dateInddMMyyyy = this.util.GetddMMMyyyyDate(new Date());

    this.http.get<BoatCustomer>(
      this.util.GetServerUrl() + '/boatcustomer/' + custmomerId,
      { headers, observe: 'response' }).subscribe(res => {
        const SelCustomer = res.body;
        this.BoatCustomerId = custmomerId;
        this.CustomerName = SelCustomer.CustomerName;
        this.FatherOrHusbandName = SelCustomer.FatherOrHusbandName;
        this.Address = SelCustomer.Address;
        this.MobileNo = SelCustomer.MobileNo;
        this.AadhaarNo = SelCustomer.AadhaarNo;
        this.SocietyName = SelCustomer.SocietyName;
        this.SocietyMemberNo = SelCustomer.SocietyMemberNo;
        this.BankIfscCode = SelCustomer.BankIfscCode;
        this.BankAccNo = SelCustomer.BankAccNo;
        this.PhotoFileUniqueId = SelCustomer.PhotoFileUniqueId;
      }
      );
  }

  Save() {
    if (this.CustomerName == null || this.CustomerName.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the customer name', null);
      return;
    }
    if (this.FatherOrHusbandName == null || this.FatherOrHusbandName.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the father/husband name', null);
      return;
    }
    if (this.Address == null || this.Address.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the address name', null);
      return;
    }
    if (this.MobileNo == null || this.MobileNo.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the mobile number', null);
      return;
    }

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const customer = new BoatCustomer();
    customer.CustomerName = this.CustomerName;
    customer.FatherOrHusbandName = this.FatherOrHusbandName;
    customer.Address = this.Address;
    customer.MobileNo = this.MobileNo;
    customer.AadhaarNo = this.AadhaarNo;
    customer.SocietyName = this.SocietyName;
    customer.SocietyMemberNo = this.SocietyMemberNo;
    customer.BankIfscCode = this.BankIfscCode;
    customer.BankAccNo = this.BankAccNo;
    if (this.BoatCustomerId > 0) {
      customer.BoatCustomerId = this.BoatCustomerId;
      this.http.post(
        this.util.GetServerUrl() + '/updateboatcustomer', customer, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.dialogRef.close(true);
          });
        },
          err => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
          }

        );
    } else {
      this.http.post(
        this.util.GetServerUrl() + '/createboatcustomer', customer, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.dialogRef.close(true);
          });
        }, err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
        );
    }
  }

  Cancel() {
    this.dialogRef.close(false);
  }

  getFileName(guid: string) {
    if (guid == null) {
      guid = '0';
    }
    return this.util.GetServerUrl() + '/download/' + guid + '?noimage=Customer';
  }

  SelectImage(fileObj: HTMLElement, evnt: any) {
    fileObj.click();
  }

  uploadFile(evnt: any) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      token
    });
    headers.set('Content-Type', undefined);
    // this.SelecetdFile = evnt.target.files[0];
    const fileObj = evnt.target;
    const files = fileObj.files;
    const uploadFormData = new FormData();
    uploadFormData.append('file', files[0], files[0].name);
    this.http.post(this.util.GetServerUrl() + '/customerPhoto/' + this.BoatCustomerId,
      uploadFormData, { headers, reportProgress: true, observe: 'events' }).subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            const progress = Math.round(100 * event.loaded / event.total);
          } else if (event.type === HttpEventType.Response) {
            const message = 'Upload success.';
            this.PhotoFileUniqueId = event.body as string;
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Updated', x => { });
          }
        }
      );
  }
}
