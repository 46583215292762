import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Util } from '../models/Util';
import { Role, ShopShortInfo, UserSummary, UserShop } from '../models/Poco';
import { WindowRef } from '../service/window-ref';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css'],
  providers: [WindowRef]
})
export class NewUserComponent implements OnInit {

  AvailableRoles: Role[];
  ActiveShops: ShopShortInfo[];

  UserName: string;
  FullName: string;
  SelRole: Role;
  Email: string;
  MobileNo: string;

  constructor(private dialogRef: MatDialogRef<NewUserComponent>, private http: HttpClient,
              private util: Util, private dialog: MatDialog, private winref: WindowRef) { }

  ngOnInit() {
    this.loadRoles();
    this.loadShops();
  }

  doSave() {

    if (this.UserName == null || this.UserName.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the user\'s login name', null);
      return;
    }
    if (this.FullName == null || this.FullName.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the name of the user', null);
      return;
    }
    if (this.SelRole == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the role', null);
      return;
    }

    const selectedShops = this.ActiveShops.filter(x => x.IsSelected).map(x => new UserShop(x.ShopId, x.ShopName));

    if (this.SelRole.RoleName === 'Billing') {
      if (selectedShops.length === 0) {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the shop', null);
        return;
      }
      if (selectedShops.length > 1) {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select only one shop', null);
        return;
      }
    }

    if (this.SelRole.RoleName === 'Incharge') {
      if (selectedShops.length === 0) {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the shop', null);
        return;
      }
    }

    if (this.SelRole.RoleName === 'Mgmt' || this.SelRole.RoleName === 'SysAdmin' || this.SelRole.RoleName === 'Advisor') {
      if (selectedShops.length > 0) {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Do not select the shop', null);
        return;
      }
    }

    if (this.Email == null || this.Email.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the user\'s email address', null);
      return;
    }

    if (this.MobileNo == null || this.MobileNo.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the user\'s mobile number', null);
      return;
    }

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const user = new UserSummary();
    user.UserName = this.UserName;
    user.FullName = this.FullName;
    if (this.SelRole == null) {
      return;
    }
    user.RoleId = this.SelRole.RoleId;
    user.EmailId = this.Email;
    user.MobileNo = this.MobileNo;

    user.UserShops = selectedShops;

    this.http.post(
      this.util.GetServerUrl() + '/adduser', user, { headers, observe: 'response' }).subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
          this.dialogRef.close(true);
        });
      }, err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
      }
      );
  }

  doCancel() {
    this.dialogRef.close(false);
  }

  loadRoles() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<Role[]>(
      this.util.GetServerUrl() + '/allroles', { headers, observe: 'response' }).subscribe(res => {
        this.AvailableRoles = res.body;
      }
      );
  }

  loadShops() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ShopShortInfo[]>(
      this.util.GetServerUrl() + '/activeshops', { headers, observe: 'response' }).subscribe(res => {
        this.ActiveShops = res.body;
      }
      );
  }
}
