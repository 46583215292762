import { Component, OnInit, Inject } from '@angular/core';
import { SettlementReceipt, SettlementConfirmation } from '../models/Poco';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-bank-receipts',
  templateUrl: './bank-receipts.component.html',
  styleUrls: ['./bank-receipts.component.css'],
  providers: [WindowRef]
})
export class BankReceiptsComponent implements OnInit {

  CashbookSummaryId: number;
  bankReceipts: SettlementReceipt[] = [];
  IsConfirmed = false;
  displayedColumns: string[];
  Description: string;
  Amount: number;

  constructor(private dialogRef: MatDialogRef<BankReceiptsComponent>, @Inject(MAT_DIALOG_DATA) data,
    private http: HttpClient, private util: Util, private dialog: MatDialog,
    private winref: WindowRef, private router: Router) {
    const settleConf = data as SettlementConfirmation;
    this.CashbookSummaryId = settleConf.CashbookSummaryId;
    this.IsConfirmed = settleConf.IsConfirmed;
    this.Amount = settleConf.Amount;
  }

  ngOnInit() {
    if (this.IsConfirmed) {
      this.displayedColumns = ['UploadDate', 'FileName', 'Description', 'UploadBy'];
    } else {
      this.displayedColumns = ['UploadDate', 'FileName', 'Description', 'UploadBy', 'Action'];
    }

    this.loadReceipts();
  }

  newreceipt() {

  }

  cancelReceipt(receiptId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.post(
      this.util.GetServerUrl() + '/cancelsettlementreceipt/' + receiptId, null,
      { headers, observe: 'response' }).subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Removed', x => {
          this.loadReceipts();
        });
      },
        err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
      );
  }

  SaveReceipt(fileObj: HTMLInputElement) {
    const files = fileObj.files;
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      token
    });
    headers['Content-Type'] = undefined;
    const uploadFormData = new FormData();

    if (files == null || files.length <= 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the file', null);
      return;
    }

    if (this.Description == null || this.Description.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the description', null);
      return;
    }

    uploadFormData.append('description', this.Description);
    uploadFormData.append('file', files[0], files[0].name);
    this.http.post(this.util.GetServerUrl() + '/newsettlementreceipt/' + this.CashbookSummaryId, uploadFormData,
      { headers, reportProgress: true, observe: 'events' }).subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            const progress = Math.round(100 * event.loaded / event.total);
          } else if (event.type === HttpEventType.Response) {
            const message = 'Upload success.';
            const result = event.body;
            this.Description = '';
            fileObj.value = '';
            this.loadReceipts();
          }
        });
  }

  loadReceipts() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<SettlementReceipt[]>(
      this.util.GetServerUrl() + '/bankReceipts/' + this.CashbookSummaryId, { headers, observe: 'response' }).subscribe(res => {
        const receipts = res.body;
        this.bankReceipts = receipts;
      }
      );
  }

  ConfirmReceipt() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    if (this.Amount > 0 && this.bankReceipts.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'No receipts uploaded', null);
      return;
    }

    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Have you completed uploading of scanned copy of receipts.?', opt => {
      if (opt === 1) {
        this.http.post(
          this.util.GetServerUrl() + '/confirmReceipt/' + this.CashbookSummaryId, null,
          { headers, observe: 'response' }).subscribe(res => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Submitted', x => {
              this.dialogRef.close();
              this.router.navigate(['../Settlements']);
            });
          },
            err => {
              this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
            }
          );
      }
    });
  }


  getFileName(guid: string) {
    return this.util.GetServerUrl() + '/download/' + guid;
  }

  Close() {
    this.dialogRef.close();
  }

}
