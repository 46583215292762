import { Component, OnInit, Inject } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';
import { BankIfscCode, LkType, DirectCustomer } from '../models/Poco';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-direct-customer',
  templateUrl: './edit-direct-customer.component.html',
  styleUrls: ['./edit-direct-customer.component.css'],
  providers: [WindowRef]
})
export class EditDirectCustomerComponent implements OnInit {

  DirectCustomerId: number;
  CustomerName: string;
  Address: string;
  MobileNo: string;
  IsReseller: boolean;
  ContactPerson: string;

  CustomerTypes: LkType[];
  SelCustomerType: LkType;
  IsOwn = false;

  constructor(private dialogRef: MatDialogRef<EditDirectCustomerComponent>, private http: HttpClient,
              @Inject(MAT_DIALOG_DATA) data: number, private dialog: MatDialog, private util: Util, private winref: WindowRef) {
    this.DirectCustomerId = data;
  }

  ngOnInit() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/AllCustomerTypes',
      { headers, observe: 'response' }).subscribe(res => {
        this.CustomerTypes = res.body;
        if (this.DirectCustomerId > 0) {
          this.ShowCustomer();
        }
      }
      );
  }

  ShowCustomer() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<DirectCustomer>(
      this.util.GetServerUrl() + '/getDirectCustomer/' + this.DirectCustomerId,
      { headers, observe: 'response' }).subscribe(res => {
        this.CustomerName = res.body.CustomerName;
        this.Address = res.body.Address;
        this.MobileNo = res.body.MobileNo;
        this.IsReseller = res.body.IsReseller;
        this.IsOwn = res.body.IsOwn;
        this.SelCustomerType = this.CustomerTypes.filter(x => x.TypeId === res.body.CustomerTypeId)[0];
        this.ContactPerson = res.body.ContactPerson;
      }
      );
  }

  doSave() {

    const directCustomer = new DirectCustomer();
    directCustomer.DirectCustomerId = this.DirectCustomerId;
    directCustomer.CustomerName = this.CustomerName;
    directCustomer.CustomerTypeId = this.SelCustomerType.TypeId;
    directCustomer.IsOwn = this.IsOwn;
    directCustomer.Address = this.Address;
    directCustomer.MobileNo = this.MobileNo;
    directCustomer.IsReseller = this.IsReseller;
    directCustomer.ContactPerson = this.ContactPerson;
    
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    if (this.DirectCustomerId > 0) {
      this.http.post(
        this.util.GetServerUrl() + '/updatdirectcustomer', directCustomer, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.dialogRef.close(true);
          });
        }
        );
    } else {
      this.http.post(
        this.util.GetServerUrl() + '/adddirectcustomer', directCustomer, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.dialogRef.close(true);
          });
        }, err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
        );
    }
  }

  doCancel() {
    this.dialogRef.close(false);
  }

}
