import { Quota, QuotaPaged } from '../models/Poco';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { QuotaService } from '../service/quota.service';

// https://blog.angular-university.io/angular-material-data-table/

export class QuotaDataSource implements DataSource<Quota> {

    private quotaSubject = new BehaviorSubject<QuotaPaged>(new QuotaPaged());
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    totalRecords = 0;

    constructor(private quotaService: QuotaService) { }

    connect(collectionViewer: CollectionViewer): Observable<Quota[]> {
        return this.quotaSubject.asObservable()
            .pipe(map((x) => {
                // console.log(this.totalRecords);
                this.totalRecords = x.TotalRecords;
                return x.Summary;
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.quotaSubject.complete();
        this.loadingSubject.complete();
    }

    loadQuota(pageIndex: number, pageSize: number, sortKey = 'FromDate', sortDir = 'asc') {
        this.loadingSubject.next(true);
        this.quotaService.listQuota(pageIndex, pageSize, sortKey, sortDir)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((quotaPaged: QuotaPaged) => {
                this.totalRecords = quotaPaged.TotalRecords;
                this.quotaSubject.next(quotaPaged);
            }
            );
    }
}
