import { Component, OnInit, Inject } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';
import { BankIfscCode, LkType, Supplier, Shop, MyShops, ShopShortInfo } from '../models/Poco';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-supplier-account',
  templateUrl: './edit-supplier.component.html',
  styleUrls: ['./edit-supplier.component.css'],
  providers: [WindowRef]
})
export class EditSupplierComponent implements OnInit {

  SupplierId: number;
  SupplierName: string;
  SupplierShortName: string;
  Address: string;
  ContactNo: string;
  SupplierGstNo: string;
  IsInternalSupplier: boolean;

  Shops: ShopShortInfo[];
  SelectedShops: ShopShortInfo[] = [];

  shops: any;

  constructor(private dialogRef: MatDialogRef<EditSupplierComponent>, private http: HttpClient,
              @Inject(MAT_DIALOG_DATA) data: number, private dialog: MatDialog, private util: Util, private winref: WindowRef) {
    this.SupplierId = data;
  }

  ngOnInit() {
    this.loadShops(true);
  }

  ShowSupplier() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<Supplier>(
      this.util.GetServerUrl() + '/getsupplier/' + this.SupplierId,
      { headers, observe: 'response' }).subscribe(res => {
        this.SupplierName = res.body.SupplierName;
        this.Address = res.body.Address;
        this.ContactNo = res.body.ContactNo;
        this.Address = res.body.Address;
        this.SupplierShortName = res.body.SupplierShortName;
        this.SupplierGstNo = res.body.SupplierGstNo;
      }
      );
  }

  loadShops(init: boolean) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    const p = new HttpParams()
      .set('context', 'AS'); // Add supplier
    this.http.get<ShopShortInfo[]>(
      this.util.GetServerUrl() + '/activeshops', { headers, observe: 'response', params: p }).subscribe(res => {
        this.Shops = res.body;
        if (init) {
          if (this.SupplierId > 0) {
            this.ShowSupplier();
          }
        }
      }
      );
  }

  doSave() {

    const supplier = new Supplier();
    supplier.SupplierId = this.SupplierId;
    supplier.SupplierName = this.SupplierName;
    supplier.Address = this.Address;
    supplier.ContactNo = this.ContactNo;
    supplier.Address = this.Address;
    supplier.SupplierShortName = this.SupplierShortName;
    supplier.SupplierGstNo = this.SupplierGstNo;
    supplier.ShopIds = this.SelectedShops.map(x => x.ShopId);

    if (supplier.ShopIds.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Atleast one shop should be selected', null);
      return;
    }

    supplier.IsInternalSupplier = this.IsInternalSupplier;

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    if (this.SupplierId > 0) {
      this.http.post(
        this.util.GetServerUrl() + '/updateSupplier', supplier, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.dialogRef.close(true);
          });
        }
        );
    } else {
      this.http.post(
        this.util.GetServerUrl() + '/addSupplier', supplier, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.dialogRef.close(true);
          });
        }, err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
        );
    }
  }

  doCancel() {
    this.dialogRef.close(false);
  }

}
