import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Moment } from 'moment';
//import * as moment from 'moment';
import { ShopDocument } from '../models/Poco';
import { Util } from '../models/Util';
import { ShopDocmentUploadComponent } from '../shop-docment-upload/shop-docment-upload.component';

@Component({
  selector: 'app-shop-docment-files',
  templateUrl: './shop-docment-files.component.html',
  styleUrls: ['./shop-docment-files.component.css']
})
export class ShopDocmentFilesComponent implements OnInit {

  ReferenceDate: Moment;
  Documents: ShopDocument[] = [];
  displayedColumns = ['FileName', 'Description', 'FileDate', 'Action'];
  constructor(private dialog: MatDialog, private http: HttpClient, private util: Util,
    private dialogRef: MatDialogRef<ShopDocmentFilesComponent>,
    @Inject(MAT_DIALOG_DATA) data: { RefDate: Moment } ) { 
      this.ReferenceDate = data.RefDate;
    }

  ngOnInit(): void {
    this.loadDocuments();
  }

  loadDocuments() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    const currentDateTime = new Date();
    const currentDate = new Date(currentDateTime.getFullYear(),currentDateTime.getMonth(), currentDateTime.getDate());
    const referenceDate = moment(currentDate);
    this.http.get<ShopDocument[]>(
      this.util.GetServerUrl() + '/ListShopDocument?sDate=' + referenceDate.format('MM/DD/YYYY'),
      { headers, observe: 'response' }).subscribe(res => {
        this.Documents = res.body;
      }
      );
  }

  AddShopDocument() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { RefDate: this.ReferenceDate, ParentShopDocumentId: null, FrequencyId: null };
    const dialogRef = this.dialog.open(ShopDocmentUploadComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadDocuments();
    }
    );
  }

  EditShopDocument(parentShopDocumentId: number, frequencyId: number){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { RefDate: this.ReferenceDate, ParentShopDocumentId: parentShopDocumentId, FrequencyId: frequencyId };
    const dialogRef = this.dialog.open(ShopDocmentUploadComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadDocuments();
    }
    );
  }

  doCancel() {
    this.dialogRef.close();
  }

}
