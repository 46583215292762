import { ShopSummary, ShopPaged, Supplier, SupplierPaged } from '../models/Poco';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { ShopService } from '../service/shop.service';
import { SupplierService } from '../service/supplier.service';

// https://blog.angular-university.io/angular-material-data-table/

export class SupplierDataSource implements DataSource<Supplier> {

    private shopSubject = new BehaviorSubject<SupplierPaged>(new SupplierPaged());
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    totalRecords = 0;

    constructor(private supplierService: SupplierService) { }

    connect(collectionViewer: CollectionViewer): Observable<Supplier[]> {
        return this.shopSubject.asObservable()
            .pipe(map((x) => {
                // console.log(this.totalRecords);
                this.totalRecords = x.TotalRecords; return x.Summary;
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.shopSubject.complete();
        this.loadingSubject.complete();
    }

    loadSuppliers(pageIndex: number, pageSize: number, sortKey = 'FromDate', sortDir = 'asc') {
        this.loadingSubject.next(true);
        this.supplierService.listSupplier(pageIndex, pageSize, sortKey, sortDir)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((supplierPaged: SupplierPaged) => {
                this.totalRecords = supplierPaged.TotalRecords;
                this.shopSubject.next(supplierPaged);
            }
            );
    }
}
