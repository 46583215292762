import { Component, OnInit, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { PumpNozzle } from '../models/Poco';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-nozzle',
  templateUrl: './edit-nozzle.component.html',
  styleUrls: ['./edit-nozzle.component.css'],
  providers: [WindowRef]
})
export class EditNozzleComponent implements OnInit {

  NozzleId: number;
  PumpId: number;
  NozzleNo: string;
  constructor(@Inject(MAT_DIALOG_DATA) data: PumpNozzle, private http: HttpClient, private util: Util,
              private dialogRef: MatDialogRef<EditNozzleComponent>, private dialog: MatDialog, private winref: WindowRef) {
    this.PumpId = data.ShopPumpId;
    this.NozzleId = data.PumpNozzleId;
    this.NozzleNo = data.NozzleNo;
  }

  ngOnInit() {
    if (this.NozzleId > 0) {
      this.showDetail(this.NozzleId);
    }
  }

  showDetail(nozzleId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<PumpNozzle>(
      this.util.GetServerUrl() + '/getnozzle/' + nozzleId, { headers, observe: 'response' })
      .subscribe(res => {
        this.NozzleNo = res.body.NozzleNo;
      }
      );
  }

  doSave() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    const pumpNozzle = new PumpNozzle();
    pumpNozzle.ShopPumpId = this.PumpId;
    pumpNozzle.PumpNozzleId = this.NozzleId;
    pumpNozzle.NozzleNo = this.NozzleNo;

    if (this.NozzleId > 0) {
      this.http.post<number>(
        this.util.GetServerUrl() + '/updatenozzle', pumpNozzle, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.dialogRef.close(true);
          });
        }
        );
    } else {
      this.http.post(
        this.util.GetServerUrl() + '/newnozzle', pumpNozzle, { headers, observe: 'response' }).subscribe(res => {
          const billSummaryId = res.body;
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.dialogRef.close(true);
          });
        }, err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
        );
    }
  }

  doCancel() {
    this.dialogRef.close(false);
  }

}
