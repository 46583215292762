import { Injectable } from '@angular/core';
import { SettlementPaged as CashbookEntryPaged } from '../models/Poco';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Util } from '../models/Util';

@Injectable()
export class SettlementService {

  constructor(private http: HttpClient, private util: Util) { }

  listSettlement(pageIndex: number, pageSize: number,  sortKey: string, sortDir: string): Observable<CashbookEntryPaged> {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    const p = new HttpParams().set('pageIndex', pageIndex + '').set('pageSize', pageSize + '')
    .set('sortKey', sortKey).set('sortDir', sortDir);

    return this.http.get<CashbookEntryPaged>(
      this.util.GetServerUrl() + '/getallcashbookentries', { headers, params: p, observe: 'response' })
      .pipe(
        map(res => res.body)
      );
    }
}
