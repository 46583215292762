import { Component, OnInit, Inject } from '@angular/core';
import { WindowRef } from '../service/window-ref';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Util } from '../models/Util';
import { Quota, LkType } from '../models/Poco';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-quota',
  templateUrl: './edit-quota.component.html',
  styleUrls: ['./edit-quota.component.css'],
  providers: [
    WindowRef
  ]
})
export class EditQuotaComponent implements OnInit {

  QuotaId: number;
  QuotaName: string;
  fromDate: Moment;
  toDate: Moment;
  IsActive = true;

  QuotaTypes: LkType[];
  SelQuotaType: LkType;

  constructor(@Inject(MAT_DIALOG_DATA) data: number, private http: HttpClient, private util: Util,
              private dialogRef: MatDialogRef<EditQuotaComponent>, private dialog: MatDialog,
              private winref: WindowRef) {
    this.QuotaId = data;
  }

  ngOnInit(): void {
    this.loadQuotaTypes(true);
  }

  loadQuotaTypes(loadDetail: boolean): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/allquotatypes',
      { headers, observe: 'response' }).subscribe(res => {
        this.QuotaTypes = res.body;
        if (loadDetail) {
          if (this.QuotaId > 0) {
            this.showDetail(this.QuotaId);
          }
        }
      }
      );
  }

  showDetail(quotaId: number): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<Quota>(
      this.util.GetServerUrl() + '/getquota/' + quotaId, { headers, observe: 'response' })
      .subscribe(res => {
        const quota = res.body as Quota;
        this.QuotaName = quota.QuotaName;
        this.SelQuotaType = this.QuotaTypes.filter(x => x.TypeId === quota.QuotaTypeId)[0];
        const validFrom = new Date(quota.ValidFrom);
        const validUpto = new Date(quota.ValidUpto);
        this.IsActive = quota.IsActive;
        this.fromDate = moment(validFrom);
        this.toDate = moment(validUpto);
      }
      );
  }

  SaveOrUpdate(): void {

    if (this.QuotaName == null || this.QuotaName.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the quota name', null);
      return;
    }

    if (this.fromDate == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the validity from date', null);
      return;
    }

    if (this.toDate == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the validity to date', null);
      return;
    }

    if (this.SelQuotaType == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the quota type', null);
      return;
    }
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const quota = new Quota(this.QuotaId, this.QuotaName, this.fromDate.utcOffset(0, true).toDate(),
      this.toDate.utcOffset(0, true).toDate(),
      this.SelQuotaType.TypeId, this.SelQuotaType.TypeName, this.IsActive);

    if (this.QuotaId > 0) {
      this.http.post(
        this.util.GetServerUrl() + '/updatequota', quota, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.dialogRef.close(true);
          });
        }, err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
        );
    } else {
      this.http.post(
        this.util.GetServerUrl() + '/addquota', quota, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.dialogRef.close(true);
          });
        }, err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
        );
    }
  }

  doCancel(): void {
    this.dialogRef.close(false);
  }

}
