import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BoatCustomer } from '../models/Poco';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Util } from '../models/Util';

@Injectable()
export class CustomerSearchService {

    constructor(private http: HttpClient, private util: Util) { }

    search(name: string): Observable<BoatCustomer[]> {
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        const p = new HttpParams()
            .set('customerName', name);

        return this.http.get<BoatCustomer[]>(this.util.GetServerUrl() + '/searchboatcustomer', { headers, params: p })
            .pipe(
                tap((response: BoatCustomer[]) => {
                    return response;
                })
            );
    }
}
