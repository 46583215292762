import { SalesPurchaseHistory, SalesPurchasePaged } from '../models/Poco';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { StockReportService } from '../service/stock-report.service';


// https://blog.angular-university.io/angular-material-data-table/

export class StockReportDataSource implements DataSource<SalesPurchaseHistory> {

    private billingSubject = new BehaviorSubject<SalesPurchasePaged>(new SalesPurchasePaged());
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    public totalRecords = 0;

    constructor(private stockReportService: StockReportService) { }

    connect(collectionViewer: CollectionViewer): Observable<SalesPurchaseHistory[]> {
        return this.billingSubject.asObservable()
            .pipe(map((x) => {
                console.log(this.totalRecords);
                this.totalRecords = x.TotalRecords; return x.Summary;
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.billingSubject.complete();
        this.loadingSubject.complete();
    }

    loadHistory(shopId: number, fromDate: Date, toDate: Date, productId: number, isSubsidy: boolean,
                pageIndex: number, pageSize: number) {
        this.loadingSubject.next(true);
        this.stockReportService.listHistory(shopId, fromDate, toDate, productId, isSubsidy, pageIndex, pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((salesPurchasePaged: SalesPurchasePaged) => {
                this.totalRecords = salesPurchasePaged.TotalRecords;
                this.billingSubject.next(salesPurchasePaged);
            }
            );
    }
}
