import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BulkBoatBillEntryRequest, BulkBoatBillEntryResponse, BulkUploadBoatShortInfo } from '../models/Poco';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';


@Component({
  selector: 'app-bulk-customer-list-preview',
  templateUrl: './bulk-customer-list-preview.component.html',
  styleUrls: ['./bulk-customer-list-preview.component.css']
})
export class BulkCustomerListPreviewComponent implements OnInit {
  List: any[];
  RefId?: number;
  dataType: string;
  displayedColumns: string[] =[];
  constructor(private http: HttpClient, private util: Util, private dialog: MatDialog,
    private dialogRef: MatDialogRef<BulkCustomerListPreviewComponent>, private winref: WindowRef,
    @Inject(MAT_DIALOG_DATA) data: { dataType: string, refId?: number, list: any[] }) {
      this.dataType = data.dataType;
      if ( data.dataType === 'BoatCustomer') {
        this.displayedColumns = ['CustomerId', 'CustomerName', 'PhoneNo', 'BoatRegNo', 'BoatType'];
        this.List = data.list;
        this.RefId = data.refId as number;
      } else if ( data.dataType === 'NewCustomerQty' || data.dataType === 'UpdCustomerQty') {
        this.displayedColumns = ['BoatRegNo', 'Qty', 'BoatType', 'CustomerId', 'CustomerName', 'PhoneNo'];
        this.List = data.list;
        this.RefId = data.refId as number;
      }
  }
  ngOnInit(): void {
  }

  doCancel() {
    this.dialogRef.close(null);
  }

  doProceed() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    if (this.dataType == 'NewCustomerQty') {

      const _lst = this.List as BulkBoatBillEntryResponse[];
      const lst =_lst.map(x=> this.ToRequest(x));
      this.dialogRef.close(lst);


    } else if (this.dataType == 'UpdCustomerQty') {

      //debugger;
      const _lst = this.List as BulkBoatBillEntryResponse[];
      const lst =_lst.map(x=> this.ToRequest(x));

      this.http.post<boolean>(this.util.GetServerUrl() + '/updatBoatBillQty/' + this.RefId, lst, { headers, observe: 'response' })
      .subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Updated', x => {
          this.dialogRef.close(lst);
        })
      }, err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
      });

    } else if (this.dataType == 'BoatCustomer') {

      this.http.post<boolean>(this.util.GetServerUrl() + '/updateNewCustomerData/' + this.RefId, this.List, { headers, observe: 'response' })
      .subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Updated', x => {
          this.dialogRef.close(true);
        })
      }, err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
      });

    } else {
      //debugger;
    }



    }

    ToRequest(resp: BulkBoatBillEntryResponse): BulkBoatBillEntryRequest {
      const obj = new BulkBoatBillEntryRequest();
      obj.RegNo = resp.BoatRegNo;
      obj.Qty = resp.Qty;
      return obj;
    }
}
