import { Component, OnInit } from '@angular/core';
import { DayAmount, PaymentModeAmount, PendingPayment, ShopNozzleQty } from '../models/Poco';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-cash-sales',
  templateUrl: './cash-sales.component.html',
  styleUrls: ['./cash-sales.component.css'],
  providers: [WindowRef]
})
export class CashSalesComponent implements OnInit {
  math = Math;
  constructor(private http: HttpClient, private util: Util,
              public dialogRef: MatDialogRef<CashSalesComponent>,
              private dialog: MatDialog, private winref: WindowRef) { }

  ShopPumpNozzleQty: ShopNozzleQty[];
  displayedColumns: string[] = ['PumpNo', 'NozzleNo', 'Qty'];
  StockViewed = false;

  CreditSaleAmt = 0;
  CreditSaleRecdAmt = 0;
  OnLineSaleAmt = 0;
  OnLineSaleRecdAmt = 0;
  OwnSaleAmt = 0;
  OwnSaleRecdAmt = 0;
  PayLaterSaleAmt = 0;
  PayLaterSaleRecdAmt = 0;
  PayOnSaleAmt = 0;
  PayOnSaleRecdAmt = 0;
  CashSaleAmt = 0;
  CashSaleRecdAmt = 0;
  DayAmounts: DayAmount[] = [];
  PaymentModeAmounts: PaymentModeAmount[];

  BillTotalAmt = 0;

  ngOnInit() {
    this.loadLastSavedBillPayments();
  }

  getTotal() {
    return this.PaymentModeAmounts == null || this.PaymentModeAmounts.length == 0 ? 0 : 
    this.PaymentModeAmounts.map(x=> x.Amount).reduce((x,y)=> (x ? parseFloat(x+'') : 0) + (y ? parseFloat(y+'') : 0));
  }

  closeBilling() {
    //debugger;
    if (this.ShopPumpNozzleQty) {
      for (const nzQty of this.ShopPumpNozzleQty) {
        if (nzQty.Qty == null) {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Please fill the qty for Pump nozzile "' +
            nzQty.NozzleNo + '"', null);
          return;
        }
      }
    }
    this.dialogRef.close(this.ShopPumpNozzleQty);
  }

  loadLastSavedBillPayments() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<PendingPayment>(
      this.util.GetServerUrl() + '/GetLastSavedBillPayments',
      { headers, observe: 'response' }).subscribe(res => {
        this.CreditSaleAmt = res.body.CreditSaleAmt;
        this.CreditSaleRecdAmt = res.body.CreditSaleRecdAmt;//
        this.OnLineSaleAmt = res.body.OnLineSaleAmt;
        this.OnLineSaleRecdAmt = res.body.OnLineSaleRecdAmt;
        this.OwnSaleAmt = res.body.OwnSaleAmt;
        this.OwnSaleRecdAmt = res.body.OwnSaleRecdAmt;
        this.PayLaterSaleAmt = res.body.PayLaterSaleAmt;
        this.PayLaterSaleRecdAmt = res.body.PayLaterSaleRecdAmt;
        this.PayOnSaleAmt = res.body.PayOnSaleAmt;
        this.PayOnSaleRecdAmt = res.body.PayOnSaleRecdAmt;
        this.CashSaleAmt = res.body.CashSaleAmt;
        this.CashSaleRecdAmt = res.body.CashSaleRecdAmt;
        this.PaymentModeAmounts = res.body.PaymentModeAmounts;
        this.BillTotalAmt = res.body.DayAmounts == null || res.body.DayAmounts.length == 0 ? 0 : res.body.DayAmounts.map(x=> x.SaleAmount).reduce((x,y)=> x+y);
        this.loadNozzles();
      }
      );
  }

  cancelClose() {
    this.dialogRef.close(null);
  }

  loadNozzles() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ShopNozzleQty[]>(
      this.util.GetServerUrl() + '/shopNozzles',
      { headers, observe: 'response' }).subscribe(res => {
        this.ShopPumpNozzleQty = res.body;
      }
      );
  }
  tabChanged(evnt: MatTabChangeEvent) {
    if (evnt.tab.textLabel === 'Current Stock') {
      this.StockViewed = true;
    }
  }

}
