import { Component, OnInit, Inject } from '@angular/core';
import { LkType, MiscEntry } from '../models/Poco';
import { WindowRef } from '../service/window-ref';
import { Util } from '../models/Util';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
  selector: 'app-misc-bank-ledger-entry',
  templateUrl: './misc-bank-ledger-entry.component.html',
  styleUrls: ['./misc-bank-ledger-entry.component.css'],
  providers: [WindowRef]
})
export class MiscBankLedgerEntryComponent implements OnInit {

  Amount: number;
  Description: string;
  BankAccountId: number;
  SelEntryType: LkType;
  LedgerEntryTypes: LkType[];
  TempLedgerEntryId?: number;
  ReferenceDate: Date;
  ActionDate: Moment;
  minDate?: Moment;
  constructor(private util: Util, private dialog: MatDialog, private winref: WindowRef,
    private http: HttpClient,
              public dialogRef: MatDialogRef<MiscBankLedgerEntryComponent>, @Inject(MAT_DIALOG_DATA) private data: MiscEntry) {
                this.BankAccountId = data.BankAccountId;
                this.Description = data.Description;
                this.Amount = data.Amount;
                this.TempLedgerEntryId = data.TempLedgerEntryId;
                debugger;
                if (data.ReferenceDate)
                {
                  this.minDate = moment(data.ReferenceDate);
                  this.ReferenceDate = data.ReferenceDate;
                }
                //const today = new Date();
                //const targetMinDate = new Date(today.getFullYear(),today.getMonth(), today.getDate());
                //targetMinDate.setDate(targetMinDate.getDate()-15);
                //if (data.)
                //this.minDate = moment(targetMinDate);
  }

  ngOnInit() {
    this.loadLedgerTypes(this.data.LedgerTypeId);
  }

  loadLedgerTypes(selLedgerTypeId?: number): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/AllLedgerTypes',
      { headers, observe: 'response' }).subscribe(res => {
        this.LedgerEntryTypes = res.body;
        if (selLedgerTypeId != null)
        {
          const filtered = res.body.filter(x=>x.TypeId==selLedgerTypeId);
          if (filtered.length > 0)
          {
            this.SelEntryType = filtered[0];
          }
        }
      }
      );
  }

  Save() {
    if (this.SelEntryType === null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select Ledger type', x => { });
      return;
    }
    if (this.Description === null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the description', x => { });
      return;
    }
    if (this.Amount === null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the amount', x => { });
      return;
    }
    const entry = new MiscEntry(this.BankAccountId, this.ActionDate.utcOffset(0, true).toDate(), this.Description, this.SelEntryType.TypeId, this.Amount, this.data.TempLedgerEntryId);
    this.dialogRef.close(entry);
  }

  Close() {
    this.dialogRef.close(null);
  }


}
