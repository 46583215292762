import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Product, ShopQuota, LkType, Quota, Shop,
  BoatQuotaFilter, BoatQuotaUpdate, MyShops } from '../models/Poco';
import { Util } from '../models/Util';
import { FormControl } from '@angular/forms';
import { QuotaService } from '../service/quota.service';
import { WindowRef } from '../service/window-ref';
import { MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-boat-quota',
  templateUrl: './boat-quota.component.html',
  styleUrls: ['./boat-quota.component.css'],
  providers: [
    WindowRef,
    QuotaService
  ]
})
export class BoatQuotaComponent implements OnInit {

  displayedColumns: string[] = ['ShopName', 'BoatRegNo',  'CustomerName', 'Qty'];

  monthDate: Date;
  quotas: Quota[];
  products: Product[];
  shops: Shop[];

  selectedQuota: Quota;
  selectedProduct: Product;
  selectedShop: Shop;

  quotaListFound = false;
  QuotaList: ShopQuota[];
  @ViewChild('details', { static: true }) details: MatTable<ShopQuota>;

  alertVisible = false;
  alertType = 'success';
  alertMessage = '';
  staticAlertClosed = false;

  constructor(private http: HttpClient, private util: Util, public dialog: MatDialog, private winref: WindowRef) { }

  ngOnInit() {
    this.loadQuota();
    this.loadProduct();
    this.loadShops();
  }

  loadQuota() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<Quota[]>(
      this.util.GetServerUrl() + '/GetAllActiveQuota', { headers, observe: 'response' }).subscribe(res => {
        this.quotas = res.body;
      }
      );
  }

  loadProduct() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<Product[]>(
      this.util.GetServerUrl() + '/allproducts', { headers, observe: 'response' }).subscribe(res => {
        this.products = res.body.filter(x => !x.IsDummy);
      }
      );
  }

  loadShops() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<MyShops>(
      this.util.GetServerUrl() + '/myshops', { headers, observe: 'response' }).subscribe(res => {
        this.shops = res.body.Shops;
      }
      );
  }

  loadQuotas(quota: Quota, product: Product, shop: Shop) {

    if (quota == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the quota', null);
      return;
    }
    if (product == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the product', null);
      return;
    }
    if (shop == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the shop', null);
      return;
    }

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<ShopQuota[]>(
      this.util.GetServerUrl() + '/getboatquota?QuotaId=' + quota.QuotaId + '&ShopId=' + shop.ShopId
      + '&ProductId=' + product.ProductId
      , { headers, observe: 'response' }).subscribe(res => {
        this.QuotaList = res.body;
        this.quotaListFound = (this.QuotaList.length > 0);
      }
      );
  }

  updateQuotas(quota: Quota, product: Product) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const update = new BoatQuotaUpdate(
      new BoatQuotaFilter(quota.QuotaId, product.ProductId),
      this.QuotaList);

    this.http.post(
      this.util.GetServerUrl() + '/updateboatquota', update, { headers, observe: 'response' }).subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'Ok', null, 'Updated', null);
      }
      );
  }
}
