import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { merge, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WindowRef } from '../service/window-ref';
import { Util } from '../models/Util';
import { ContextService } from '../service/context-change';
import { BankAccountService } from '../service/bank-account.service';
import { BankAccountDataSource } from '../datasource/bank-account.datasource';
import { EditBankAccountComponent } from '../edit-bank-account/edit-bank-account.component';
import { LkType } from '../models/Poco';

@Component({
  selector: 'app-bank-account-list',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.css'],
  providers: [BankAccountService]
})
export class BankAccountComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['IfscCode', 'AccountNumber', 'Description', 'Bank', 'Branch',
    'Address', 'Contact', 'City', 'District',
    'State', 'Action'];
  pageSize = 10;
  totalRecords: number;
  dataSource: BankAccountDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  contextSubscriber: Subscription;
  SelAccountForType: LkType;
  AccountForTypes: LkType[];

  constructor(private bankAccountService: BankAccountService, private contextChange: ContextService,
              private http: HttpClient, private util: Util,
              public router: Router, private dialog: MatDialog) {
    this.contextSubscriber = contextChange.getObservable().subscribe(x => {
      this.dataSource.loadBankAccounts(this.SelAccountForType.TypeId, 0, 10, 'BankAccountName', 'asc');
    });
  }

  ngOnInit(): void {
    this.dataSource = new BankAccountDataSource(this.bankAccountService);
    this.totalRecords = this.dataSource.totalRecords;
    this.loadAccountForTypes();
  }

  AccountForTypeChanged(curVal: any): void {
    this.paginator.pageIndex = 0;
    this.dataSource.loadBankAccounts(this.SelAccountForType.TypeId, this.paginator.pageIndex, this.paginator.pageSize);
  }

  ngAfterViewInit(): void {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadBankAccounts())
      )
      .subscribe();
  }

  loadBankAccounts(): void {
    this.dataSource.loadBankAccounts(this.SelAccountForType.TypeId, this.paginator.pageIndex, this.paginator.pageSize);
  }

  newBankAccount(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      accountForTypeId: this.SelAccountForType.TypeId,
      accountForTypeName: this.SelAccountForType.TypeName,
      bankAccountId: 0 };
    const dialogRef = this.dialog.open(EditBankAccountComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadBankAccounts();
    }
    );
  }

  editBankAccount(BankAccountId: number) {
    this.router.navigate(['../EditBankAccount/' + BankAccountId]);
  }


  loadAccountForTypes() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/accountfortypes',
      { headers, observe: 'response' }).subscribe(res => {
        this.AccountForTypes = res.body;
        if (this.AccountForTypes.length > 0) {
          this.SelAccountForType = this.AccountForTypes[0];
          this.dataSource.loadBankAccounts(this.SelAccountForType.TypeId, 0, 10, 'BankAccountName', 'asc');
        }
      }
      );
  }

}
