import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageBoxCall } from '../models/Poco';

@Component({
  selector: 'app-information-box',
  templateUrl: './information-box.component.html',
  styleUrls: ['./information-box.component.css']
})
export class InformationBoxComponent implements OnInit {

  MessageContent: string;
  Callback: (x: number) => void;
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogRef: MatDialogRef<InformationBoxComponent>) {
    this.MessageContent = data;
  }

  ngOnInit() {
    this.dialogRef.updatePosition({ top: '50px', right: '50px' });
    setInterval(() => {
      this.dialogRef.close();
    }, 1000);
  }
}
