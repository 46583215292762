import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Denomination, DepositEntry } from '../models/Poco';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';
import { DenominationPageComponent } from '../settlement/denomination-page/denomination-page.component';

@Component({
  selector: 'app-deposit-entry-confirm',
  templateUrl: './deposit-entry-confirm.component.html',
  styleUrls: ['./deposit-entry-confirm.component.css']
})
export class DepositEntryConfirmComponent implements OnInit {

  data: DepositEntry;
  denoDate: Moment;
  isDepositEntry: boolean;
  DepositForwardCompleted = false;
  ShopOpenCloseId: number;
  maxDate = moment(new Date()).utcOffset(0, true);
  constructor(private http: HttpClient, private util: Util, private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public _data: { isDepositEntry: boolean, data: DepositEntry, ShopOpenCloseId: number } ,
    private winref: WindowRef, private dialogRef: MatDialogRef<DepositEntryConfirmComponent>) {
      debugger;
      this.data = _data.data;
      this.ShopOpenCloseId = _data.ShopOpenCloseId;
      this.isDepositEntry = _data.isDepositEntry;
  }

  ngOnInit(): void {
  }

  proceed(isDepositEntry: boolean) {
    if (isDepositEntry)
      this.proceedDeposit();
    else 
      this.proceedFwd();
  }

  proceedDeposit() {
    const newtoken = sessionStorage.getItem('Token') ?? '';
    const newheaders = new HttpHeaders({
      'Content-Type': 'application/json',
      token: newtoken
    });

    const depositDate = new DepositEntry(this.ShopOpenCloseId, this.data.TransactionDate);
    depositDate.TransactionDate = this.data.TransactionDate;

    const amount = 
    (+(this.data.Denomination.Rs2000 === undefined ? 0 : this.data.Denomination.Rs2000 * 2000) +
                        +(this.data.Denomination.Rs1000 === undefined ? 0 : this.data.Denomination.Rs1000 * 1000)+
                        +(this.data.Denomination.Rs500 === undefined ? 0 : this.data.Denomination.Rs500 * 500)+
                        +(this.data.Denomination.Rs200 === undefined ? 0 : this.data.Denomination.Rs200 * 200)+
                        +(this.data.Denomination.Rs100 === undefined ? 0 : this.data.Denomination.Rs100 * 100)+
                        +(this.data.Denomination.Rs50 === undefined ? 0 : this.data.Denomination.Rs50 * 50)+
                        +(this.data.Denomination.Rs20 === undefined ? 0 : this.data.Denomination.Rs20 * 20)+
                        +(this.data.Denomination.Rs10 === undefined ? 0 : this.data.Denomination.Rs10 * 10)+
                        +(this.data.Denomination.Rs5 === undefined ? 0 : this.data.Denomination.Rs5 * 5)+
                        +(this.data.Denomination.Rs2 === undefined ? 0 : this.data.Denomination.Rs2 * 2)+
                        +(this.data.Denomination.Rs1 === undefined ? 0 : this.data.Denomination.Rs1 * 1));


    depositDate.Amount = amount;
    depositDate.CashbookDate = this.denoDate.utcOffset(0, true).toDate();
    depositDate.Denomination = this.data.Denomination;
    depositDate.IsCompleted = this.DepositForwardCompleted;
    debugger;
    this.http.post<boolean>(
      this.util.GetServerUrl() + '/MakeDepositEntry', depositDate,
      { headers: newheaders, observe: 'response' }).subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Updated', null);
        this.dialogRef.close(true);
      }, err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error, null);
      }
      );
  }
  
  proceedFwd() {
    debugger;
    const newtoken = sessionStorage.getItem('Token') ?? '';
    const newheaders = new HttpHeaders({
      'Content-Type': 'application/json',
      token: newtoken
    });

    const depositDate = new DepositEntry(this.ShopOpenCloseId, this.data.TransactionDate);
    depositDate.TransactionDate = this.data.TransactionDate;

    const amount = 
    (+(this.data.Denomination.Rs2000 === undefined ? 0 : this.data.Denomination.Rs2000 * 2000) +
                        +(this.data.Denomination.Rs1000 === undefined ? 0 : this.data.Denomination.Rs1000 * 1000)+
                        +(this.data.Denomination.Rs500 === undefined ? 0 : this.data.Denomination.Rs500 * 500)+
                        +(this.data.Denomination.Rs200 === undefined ? 0 : this.data.Denomination.Rs200 * 200)+
                        +(this.data.Denomination.Rs100 === undefined ? 0 : this.data.Denomination.Rs100 * 100)+
                        +(this.data.Denomination.Rs50 === undefined ? 0 : this.data.Denomination.Rs50 * 50)+
                        +(this.data.Denomination.Rs20 === undefined ? 0 : this.data.Denomination.Rs20 * 20)+
                        +(this.data.Denomination.Rs10 === undefined ? 0 : this.data.Denomination.Rs10 * 10)+
                        +(this.data.Denomination.Rs5 === undefined ? 0 : this.data.Denomination.Rs5 * 5)+
                        +(this.data.Denomination.Rs2 === undefined ? 0 : this.data.Denomination.Rs2 * 2)+
                        +(this.data.Denomination.Rs1 === undefined ? 0 : this.data.Denomination.Rs1 * 1));


    depositDate.Amount = amount;
    //depositDate.CashbookDate = this.denoDate.utcOffset(0, true).toDate();
    depositDate.IsCompleted = this.DepositForwardCompleted;

    this.http.post<boolean>(
      this.util.GetServerUrl() + '/FwdDepositEntry', depositDate,
      { headers: newheaders, observe: 'response' }).subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Updated', null);
        this.dialogRef.close(true);
      }, err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error, null);
      }
      );
  }

  cancel() {
    this.dialogRef.close(false);
  }

  showDenomination() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { Amount: Math.ceil(this.data.Amount), Denomination: this.data.Denomination, ReadOnly: false, MisMatchAllowed: true };
    const dialogRef = this.dialog.open(DenominationPageComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((x: Denomination)=> {
      if (x) {
        this.data.Denomination = x;
      }
    })
  }
}
