import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Moment } from 'moment';
import { PendingPaymentTransfer } from '../models/Poco';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';

@Component({
  selector: 'app-accept-amount',
  templateUrl: './accept-amount.component.html',
  styleUrls: ['./accept-amount.component.css']
})
export class AcceptAmountComponent implements OnInit {

  ShopOpenCloseId: number;
  TransactionDate: Date;
  displayedColumns = ['ColumnSelect','ForwardDate','ShopName','Amount'];
  PendingList: PendingPaymentTransfer[] = [];
  constructor(private dialogRef: MatDialogRef<AcceptAmountComponent>, private http: HttpClient,
    private util: Util, @Inject(MAT_DIALOG_DATA) data: { ShopOpenCloseId: number, TransactionDate: Date, PendingList: PendingPaymentTransfer[] },
    private winref: WindowRef, private dialog: MatDialog) {
    this.ShopOpenCloseId = data.ShopOpenCloseId;
    this.TransactionDate = data.TransactionDate;
    this.PendingList = data.PendingList;
  }

  ngOnInit(): void {
    
  }

  cancel() {
    this.dialogRef.close(false);
  }
  proceed() {
    const token = sessionStorage.getItem('Token') ?? '';
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        token
      });

    var postData = { ShopOpenCloseId: this.ShopOpenCloseId, PaymentTransferIds: this.PendingList.map(x=> x.PaymentTransferId) };

    this.http.post<boolean>(
      this.util.GetServerUrl() + '/ConfirmDepositFwd', postData,
      { headers, observe: 'response' })
      .subscribe(res => {
        this.dialogRef.close(true);
      }, err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
      });
    ;
  }
}
