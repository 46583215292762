import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { merge, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { WindowRef } from '../service/window-ref';
import { Util } from '../models/Util';
import { ContextService } from '../service/context-change';
import { DirectCustomerService } from '../service/direct-customer.service';
import { DirectCustomerDataSource } from '../datasource/direct-customer.datasource';
import { EditDirectCustomerComponent } from '../edit-direct-customer/edit-direct-customer.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-direct-customer',
  templateUrl: './direct-customer.component.html',
  styleUrls: ['./direct-customer.component.css'],
  providers: [DirectCustomerService]
})
export class DirectCustomerComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['CustomerName', 'Address', 'MobileNo', 'IsReseller', 'ContactPerson', 'Action'];
  pageSize = 10;
  totalRecords: number;
  dataSource: DirectCustomerDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  contextSubscriber: Subscription;

  constructor(private directCustomerService: DirectCustomerService, private contextChange: ContextService,
              private http: HttpClient, private util: Util,
              public router: Router, private dialog: MatDialog, private winref: WindowRef) {
    this.contextSubscriber = contextChange.getObservable().subscribe(x => {
      this.dataSource.loadCustomers(0, 10, 'ShopName', 'asc');
    });
  }

  ngOnInit() {
    this.dataSource = new DirectCustomerDataSource(this.directCustomerService);
    this.totalRecords = this.dataSource.totalRecords;
    this.dataSource.loadCustomers(0, 10, 'ShopName', 'asc');
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadDirectCustomers())
      )
      .subscribe();
  }

  loadDirectCustomers() {
    this.dataSource.loadCustomers(this.paginator.pageIndex, this.paginator.pageSize);
  }

  newCustomer() {
    this.editCustomer(0);
  }

  editCustomer(customerId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = customerId;
    const dialogRef = this.dialog.open(EditDirectCustomerComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadDirectCustomers();
    }
    );
  }
}
