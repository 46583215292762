import { Injectable } from '@angular/core';
import { BillingPaged, SalesPurchasePaged, StockHistoryPaged } from '../models/Poco';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Util } from '../models/Util';

@Injectable()
export class StockHistoryService {

  constructor(private http: HttpClient, private util: Util) { }

  listHistory(shopId: number, fromDate: Date, toDate: Date, productId: number, quotaId: number, pageIndex: number,
              pageSize: number): Observable<StockHistoryPaged> {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    let p = new HttpParams();

    if (fromDate) {
      const date = ('0' + (fromDate.getMonth() + 1)).slice(-2) + '/' +
        ('0' + fromDate.getDate()).slice(-2) + '/' + ('0000' + fromDate.getFullYear()).slice(-4);
      p = p.set('FromDate', date + '');
    }
    if (fromDate) {
      const date = ('0' + (toDate.getMonth() + 1)).slice(-2) + '/' +
        ('0' + toDate.getDate()).slice(-2) + '/' + ('0000' + toDate.getFullYear()).slice(-4);
      p = p.set('ToDate', date + '');
    }

    p = p.set('pageIndex', pageIndex + '').set('pageSize', (pageSize ? pageSize : 10) + '');
    if (shopId) {
      p = p.set('ShopId', shopId + '');
    }

    if (productId != null && productId > 0) {
      p = p.set('ProductId', productId + '');
    }

    if (quotaId != null) {
      p = p.set('QuotaId', quotaId + '');
    }

    return this.http.get<StockHistoryPaged>(
      this.util.GetServerUrl() + '/GetStockHistory', { headers, params: p, observe: 'response' })
      .pipe(
        map(res => res.body)
      );
  }
}
