import { Injectable } from '@angular/core';
import { ExpensePaged, TaskPaged } from '../models/Poco';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Util } from '../models/Util';

@Injectable()
export class UserTaskService {

  constructor(private http: HttpClient, private util: Util) { }

  listTask(taskTypeId: number, taskStatusId: number, isMyTask: boolean,
           pageIndex: number, pageSize: number,  sortKey: string, sortDir: string): Observable<TaskPaged> {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    let p = new HttpParams().set('pageIndex', pageIndex + '').set('pageSize', pageSize + '')
    .set('sortKey', sortKey).set('sortDir', sortDir);
    if (taskTypeId != null && taskTypeId > 0) {
      p = p.set('taskTypeId', taskTypeId + '');
    }
    if (taskStatusId != null && taskStatusId > 0) {
      p = p.set('taskStatusId', taskStatusId + '');
    }
    p = p.set('isMyTask', isMyTask ? 'true' : 'false');

    return this.http.get<TaskPaged>(
      this.util.GetServerUrl() + '/mytasks', { headers, params: p, observe: 'response' })
      .pipe(
        map(res => res.body)
      );
    }
}
