import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { PageSetting, PaperSetting } from 'src/app/models/Poco';
import { Util } from 'src/app/models/Util';

@Component({
  selector: 'app-page-settings',
  templateUrl: './page-settings.component.html',
  styleUrls: ['./page-settings.component.css']
})
export class PageSettingsComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) private data: { settingName; string, pageSetting: PageSetting }, private util: Util,
    private dialogRef: MatDialogRef<PageSettingsComponent>) { }

  AllPageSettings: PaperSetting[] = [
    new PaperSetting('Letter', 8.5, 11, 'in'),
    new PaperSetting('Tabloid', 11, 17, 'in'),
    new PaperSetting('Legal', 8.5, 14, 'in'),
    new PaperSetting('Statement', 5.5, 8.5, 'in'),
    new PaperSetting('Executive', 7.25, 10.5, 'in'),
    new PaperSetting('A3', 11.69, 16.54, 'in'),
    new PaperSetting('A4', 8.27, 11.69, 'in'),
    new PaperSetting('A5', 5.83, 8.27, 'in'),
    new PaperSetting('A6', 4.13, 5.83, 'in'),
    new PaperSetting('215x330', 8.5, 13, 'in'),
    new PaperSetting('9x11', 9, 11, 'in'),
    new PaperSetting('B4', 10.12, 14.33, 'in'),
    new PaperSetting('5x7', 5, 7, 'in'),
    new PaperSetting('B6', 5.04, 7.17, 'in'),
    new PaperSetting('8x10', 8, 10, 'in'),
    new PaperSetting('Postcard', 4.5, 6, 'in'),
    new PaperSetting('Oversized A4', 8.78, 11.69, 'in'),
    new PaperSetting('Letter', 21.59, 27.94, 'cm'),
    new PaperSetting('Tabloid', 27.94, 43.18, 'cm'),
    new PaperSetting('Legal', 21.59, 35.56, 'cm'),
    new PaperSetting('Statement', 13.97, 21.59, 'cm'),
    //new PaperSetting('Executive', 7.25*2,54, 10.5, 'cm'),//
    new PaperSetting('Executive', 18.42, 26.67, 'cm'),
    new PaperSetting('A3', 29.7, 42, 'cm'),
    new PaperSetting('A4', 21, 29.69, 'cm'),
    new PaperSetting('A5', 14.8, 21, 'cm'),
    new PaperSetting('A6', 10.49, 14.81, 'cm'),
    new PaperSetting('215x330', 21.59, 33.02, 'cm'),
    new PaperSetting('9x11', 22.86, 27.94, 'cm'),
    new PaperSetting('B4', 25.7, 36.4, 'cm'),
    new PaperSetting('5x7', 12.7, 17.78, 'cm'),
    new PaperSetting('B6', 12.8, 18.21, 'cm'),
    new PaperSetting('8x10', 20.32, 25.4, 'cm'),
    new PaperSetting('Postcard', 11.43, 15.24, 'cm'),
    new PaperSetting('Oversized A4', 22.3, 29.69, 'cm'),
    new PaperSetting('Custom', null, null, null),
  ];

  PageUnit: string;
  PageSizes: PaperSetting[] = [];

  PageWidth: number;
  PageHeight: number;

  LeftMargin: number;
  RightMargin: number;
  TopMargin: number;
  BottomMargin: number;
  Orientation: string;

  SelPageSize: PaperSetting;

  ngOnInit(): void {
    this.PageUnit = 'cm';
    this.Orientation = 'portrait';
    this.PageSizes = this.AllPageSettings.filter(x => x.UnitType == null || x.UnitType === this.PageUnit);
    if (this.data != null) {
      this.PageUnit = this.data.pageSetting.UnitType;
      this.Orientation = this.data.pageSetting.Orientation;
      this.LeftMargin = this.data.pageSetting.Left;
      this.RightMargin = this.data.pageSetting.Right;
      this.TopMargin = this.data.pageSetting.Top;
      this.BottomMargin = this.data.pageSetting.Bottom;
      this.PageWidth = this.data.pageSetting.Width;
      this.PageHeight = this.data.pageSetting.Height;
      this.PageSizes = this.AllPageSettings.filter(x => x.SettingName == 'Custom' || x.UnitType == this.PageUnit);
      this.findPagerSize(this.data.pageSetting.Width, this.data.pageSetting.Height);
    }
  }

  setPagerSize() {
    this.findPagerSize(this.PageWidth, this.PageHeight);
  }

  findPagerSize(width: number, height: number): void {
    let page = this.PageSizes.filter(x => x.Width == width && x.Height == height);
    if (page.length > 0) {
      this.Orientation = 'portrait';
      this.SelPageSize = page[0];
    } else {
      page = this.PageSizes.filter(x => x.Height == width && x.Width == height);
      if (page.length > 0) {
        this.Orientation = 'landscape';
        this.SelPageSize = page[0];
      }
      else {
        this.Orientation = 'portrait';
        this.SelPageSize = this.PageSizes.filter(x => x.SettingName == 'Custom')[0];;
      }
    }
  }

  SaveOrUpdate(): void {
    const obj = new PageSetting(this.PageWidth, this.PageHeight, this.LeftMargin, this.RightMargin, this.TopMargin, this.BottomMargin, this.PageUnit, this.Orientation);
    if (this.data && this.data.settingName != null && this.data.settingName.length > 0) {
      localStorage.setItem(this.data.settingName, this.util.SerializePageSetting(this.data.settingName, obj));
    }
    this.dialogRef.close(obj);
  }

  doCancel(): void {
    this.dialogRef.close(null);
  }

  unitChanged(evnt: MatRadioChange): void {
    debugger;
    this.PageSizes = this.AllPageSettings.filter(x => x.SettingName == 'Custom' || x.UnitType == null || x.UnitType === evnt.value);
  }

  pageSizeChanged(change: MatSelectChange): void {
    debugger;
    const setting: PaperSetting = change.value as PaperSetting;
    if (this.PageUnit === 'in' && this.Orientation === 'portrait') {
      this.PageWidth = setting.Width;
      this.PageHeight = setting.Height;
    } else if (this.PageUnit === 'in' && this.Orientation === 'landscape') {
      this.PageWidth = setting.Height;
      this.PageHeight = setting.Width;
    } else if (this.PageUnit === 'cm' && this.Orientation === 'portrait') {
      this.PageWidth = setting.Width;
      this.PageHeight = setting.Height;
    } else if (this.PageUnit === 'cm' && this.Orientation === 'landscape') {
      this.PageWidth = setting.Height;
      this.PageHeight = setting.Width;
    }
  }

  OrientationChanged(evnt: MatButtonToggleChange): void {
    this.Orientation = evnt.value;
    const setting: PaperSetting = this.SelPageSize;
    if (this.PageUnit === 'in' && this.Orientation === 'portrait') {
      this.PageWidth = setting.Width;
      this.PageHeight = setting.Height;
    } else if (this.PageUnit === 'in' && this.Orientation === 'landscape') {
      this.PageWidth = setting.Height;
      this.PageHeight = setting.Width;
    } else if (this.PageUnit === 'cm' && this.Orientation === 'portrait') {
      this.PageWidth = setting.Width;
      this.PageHeight = setting.Height;
    } else if (this.PageUnit === 'cm' && this.Orientation === 'landscape') {
      this.PageWidth = setting.Height;
      this.PageHeight = setting.Width;
    }
  }

}

