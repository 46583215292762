import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { merge, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WindowRef } from '../service/window-ref';
import { Util } from '../models/Util';
import { ContextService } from '../service/context-change';
import { ShopService } from '../service/shop.service';
import { ShopDataSource } from '../datasource/shop.datasource';
import { ShopProductComponent } from '../shop-product/shop-product.component';
import { BumpListComponent } from '../bump-list/bump-list.component';
import { ClearShopComponent } from '../clear-shop/clear-shop.component';
import { ShopCategoryComponent } from '../shop-category/shop-category.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment';
import { BulkUploadBoatShortInfo, ClearDay } from '../models/Poco';
import { SingleFileUploadComponent } from '../single-file-upload/single-file-upload.component';
import { BulkCustomerListPreviewComponent } from '../bulk-customer-list-preview/bulk-customer-list-preview.component';

@Component({
  selector: 'app-shop-list',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css'],
  providers: [ShopService]
})
export class ShopComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['ShopName', 'ShopCode', 'BoatCustomerBill', 'DirectCustomerBill', 'BulkBill',
    'Address', 'ContactNo', 'GstNo', 'ShopUnitName', 'ActiveOpenDate', 'Action'];
  pageSize = 10;
  totalRecords: number;
  dataSource: ShopDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  contextSubscriber: Subscription;

  constructor(private shopService: ShopService, private contextChange: ContextService,
              private http: HttpClient, private util: Util,
              public router: Router, private dialog: MatDialog, private winref: WindowRef) {
    this.contextSubscriber = contextChange.getObservable().subscribe(x => {
      this.dataSource.loadShops(0, 10, 'ShopName', 'asc');
    });
  }

  ngOnInit(): void {
    this.dataSource = new ShopDataSource(this.shopService);
    this.totalRecords = this.dataSource.totalRecords;
    this.dataSource.loadShops(0, 10, 'ShopName', 'asc');
  }

  ngAfterViewInit(): void {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadShops())
      )
      .subscribe();
  }

  loadShops(): void {
    this.dataSource.loadShops(this.paginator.pageIndex, this.paginator.pageSize);
  }

  newShop(): void {
    this.router.navigate(['../EditShop/0']);
  }

  editShop(shopId: number): void {
    this.router.navigate(['../EditShop/' + shopId]);
  }

  clearLastday(shopId: number, activeDate: number): void {
    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Clear last day transactions', x => {
      if ( x === 1 ) {

        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });

        const dayClear = new ClearDay();
        dayClear.ShopId = shopId;
        dayClear.ClearDate = moment(activeDate).utcOffset(0, true).toDate();
        this.http.post(
          this.util.GetServerUrl() + '/clearBillDay', dayClear, { headers, observe: 'response' }).subscribe(res => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Done', y => {
              this.loadShops();
            });
          },
            err => {
              this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
            }
          );
      }
    });
  }

  bulkBoatCustomers(shopId: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { ShopId: shopId, Type: 'BoatCustomer' } ;
    const dialogRef = this.dialog.open(SingleFileUploadComponent, dialogConfig);
    dialogRef.afterClosed().subscribe( (result:BulkUploadBoatShortInfo[]) => {
      const _dialogConfig = new MatDialogConfig();
      _dialogConfig.disableClose = true;
      _dialogConfig.autoFocus = true;
      _dialogConfig.data = { dataType: 'BoatCustomer',  refId: shopId, list: result } ;
      const _dialogRef = this.dialog.open(BulkCustomerListPreviewComponent, _dialogConfig);
    });
  }

  shopProducts(shopId: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = shopId;
    const dialogRef = this.dialog.open(ShopProductComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // this.loadShops();
    }
    );
  }

  shopCategories(shopId: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = shopId;
    const dialogRef = this.dialog.open(ShopCategoryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // this.loadShops();
    }
    );
  }

  shopPumps(shopId: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = shopId;
    const dialogRef = this.dialog.open(BumpListComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadShops();
    }
    );
  }

  clearShop(shopId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = shopId;
    const dialogRef = this.dialog.open(ClearShopComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadShops();
    }
    );
  }

}
