import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import {
  Product, PurchaseDetail, PurchaseSummary, Purchase, ProductRate, QuotaShortInfo,
  OpenCloseStatusResponse, Supplier
} from '../models/Poco';

import { Util } from '../models/Util';
import { Router } from '@angular/router';
import { WindowRef } from '../service/window-ref';
import { EditExpenseComponent } from '../edit-expense/edit-expense.component';
import { MatTable } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
  templateUrl: './new-purchase.component.html',
  styleUrls: ['./new-purchase.component.css']
})
export class NewPurchaseComponent implements OnInit {

  PurchaseSummaryId: number;
  InvoiceNo: string;
  InvoiceAmount: number;

  billDate: Date;
  
  CarrierNo = '';
  Sgst = 0;
  Cgst = 0;
  Igst = 0;
  Vat = 0;
  Tcs = 0;
  PurchaseRate: any = null;
  Qty: any = null;
  Amount: any = null;
  PurchaseTaxFlag = false;

  TotalQty = 0;
  TotalAmount = 0;
  RoundOff = 0;

  suppliers: Supplier[];
  products: Product[];
  purchaseDetails: PurchaseDetail[] = new Array();
  purchOpenDate: Moment;
  purchaseDate: Moment;
  sPurchaseDate: string;
  Quotas: QuotaShortInfo[];

  SelProduct: Product;
  SellingRate: 0;
  SelSupplier: Supplier;
  SelQuota: QuotaShortInfo;

  displayedColumns: string[] = ['ProductName', 'Amount', 'Sgst', 'Cgst', 'Igst', 'Vat', 'Qty', 'NetAmount', 'Action'];
  totalColumns: string[] = ['ProductName', 'Qty', 'Amount'];

  @ViewChild('ctrlFromDate', { static: true }) ctrlFromDate: MatInput;
  @ViewChild('ctrlToDate', { static: true }) ctrlToDate: MatInput;
  @ViewChild('ctrlTotalAmount', { static: true }) ctrlTotalAmount: MatInput;
  @ViewChild('details', { static: true }) details: MatTable<PurchaseDetail>;

  constructor(private http: HttpClient, private util: Util, private messageDialog: MatDialog,
              private router: Router, private dialog: MatDialog, private winref: WindowRef) {
    this.PurchaseSummaryId = 0;
    this.PurchaseTaxFlag = sessionStorage.getItem('PurchaseTaxFlag') === '1';
  }

  loadPurchaseStatus(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const dateInddMMyyyy = this.util.GetddMMMyyyyDate(new Date());

    this.http.get<OpenCloseStatusResponse>(
      this.util.GetServerUrl() + '/GetOpenCloseStatus?StatusDate=' + dateInddMMyyyy,
      { headers, observe: 'response' }).subscribe(res => {
        this.purchOpenDate = moment(new Date(res.body.TransactionDate));
        this._ngOnInit();
      }
      );
  }

  ngOnInit(): void {
    this.loadPurchaseStatus();
  }

  _ngOnInit(): void {
    this.loadSupplierAccounts();
    this.loadProduct();
  }

  loadSupplierAccounts(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const p = new HttpParams()
      .set('context', 'P');

    this.http.get<Supplier[]>(
      this.util.GetServerUrl() + '/mysuppliers', { headers, params: p, observe: 'response' }).subscribe(res => {
        this.suppliers = res.body;
        const defaultSelection = this.suppliers.filter(x => x.IsSelected);
        if (defaultSelection.length > 0) {
          this.SelSupplier = defaultSelection[0];
        }
      }
      );
  }

  loadProduct(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const p = new HttpParams()
      .set('context', 'P');

    this.http.get<Product[]>(
      this.util.GetServerUrl() + '/allproducts', { headers, params: p, observe: 'response' }).subscribe(res => {
        this.products = res.body;
      }
      );
  }

  addPurchaseDetail(product: Product, purchaseAmount: number, qty: number,
                    sgst: number, cgst: number, igst: number, vat: number, tcs: number,amount: number,
                    sellingRate: number): void {

    if (product === undefined) {
      this.util.ShowMessageBox(this.messageDialog, this.winref, 'OK', null, 'Select the product', null);
      return;
    }
    if (this.SelProduct.IsConcessional && this.SelQuota == null) {
      this.util.ShowMessageBox(this.messageDialog, this.winref, 'OK', null, 'Select the Quota', null);
      return;
    }
    if (!this.SelProduct.IsConcessional && this.SelQuota != null) {
      this.util.ShowMessageBox(this.messageDialog, this.winref, 'OK', null, 'Quota is not allowed', null);
      return;
    }
    if (vat === undefined) {
      this.util.ShowMessageBox(this.messageDialog, this.winref, 'OK', null, 'Enter the VAT', null);
      return;
    }
    if (purchaseAmount === undefined) {
      this.util.ShowMessageBox(this.messageDialog, this.winref, 'OK', null, 'Enter the purchase amount', null);
      return;
    }
    if (qty === undefined) {
      this.util.ShowMessageBox(this.messageDialog, this.winref, 'OK', null, 'Enter the qty', null);
      return;
    }
    if (amount === undefined) {
      this.util.ShowMessageBox(this.messageDialog, this.winref, 'OK', null, 'Enter the amount', null);
      return;
    }
    if (product.IsDummy) {
      this.SellingRate = 0;
    }

    this.purchaseDetails.push(new PurchaseDetail(product.ProductId, product.ProductDisplayName,
      qty, sgst, cgst, igst, vat, tcs, amount, sellingRate,
      (this.SelQuota != null ? this.SelQuota.QuotaId : null), this.SelQuota != null ? this.SelQuota.QuotaName : null));
    this.SelProduct = null;
    this.Sgst = 0;
    this.Cgst = 0;
    this.Igst = 0;
    this.Vat = 0;
    this.Tcs = 0;
    this.Qty = null;
    this.Amount = null;
    this.SelQuota = null;
    this.calculateTotal();
    this.details.renderRows();
  }

  calculateTotal(): void {
    let totalQty = 0;
    let totalAmount = 0;

    for (const detail of this.purchaseDetails) {
      if (!isNaN(detail.Qty) && !isNaN(detail.Amount)) {
        totalQty = totalQty + 1 * detail.Qty;
        totalAmount = totalAmount + 1 * (
          (detail.Amount != null ? detail.Amount : 0)
          + (detail.Sgst != null ? detail.Sgst : 0)
          + (detail.Cgst != null ? detail.Cgst : 0)
          + (detail.Igst ? detail.Igst : 0)
          + (detail.Vat != null ? detail.Vat : 0)
          + (detail.Tcs != null ? detail.Tcs : 0));
      }
    }
    this.TotalQty = totalQty;
    this.TotalAmount = totalAmount;
    this.InvoiceAmount = totalAmount + this.RoundOff;
  }

  updatePurchaseRate(): void {
    if (this.Qty == null) {
      this.PurchaseRate = null;
    } else {
      this.PurchaseRate = ((this.Amount != null ? this.Amount : 0)
        + (this.Sgst != null ? this.Sgst : 0) + (this.Cgst != null ? this.Cgst : 0)
        + (this.Igst != null ? this.Igst : 0) + (this.Vat != null ? this.Vat : 0) + (this.Tcs != null ? this.Tcs : 0)) / this.Qty;
    }
  }

  loadPurchase(purchase: Purchase): void {
    this.purchaseDetails = purchase.PurchaseDetails;
    this.InvoiceNo = purchase.Summary.InvoiceNo;
    this.InvoiceAmount = purchase.Summary.InvoiceAmount;
  }

  loadPrice(curVal: any): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    if (this.SelProduct == null) {
      return;
    }

    const billDate = this.purchOpenDate.format('MM/DD/YYYY');

    this.http.get<ProductRate>(
      this.util.GetServerUrl() + '/getproductrate?ProductId=' + this.SelProduct.ProductId + '&RateDate=' + billDate
      + '&IsConcessional=' + (this.SelProduct.IsConcessional ? 'true' : 'false'),
      { headers, observe: 'response' }).subscribe(res => {
        const rate = res.body as ProductRate;
        if (rate == null) {
          this.Quotas = null;
        } else {
          this.Quotas = rate.Quotas;
        }
        if (this.SelProduct.IsConcessional) {
          if (rate == null || rate.Quotas != null && rate.Quotas.length === 0) {
          }
        }
      }
      );
  }

  doSave(carrierNo: string, invoiceNo: string, invoiceAmount: number, roundOff: number): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    if (this.purchaseDate === undefined || this.purchaseDate === null) {
      this.util.ShowMessageBox(this.messageDialog, this.winref, 'OK', null, 'Invoice date is Empty', null);
      return;
    }

    if (this.util.IsNullOrEmpty(invoiceNo)) {
      this.util.ShowMessageBox(this.messageDialog, this.winref, 'OK', null, 'Invoice no is Empty', null);
      return;
    }
    if ((this.TotalQty === undefined) || this.TotalQty <= 0) {
      this.util.ShowMessageBox(this.messageDialog, this.winref, 'OK', null, 'No items added', null);
      return;
    }

    const summary = new PurchaseSummary(this.purchaseDate.utcOffset(0, true).toDate(), carrierNo,
        invoiceNo, invoiceAmount, roundOff, this.SelSupplier.SupplierId);
    const purchase = new Purchase(summary, this.purchaseDetails);

    if (this.PurchaseSummaryId > 0) {
      this.http.post(
        this.util.GetServerUrl() + '/updatepurchase', purchase, { headers, observe: 'response' }).subscribe(res => {
          this.router.navigate(['../Purchase']);
        }
        );
    } else {
      this.http.post<number>(
        this.util.GetServerUrl() + '/makepurchase', purchase, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            const lanuchExpense = sessionStorage.getItem('PurchExpenseAssd') === '1';
            if (lanuchExpense) {
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
              dialogConfig.autoFocus = true;
              dialogConfig.data = { expenseId: 0, mode: 'purchase',
                purchase: { purchInvoiceNo: this.InvoiceNo, purchaseQty: this.TotalQty,
                  purchaseId: res.body, invoiceDate: purchase.Summary.PurchaseDate,
                  supplierName: this.SelSupplier.SupplierName } };
              dialogConfig.panelClass = 'full-width-dialog';
              const dialogRef = this.dialog.open(EditExpenseComponent, dialogConfig);
              dialogRef.afterClosed().subscribe(result => {
                this.router.navigate(['../Purchase']);
              });
            } else {
              this.router.navigate(['../Purchase']);
            }
          });
        },
          err => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
          }
        );
    }
  }

  removeItem(rowItem: PurchaseDetail): void {
    const idx = this.purchaseDetails.indexOf(rowItem);
    this.purchaseDetails.splice(idx, 1);
    this.calculateTotal();
    this.details.renderRows();
  }

  doCancel(): void {
    this.router.navigate(['../Purchase']);
  }

}
