import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { merge, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WindowRef } from '../service/window-ref';
import { Util } from '../models/Util';
import { ContextService } from '../service/context-change';
import { SupplierService } from '../service/supplier.service';
import { SupplierDataSource } from '../datasource/supplier.datasource';
import { EditSupplierComponent } from '../edit-supplier/edit-supplier.component';
import { EditBankAccountComponent } from '../edit-bank-account/edit-bank-account.component';
import { LkType } from '../models/Poco';
import { SupplierShopComponent } from '../supplier-shop/supplier-shop.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.css'],
  providers: [SupplierService]
})
export class SupplierComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['ShopName', 'SupplierName', 'SupplierShortName', 'Address', 'ContactNo', 'SupplierGstNo',
  'IfscCode', 'AccountNumber', 'Action'];
  pageSize = 10;
  totalRecords: number;
  dataSource: SupplierDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  contextSubscriber: Subscription;

  isMgmt = false;

  AccountForTypes: LkType[];

  constructor(private supplierService: SupplierService, private contextChange: ContextService,
              private http: HttpClient, private util: Util,
              public router: Router, private dialog: MatDialog, private winref: WindowRef) {
    this.contextSubscriber = contextChange.getObservable().subscribe(x => {
      this.dataSource.loadSuppliers(0, 10, 'ShopName', 'asc');
    });
  }

  ngOnInit() {
    const role = sessionStorage.getItem('Role');
    this.isMgmt = role === 'Mgmt';
    this.dataSource = new SupplierDataSource(this.supplierService);
    this.totalRecords = this.dataSource.totalRecords;
    this.loadAccountForTypes();
  }

  loadAccountForTypes() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/accountfortypes',
      { headers, observe: 'response' }).subscribe(res => {
        this.AccountForTypes = res.body;
        this.dataSource.loadSuppliers(0, 10, 'ShopName', 'asc');
      });
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadSuppliers())
      )
      .subscribe();
  }

  loadSuppliers() {
    this.dataSource.loadSuppliers(this.paginator.pageIndex, this.paginator.pageSize);
  }

  newSupplier() {
    this.editSupplier(0);
  }

  editSupplier(supplierId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = supplierId;
    const dialogRef = this.dialog.open(EditSupplierComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadSuppliers();
    }
    );
  }

  editShops(supplierId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = supplierId;
    const dialogRef = this.dialog.open(SupplierShopComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.loadSuppliers();
    }
    );
  }

  AssignBankAccount(shopSupplierId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    const accountType = this.AccountForTypes.filter(x => x.TypeName === 'SupplierAccount')[0];

    dialogConfig.data = {
      accountForTypeId: accountType.TypeId,
      accountForTypeName: accountType.TypeName,
      ShopSupplierId: shopSupplierId,
      bankAccountId: 0 };
    const dialogRef = this.dialog.open(EditBankAccountComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadSuppliers();
    }
    );
  }
}
