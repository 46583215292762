import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BoatService } from '../service/boat.service';
import { Util } from '../models/Util';
import { MyShops } from '../models/Poco';
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { BoatDataSource } from '../datasource/boat.datasource';
import { WindowRef } from '../service/window-ref';
import { ShopSelectionComponent } from '../shop-selection/shop-selection.component';
import { EditBoatCustomerComponent } from '../edit-boat-customer/edit-boat-customer.component';
import { EditBoatComponent } from '../edit-boat/edit-boat.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  templateUrl: './boat-list.component.html',
  styleUrls: ['./boat-list.component.css'],
  providers: [BoatService, WindowRef]
})
export class BoatListComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['BoatRegNo', 'BoatTypeName', 'ShopName', 'CustomerName', 'MobileNo', 'AadhaarNo',
    'BiometricIdNo', 'StatusDisplayName', 'Action'];
  BoatList = [];
  pageSize = 10;
  totalRecords: number;

  dataSource: BoatDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  constructor(private http: HttpClient, private boatService: BoatService, public dialog: MatDialog,
              private util: Util, private winref: WindowRef) {
  }

  ngOnInit() {
    this.dataSource = new BoatDataSource(this.boatService);
    this.totalRecords = this.dataSource.totalRecords;
    this.dataSource.loadBoat(0, 10, 'BoatRegNo', 'asc');
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadBoat())
      )
      .subscribe();
  }

  loadBoat() {
    this.dataSource.loadBoat(this.paginator.pageIndex, this.paginator.pageSize);
  }

  viewboat(boatId: number) {
  }

  printboat(boatId: number) {
  }

  editboat(boatId: number) {
  }

  changeowner(boatId: number) {
  }

  block(boatId: number) {
    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Are you sure?', (x: number) => {
      if (x === 1) {
        const inp = { BoatId: boatId };
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });
        this.http.post<boolean>(
          this.util.GetServerUrl() + '/blockboat', inp, { headers, observe: 'response' }).subscribe(res => {
            this.loadBoat();
          });
      }
    });
  }

  unblock(boatId: number) {
    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Are you sure?', (x: number) => {
      if (x === 1) {
        const inp = { BoatId: boatId };
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });
        this.http.post<boolean>(
          this.util.GetServerUrl() + '/unblockboat', inp, { headers, observe: 'response' }).subscribe(res => {
            this.loadBoat();
          });
      }
    });
  }

  transfer(boatId: number, shopId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<MyShops>(
      this.util.GetServerUrl() + '/myshops', { headers, observe: 'response' }).subscribe(res => {
        const dialogRef = this.dialog.open(ShopSelectionComponent, {
          width: '300px',
          height: '200px',
          data: res.body.Shops.filter(x => x.ShopId !== shopId)
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this._transfer(boatId, result);
          }
        });
      }
      );
  }

  _transfer(boatId: number, shopId: number) {
    const inp = { BoatId: boatId, ShopId: shopId };
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.post<boolean>(
      this.util.GetServerUrl() + '/transferboat', inp, { headers, observe: 'response' }).subscribe(res => {
        this.paginator.pageIndex = 0;
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Transfer initiated', x => { this.loadBoat(); } );
      });
  }

  getCustFileName(guid: string) {
    if (guid == null) {
      guid = '0';
    }
    return this.util.GetServerUrl() + '/download/' + guid + '?noimage=Customer&thumbnail=true';
  }

  acceptTransfer(boatId: number) {
    const inp = { BoatId: boatId };
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.post<boolean>(
      this.util.GetServerUrl() + '/accepttransfer', inp, { headers, observe: 'response' }).subscribe(res => {
        this.paginator.pageIndex = 0;
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Transfer Accepted', x => { this.loadBoat(); } );
      });
  }

  newBoat() {
    this.editBoat(0);
  }

  editBoat(summaryId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = summaryId;
    const dialogRef = this.dialog.open(EditBoatComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadBoat();
    }
    );
  }

  EditCustomer(summaryId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = summaryId;
    const dialogRef = this.dialog.open(EditBoatCustomerComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadBoat();
    }
    );
  }

  activatetransfered(boatId: number) {
    const inp = { BoatId: boatId };
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.post<boolean>(
      this.util.GetServerUrl() + '/activatetransfered', inp, { headers, observe: 'response' }).subscribe(res => {
        this.paginator.pageIndex = 0;
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Boat account activated', x => { this.loadBoat(); } );
      });
  }
}
