import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {
  BillDateAmount, AmountDetail, BillTotalAmount, SkipPayment, Denomination, PendingPayment,
  PaymentModeAmount,
  DayAmount
} from '../models/Poco';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';
import { PaymentCommentComponent } from '../payment-comment/payment-comment.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-settlement',
  templateUrl: './settlement.component.html',
  styleUrls: ['./settlement.component.css'],
  providers: [
    {
      provide: MAT_DIALOG_DATA, useValue: {}
    },
    {
      provide: MatDialogRef, useValue: {}
    },
    WindowRef
  ]
})
export class SettlementComponent implements OnInit {

  BillDate?: Date;
  CreditSaleAmt = 0;
  CreditSaleRecdAmt = 0;
  OnLineSaleAmt = 0;
  OnLineSaleRecdAmt = 0;
  OwnSaleAmt = 0;
  OwnSaleRecdAmt = 0;
  PayLaterSaleAmt = 0;
  PayLaterSaleRecdAmt = 0;
  PayOnSaleAmt = 0;
  PayOnSaleRecdAmt = 0;
  CashSaleAmt = 0;
  CashSaleRecdAmt = 0;
  DayAmounts: DayAmount[] = [];
  PaymentModeAmounts: PaymentModeAmount[];
  ReceivedAmount = 0;
  IsUpdated = false;

  AmountDenomination: Denomination;

  constructor(private http: HttpClient, private util: Util, private dialog: MatDialog, private winref: WindowRef) { }

  ngOnInit() {
    this.loadPendingBillPayments();
  }

  getPaymentTotal() {
    return (this.DayAmounts == null || this.DayAmounts.length == 0) ? 0 : this.DayAmounts.map(x=> x.SaleAmount).reduce((x,y)=> x+y);
  }

  loadPendingBillPayments() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<PendingPayment>(
      this.util.GetServerUrl() + '/GetPendingBillPayments',
      { headers, observe: 'response' }).subscribe(res => {
        this.BillDate = res.body.BillDate;
        this.CreditSaleAmt = res.body.CreditSaleAmt;
        this.CreditSaleRecdAmt = res.body.CreditSaleRecdAmt;//
        this.OnLineSaleAmt = res.body.OnLineSaleAmt;
        this.OnLineSaleRecdAmt = res.body.OnLineSaleRecdAmt;
        this.OwnSaleAmt = res.body.OwnSaleAmt;
        this.OwnSaleRecdAmt = res.body.OwnSaleRecdAmt;
        this.PayLaterSaleAmt = res.body.PayLaterSaleAmt;
        this.PayLaterSaleRecdAmt = res.body.PayLaterSaleRecdAmt;
        this.PayOnSaleAmt = res.body.PayOnSaleAmt;
        this.PayOnSaleRecdAmt = res.body.PayOnSaleRecdAmt;
        this.CashSaleAmt = res.body.CashSaleAmt;
        this.CashSaleRecdAmt = res.body.CashSaleRecdAmt;
        this.DayAmounts = res.body.DayAmounts;
        this.ReceivedAmount = res.body.DayAmounts == null || res.body.DayAmounts.length == 0 ? 0 : res.body.DayAmounts.map(x=> x.SaleAmount).reduce((x,y)=> x+y);
        this.PaymentModeAmounts = res.body.PaymentModeAmounts;
        this.IsUpdated = res.body.IsUpdated;
        this.PaymentModeAmounts.forEach(x=> {
          x.Amount = null;
        })
        this.AmountDenomination = new Denomination(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);//
      }
      );
  }
  getTotal() {
    return this.PaymentModeAmounts == null || this.PaymentModeAmounts.length == 0 ? 0 : this.PaymentModeAmounts.map(x=> x.Amount).reduce((x,y)=> (x ? parseFloat(x+'') : 0) + (y ? parseFloat(y+'') : 0));
  }

  savePendingBillPayments() {

    let mismatchFound = false;
    const totalAmount = this.getTotal();
    if (Math.round(this.ReceivedAmount - totalAmount) < -1) {
      mismatchFound = true;
    }

    if (mismatchFound) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Mismatch found', null);
      return;
    }
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    // const billDetailAmount = this.BillAmts;

    const billTotalAmount = new BillTotalAmount();
    billTotalAmount.BillDate = this.BillDate;
    billTotalAmount.PaymentModeAmounts = this.PaymentModeAmounts;
    this.http.post<boolean>(
      this.util.GetServerUrl() + '/SavePendingBillPayments', billTotalAmount,
      { headers, observe: 'response' }).subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Updated', _ => {
          this.IsUpdated = true;
          //this.loadPendingBillPayments();
        });
      }, err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error, null);
      }
      );
  }

  Changed(evnt: any) {
    console.log('Changed', evnt);
    this.loadPendingBillPayments();
  }

}
