import { ExpenseSummary, ExpensePaged } from '../models/Poco';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { ExpenseService } from '../Service/Expense.service';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';

// https://blog.angular-university.io/angular-material-data-table/

export class ExpenseDataSource implements DataSource<ExpenseSummary> {

    private ExpenseSubject = new BehaviorSubject<ExpensePaged>(new ExpensePaged());
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    totalRecords = 0;

    constructor(private expenseService: ExpenseService) { }

    connect(collectionViewer: CollectionViewer): Observable<ExpenseSummary[]> {
        return this.ExpenseSubject.asObservable()
            .pipe(map((x) => {
                // console.log(this.totalRecords);
                this.totalRecords = x.TotalRecords; return x.Summary;
            }));
    }

    subscribeForList(): Observable<ExpensePaged> {
        return this.ExpenseSubject.asObservable();
     }

    disconnect(collectionViewer: CollectionViewer): void {
        this.ExpenseSubject.complete();
        this.loadingSubject.complete();
    }

    loadExpense(statusId: number, shopId: number, pageIndex: number, pageSize: number, sortKey = 'CreatedAt', sortDir = 'asc') {
        this.loadingSubject.next(true);
        this.expenseService.listExpense(statusId, shopId, pageIndex, pageSize, sortKey, sortDir)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((expensePaged: ExpensePaged) => {
                this.totalRecords = expensePaged.TotalRecords;
                this.ExpenseSubject.next(expensePaged);
            }
            );
    }
}
