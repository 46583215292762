import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';
import { Boat, LkType, ShopShortInfo, BoatCustomer } from '../models/Poco';
import { debounceTime, switchMap } from 'rxjs/operators';
import { CustomerSearchService } from '../service/customer-search.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-boat',
  templateUrl: './edit-boat.component.html',
  styleUrls: ['./edit-boat.component.css'],
  providers: [WindowRef, CustomerSearchService]
})
export class EditBoatComponent implements OnInit {

  BoatId: number;
  SelBoatType: LkType;
  boatTypes: LkType[];
  ActiveShops: ShopShortInfo[];
  selectedShop: ShopShortInfo;
  SelectedCustomer: BoatCustomer;

  filter = new FormControl();
  filteredCustomers: Observable<BoatCustomer[]>;
  BoatRegNo: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: number, private http: HttpClient, private util: Util,
              private dialogRef: MatDialogRef<EditBoatComponent>, private winref: WindowRef,
              private dialog: MatDialog, private searchService: CustomerSearchService) {
    this.BoatId = data;
  }

  ngOnInit() {
    this.loadShops();

    this.filteredCustomers = this.filter.valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value =>
          this.searchService.search(value as string)
        )
      );
  }

  loadBoatType() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/AllBoatTypes',
      { headers, observe: 'response' }).subscribe(res => {
        this.boatTypes = res.body;
        if (this.BoatId > 0) {
          this.showDetail(this.BoatId);
        }
      }
      );
  }

  displayFn(cust: BoatCustomer) {
    if (cust) { return cust.CustomerName; }
    return null;
  }

  setCustomer(evnt: any) {
    this.SelectedCustomer = evnt.option.value;
  }

  getCustFileName(customer: BoatCustomer) {
    let guid = '0';
    if (customer == null || customer.PhotoFileUniqueId == null) {
      guid = '0';
    } else {
      guid = customer.PhotoFileUniqueId;
    }
    return this.util.GetServerUrl() + '/download/' + guid + '?noimage=Customer&thumbnail=false';
  }

  loadShops() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<ShopShortInfo[]>(
      this.util.GetServerUrl() + '/activeshops', { headers, observe: 'response' }).subscribe(res => {
        this.ActiveShops = res.body;
        this.loadBoatType();
      }
      );
  }

  showDetail(boatId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<Boat>(
      this.util.GetServerUrl() + '/boat/' + boatId, { headers, observe: 'response' })
      .subscribe(res => {
        const boat = res.body as Boat;
        this.BoatRegNo = boat.BoatRegNo;
        this.selectedShop = this.ActiveShops.filter(x => x.ShopId === boat.ShopId)[0];
        this.SelBoatType = this.boatTypes.filter(x => x.TypeId === boat.BoatTypeId)[0];
        const customer = new BoatCustomer();
        customer.BoatCustomerId = boat.BoatCustomerId;
        customer.CustomerName = boat.CustomerName;
        customer.PhotoFileUniqueId = boat.PhotoFileUniqueId;
        this.SelectedCustomer = customer;
      }
      );
  }

  SaveOrUpdate() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const boat = new Boat();
    boat.BoatId = this.BoatId;
    boat.ShopId = this.selectedShop.ShopId;
    boat.BoatCustomerId = this.SelectedCustomer.BoatCustomerId;
    boat.BoatTypeId = this.SelBoatType.TypeId;
    boat.BoatRegNo = this.BoatRegNo;
    if (this.BoatId > 0) {
      this.http.post(
        this.util.GetServerUrl() + '/updateboat', boat, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.dialogRef.close(true);
          });
        }
        );
    } else {
      this.http.post(
        this.util.GetServerUrl() + '/newboat', boat, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.dialogRef.close(true);
          });
        }, err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
        );
    }
  }

  doCancel() {
    this.dialogRef.close(false);
  }

}
