import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Shop } from '../models/Poco';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Util } from '../models/Util';
import { Moment } from 'moment';

declare var $: any;

@Component({
  templateUrl: './gst-report.component.html',
  styleUrls: ['./gst-report.component.css']
})
export class GstReportComponent implements OnInit {

  selectedShop: Shop;
  fromDate: Moment;
  toDate: Moment;
  reportUrl: string;
  showReport = false;
  shops: Shop[];
  reportInProgress = false;
  downloadUrlXls: string;
  downloadUrlDoc: string;
  downloadUrlPdf: string;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private util: Util) { }

  ngOnInit(): void {
    this.loadShop();
  }

  loadShop(): void  {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<any>(
      this.util.GetServerUrl() + '/myshops', { headers, observe: 'response' }).subscribe(res => {
        // console.log(res);
        this.shops = res.body.Shops;
      }
    );
  }


  loadReport(): void {
    const randomstring = new Date().toISOString();
    const selFromDate = this.fromDate.format('MM/DD/YYYY');
    const selToDate = this.toDate.format('MM/DD/YYYY');
    const url = this.util.GetServerUrl() + '/gstdetailreport?ShopIds=' + this.selectedShop.ShopId +
      '&FromDate=' + selFromDate + '&ToDate=' + selToDate + '&chk=' + randomstring;
    this.reportUrl = url;
    this.showReport = true;
    this.downloadUrlXls = this.downloadUrl('xls');
    this.downloadUrlDoc = this.downloadUrl('doc');
    this.downloadUrlPdf = this.downloadUrl('pdf');
    this.reportInProgress = true;
  }

  downloadUrl(downformat: string): string {
    const randomstring = new Date().toISOString();
    const selFromDate = this.fromDate.format('MM/DD/YYYY');
    const selToDate = this.toDate.format('MM/DD/YYYY');
    const url = this.util.GetServerUrl() + '/gstdetailreport?ShopIds=' + this.selectedShop.ShopId +
      '&FromDate=' + selFromDate + '&ToDate=' + selToDate + '&chk=' + randomstring + '&format=' + downformat;
    return url;
  }

  afterLoadComplete(pdfData: any): void {
    console.log(pdfData);
    this.reportInProgress = false;
    $('.ng2-pdf-viewer-container').height(300);
  }

}
