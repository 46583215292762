import { StktransferSummary, StktransferPaged } from '../models/Poco';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { StktransferService } from '../service/stktransfer.service';
import { Moment } from 'moment';

// https://blog.angular-university.io/angular-material-data-table/

export class StktransferDataSource implements DataSource<StktransferSummary> {

    private stktransferSubject = new BehaviorSubject<StktransferPaged>(new StktransferPaged());
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    totalRecords = 0;

    constructor(private stktransferService: StktransferService) { }

    connect(collectionViewer: CollectionViewer): Observable<StktransferSummary[]> {
        return this.stktransferSubject.asObservable()
            .pipe(map((x) => {
                this.totalRecords = x.TotalRecords; return x.Summary;
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.stktransferSubject.complete();
        this.loadingSubject.complete();
    }

    loadStktransfer(pageIndex: number, pageSize: number, sortKey: string, sortDir: string, pending: boolean, fromDate: Moment, toDate: Moment) {
        this.loadingSubject.next(true);
        this.stktransferService.listStktransfer(pageIndex, pageSize, sortKey, sortDir, pending, fromDate, toDate)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((stktransferPaged: StktransferPaged) => {
                this.totalRecords = stktransferPaged.TotalRecords;
                this.stktransferSubject.next(stktransferPaged);
            }
            );
    }
}
