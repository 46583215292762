import { Component, OnInit, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Util } from '../models/Util';
import { LkType, ExpenseSummary, ServiceProvider, ExpenseAction, Shop, ShopShortInfo, BankAccountShopInfo } from '../models/Poco';

import { Router } from '@angular/router';
import { WindowRef } from '../service/window-ref';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { GstNoSearchService } from '../service/gst-search.service';
import { NewServiceProviderComponent } from '../new-service-provider/new-service-provider.component';
import { GstAddInfoService } from '../service/gst-addinfo.service';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-expense',
  templateUrl: './edit-expense.component.html',
  styleUrls: ['./edit-expense.component.css'],
  providers: [
    WindowRef, GstNoSearchService, GstAddInfoService
  ]
})
export class EditExpenseComponent implements OnInit {
  ExpenseId: number;
  Mode = 'add';
  ExpenseTypes: LkType[];
  SelExpenseType: LkType;
  OtherExpenseType: string;
  IsOtherExpense: boolean;
  ExpenseAmount: number;
  invoiceDate: Moment;
  GstNumber: string;
  PanNumber: string;
  PartyName: string;
  Sgst = 0;
  Cgst = 0;
  Igst = 0;
  expenseDate: Moment;
  InvoiceNo: string;
  VoucherTypes: LkType[];
  SelVoucherType: LkType;
  VoucherNo: string;
  TDS = 0;
  TDS2 = 0;
  Qty: number;
  RatePerQty: number;
  RoundValue = 0;

  BankDetail: string;
  ChequeNo: string;
  ChequeClrDate?: Moment;
  FileNo: string;

  TargetShops: ShopShortInfo[];
  SelTargetShop: ShopShortInfo;

  AdditionalInfo: string;

  ListFlag = false;

  PurchaseSummaryId: number;
  SupplierName: string;

  filter = new FormControl();
  addnInfo = new FormControl();
  filteredServiceProviders: Observable<ServiceProvider[]>;
  filteredAddnInfo: Observable<string[]>;
  SelectedServiceProvider: ServiceProvider;

  CanRecordOnbehalf = false;

  loadForPurchase = false;

  BankAccounts: BankAccountShopInfo[];
  SelBankAccount: BankAccountShopInfo;

  constructor(@Inject(MAT_DIALOG_DATA) data: { expenseId: number, mode: string,
      purchase: { purchInvoiceNo: string, purchaseQty: number, purchaseId: number, invoiceDate: Date, supplierName: string } },
              private http: HttpClient, private util: Util, private dialog: MatDialog,
              private winref: WindowRef,
              private router: Router, private dialogRef: MatDialogRef<EditExpenseComponent>,
              private searchService: GstNoSearchService, private addnInfoService: GstAddInfoService) {
                this.CanRecordOnbehalf = sessionStorage.getItem('OthersExpense') == "1";
    this.ExpenseId = data.expenseId;
    this.Mode = data.mode;
    if (data.mode === 'purchase') {
      this.InvoiceNo = data.purchase.purchInvoiceNo;
      this.Qty = data.purchase.purchaseQty;
      this.PurchaseSummaryId = data.purchase.purchaseId;
      this.invoiceDate = moment(data.purchase.invoiceDate);
      this.expenseDate = moment(data.purchase.invoiceDate); // this.dateParser.formatDate(new Date());
      this.SupplierName = data.purchase.supplierName;
      this.loadForPurchase = true;
      // this.  LFRRent, BankVoucher
    }
  }

  ngOnInit(): void {
    this.loadMyBankAccounts();
  }

  _ngOnInit(): void {
    this.filteredServiceProviders = this.filter.valueChanges
      .pipe(
        debounceTime(800),
        switchMap(value =>
          this.searchService.search(value as string)
        )
      );

    this.filteredAddnInfo = this.addnInfo.valueChanges
      .pipe(
        debounceTime(800),
        switchMap(value =>
          this.addnInfoService.search(
            (this.SelExpenseType != null ? this.SelExpenseType.TypeId : null),
          value as string)
        )
      );

    if (this.CanRecordOnbehalf && this.Mode =='add') {
      this.loadShops();
    } else 
    {
      this.loadExpenseTypes();
    }
    this.ListFlag = this.ExpenseId === 0;
  }


  loadShops(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ShopShortInfo[]>(
      this.util.GetServerUrl() + '/activeshops', { headers, observe: 'response' }).subscribe(res => {
        this.TargetShops = res.body;
        this.loadExpenseTypes();
      }
      );
  }

  onSearchChange(evnt: any): void {
    // this.filter.setValue(null);
  }

  newGstAddition(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = 0;
    const dialogRef = this.dialog.open(NewServiceProviderComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    }
    );
  }
  autocompleteOpened(evnt: any): void {
    // this.filter.setValue(null);
  }

  clearSelection(): void {
    this.filter.setValue(null);
    this.SelectedServiceProvider = null;
    this.GstNumber = null;
    this.PanNumber = null;
  }

  loadMyBankAccounts() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<BankAccountShopInfo[]>(
      this.util.GetServerUrl() + '/mybankaccounts',
      { headers, observe: 'response' }).subscribe(res => {
        this.BankAccounts = res.body;
        this._ngOnInit();
      }
      );
  }

  loadExpenseTypes(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/myexpensetypes',
      { headers, observe: 'response' }).subscribe(res => {
        this.ExpenseTypes = res.body;

        if (this.loadForPurchase){
          this.SelExpenseType = this.ExpenseTypes.filter(x=> x.TypeName=='LFRRent')[0];
        } 

        this.loadVoucherTypes();
      }
      );
  }

  loadVoucherTypes(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/vouchertypes',
      { headers, observe: 'response' }).subscribe(res => {
        this.VoucherTypes = res.body;
        if (this.loadForPurchase){
          this.SelVoucherType = this.VoucherTypes.filter(x=> x.TypeName=='BankVoucher')[0];
          this.VoucherNo = "0";
          this.loadForPurchase = false;
        } 

        if (this.ExpenseId > 0) {
          this.showDetail(this.ExpenseId);
        }
      }
      );
  }

  displayFn(cust: ServiceProvider): string {
    if (cust) { return cust.PartyName; }
    return null;
  }

  setGstNo(evnt: any): void {
    this.SelectedServiceProvider = evnt.option.value;
    // this.PartyName = evnt.option.value.PartyName;
    this.GstNumber = evnt.option.value.GstNumber;
    this.PanNumber = evnt.option.value.PanNumber;
  }

  setAddnInfo(evnt: any): void {
    this.AdditionalInfo = evnt.option.value;
  }

  showDetail(expenseId: number): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ExpenseSummary>(
      this.util.GetServerUrl() + '/expenseid/' + expenseId, { headers, observe: 'response' })
      .subscribe(res => {
        const expense = res.body as ExpenseSummary;
        this.SelExpenseType = this.ExpenseTypes.filter(x => x.TypeId === expense.ExpenseTypeId)[0];
        this.ExpenseAmount = expense.Amount;
        this.expenseDate = moment(expense.ExpenseDate);
        if (expense.ServiceProviderId > 0) {
          const serviceProvider = new ServiceProvider();
          serviceProvider.ServiceProviderId = expense.ServiceProviderId;
          serviceProvider.GstNumber = expense.GstNumber;
          serviceProvider.PanNumber = expense.PanNumber;
          serviceProvider.PartyName = expense.PartyName;
          this.GstNumber = expense.GstNumber;
          this.PanNumber = expense.PanNumber;
          this.PartyName = expense.PartyName;
          this.filter.setValue(serviceProvider);
          this.SelectedServiceProvider = serviceProvider;
        } else {
          const serviceProvider = new ServiceProvider();
          serviceProvider.PartyName = expense.PartyName;
          this.filter.setValue(serviceProvider);
          this.SelectedServiceProvider = serviceProvider;
          // this.PartyName = expense.PartyName;
        }
        this.PurchaseSummaryId = expense.PurchaseSummaryId;
        this.SupplierName = expense.SupplierName;

        this.Sgst = expense.Sgst;
        this.Cgst = expense.Cgst;
        this.Igst = expense.Igst;
        if (expense.InvoiceDate != null) {
          this.invoiceDate = moment(expense.InvoiceDate);
        } else {
          this.invoiceDate = null;
        }
        this.InvoiceNo = expense.InvoiceNo;
        if (expense.VoucherTypeId != null)
          this.SelVoucherType = this.VoucherTypes.filter(x=> x.TypeId == expense.VoucherTypeId)[0] ;
        else 
          this.SelVoucherType = null;
        this.VoucherNo = expense.VoucherNo;
        this.TDS = expense.TDS;
        this.TDS2 = expense.TDS2;
        this.RoundValue = expense.RoundOff;
        this.Qty = expense.Qty;
        this.RatePerQty = expense.RatePerQty;
        this.AdditionalInfo = expense.AdditionalInfo;
        this.BankDetail = expense.BankDetail;
        this.ChequeNo = expense.ChequeNo;
        if (expense.InvoiceDate != null) {
          this.ChequeClrDate = moment(expense.ChequeClrDate);
        } else {
          this.ChequeClrDate = null;
        }
        this.FileNo = expense.FileNo;
        const shopShortInfo = new ShopShortInfo(expense.TargetShopId != null ?  expense.TargetShopId : expense.TargetShopId, expense.ShopName );
        this.TargetShops = [ shopShortInfo ];
        this.SelTargetShop =  shopShortInfo;
      }
      );
  }

  SaveOrUpdate(request: boolean): void {

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    if (this.expenseDate == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the date', x => { });
      return;
    }

    if (this.SelExpenseType == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the expense type', x => { });
      return;
    }

    if (this.ExpenseAmount == null || this.ExpenseAmount === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the amount', x => { });
      return;
    }

    if ( this.SupplierName == null || this.SupplierName.length === 0) {
      if (this.PartyName == null && this.SelectedServiceProvider == null) {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the Party name', x => { });
        return;
      }
    }

    if (this.AdditionalInfo == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the detail', x => { });
      return;
    }

    if ( this.SupplierName == null || this.SupplierName.length === 0) {
      if ((this.SelectedServiceProvider == null || this.SelectedServiceProvider.ServiceProviderId == null) &&
        (this.Sgst > 0 || this.Cgst > 0 || this.Igst > 0)) {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the GST No', x => { });
        return;
      }
    }


    if ( this.SelBankAccount == null && this.BankAccounts.length > 0) {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the Bank account', x => { });
        return;
    }

    const expense = new ExpenseSummary(this.SelExpenseType.TypeId, this.SelExpenseType.TypeDisplayName, this.expenseDate.utcOffset(0, true).toDate(),
      this.OtherExpenseType,
      this.ExpenseAmount,
      this.SelectedServiceProvider != null && this.SelectedServiceProvider.ServiceProviderId > 0
        ? this.SelectedServiceProvider.ServiceProviderId : null,
      this.SelectedServiceProvider != null && this.SelectedServiceProvider.ServiceProviderId > 0
        ? this.SelectedServiceProvider.GstNumber : null,
        this.SelectedServiceProvider != null && this.SelectedServiceProvider.ServiceProviderId > 0
        ? this.SelectedServiceProvider.PanNumber : null,
        this.PartyName,
      this.Sgst, this.Cgst, this.Igst, this.invoiceDate.utcOffset(0, true).toDate(),
      this.InvoiceNo, this.SelVoucherType ? this.SelVoucherType.TypeId : null, this.VoucherNo, this.TDS, this.TDS2, this.RoundValue, this.Qty, this.RatePerQty, this.AdditionalInfo,
      this.BankDetail, this.ChequeNo, this.ChequeClrDate == null ? null : this.ChequeClrDate.utcOffset(0, true).toDate(), this.FileNo,
      (this.Mode === 'add' && this.SelTargetShop != null) ? this.SelTargetShop.ShopId : null,
      this.SelBankAccount != null ? this.SelBankAccount.BankAccountId : null);
    expense.PurchaseSummaryId = this.PurchaseSummaryId;
    expense.ExpenseId = this.ExpenseId;
    if (this.ExpenseId > 0) {
      this.http.post(
        this.util.GetServerUrl() + '/updateexpense', expense, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            if (this.ListFlag) {
              // this.ExpenseList.push(expense);
              // this.details.renderRows();
              this.dialogRef.close(true);
            } else {
              this.dialogRef.close(true);
            }
          });
        }
        );
    } else {
      this.http.post<number>(
        this.util.GetServerUrl() + '/recordexpense', expense, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            expense.ExpenseId = res.body;
            this.dialogRef.close(true);
          });
        }, err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
        );
    }
  }

  Accept(): void {

    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Are you sure?', (x: number) => {
      if (x === 1) {
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });

        const expAction = new ExpenseAction(this.ExpenseId, 'Accept');

        this.http.post<number>(
          this.util.GetServerUrl() + '/expenseaction', expAction, { headers, observe: 'response' }).subscribe(res => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
              this.dialogRef.close(true);
            });
          }, err => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
          }
          );
      }
    }
    );
  }

  Refuse(): void {

    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Are you sure?', (x: number) => {
      if (x === 1) {
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });

        const expAction = new ExpenseAction(this.ExpenseId, 'Refuse');

        this.http.post<number>(
          this.util.GetServerUrl() + '/expenseaction', expAction, { headers, observe: 'response' }).subscribe(res => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
              this.dialogRef.close(true);
            });
          }, err => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
          }
          );
      }
    }
    );
  }

  doCancel(): void {
    this.dialogRef.close(false);
  }

  editExpense(expenseId: number): void {
    this.ResetScreen();
    this.ExpenseId = expenseId;
    this.showDetail(expenseId);
  }

  ResetScreen(): void {
    this.ExpenseId = 0;
    this.SelExpenseType = null;
    this.OtherExpenseType = null;
    this.IsOtherExpense = false;
    this.ExpenseAmount = null;
    this.expenseDate = null;
    this.SelectedServiceProvider = null;
    this.PartyName = null;
    this.GstNumber = null;
    this.PanNumber = null;
    this.Sgst = null;
    this.Cgst = null;
    this.Igst = null;
    this.AdditionalInfo = null;
  }
}
