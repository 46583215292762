import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { BarcodeFire } from './service/barcode-fire';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'FdcApp';
  AppVersion: string;
  constructor(private translate: TranslateService, private barcodefire: BarcodeFire,
              private route: Router) {
    this.AppVersion = environment.version;
    const lang = localStorage.getItem('Lang');
    if (lang !== null) {
      translate.setDefaultLang(lang);
    } else {
      translate.setDefaultLang('english');
    }
  }

  @HostListener('document:keydown', ['$event']) onKeyUp(evnt: KeyboardEvent): void {
    if (evnt.ctrlKey && (evnt.key === 'b' || evnt.key === 'B')) {
      this.barcodefire.Switched();
    }
  }

  switchLanguage(language: string): boolean {
    localStorage.setItem('Lang', language);
    this.translate.use(language);
    this.route.navigateByUrl(this.route.url);
    return false;
  }
}
