import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { tap, scan, catchError, retry } from 'rxjs/operators';
import { Util } from './models/Util';
import { RequestResponse } from './service/request-response';
import { WindowRef } from './service/window-ref';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class FdcInterceptor implements HttpInterceptor {
    constructor(private requestResponse: RequestResponse, private util: Util,
        private dialog: MatDialog, private winref: WindowRef,
        private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let langCode = localStorage.getItem('Lang');
        if (!langCode) {
            langCode = '';
        }

        // Clone the request to add the new header.
        const authReq = req.clone({ headers: req.headers.set('AppVersion', environment.version).set('AppLangId', langCode) });

        this.requestResponse.Requested();

        // send the newly created request
        return next.handle(authReq).pipe(
            // retry(1),
            catchError((err: HttpErrorResponse) => {
                this.requestResponse.Responded();
                if (err.status === 401) {
                    if (err.statusText === 'IncorrectUserIdOrPassword') {
                        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Not authorised. Try again!', x => {
                            this.router.navigate(['/']);
                            return;
                        });
                    } else {
                        document.location.href = '/';
                    }
                }
                if (err.error instanceof ErrorEvent) {
                } else {
                    // debugger;
                }
                return throwError(err); // of(err);
            }),
            tap(
                (resp: any) => {
                    if (resp instanceof HttpResponse) {
                        this.requestResponse.Responded();
                    } else if (resp instanceof HttpErrorResponse) {
                        // this.requestResponse.Responded();
                    } else {
                        // this.requestResponse.Responded();
                    }
                })
        );
    }
}
