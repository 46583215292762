import { AdjustmentSummary, AdjustmentPaged } from '../models/Poco';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { AdjustmentService } from '../service/adjustment.service';

// https://blog.angular-university.io/angular-material-data-table/

export class AdjustmentDataSource implements DataSource<AdjustmentSummary> {

    private adjustmentSubject = new BehaviorSubject<AdjustmentPaged>(new AdjustmentPaged());
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    totalRecords = 0;

    constructor(private adjustmentService: AdjustmentService) { }

    connect(collectionViewer: CollectionViewer): Observable<AdjustmentSummary[]> {
        return this.adjustmentSubject.asObservable()
            .pipe(map((x) => {
                this.totalRecords = x.TotalRecords; return x.Summary;
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.adjustmentSubject.complete();
        this.loadingSubject.complete();
    }

    loadAdjustment(pageIndex: number, pageSize: number, sortKey = 'FromDate', sortDir = 'asc') {
        this.loadingSubject.next(true);
        this.adjustmentService.listAdjustment(pageIndex, pageSize, sortKey, sortDir)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((adjustmentPaged: AdjustmentPaged) => {
                this.totalRecords = adjustmentPaged.TotalRecords;
                this.adjustmentSubject.next(adjustmentPaged);
            }
            );
    }
}
