import { Component, OnInit, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ShopProduct, ShopProductUpdate, UserSession } from '../models/Poco';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-sessions',
  templateUrl: './user-sessions.component.html',
  styleUrls: ['./user-sessions.component.css'],
  providers: [WindowRef]
})

export class UserSessionsComponent implements OnInit {

  UserId: number;
  UserSessions: UserSession[];

  displayedColumns: string[] = ['IpAddress', 'LoggedInTime', 'Action'];
  totalColumns: string[] = [];

  constructor(private dialogRef: MatDialogRef<UserSessionsComponent>, @Inject(MAT_DIALOG_DATA) data: any,
              private http: HttpClient, private util: Util, private dialog: MatDialog,
              private winref: WindowRef) {
    this.UserId = data as number;
  }

  ngOnInit() {
    this.loadUserSessions(this.UserId);
  }

  doCancel() {
    this.dialogRef.close(false);
  }

  loadUserSessions(userId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<UserSession[]>(
      this.util.GetServerUrl() + '/usersessions/' + userId , { headers, observe: 'response' })
      .subscribe(res => {
        this.UserSessions = res.body;
      }
      );
  }

  terminateSession(tokenId: number) {

  }
}
