import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UserSummary, Constant } from '../models/Poco';
import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Util } from '../models/Util';

@Injectable()
export class UserSearchService {

    constructor(private http: HttpClient, private util: Util) { }

    search(roleName: string, name: string): Observable<UserSummary[]> {

        if (name.length < 3) {
             return EMPTY;
        }

        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        let p = new HttpParams()
            .set('userName', name);
        if (roleName != null && roleName.length > 0) {
            p = p.set('roleName', Constant.AdvisorRole);
        }
        return this.http.get<UserSummary[]>(this.util.GetServerUrl() + '/searchuser', { headers, params: p })
            .pipe(
                tap((response: UserSummary[]) => {
                    return response;
                })
            );
    }
}
