import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BillingRole, Util } from '../models/Util';
import { CurrentUser, Shop, SelectedShop, MyShops, UserSummary } from '../models/Poco';
import { ContextService } from '../service/context-change';
import { Observable, Subscription } from 'rxjs';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { WindowRef } from '../service/window-ref';
import { CurrentStockComponent } from '../current-stock/current-stock.component';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar'
import * as moment from 'moment';
import { ShopDocmentFilesComponent } from '../shop-docment-files/shop-docment-files.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  providers: [WindowRef]
})
export class MainComponent implements OnInit {

  IsMobile = false;
  isBilling = false;
  isIncharge = false;
  isMgmt = false;
  isSysAdm = false;
  IsAdvisor = false;

  ShowMenu = true;

  AppVersion: string;

  IsLoggedIn = false;
  IsBiller = false;
  ShopName: string = null;
  IsChildShop = false;
  UserName: string = null;
  Name: string = null;
  TaskCount = 0;

  shops: Shop[] = [];
  selectedShopId: number = null;

  @Output() LogggedOut = new EventEmitter<boolean>();

  constructor(private router: Router, private http: HttpClient, private contextChange: ContextService,
    private util: Util, private dialog: MatDialog, private winref: WindowRef,
    private snackBar: MatSnackBar) {
      this.AppVersion = environment.version;
      this.ShowMenu = environment.appMode != 'I';
  }

  contextChanged() {
    this.contextChange.Changed();
  }

  
  ShowAddShopDocument() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const currentDateTime = new Date();
    const currentDate = new Date(currentDateTime.getFullYear(),currentDateTime.getMonth(), currentDateTime.getDate());
    const referenceDate = moment(currentDate);
    dialogConfig.data = { RefDate: referenceDate };
    const dialogRef = this.dialog.open(ShopDocmentFilesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      
    }
    );
  }

  ngOnInit() {
    const role = sessionStorage.getItem('Role');
    this.isBilling = role === 'Billing';
    this.isIncharge = role === 'Incharge';
    this.isMgmt = role === 'Mgmt';
    this.isSysAdm = role === 'SysAdmin';

    //this.IsMobile = role === 'Billing';
    //this.IsWeb = role !== 'Billing';
  }

  ShowProfile(saveOnly: boolean): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = saveOnly;
    const dialogRef = this.dialog.open(UserProfileComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        const user = result as UserSummary;
        this.Name = user.FullName;
      }
    }
    );
  }

  showStock() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.selectedShopId;
    this.dialog.open(CurrentStockComponent, dialogConfig);
  }

  test() {

  }

  doLogout() {
    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Do you want to Logout?', x => {
      if (x === 1) {
        sessionStorage.removeItem('Token');
        if (this.winref.nativeWindow.navigator && this.winref.nativeWindow.navigator.app) {
          this.winref.nativeWindow.navigator.app.exitApp();
        }
        this.IsLoggedIn = false;
        this.router.navigate(['/']);
      }
    }
    );
  }

  onLoggedIn() {

    const role = sessionStorage.getItem('Role');
    this.isBilling = role === 'Billing';
    this.isIncharge = role === 'Incharge';
    this.isMgmt = role === 'Mgmt';
    this.isSysAdm = role === 'SysAdmin';
    this.IsAdvisor = role === 'Advisor';

    // this.IsMobile = role === 'Billing';
    // this.IsWeb = role !== 'Billing';

    this.IsLoggedIn = true;
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<CurrentUser>(
      this.util.GetServerUrl() + '/getcurrentuserinfo', { headers, observe: 'response' }).subscribe(res => {
        const user = res.body;
        this.IsBiller = user.RoleName === BillingRole;
        this.ShopName = user.ShopName;
        this.UserName = user.UserName;
        this.Name = user.Name;
        this.TaskCount = user.PendingTaskCount;
        sessionStorage.setItem('curuserId', user.UserId + '');
        sessionStorage.setItem('PurchaseTaxFlag', user.PurchaseTaxFlag ? '1' : '0');
        sessionStorage.setItem('QuotaApplicable', user.QuotaApplicable ? '1' : '0');
        sessionStorage.setItem('PurchExpenseAssd', user.PurchExpenseAssd ? '1' : '0');
        sessionStorage.setItem('DiscountApplicable', user.DiscountApplicable ? '1' : '0');

        sessionStorage.setItem('PayOptionCash', user.PayOptionCash ? '1' : '0');
        sessionStorage.setItem('PayOptionCredit', user.PayOptionCredit ? '1' : '0');
        sessionStorage.setItem('PayOptionOnlineCredit', user.PayOptionOnlineCredit ? '1' : '0');
        sessionStorage.setItem('PayOptionOwn', user.PayOptionOwn ? '1' : '0');
        sessionStorage.setItem('PayOptionPayOnSale', user.PayOptionPayOnSale ? '1' : '0');
        sessionStorage.setItem('PayOptionPayLater', user.PayOptionPayLater ? '1' : '0');
        sessionStorage.setItem('NonValidityProdFound', user.NonValidityProdFound ? '1' : '0');

        sessionStorage.setItem('OthersExpense', user.OthersExpense ? '1' : '0');

        if (user.ParentShopId)
          sessionStorage.setItem('ParentShopId', user.ParentShopId.toString());
        else if (sessionStorage.getItem('ParentShopId') != null)
          sessionStorage.removeItem('ParentShopId');
        
        if (user.ChildShopIds && user.ChildShopIds.length > 0)
          sessionStorage.setItem('ChildShopIds', user.ChildShopIds.map(x=> x.toString()).reduce((x,y)=> x+','+y));
        else if (sessionStorage.getItem('ChildShopIds') != null)
          sessionStorage.removeItem('ChildShopIds');

        this.IsChildShop = user.ParentShopId !== null && user.ParentShopId > 0;
        
        if (this.IsBiller) {
          this.selectedShopId = user.ShopId;
          sessionStorage.setItem('BillerShopId', user.ShopId.toString());
          this.loadShops();
        }
        else if (sessionStorage.getItem('BillerShopId') != null)
          sessionStorage.removeItem('BillerShopId');

        if (environment.appMode === 'M') {
          this.IsMobile = true;
          if (role === 'Mgmt') {
            this.router.navigate(['/Dashboard/InchargeConsolidated']);
          } else if (role === 'Incharge') {
            this.router.navigate(['/UserTask']);
          } else {
            sessionStorage.removeItem('Token');
            sessionStorage.removeItem('dateViolationPromptDone');
            if (this.winref.nativeWindow.navigator && this.winref.nativeWindow.navigator.app) {
              this.winref.nativeWindow.navigator.app.exitApp();
            }
            this.LogggedOut.emit(true);
          }
        } else if (localStorage.getItem(user.UserName + '/HomeUrl') != null) {
          this.router.navigate([localStorage.getItem(user.UserName + '/HomeUrl')]);
        } else if (role === 'Advisor') {
          this.router.navigate(['Expense']);
        } else if (!user.QuotaApplicable) {
          this.router.navigate(['UserTask']);
        } else if (this.IsBiller) {
          this.router.navigate(['Dashboard']);
        } else {
          this.router.navigate(['UserTask']);
        }

        if (sessionStorage.getItem('PwdStrong') === '0') {
          this.ShowProfile(true);
        }
      }
      );
  }

  setasHome() {
    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Do you want to set current location as default?', x => {
      if (x === 1) {
        localStorage.setItem(this.UserName + '/HomeUrl', this.router.url);
        this.snackBar.open('Current location has been set as default', null, {
          duration: 1000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: ['snackbar']
        });
      }
    });
  }

  loadShops() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<MyShops>(
      this.util.GetServerUrl() + '/myshops', { headers, observe: 'response' }).subscribe(res => {
        const shopsJson = JSON.stringify(res.body.Shops);
        sessionStorage.setItem('Shops', shopsJson);
        // const _s = JSON.parse(shopsJson) as Shop[];

        this.shops = res.body.Shops;
        if (res.body.DefaultShopId && res.body.DefaultShopId > 0) {
          this.selectedShopId = res.body.DefaultShopId;
        } else {
          this.selectedShopId = res.body.Shops[0].ShopId;
        }

        const s = this.shops.filter(x => x.ShopId === this.selectedShopId)[0];

        this.util.SetShopSelected(this.selectedShopId);
      }
      );
  }

  changeShop() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    const shop = new SelectedShop(this.selectedShopId);
    this.http.post(
      this.util.GetServerUrl() + '/changeactiveshop', shop, { headers, observe: 'response' }).subscribe(res => {
        this.util.SetShopSelected(this.selectedShopId);
        this.contextChanged();
      }
      );
  }

  onLoggedOut() {
    this.IsLoggedIn = false;
    this.router.navigate(['/']);
  }

}
