import { Component, OnInit, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Util } from '../models/Util';
import { ShopPump } from '../models/Poco';
import { NozzleListComponent } from '../nozzle-list/nozzle-list.component';
import { EditBumpComponent } from '../edit-bump/edit-bump.component';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-bump-list',
  templateUrl: './bump-list.component.html',
  styleUrls: ['./bump-list.component.css']
})
export class BumpListComponent implements OnInit {
  ShopId: number;
  Pumps: ShopPump[];

  displayedColumns: string[] = ['PumpNo', 'Action'];
  totalColumns: string[] = [];

  constructor(private dialogRef: MatDialogRef<BumpListComponent>, @Inject(MAT_DIALOG_DATA) data: number,
              private http: HttpClient, private util: Util, private dialog: MatDialog) {
    this.ShopId = data as number;
  }

  ngOnInit() {
    this.loadShopPumps(this.ShopId);
  }

  loadShopPumps(shopId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ShopPump[]>(
      this.util.GetServerUrl() + '/shopbumps/' + shopId, { headers, observe: 'response' })
      .subscribe(res => {
        this.Pumps = res.body;
      }
      );
  }

  shopNozzles(pumpId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = pumpId;
    const dialogRef = this.dialog.open(NozzleListComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadShopPumps(this.ShopId);
    }
    );
  }

  addPump() {
    const bump = new ShopPump();
    bump.ShopPumpId = 0;
    bump.ShopId = this.ShopId;
    this.editPump(bump);
  }

  editPump(bump: ShopPump) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = bump;
    const dialogRef = this.dialog.open(EditBumpComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadShopPumps(this.ShopId);
    }
    );
  }

  doCancel() {
    this.dialogRef.close(false);
  }

}
