import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {
  BillingDetail, OpenCloseStatusResponse, AdjustmentDetail, Adjustment, AdjustmentSummary, Quota, AuditProduct, LkType
} from '../models/Poco';


import { Util } from '../models/Util';
import { Router, ActivatedRoute } from '@angular/router';
import { WindowRef } from '../service/window-ref';
import { MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

declare var cordova: any;

@Component({
  selector: 'app-new-adjustment',
  templateUrl: './new-adjustment.component.html',
  styleUrls: ['./new-adjustment.component.css'],
  providers: [
    WindowRef
  ]
})
export class NewAdjustmentComponent implements OnInit {

  AdjustmentSummaryId: number;
  Description: string = null;
  Quotas: Quota[];
  SelQuota: Quota;
  Qty: number = null;

  SelAdjustmentType: LkType;
  AdjustmentTypes: LkType[];

  SelProduct: AuditProduct;
  products: AuditProduct[];
  adjustmentDetails: AdjustmentDetail[] = [];
  adjustmentDate: Date;

  displayedColumns: string[] = ['ProductName', 'Qty', 'Action'];
  totalColumns: string[] = ['ProductName', 'Qty'];

  @ViewChild('details', { static: true }) details: MatTable<AdjustmentDetail>;

  constructor(private http: HttpClient, private util: Util, private router: Router,
    private route: ActivatedRoute, private messageDialog: MatDialog,
    private dialog: MatDialog, private winref: WindowRef,
    private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.loadBillingStatus();
    this.updateQuotaColumn();
  }

  loadProducts() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<AuditProduct[]>(
      this.util.GetServerUrl() + '/currentauditproducts',
      { headers, observe: 'response' }).subscribe(res => {
        this.products = res.body;
        this.loadQuotas();
      }
      );
  }

  AdjustmentTypeChanged(event: any): void {
  }

  updateQuotaColumn(): void {
    if (this.adjustmentDetails.filter(x => x.QuotaId != null).length > 0) {
      if (this.displayedColumns.length !== 4){
        this.displayedColumns = ['ProductName', 'QuotaName', 'Qty', 'Action'];
        this.totalColumns = ['ProductName', 'QuotaName', 'Qty'];
      }
    } else {
      if (this.displayedColumns.length !== 3){
        this.displayedColumns = ['ProductName', 'Qty', 'Action'];
        this.totalColumns = ['ProductName', 'Qty'];
      }
    }
  }
  loadQuotas(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<Quota[]>(
      this.util.GetServerUrl() + '/currentquotas',
      { headers, observe: 'response' }).subscribe(res => {
        this.Quotas = res.body;
        this.initGrid();
      }
      );
  }

  initGrid(): void {
    const id = this.route.snapshot.params.Id as number;
    if (id > 0) {
      this.updateQuotaColumn();
      this.AdjustmentSummaryId = id;
      const token = sessionStorage.getItem('Token') ?? '';
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        token
      });
      this.http.get<Adjustment>(
        this.util.GetServerUrl() + '/adjustmentid/' + id, { headers, observe: 'response' }).subscribe(res => {
          const billing = res.body;
          this.loadAdjustment(billing);
        }
        );
    } else {
      this.AdjustmentSummaryId = 0;
      this.updateQuotaColumn();
    }
  }

  loadBillingStatus(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const dateInddMMyyyy = this.util.GetddMMMyyyyDate(new Date());

    this.http.get<OpenCloseStatusResponse>(
      this.util.GetServerUrl() + '/GetOpenCloseStatus?StatusDate=' + dateInddMMyyyy,
      { headers, observe: 'response' }).subscribe(res => {
        this.adjustmentDate = new Date(res.body.TransactionDate);
        this.loadAdjustmentTypes();
      }
      );
  }

  loadAdjustmentTypes(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/adjustmenttypes',
      { headers, observe: 'response' }).subscribe(res => {
        this.AdjustmentTypes = res.body.filter(x => x.TypeName !== 'StockQuota');
        this.loadProducts();
      }
      );
  }

  addAdjustmentDetail(): void {

    if (this.SelProduct == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Product is not selected', null);
      return;
    }
    if (this.Qty == null || this.Qty === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Qty is not entered', null);
      return;
    }

    this.adjustmentDetails.push(new AdjustmentDetail(this.SelProduct.ProductId, this.SelProduct.ProductDisplayName,
      this.Qty, this.SelProduct.QtyPresc,
      (this.SelQuota != null ? this.SelQuota.QuotaId : null), this.SelQuota != null ? this.SelQuota.QuotaName : null));
    this.Description = null;
    this.SelProduct = null;
    this.SelQuota = null;
    this.Qty = null;
    this.details.renderRows();
  }

  loadAdjustment(adjustment: Adjustment): void {
    this.adjustmentDetails = adjustment.AdjustmentDetails;
    this.adjustmentDate = new Date(adjustment.Summary.AdjustmentDate);
  }

  doSave(): void {

    if (this.SelAdjustmentType == null) {
      this.util.ShowMessageBox(this.messageDialog, this.winref, 'OK', null, 'Choose the adjustment type', null);
      return;
    }

    if (this.SelAdjustmentType != null && this.SelAdjustmentType.WarningText != null && this.SelAdjustmentType.WarningText.length > 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', this.SelAdjustmentType.WarningText
            + '. Do you want to continue?', x => {
        if (x === 1) {
          this.save();
        }
      });
    } else {
      this.save();
    }
  }

  save(): void {
    if (this.adjustmentDetails === undefined || this.adjustmentDetails === null || this.adjustmentDetails.length === 0) {
      this.util.ShowMessageBox(this.messageDialog, this.winref, 'OK', null, 'Add the products to proceed', null);
      return;
    }

    if (this.Description === undefined || this.Description === null) {
      this.util.ShowMessageBox(this.messageDialog, this.winref, 'OK', null, 'Enter the description', null);
      return;
    }

    let token = sessionStorage.getItem('Token') ?? '';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const adjustmentSummary = new AdjustmentSummary(this.adjustmentDate, this.Description, this.SelAdjustmentType.TypeId);
    const adjustment = new Adjustment(adjustmentSummary, this.adjustmentDetails);

    if (this.AdjustmentSummaryId > 0) {
      this.http.post(
        this.util.GetServerUrl() + '/updatebilling', adjustment, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => { this.router.navigate(['../Billing']); });
        }
        );
    } else {
      this.http.post(
        this.util.GetServerUrl() + '/makeadjustment', adjustment, { headers, observe: 'response' }).subscribe(res => {
          const billSummaryId = res.body;
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            token = sessionStorage.getItem('Token') ?? '';
            headers = new HttpHeaders({
              'Content-Type': 'application/json',
              token
            });
            this.router.navigate(['../Adjustment']);
          });
        }, err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
        );
    }
  }

  doCancel(): void {
    this.router.navigate(['../Adjustment']);
  }

  editBillingDetail(billDetail: BillingDetail): void {


  }

  removeItem(rowItem: AdjustmentDetail): void {
    const idx = this.adjustmentDetails.indexOf(rowItem);
    // console.log(idx);
    this.adjustmentDetails.splice(idx, 1);
    this.details.renderRows();
  }

  getTotal(): number {
    if (this.adjustmentDetails.length === 0) {
      return 0;
    }
    return this.adjustmentDetails.map(x => x.Qty).reduce( (x, y) => x + y, 0);
  }

  getMaxPres(): number {
    if (this.adjustmentDetails.length === 0) {
      return 3;
    }
    return this.adjustmentDetails.map(x => x.QtyPres).reduce( (x, y) => x > y ? x : y);
  }
}
