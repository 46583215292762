import {Pipe, PipeTransform} from '@angular/core';
@Pipe ({
   name : 'indianCurrency'
})
export class IndianCurrencyPipe implements PipeTransform {
   transform(val: number): string {
      if (val) {
         const powerVal = Math.round(val * Math.pow(10, 2)).toString();
         return '₹' + powerVal.substring(0,powerVal.length-2)+'.'+ powerVal.substring(powerVal.length-2);
      } else if (val == 0) {
         return '₹ 0.00';
      } else {
         return '';
     }
   }
}
