import { Component, OnInit, Inject, Input } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Util } from '../models/Util';
import { CurrentStock, Quota, QuotaTransfer } from '../models/Poco';
import { WindowRef } from '../service/window-ref';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-current-stock',
  templateUrl: './current-stock.component.html',
  styleUrls: ['./current-stock.component.css'],
  providers: [WindowRef]
})
export class CurrentStockComponent implements OnInit {

  displayedColumns: string[] = [];
  QuotaApplicable = false;
  ShopId: number;
  StockList: CurrentStock[] = [];
  Quotas: Quota[];
  TotalQty = 0;


  @Input() showHeader = true;
  constructor(@Inject(MAT_DIALOG_DATA) data: number, private http: HttpClient, private util: Util,
    private winref: WindowRef, private dialog: MatDialog, private dialogRef: MatDialogRef<CurrentStockComponent>) {
    this.ShopId = data;
  }

  ngOnInit() {
    this.QuotaApplicable = sessionStorage.getItem('QuotaApplicable') === '1';
    if (this.QuotaApplicable) {
      this.displayedColumns = ['ProductName', 'QuotaName', 'Qty'];
    } else {
      this.displayedColumns = ['ProductName', 'Qty'];
    }
    this.loadQuotas();
  }

  loadQuotas() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<Quota[]>(
      this.util.GetServerUrl() + '/currentquotas',
      { headers, observe: 'response' }).subscribe(res => {
        this.Quotas = res.body;
        this.showStock(this.ShopId);
      }
      );
  }

  showStock(shopId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<CurrentStock[]>(
      shopId != null && shopId > 0 ? this.util.GetServerUrl() + '/currentstock/' + shopId : this.util.GetServerUrl() + '/currentshopstock',
      { headers, observe: 'response' })
      .subscribe(res => {
        this.StockList = res.body;
        this.TotalQty = this.StockList.map(x => x.Qty).reduce((x, y) => x + y, 0);
        this.StockList.forEach(x => {
          if (x.QuotaId > 0) {
            x.NextQuotas = this.Quotas;
            if (x.NextQuotas.filter(y => y.QuotaId === x.QuotaId).length === 0) {
              const q = new Quota(x.QuotaId, x.QuotaName, null, null, null, null, true);
              x.NextQuotas.push(q);
            }
            x.SelNewQuota = x.NextQuotas.filter(y => y.QuotaId === x.QuotaId)[0];
          }
        });
      }
      );
  }



  Changed(): boolean {
    return this.StockList.filter((element: CurrentStock) =>
      element.QuotaId > 0 && element.SelNewQuota != null &&
      element.SelNewQuota.QuotaId > 0 && element.QuotaId !== element.SelNewQuota.QuotaId
    ).length > 0;
  }

  getMaxPres(): number {
    if (this.StockList.length === 0) {
      return 3;
    }
    return this.StockList.map(x => x.QtyPres).reduce( (x, y) => x > y ? x : y);
  }

  update() {
    const upd = this.StockList.filter(x => (
      x.QuotaId > 0 && x.SelNewQuota != null &&
      x.SelNewQuota.QuotaId > 0 && x.QuotaId !== x.SelNewQuota.QuotaId)
    ).map(x => new QuotaTransfer(x.ProductId, x.QuotaId, x.SelNewQuota.QuotaId));
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Do you want to do Quota transfer ?', opt => {
      if (opt === 1) {
        this.http.post(
          this.util.GetServerUrl() + '/quotatransfer',
          upd, { headers, observe: 'response' }).subscribe(res => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
              this.showStock(this.ShopId);
            });
          }, err => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
          }
          );
      }
    }
    );

  }

  cancel() {
    this.dialogRef.close(false);
  }

  isGroup(index, item): boolean {
    return true; // index % 2 === 0;
  }

}
