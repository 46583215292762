import { ExpenseSummary, ExpensePaged, UserTask, TaskPaged } from '../models/Poco';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, Observer } from 'rxjs';
import { ExpenseService } from '../Service/Expense.service';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserTaskService } from '../service/user-task.service';

// https://blog.angular-university.io/angular-material-data-table/

export class UserTaskDataSource implements DataSource<UserTask> {

    private TaskSubject = new BehaviorSubject<TaskPaged>(new TaskPaged());
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    totalRecords = 0;

    constructor(private taskService: UserTaskService) { }

    connect(collectionViewer: CollectionViewer): Observable<UserTask[]> {
        return this.TaskSubject.asObservable()
            .pipe(map((x) => {
                // console.log(this.totalRecords);
                this.totalRecords = x.TotalRecords; return x.Summary;
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.TaskSubject.complete();
        this.loadingSubject.complete();
    }

    subscribeForList(): Observable<TaskPaged> {
       return this.TaskSubject.asObservable();
    }

    loadTask(taskTypeId: number, taskStatusId: number, isMyTask: boolean,
             pageIndex: number, pageSize: number, sortKey = 'CreatedAt', sortDir = 'asc') {
        this.loadingSubject.next(true);
        this.taskService.listTask(taskTypeId, taskStatusId, isMyTask, pageIndex, pageSize, sortKey, sortDir)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((taskPaged: TaskPaged) => {
                this.totalRecords = taskPaged.TotalRecords;
                this.TaskSubject.next(taskPaged);
            }
            );
    }
}
