import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BoatCustomerService } from '../service/boat-customer.service';
import { Util } from '../models/Util';
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { BoatCustomerDataSource } from '../datasource/boat-customer.datasource';
import { Router } from '@angular/router';
import { EditBoatCustomerComponent } from '../edit-boat-customer/edit-boat-customer.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-boat-customer-list',
  templateUrl: './boat-customer-list.component.html',
  styleUrls: ['./boat-customer-list.component.css'],
  providers: [BoatCustomerService]
})
export class BoatCustomerListComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['CustomerName', 'CustomerPhoto', 'MobileNo', 'AadhaarNo', 'BiometricIdNo', 'SocietyName', 'SocietyMemberNo',
    'NoOfBoat', 'Action'];
  pageSize = 10;
  totalRecords: number;

  dataSource: BoatCustomerDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  constructor(private http: HttpClient, private customerService: BoatCustomerService, public dialog: MatDialog,
              private util: Util, private router: Router) {
  }

  ngOnInit() {
    this.dataSource = new BoatCustomerDataSource(this.customerService);
    this.totalRecords = this.dataSource.totalRecords;
    this.dataSource.loadCustomer(0, 10, 'CustomerName', 'asc');
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadCustomer())
      )
      .subscribe();
  }

  loadCustomer() {
    this.dataSource.loadCustomer(this.paginator.pageIndex, this.paginator.pageSize);
  }

  getCustFileName(guid: string) {
    if (guid == null) {
      guid = '0';
    }
    return this.util.GetServerUrl() + '/download/' + guid + '?noimage=Customer&thumbnail=true';
  }


  newCustomer() {
    this.EditCustomer(0);
  }

  EditCustomer(summaryId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = summaryId;
    const dialogRef = this.dialog.open(EditBoatCustomerComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadCustomer();
    }
    );
  }
}
