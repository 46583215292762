import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PdfViewData } from '../models/Poco';

@Component({
  selector: 'app-pdf-view',
  templateUrl: './pdf-view.component.html',
  styleUrls: ['./pdf-view.component.css']
})
export class PdfViewComponent implements OnInit {

  pdfTitle: string;
  pdfUrl: SafeResourceUrl;

  constructor(
    private dialogRef: MatDialogRef<PdfViewComponent>,
    @Inject(MAT_DIALOG_DATA) data: PdfViewData,
    private sanitizer: DomSanitizer
  ) {
    this.pdfTitle = data.PdfTitle;
    this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.PdfUrl);
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
