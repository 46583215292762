import { Component, OnInit, AfterContentChecked, ChangeDetectorRef, AfterViewInit, Inject } from '@angular/core';
import { UserSummary, UserShop } from '../models/Poco';
import { Util } from '../models/Util';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WindowRef } from '../service/window-ref';
import { timeout } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
  providers: [WindowRef]
})
export class UserProfileComponent implements OnInit, AfterViewInit {

  FullName: string;
  CheckPassword: boolean;
  Password: string;
  ReTypePassword: string;
  Email: string;
  MobileNo: string;
  HideCancel: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) saveOnly: boolean, private dialogRef: MatDialogRef<UserProfileComponent>, private http: HttpClient,
              private util: Util, private dialog: MatDialog, private winref: WindowRef) {
                this.HideCancel = saveOnly;
               }

  ngOnInit() {
  }
  ngAfterViewInit() {
    setTimeout(() => {
      const token = sessionStorage.getItem('Token') ?? '' ?? '';
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        token
      });
      this.http.get<UserSummary>(
        this.util.GetServerUrl() + '/getprofile', { headers, observe: 'response' }).subscribe(res => {
          const summary = res.body;
          this.FullName = summary.FullName;
          this.Email = summary.EmailId;
          this.MobileNo = summary.MobileNo;
        }
        );
    }, 200);
  }

  doSave() {

    if (this.FullName == null || this.FullName.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the name of the user', null);
      return;
    }

    if (this.CheckPassword && (this.Password == null || this.Password.length === 0)) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the password', null);
      return;
    }

    if (this.CheckPassword && (this.ReTypePassword == null || this.ReTypePassword.length === 0)) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Re-type the password', null);
      return;
    }

    if (this.CheckPassword && !this.util.PassworStrong(this.Password)) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the strong password ( one number, one lowercase letter, one uppercase, minimum 8 characters required )', null);
      return;
    }

    if (this.CheckPassword && (this.Password !== this.ReTypePassword)) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the same password', null);
      return;
    }

    if (this.Email == null || this.Email.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the user\'s email address', null);
      return;
    }

    if (this.MobileNo == null || this.MobileNo.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the user\'s mobile number', null);
      return;
    }

    const user = new UserSummary();
    user.FullName = this.FullName;
    if (this.CheckPassword) {
      user.Password = this.Password;
    }
    user.EmailId = this.Email;
    user.MobileNo = this.MobileNo;

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.post(
      this.util.GetServerUrl() + '/updateprofile', user, { headers, observe: 'response' }).subscribe(res => {
        /*
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
          this.dialogRef.close(user);
        });
        */
        this.util.ShowInformationBox(this.dialog, 'Profile has been updated');
        this.dialogRef.close(user);

      }, err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
      }
      );
  }

  doCancel() {
    this.dialogRef.close(null);
  }

}
