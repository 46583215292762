import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';

@Component({
  selector: 'app-single-file-upload',
  templateUrl: './single-file-upload.component.html',
  styleUrls: ['./single-file-upload.component.css']
})
export class SingleFileUploadComponent implements OnInit {
  ShopId: number; 
  Type: string;
  constructor(private http: HttpClient, private util: Util, private dialog: MatDialog,
    private dialogRef: MatDialogRef<SingleFileUploadComponent>, private winref: WindowRef,
    @Inject(MAT_DIALOG_DATA) data: { ShopId: number, Type: string }) {
      this.ShopId = data.ShopId;
      this.Type = data.Type;
     }

  ngOnInit(): void {
  }

  LoadCustomerData(fileObj: HTMLInputElement) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      token
    });

    headers['Content-Type'] = undefined;

    const uploadFormData = new FormData();
    const files = fileObj.files;
    uploadFormData.append('file', files[0], files[0].name);
    let url = null;
    if (this.Type == 'BoatCustomer') {
      url = '/loadNewCustomerData/' + this.ShopId;
    } else if (this.Type === 'NewCustomerQty' || this.Type == 'UpdCustomerQty') {
      url = '/loadBillQty';
    }
    this.http.post(this.util.GetServerUrl() + url, uploadFormData, { headers, reportProgress: true, observe: 'events' })
    .subscribe(
      event => {
        console.log(event);
        if (event.type === HttpEventType.UploadProgress) {
          const progress = Math.round(100 * event.loaded / event.total);
        } else if (event.type === HttpEventType.Response) {
          fileObj.value = '';
          this.dialogRef.close(event.body);
        }
      },
      err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
      });
  }

  Close() {
    this.dialogRef.close(null);
  }

}
