import { Injectable } from '@angular/core';
import { ExpensePaged } from '../models/Poco';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Util } from '../models/Util';

@Injectable()
export class ExpenseService {

  constructor(private http: HttpClient, private util: Util) { }

  listExpense(statusId: number, shopId: number, pageIndex: number, pageSize: number,
              sortKey: string, sortDir: string): Observable<ExpensePaged> {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    let p = new HttpParams();
    p = p.set('pageIndex', pageIndex + '').set('pageSize', pageSize + '')
      .set('sortKey', sortKey).set('sortDir', sortDir);

    if (statusId != null) {
      p = p.set('statusId', statusId + '');
    }
    if (shopId != null) {
      p = p.set('shopId', shopId + '');
    }

    return this.http.get<ExpensePaged>(
      this.util.GetServerUrl() + '/allexpense', { headers, params: p, observe: 'response' })
      .pipe(
        map(res => res.body)
      );
  }
}
