import { LedgerEntry, LedgerPaged } from '../models/Poco';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { LedgerService } from '../service/ledger.service';

// https://blog.angular-university.io/angular-material-data-table/

export class LedgerDataSource implements DataSource<LedgerEntry> {

    private ledgerSubject = new BehaviorSubject<LedgerPaged>(new LedgerPaged());
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    totalRecords = 0;

    constructor(private ledgerService: LedgerService) { }

    connect(collectionViewer: CollectionViewer): Observable<LedgerEntry[]> {
        return this.ledgerSubject.asObservable()
            .pipe(map((x) => {
                // console.log(this.totalRecords);
                this.totalRecords = x.TotalRecords; return x.Summary;
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.ledgerSubject.complete();
        this.loadingSubject.complete();
    }

    loadLedgers(bankAccountId: number, pageIndex: number, pageSize: number, sortKey = 'FromDate', sortDir = 'asc') {
        this.loadingSubject.next(true);
        this.ledgerService.listLedgers(bankAccountId, pageIndex, pageSize, sortKey, sortDir)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((ledgerPaged: LedgerPaged) => {
                this.totalRecords = ledgerPaged.TotalRecords;
                this.ledgerSubject.next(ledgerPaged);
            }
            );
    }
}
