import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';

@Component({
  selector: 'app-edit-custom-property',
  templateUrl: './edit-custom-property.component.html',
  styleUrls: ['./edit-custom-property.component.css']
})
export class EditCustomPropertyComponent implements OnInit {

  CustomPropertyId?: number;
  ParentProductId?: number;
  PropertyText: string;
  PropertyValue: string;
  constructor(private dialogRef: MatDialogRef<EditCustomPropertyComponent>,
    private util: Util, private dialog: MatDialog, private winref: WindowRef,
    private http: HttpClient, 
    @Inject(MAT_DIALOG_DATA) data : { CustomPropertyId?: number, ParentProductId?: number,
      PropertyText: string, PropertyValue: string }) {
        //debugger;
        if (data && data.CustomPropertyId) {
          this.CustomPropertyId = data.CustomPropertyId;
        } 
        else 
        {
          this.ParentProductId = data.ParentProductId;
        }
        this.PropertyText = data.PropertyText;
        this.PropertyValue = data.PropertyValue;
    }

  ngOnInit(): void {
  }

  OkClicked() {
    if (this.PropertyText == null || this.PropertyText.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the property key', null);
      return;
    }
    if (this.PropertyValue == null || this.PropertyValue.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the property value', null);
      return;
    }

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    if (this.CustomPropertyId > 0) {
      this.http.post(
        this.util.GetServerUrl() + '/updatecustomproperty', {CustomPropertyId: this.CustomPropertyId, PropertyText: this.PropertyText, PropertyValue: this.PropertyValue }, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.dialogRef.close(true);
          });
        },
        err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
        );
    } else {
      this.http.post(
        this.util.GetServerUrl() + '/addcustomproperty', { ParentProductId: this.ParentProductId, PropertyText: this.PropertyText, PropertyValue: this.PropertyValue  }, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.dialogRef.close(true);
          });
        },
        err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
        );
    }
  }

  CancelClicked() {
    this.dialogRef.close(null);
  }

}
