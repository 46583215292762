import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Renderer2, AfterViewInit } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import {
  Product, LkType, BillingDetail, BillingSummary, Billing, ProductRate, QuotaShortInfo,
  Boat, AmountDetail, OpenCloseStatusResponse, Shop, BillPrint, DirectCustomer, Constant, BillingScreenData, BulkBoatBillEntryResponse, BulkBoatBillEntryRequest
} from '../models/Poco';

import { Util, CustomerStatusActive } from '../models/Util';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { BillPaymentComponent } from '../bill-payment/bill-payment.component';
import { WindowRef } from '../service/window-ref';
import { BillPrintComponent } from '../bill-print/bill-print.component';
import { SingleBillPrintComponent } from '../single-bill-print/single-bill-print.component';
import { BarcodeFire } from '../service/barcode-fire';
import { MatTable } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SingleFileUploadComponent } from '../single-file-upload/single-file-upload.component';
import { BulkCustomerListPreviewComponent } from '../bulk-customer-list-preview/bulk-customer-list-preview.component';

declare var cordova: any;

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './new-billing.component.html',
  styleUrls: ['./new-billing.component.css'],
  providers: [
    WindowRef
  ],
  entryComponents: [BillPrintComponent]
})
export class NewBillingComponent implements OnInit {

  // DraftAvailable = false;

  selectedBoatCustomer: string;

  ShowBillType = false;

  BillingSummaryId: number;
  InvoiceFrom: number = null;
  InvoiceTo: number = null;
  SysRate: number = null;
  SellPrice: number = null;
  IncludingTax: boolean;
  Rate: number = null;
  PrdDiscount = 0;
  SGstPerc = 0;
  CGstPerc = 0;
  IGstPerc: number = null;
  SGst = 0;
  CGst = 0;
  IGst = 0;
  WFRate: number = null;
  Quotas: QuotaShortInfo[];
  Qty: number = null;
  RateEditable = false;
  BillRefNo: string;
  TotalQty: number = null;
  TotalAmount = 0;
  RoundOff = 0;
  Discount = 0;
  DiscPerc = 0;
  // FinalAmount = 0;

  DiscountApplicable = false;

  @ViewChild('imgCustomer', { static: false }) imgCustomer: ElementRef;

  SelProduct: Product;
  SelBoatType: LkType;
  SelPaymentType: LkType;
  SelBillType: LkType;
  SelQuota: QuotaShortInfo;
  SelBoat: Boat;
  billDetailAmount: AmountDetail;

  SelDirectCustomer: DirectCustomer;
  DirectCustomers: DirectCustomer[] = [];

  products: Product[];
  boatTypes: LkType[];
  paymentTypes: LkType[];
  billTypes: LkType[];
  billingDetails: BillingDetail[] = [];
  currentItem: BillingDetail;
  currentItemIdx = -1;

  billDate: Date;

  PrdDiscountEnabled = false;

  barcodeSubscription: Subscription;
  displayedColumns: string[] = ['ProductName', 'BoatTypeName', 'Rate', 'SGst', 'CGst', 'IGst', 'NetRate',
    'WFRate', 'Qty', 'Amount', 'Action'];
  totalColumns: string[] = ['ProductName', 'SGst', 'IGst', 'WFRate', 'Amount', 'Action']; // , 'Rate', 'NetRate', 'WFRate', 'Qty', 'Amount'

  PayOnSalePaymentType: LkType = null;

  @ViewChild('details', { static: false }) details: MatTable<BillingDetail>;
  @ViewChild('custId', { static: false }) custIdTxtBox: ElementRef;

  constructor(private http: HttpClient, private util: Util, private router: Router,
              private route: ActivatedRoute, private barcodeFire: BarcodeFire,
              private dialog: MatDialog, private winref: WindowRef,
              private ref: ChangeDetectorRef, private renderer: Renderer2) {

              this.barcodeFire.getObservable().subscribe(x => {
              if ( this.SelBillType != null && this.SelBillType.TypeName === 'BoatCustomer'
              && this.custIdTxtBox && this.custIdTxtBox.nativeElement) {
                this.custIdTxtBox.nativeElement.focus();
              }
      });
  }

  ngOnInit() {
    this.DiscountApplicable = sessionStorage.getItem('DiscountApplicable') === '1';
    this.loadBillingStatus();

    const userId = sessionStorage.getItem('curuserId');
    const strdata = localStorage.getItem(userId + '/BillingData');
    if (strdata != null && strdata.length > 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Load last bill', opt => {
        if (opt === 1) {
          this.loadBillingFromLocalDb();
        }
        localStorage.removeItem(userId + '/BillingData');
      }
      );
    }
  }

  _ngOnInit() {
    this.loadBillType(); // this.loadProduct(); this.loadBoatType();
    const id = this.route.snapshot.params.Id as number;
    if (id > 0) {
      this.displayedColumns = ['ProductName', 'BoatTypeName', 'Rate', 'SGst', 'CGst', 'IGst', 'NetRate',
        'WFRate', 'Qty', 'Amount', 'Action'];
      this.BillingSummaryId = id;
      const token = sessionStorage.getItem('Token') ?? '';
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        token
      });
      this.http.get<Billing>(
        this.util.GetServerUrl() + '/billingid/' + id, { headers, observe: 'response' }).subscribe(res => {
          const billing = res.body;
          this.loadBilling(billing);
        }
        );
    } else {
      this.BillingSummaryId = 0;
      // this.displayedColumns = ['ProductName', 'BoatTypeName', 'Rate', 'WFRate', 'DiscRate', 'Qty', 'Amount', 'Action'];
      this.displayedColumns = ['ProductName', 'BoatTypeName', 'Rate', 'SGst', 'CGst', 'IGst', 'NetRate',
        'WFRate', 'Qty', 'Amount', 'Action'];
    }
  }

  billTypeChanged() {
    this.SelBoat = null;
    this.products = [];
    this.SelProduct = null;
    this.boatTypes = [];
    this.SelBoatType = null;
    this.paymentTypes = [];
    this.SelPaymentType = null;
    this.Quotas = [];
    this.SelQuota = null;

    this.billingDetails = [];
    this.InvoiceFrom = null;
    this.InvoiceTo = null;
    this.SysRate = null;
    this.Rate = null;
    this.PrdDiscount = 0;
    this.SellPrice = null;
    this.SGst = 0;
    this.CGst = 0;
    this.IGst = 0;
    this.WFRate = null;
    this.Qty = null;
    // this.FinalAmount = 0;
    this.TotalQty = 0;

    if (this.SelBillType.TypeName === 'BoatCustomer') {

    } else {
      this.loadBoatType();
      if (this.SelBillType.TypeName === 'BulkBilling') {
        this.loadProduct();
        this.SelPaymentType = this.paymentTypes.filter(x => x.TypeName === 'Cash')[0];
      } else if (this.SelBillType.TypeName === 'DirectCustomer') {
        this.loadDirectCustomers();
      }
    }
    this.loadPaymentType();
  }

  ProductChanged(data: any) {
    if (this.SelBillType.TypeName === 'BulkBilling') {
      if (this.SelProduct != null && this.SelProduct.IsConcessional) {
        this.SelPaymentType = this.paymentTypes.filter(x => x.TypeName === 'Cash')[0];
      } else {
        // this.SelPaymentType = null;
      }
    } else if (this.SelBillType.TypeName === 'DirectCustomer') {
      if (this.SelDirectCustomer.IsOwn) {
        const ownlist = this.paymentTypes.filter(x => x.TypeName === 'Own');
        if (ownlist.length > 0) {
          this.SelPaymentType = ownlist[0];
        }
      }
    }
  }

  loadDirectCustomers() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const dateInddMMyyyy = this.util.GetddMMMyyyyDate(new Date());

    this.http.get<DirectCustomer[]>(
      this.util.GetServerUrl() + '/mydirectcustomers',
      { headers, observe: 'response' }).subscribe(res => {
        this.DirectCustomers = res.body;
      }
      );
  }

  loadBillingStatus() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const dateInddMMyyyy = this.util.GetddMMMyyyyDate(new Date());

    this.http.get<OpenCloseStatusResponse>(
      this.util.GetServerUrl() + '/GetOpenCloseStatus?StatusDate=' + dateInddMMyyyy,
      { headers, observe: 'response' }).subscribe(res => {
        this.billDate = new Date(res.body.TransactionDate);
        this._ngOnInit();
      }
      );
  }

  loadProduct() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    const billDate = ('0' + (this.billDate.getMonth() + 1)).slice(-2) + '/' +
      ('0' + this.billDate.getDate()).slice(-2) + '/' + ('0000' + this.billDate.getFullYear()).slice(-4);

    let p = new HttpParams()
      .set('context', 'B')
      .set('rateDate', billDate)
      .set('billTypeId', this.SelBillType.TypeId + '');
    if (this.SelBillType.TypeName === 'Customer') {
      if (this.SelBoat && this.SelBoat.BoatId > 0) {
        p = p.set('boatId', this.SelBoat.BoatId + '');
      }
    }
    this.http.get<Product[]>(
      this.util.GetServerUrl() + '/allproducts', { headers, params: p, observe: 'response' }).subscribe(res => {
        if (this.SelBillType.TypeName === 'DirectCustomer') {
          this.products = res.body.filter(x => !x.IsConcessional);
        } else {
          this.products = res.body;
        }
      }
      );
  }

  loadBillType() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/AllBillTypes',
      { headers, observe: 'response' }).subscribe(res => {

        const shopId = parseInt(sessionStorage.getItem('SelectedShopId'), 0);

        const shopsJson = sessionStorage.getItem('Shops');
        const shops = JSON.parse(shopsJson) as Shop[];
        const shop = shops.filter(x => x.ShopId === shopId)[0];

        let v = res.body;
        if (!shop.BoatCustomerBill) {
          v = v.filter(x => x.TypeName !== 'BoatCustomer');
        }
        if (!shop.DirectCustomerBill) {
          v = v.filter(x => x.TypeName !== 'DirectCustomer');
        }
        if (!shop.BulkBill) {
          v = v.filter(x => x.TypeName !== 'BulkBilling');
        }

        this.billTypes = v;
        if (this.billTypes.length > 0) {
          this.SelBillType = this.billTypes[0];
          this.ShowBillType = this.billTypes.length > 1;
          this.billTypeChanged();
        }

      }
      );
  }

  loadBoatType() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/AllBoatTypes',
      { headers, observe: 'response' }).subscribe(res => {
        if (this.SelBillType.TypeName === 'BoatCustomer') {
          this.boatTypes = res.body.filter(x => x.TypeId === this.SelBoatType.TypeId);
        } else if (this.SelBillType.TypeName === 'DirectCustomer') {
          this.boatTypes = res.body.filter(x => false);
        } else {
          this.boatTypes = res.body;
        }
      }
      );
  }

  loadPaymentType() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/AllPaymentTypes',
      { headers, observe: 'response' }).subscribe(res => {
        let paymentOptions = res.body;
        if (sessionStorage.getItem('PayOptionCash') !== '1') {
          paymentOptions = paymentOptions.filter(x => x.TypeName !== Constant.CashPayment);
        }
        if (sessionStorage.getItem('PayOptionCredit') !== '1') {
          paymentOptions = paymentOptions.filter(x => x.TypeName !== Constant.CreditPayment);
        }
        if (sessionStorage.getItem('PayOptionOnlineCredit') !== '1') {
          paymentOptions = paymentOptions.filter(x => x.TypeName !== Constant.OnLineCreditPayment);
        }
        if (sessionStorage.getItem('PayOptionOwn') !== '1') {
          paymentOptions = paymentOptions.filter(x => x.TypeName !== Constant.PaymentOwnUse);
        }
        if (sessionStorage.getItem('PayOptionPayLater') !== '1') {
          paymentOptions = paymentOptions.filter(x => x.TypeName !== Constant.PayLaterPayment);
        }
        if (sessionStorage.getItem('PayOptionPayOnSale') !== '1') {
          paymentOptions = paymentOptions.filter(x => x.TypeName !== Constant.PayOnSalePayment);
        }

        if (this.SelDirectCustomer == null || !this.SelDirectCustomer.IsReseller) {
          this.paymentTypes = paymentOptions.filter(x => x.TypeName !== Constant.PayOnSalePayment);
        } else {
          this.paymentTypes = paymentOptions;
        }
        if (res.body.filter(x => x.TypeName === Constant.PayOnSalePayment).length > 0) {
          this.PayOnSalePaymentType = paymentOptions.filter(x => x.TypeName === Constant.PayOnSalePayment)[0];
        }
        this.resetPaymentType();
      }
      );
  }

  resetPaymentType() {
    if (this.paymentTypes.filter(x => x.TypeName === 'NonCredit').length > 0) {
      const cashType = this.paymentTypes.filter(x => x.TypeName === 'NonCredit')[0];
      this.SelPaymentType = new LkType(cashType.TypeId, cashType.TypeName, cashType.TypeDisplayName);
    }
  }

  DirectCustomerChanged(data: any) {
    if (this.PayOnSalePaymentType != null) {
      if (this.SelDirectCustomer == null || !this.SelDirectCustomer.IsReseller) {
        if (this.paymentTypes.filter(x => x.TypeName === Constant.PayOnSalePayment).length > 0) {
          this.paymentTypes = this.paymentTypes.filter(x => x.TypeName !== Constant.PayOnSalePayment);
        }
      } else {
        if (this.paymentTypes.filter(x => x.TypeName === Constant.PayOnSalePayment).length === 0) {
          this.paymentTypes.push(this.PayOnSalePaymentType);
        }
      }
    }
    this.loadProduct();
    this.billingDetails.length = 0;
    this.details.renderRows();
    this.TotalAmount = 0;
  }

  addBillingDetail(isEdit: boolean) {

    if (this.SelProduct == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Product is not selected', null);
      return;
    }
    if (this.Qty == null || this.Qty <= 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Qty is not entered', null);
      return;
    }

    if (this.SelPaymentType == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the payment type', null);
      return;
    }

    if (this.SelBillType != null && this.SelBillType.TypeName === 'BulkBilling') {
      if (this.InvoiceFrom == null || this.InvoiceFrom === 0) {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'InvoiceNo From is not entered', null);
        return;
      }
      if (this.InvoiceTo == null || this.InvoiceTo === 0) {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'InvoiceNo upto is not entered', null);
        return;
      }
    }

    if (this.Rate == null || this.WFRate == null || this.Qty == null) {
      return;
    }

    this.billingDetails.forEach(x => {
      x.PaymentTypeId = this.SelPaymentType.TypeId;
      x.invoiceFrom = this.InvoiceFrom;
      x.invoiceTo = this.InvoiceTo;
    });

    const billDet = new BillingDetail(this.SelProduct.ProductId, this.SelProduct.ProductDisplayName,
      this.SelBoatType != null ? this.SelBoatType.TypeId : null, this.SelBoatType != null ? this.SelBoatType.TypeDisplayName : null,
      this.SelPaymentType != null ? this.SelPaymentType.TypeId : null,
      this.SelPaymentType != null ? this.SelPaymentType.TypeName : null,
      (this.SelQuota != null ? this.SelQuota.QuotaId : null), this.SelQuota != null ? this.SelQuota.QuotaName : null,
      this.InvoiceFrom, this.InvoiceTo, this.Rate, this.PrdDiscount, this.SGst, this.CGst, this.IGst,
      this.WFRate, this.SelQuota != null ? this.SelQuota.Discount : null, this.Qty, this.SysRate);

    if ( this.currentItem != null ) {
      this.billingDetails.splice(this.currentItemIdx, 1, billDet);
      this.currentItem = null;
      this.currentItemIdx = -1;
    } else {
      this.billingDetails.push(billDet);
    }


    // this.InvoiceFrom = null;
    // this.InvoiceTo = null;
    this.SelProduct = null;
    this.SelBoatType = null;
    this.SelQuota = null;
    this.SysRate = null;
    this.Rate = null;
    this.PrdDiscount = 0;
    this.SellPrice = null;
    this.SGst = 0;
    this.CGst = 0;
    this.IGst = 0;
    this.WFRate = null;
    this.Qty = null;
    this.calculateTotal();
    this.resetPaymentType();
    this.details.renderRows();
  }

  saveBillingToLocalDb() {
    const userId = sessionStorage.getItem('curuserId');
    const billData = new BillingScreenData();
    billData.SelectedBoatCustomer = this.selectedBoatCustomer;
    billData.ShowBillType = this.ShowBillType;
    billData.Quotas = this.Quotas;
    billData.DiscountApplicable = this.DiscountApplicable;
    billData.SelProduct = this.SelProduct;
    billData.SelBoatType = this.SelBoatType;
    billData.SelPaymentType = this.SelPaymentType;
    billData.SelBillType = this.SelBillType;
    billData.SelQuota = this.SelQuota;
    billData.SelBoat = this.SelBoat;
    billData.BillDetailAmount = this.billDetailAmount;
    billData.SelDirectCustomer = this.SelDirectCustomer;
    billData.DirectCustomers = this.DirectCustomers;
    billData.products = this.products;
    billData.BoatTypes = this.boatTypes;
    billData.PaymentTypes = this.paymentTypes;
    billData.BillTypes = this.billTypes;
    billData.BillingDetails = this.billingDetails;
    billData.BillDate = this.billDate;
    billData.PayOnSalePaymentType = this.PayOnSalePaymentType;
    const strdata = JSON.stringify(billData);
    localStorage.setItem(userId + '/BillingData', strdata);
    this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved locally', x => {
      this.router.navigate(['../Billing']);
    });
  }

  loadBillingFromLocalDb() {
    const userId = sessionStorage.getItem('curuserId');
    const strdata = localStorage.getItem(userId + '/BillingData');
    const billData: BillingScreenData = JSON.parse(strdata);

    this.selectedBoatCustomer = billData.SelectedBoatCustomer;
    this.ShowBillType = billData.ShowBillType;
    this.Quotas = billData.Quotas;
    this.DiscountApplicable = billData.DiscountApplicable;
    this.SelProduct = billData.SelProduct;
    this.SelBoatType = billData.SelBoatType;
    this.SelPaymentType = billData.SelPaymentType;
    this.SelBillType = billData.SelBillType;
    this.SelQuota = billData.SelQuota;
    this.SelBoat = billData.SelBoat;
    this.billDetailAmount = billData.BillDetailAmount;
    this.SelDirectCustomer = billData.SelDirectCustomer;
    this.DirectCustomers = billData.DirectCustomers;
    this.products = billData.products;
    this.boatTypes = billData.BoatTypes;
    this.paymentTypes = billData.PaymentTypes;
    this.billTypes = billData.BillTypes;
    this.billingDetails = billData.BillingDetails;
    this.billDate = new Date(billData.BillDate);
    this.PayOnSalePaymentType = billData.PayOnSalePaymentType;

    this.calculateTotal();
    this.resetPaymentType();
    this.details.renderRows();

    // localStorage.removeItem('BillingData');
    // this.DraftAvailable = false;

  }

  loadBilling(billing: Billing) {
    this.billingDetails = billing.BillingDetails;
    this.billDate = new Date(billing.Summary.BillDate);
    // this.FinalAmount = billing.Summary.Amount;
    this.RoundOff = billing.Summary.RoundOff;
    this.Discount = billing.Summary.Discount;
    this.TotalQty = billing.Summary.Qty;
  }

  loadPrice(curVal: any) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.RateEditable = false;
    this.SysRate = null;
    this.Rate = null;
    this.PrdDiscount = 0;
    this.SellPrice = null;
    this.SGst = 0;
    this.CGst = 0;
    this.IGst = 0;

    if (this.SelProduct == null || this.SelProduct.IsConcessional && this.SelBoatType == null) {
      return;
    }

    const billDate = ('0' + (this.billDate.getMonth() + 1)).slice(-2) + '/' +
      ('0' + this.billDate.getDate()).slice(-2) + '/' + ('0000' + this.billDate.getFullYear()).slice(-4);

    this.http.get<ProductRate>(
      this.util.GetServerUrl() + '/getproductrate?ProductId=' + this.SelProduct.ProductId + '&RateDate=' + billDate
      + '&BoatTypeId=' + (this.SelBoatType ? this.SelBoatType.TypeId : 0)
      + '&IsConcessional=' + (this.SelProduct.IsConcessional ? 'true' : 'false'),
      { headers, observe: 'response' }).subscribe(res => {
        const rate = res.body as ProductRate;
        if (rate == null || rate.Rate === null && rate.Quotas === null ) {
          this.RateEditable = false;
          this.SysRate = null;
          this.Rate = null;
          this.PrdDiscount = 0;
          this.SellPrice = null;
          this.SGstPerc = 0;
          this.CGstPerc = 0;
          this.IGstPerc = 0;
          this.WFRate = null;
          this.Quotas = null;
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Price not defined', null);
        } else {
          this.RateEditable = rate.RateEditable;
          this.IncludingTax = rate.IncludingTax;
          this.SysRate = rate.Rate;
          this.SGstPerc = rate.SGstPerc;
          this.CGstPerc = rate.CGstPerc;
          this.IGstPerc = rate.IGstPerc;
          this.WFRate = rate.WFRate;
          this.Quotas = rate.Quotas;
          if (rate.Quotas != null && rate.Quotas.length === 1) {
            this.SelQuota = rate.Quotas[0];
            this.updateSellingPrice();
          } else {
            this.updateSellingPrice();
          }
        }
      }
      );
  }

  updateSellingPrice() {
    this.SellPrice =
      (this.SysRate == null ? this.SelQuota.SubsidyRate : this.SysRate - (this.SelQuota == null ? 0 : this.SelQuota.Discount));
    this.updateRate();
  }
  updateRate() {
    // const d = this.SellPrice;
    if (this.IncludingTax) {

      const SGst = this.SellPrice / (100 + this.SGstPerc + this.CGstPerc + this.IGstPerc) * this.SGstPerc;
      const CGst = this.SellPrice / (100 + this.SGstPerc + this.CGstPerc + this.IGstPerc) * this.CGstPerc;
      const IGst = this.SellPrice / (100 + this.SGstPerc + this.CGstPerc + this.IGstPerc) * this.IGstPerc;
      this.Rate = this.SellPrice - SGst - CGst - IGst;
    } else {
      this.Rate = this.SellPrice;
    }
    this.SGst = (this.Rate - this.PrdDiscount) * this.SGstPerc / 100;
    this.CGst = (this.Rate - this.PrdDiscount) * this.CGstPerc / 100;
    this.IGst = (this.Rate - this.PrdDiscount) * this.IGstPerc / 100;
  }

  UpdateDiscPerc() {
    this.DiscPerc = (this.Discount / this.TotalAmount * 100);
  }

  UpdateDiscVal() {
    this.Discount = (Math.round(this.TotalAmount * this.DiscPerc)) / 100.0;
  }

  RateOverride(evnt: any) {
    this.updateRate();
  }
  showPayment() {

    if ((this.TotalAmount === undefined) || this.TotalAmount <= 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'No items added', null);
      return;
    }

    if (this.SelBillType != null && this.SelBillType.TypeName === 'DirectCustomer' && this.SelDirectCustomer == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the customer', null);
      return;
    }

    const payType = this.paymentTypes.filter(x => x.TypeId === this.billingDetails[0].PaymentTypeId)[0].TypeName;
    if (this.SelBillType.TypeName === 'DirectCustomer' &&
      this.SelDirectCustomer != null && this.SelDirectCustomer.IsOwn && payType !== 'Own') {
      this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No',
      'Selected customer is Own, it is mismatch with payment type. Proceed?', x => {
        if (x === 1) {
          this._showPayment();
        }
      }
      );
    } else {
      this._showPayment();
    }
  }

  _showPayment() {
    if (this.billingDetails.filter(x => x.PaymentTypeName === Constant.CreditPayment || x.PaymentTypeName === Constant.OnLineCreditPayment
      || x.PaymentTypeName === Constant.PaymentOwnUse
      || x.PaymentTypeName === Constant.PayLaterPayment || x.PaymentTypeName === Constant.PayOnSalePayment).length > 0) {
      this.doSave();
      return;
    }

    const dialogRef = this.dialog.open(BillPaymentComponent, {
      data: {
        TotalAmount: this.TotalAmount + this.RoundOff - this.Discount,
        Mode: 'FullPayment'
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.billDetailAmount = result;
        this.doSave();
      }
    });
  }

  doSave() {

    const paymentTypes = this.billingDetails.map(x => x.PaymentTypeId);
    const distTypes = paymentTypes.filter((n, i) => paymentTypes.indexOf(n) === i);
    if (distTypes.length > 1) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Only one payment type should be selected', null);
      return;
    }

    // doSave(FromDate,ToDate,InvoiceFrom,InvoiceTo,TotalAmount)
    let token = sessionStorage.getItem('Token') ?? '';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const billingSummary = new BillingSummary(this.SelBoat != null ? this.SelBoat.BoatId : null,
      this.SelBillType.TypeId, this.SelDirectCustomer != null ? this.SelDirectCustomer.DirectCustomerId : null,
      this.billDate, this.TotalQty, this.TotalAmount + this.RoundOff - this.Discount,
      this.RoundOff, this.Discount, this.billingDetails[0].PaymentTypeId, this.BillRefNo);
    const billing = new Billing(billingSummary, this.billingDetails, this.billDetailAmount);

    if (billing.AmountDetail != null && billing.AmountDetail.PaymentModeAmounts != null) {
      billing.AmountDetail.PaymentModeAmounts =
        billing.AmountDetail.PaymentModeAmounts.filter(x => x.Amount > 0);
    }

    // Bill.Amount =  this.TotalAmount - this.Discount +this.RoundValue

    if (this.BillingSummaryId > 0) {
      this.http.post(
        this.util.GetServerUrl() + '/updatebilling', billing, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => { this.router.navigate(['../Billing']); });
        }
        );
    } else {
      this.http.post(
        this.util.GetServerUrl() + '/makebilling', billing, { headers, observe: 'response' }).subscribe(res => {
          const billSummaryId = res.body;
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            token = sessionStorage.getItem('Token') ?? '';
            headers = new HttpHeaders({
              'Content-Type': 'application/json',
              token
            });

            if (this.SelBillType.TypeName === 'Customer') {
              this.http.get<BillPrint>(
                this.util.GetServerUrl() + '/printBill/' + billSummaryId, { headers, observe: 'response' }).subscribe(res => {
                  const billPrint = res.body;
                  const dialogConfig = new MatDialogConfig();
                  dialogConfig.disableClose = true;
                  dialogConfig.autoFocus = true;
                  dialogConfig.data = billPrint;
                  const dialogRef = this.dialog.open(SingleBillPrintComponent, dialogConfig);
                  dialogRef.afterClosed().subscribe(
                    data => {
                      this.router.navigate(['../Billing']);
                    }
                  );
                });
            } else {
              this.router.navigate(['../Billing']);
            }
          });
        }, err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
        );
    }
  }

  doCancel() {
    this.router.navigate(['../Billing']);
  }

  editBillingDetail(billDetail: BillingDetail) {
  }

  chooseCustomer() {
    if (typeof (cordova) === 'undefined') {
      const source = timer(1000);
      const subscribe = source.subscribe(val => this.getBoatInfo(this.selectedBoatCustomer));
    } else {
      if (cordova.plugins && cordova.plugins.barcodeScanner && cordova.plugins.barcodeScanner.scan) {
        cordova.plugins.barcodeScanner.scan
          (
            (result: any) => {
              // console.log(result);
              this.imgCustomer.nativeElement.focus();
              this.getBoatInfo(result.text);
            },
            (error: any) => {
              // console.log(error);
              this.SelBoat = null;
            },
            {
              preferFrontCamera: true,
              showFlipCameraButton: true,
              showTorchButton: true,
              torchOn: true,
              prompt: 'Place a barcode inside the scan area',
              resultDisplayDuration: 500,
              formats: 'QR_CODE',
              disableAnimations: true,
              disableSuccessBeep: false
            }
          );
      }
    }
  }

  getBoatInfo(boatstring: string) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const inp = { EncBoatId: boatstring };

    this.http.post<Boat>(
      this.util.GetServerUrl() + '/boatInfo', inp,
      { headers, observe: 'response' }).subscribe(res => {
        const currentShopId = this.util.GetShopSelected();
        if (+res.body.ShopId !== +currentShopId) {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Boat is not belongs to this Shop', null);
          this.SelBoat = null;
          return;
        } else if (res.body.StatusName !== CustomerStatusActive) {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Boat is not active', null);
          this.SelBoat = null;
          return;
        }
        this.boatSelected(res.body);
      }, err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        this.SelBoat = null;
        return;
      }
      );
  }

  onCustomerEnter(value: string) {
    alert(value);
  }

  boatSelected(selboat: Boat) {
    this.SelBoat = selboat;
    this.ref.detectChanges();
    this.loadProduct();
    const boatType = new LkType(selboat.BoatTypeId, selboat.BoatTypeName, selboat.BoatTypeName);
    this.boatTypes = [boatType];
    this.SelBoatType = boatType;
    /*
        const dialogRef = this.dialog.open(ConfirmBoatComponent, {
          data: selboat
        });
        dialogRef.afterClosed().subscribe(
          (data: Boat) => {
            if (data === null) {
              this.SelBoat = null;
            } else {
              this.SelBoat = data;
              this.loadProduct();
              const boatType = new BoatType(data.BoatTypeId, data.BoatTypeName, data.BoatTypeName);
              this.boatTypes = [boatType];
              this.SelBoatType = boatType;
            }
          }
        );
        */
  }

  editItem(rowItem: BillingDetail) {
    this.currentItem = rowItem;
    this.currentItemIdx = this.billingDetails.indexOf(rowItem);
    this.SelProduct = this.products.filter(x => x.ProductId === this.currentItem.ProductId)[0];
    if (this.currentItem.BoatTypeId != null && this.currentItem.BoatTypeId > 0) {
      this.SelBoatType = this.boatTypes.filter(x => x.TypeId === this.currentItem.BoatTypeId)[0];
    } else {
      this.SelBoatType = null;
    }
    if (this.currentItem.QuotaId != null && this.currentItem.QuotaId > 0) {
      this.SelQuota = this.Quotas.filter(x => x.QuotaId === this.currentItem.QuotaId)[0];
    } else {
      this.SelQuota = null;
    }
    this.SelPaymentType = this.paymentTypes.filter(x => x.TypeId === this.currentItem.PaymentTypeId)[0];
    this.InvoiceFrom = this.currentItem.invoiceFrom;
    this.InvoiceTo = this.currentItem.invoiceTo;
    this.PrdDiscount = this.currentItem.PrdDiscount;
    this.WFRate = this.currentItem.WFRate;
    this.Qty = this.currentItem.Qty;
    this.SysRate = this.currentItem.SysRate;
    this.updateSellingPrice();
  }

  editUsers(rowItem: BillingDetail) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { Type: 'NewCustomerQty' } ;
    const dialogRef = this.dialog.open(SingleFileUploadComponent, dialogConfig);
    dialogRef.afterClosed().subscribe( (result:BulkBoatBillEntryResponse[]) => {
      const _dialogConfig = new MatDialogConfig();
      _dialogConfig.disableClose = true;
      _dialogConfig.autoFocus = true;
      _dialogConfig.data = { dataType: 'NewCustomerQty', list: result } ;
      const _dialogRef = this.dialog.open(BulkCustomerListPreviewComponent, _dialogConfig);
      _dialogRef.afterClosed().subscribe( (result: BulkBoatBillEntryRequest[]) => {
        rowItem.BoatQtys = result;
      });
    });
  }

  editCancel() {
    this.currentItem = null;
    this.currentItemIdx = -1;
    // this.InvoiceFrom = null;
    this.InvoiceTo = null;
    this.SelProduct = null;
    this.SelBoatType = null;
    this.SelQuota = null;
    this.SysRate = null;
    this.Rate = null;
    this.PrdDiscount = 0;
    this.SellPrice = null;
    this.SGst = 0;
    this.CGst = 0;
    this.IGst = 0;
    this.WFRate = null;
    this.Qty = null;
    this.calculateTotal();
    this.resetPaymentType();
    this.details.renderRows();
  }

  removeItem(rowItem: BillingDetail) {
    const idx = this.billingDetails.indexOf(rowItem);
    // console.log(idx);
    this.billingDetails.splice(idx, 1);
    this.calculateTotal();
    this.details.renderRows();
  }

  calculateTotal() {
    let totalQty = 0;
    let totalAmount = 0;
    for (const detail of this.billingDetails) {
      totalAmount += (detail.Rate - detail.PrdDiscount + detail.SGst + detail.CGst + detail.IGst + detail.WFRate
      ) * detail.Qty;
      totalQty += detail.Qty;
    }
    this.TotalAmount = totalAmount;
    this.TotalQty = totalQty;
    // this.FinalAmount = totalAmount - this.Discount;
  }
}
