import { UserSummary, UserPaged } from '../models/Poco';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserService } from '../service/user.service';

// https://blog.angular-university.io/angular-material-data-table/

export class UserDataSource implements DataSource<UserSummary> {

    private userSubject = new BehaviorSubject<UserPaged>(new UserPaged());
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    totalRecords = 0;

    constructor(private userService: UserService) { }

    connect(collectionViewer: CollectionViewer): Observable<UserSummary[]> {
        return this.userSubject.asObservable()
            .pipe(map((x) => {
                // console.log(this.totalRecords);
                this.totalRecords = x.TotalRecords; return x.Summary;
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.userSubject.complete();
        this.loadingSubject.complete();
    }

    loadUsers(pageIndex: number, pageSize: number, sortKey = 'FromDate', sortDir = 'asc') {
        this.loadingSubject.next(true);
        this.userService.listUser(pageIndex, pageSize, sortKey, sortDir)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((userPaged: UserPaged) => {
                this.totalRecords = userPaged.TotalRecords;
                this.userSubject.next(userPaged);
            }
            );
    }
}
