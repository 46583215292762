import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MonthlySalePurchase } from '../models/Poco';
import { Util } from '../models/Util';

@Component({
  selector: 'app-sale-purchase-chart',
  templateUrl: './sale-purchase-chart.component.html',
  styleUrls: ['./sale-purchase-chart.component.css']
})
export class SalePurchaseChartComponent implements OnInit {

  options: any;

  ngOnInit(): void {
    this.loadSalesData();
  }

  constructor(private http: HttpClient, private util: Util) { }
  setChartData(saleData: MonthlySalePurchase[]): void {

    const xAxisData = [];
    const data1 = [];
    const data2 = [];

    for (let i = 0; i < 100; i++) {
      xAxisData.push('category' + i);
      data1.push((Math.sin(i / 5) * (i / 5 - 10) + i / 6) * 5);
      data2.push((Math.cos(i / 5) * (i / 5 - 10) + i / 6) * 5);
    }

    this.options = {
      legend: {
        data: ['SubsidySale', 'NonSubsidySale', 'SubsidyPurchase', 'NonSubsidyPurchase'],
        align: 'left',
      },
      tooltip: {},
      xAxis: {
        data: saleData.map(x => x.MonthName),
        silent: false,
        splitLine: {
          show: false,
        },
      },
      yAxis: {},
      series: [
        {
          name: 'SubsidySale',
          type: 'bar',
          data: saleData.map(x => x.SubsidySale),
          animationDelay: (idx) => idx * 10,
        },
        {
          name: 'NonSubsidySale',
          type: 'bar',
          data: saleData.map(x => x.NonSubsidySale),
          animationDelay: (idx) => idx * 10 + 100,
        },
        {
          name: 'SubsidyPurchase',
          type: 'bar',
          data: saleData.map(x => x.SubsidyPurchase),
          animationDelay: (idx) => idx * 10,
        },
        {
          name: 'NonSubsidyPurchase',
          type: 'bar',
          data: saleData.map(x => x.NonSubsidyPurchase),
          animationDelay: (idx) => idx * 10 + 100,
        },
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: (idx) => idx * 5,
    };

  }

  loadSalesData(): void {
    const token = sessionStorage.getItem('Token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<MonthlySalePurchase[]>(
      this.util.GetServerUrl() + '/GetMonthlySalesPurchase',
      { headers, observe: 'response' }).subscribe(res => {

        this.setChartData(res.body);

      }
      );
  }
}
