import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Boat } from '../models/Poco';
import { Util } from '../models/Util';

@Component({
  selector: 'app-boat-info',
  templateUrl: './boat-info.component.html',
  styleUrls: ['./boat-info.component.css']
})
export class BoatInfoComponent implements OnInit {

  CustomerPhotoUrl: string;

  @Input() public BoatInfo: Boat;

  ServerUrl: string;

  constructor(private ref: ChangeDetectorRef, public util: Util) {
    this.ServerUrl = this.util.GetServerUrl();
  }

  ngOnInit() {
    this.ref.detectChanges();
  }

}
