import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { QuotaBalance, Product } from '../models/Poco';
import { ContextService } from '../service/context-change';
import { Subscription } from 'rxjs';
import { Util } from '../models/Util';

@Component({
  selector: 'app-quota-balance',
  templateUrl: './quota-balance.component.html',
  styleUrls: ['./quota-balance.component.css']
})
export class QuotaBalanceComponent implements OnInit {

  displayedColumns: string[] = ['UnitName', 'ShopName', 'ActiveOpenDate', 'Alloted', 'PurchasedQty',
  'PurchasedBalance', 'SoldQty', 'SoldBalance', 'QuotaName'];

  dataSource: QuotaBalance[];
  contextSubscriber: Subscription;

  products: Product[];
  SelProduct: Product;

  constructor(private http: HttpClient, private contextChange: ContextService, private util: Util) {
    this.contextSubscriber = contextChange.getObservable().subscribe(x => {
      this.loadBalance();
    });
  }

  ngOnInit() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    const p = new HttpParams()
      .set('context', 'QB');
    this.http.get<Product[]>(
      this.util.GetServerUrl() + '/allproducts', { headers, params: p, observe: 'response' }).subscribe(res => {
        this.products = res.body; // .filter(x => x.IsConcessional);
        if (this.products.length > 0) {
          this.SelProduct = this.products[0];
          this.loadBalance();
        }
      }
      );
  }

  loadBalance() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const p = new HttpParams()
    .set('ProductId', this.SelProduct.ProductId + '');

    this.http.get<QuotaBalance[]>(
      this.util.GetServerUrl() + '/getquotastage',
      { headers, params: p, observe: 'response' }).subscribe(res => {
        // console.log(res.body);
        this.dataSource = res.body;
      }
      );
  }

  productChanged(curVal: any) {
    this.loadBalance();
  }

}
