import { Injectable } from '@angular/core';
import { StktransferPaged } from '../models/Poco';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Util } from '../models/Util';
import { Moment } from 'moment';

@Injectable()
export class StktransferService {

  constructor(private http: HttpClient, private util: Util) { }

  listStktransfer(pageIndex: number, pageSize: number,  sortKey: string, sortDir: string, pending: boolean, fromDate: Moment, toDate: Moment): Observable<StktransferPaged> {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    let p = new HttpParams().set('pageIndex', pageIndex + '').set('pageSize', pageSize + '')
    .set('sortKey', sortKey).set('sortDir', sortDir).set('pending', pending ? 'true' : 'false');
    if (fromDate != null) {
      p = p.set('fromDate', fromDate.utcOffset(0, true).format('MM/DD/YYYY'));
    }
    if (toDate != null) {
      p = p.set('toDate', toDate.utcOffset(0, true).format('MM/DD/YYYY'));
    }
    return this.http.get<StktransferPaged>(
      this.util.GetServerUrl() + '/allstktransfer', { headers, params: p, observe: 'response' })
      .pipe(
        map(res => res.body)
      );
    }
}
