import { Component, OnInit, Output, EventEmitter, OnDestroy, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Shop } from '../models/Poco';
import { Util } from '../models/Util';
import { ContextService } from '../service/context-change';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { RequestResponse } from '../service/request-response';
import { WindowRef } from '../service/window-ref';
import { trigger, query, style, transition, animate } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-logged-in',
  templateUrl: './logged-in.component.html',
  styleUrls: ['./logged-in.component.css'],
  providers: [WindowRef],
  animations: [
  trigger('myAnimation', [
    transition('* => *', [
      query(
        ':enter',
        [
          style({ opacity: 0 })
        ],
        { optional: true }
      ),
      query(
        ':leave',
        [
          style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))
        ],
        { optional: true }
      ),
      query(
        ':enter',
        [
          style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))
        ],
        { optional: true }
      )
    ])
  ])

  ]
})
export class LoggedInComponent implements OnInit, OnDestroy, AfterContentChecked {
  contextSubscriber: Subscription;
  responseSubscriber: Subscription;

  @Output() LogggedOut = new EventEmitter<boolean>();

  PendingRequestCount = 0;

  constructor(private http: HttpClient, private route: Router, private util: Util,
              private dialog: MatDialog, private winref: WindowRef,
              private cdref: ChangeDetectorRef,
              private contextChange: ContextService, private requestResponse: RequestResponse) {
    this.responseSubscriber = requestResponse.getObservable().subscribe(x => {
      this.PendingRequestCount = x;
    });
  }
  ngOnInit() {
    /*
    const role = sessionStorage.getItem('Role');
    this.isBilling = role === 'Billing';
    this.isIncharge = role === 'Incharge';
    this.isMgmt = role === 'Mgmt';

    this.IsMobile = role === 'Billing';
    this.IsWeb = role !== 'Billing';
    */
    this.contextSubscriber = this.contextChange.getObservable().subscribe(x => {
      console.log('Changed');
      this.route.navigate(['Dashboard', 'QuotaBalance']);
    });

  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnDestroy() {
    this.contextSubscriber.unsubscribe();
  }

  doLogout() {
    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Do you want to Logout?', x => {
      if (x === 1) {
        sessionStorage.removeItem('Token');
        sessionStorage.removeItem('dateViolationPromptDone');
        if (this.winref.nativeWindow.navigator && this.winref.nativeWindow.navigator.app) {
          this.winref.nativeWindow.navigator.app.exitApp();
        }
        this.LogggedOut.emit(true);
      }
    }
    );
  }
}
