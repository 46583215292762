import { Subject, Observable } from 'rxjs';

export class BarcodeFire {
    private subject = new Subject<any>();
    Switched() {
        this.subject.next(null);
    }

    getObservable(): Observable<any> {
        return this.subject.asObservable();
    }


}
