import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  IsLoggedIn = false;

  constructor() { }

  IsAuthenticated() {
    const promise = new Promise(
      (resolve, reject) => {
        setTimeout(() => {
          console.log(sessionStorage.getItem('Token') ?? '');
          resolve(sessionStorage.getItem('Token') ?? '' != null);
        }, 0);
      }
    );
    return promise;
  }
}
