import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Moment } from 'moment';
import { StringLiteral } from 'typescript';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';

@Component({
  selector: 'app-shop-docment-upload',
  templateUrl: './shop-docment-upload.component.html',
  styleUrls: ['./shop-docment-upload.component.css']
})
export class ShopDocmentUploadComponent implements OnInit {

  ParentShopDocumentId: number | null;
  ReferenceDate: Moment;
  ExistFrequencyIdName: string;
  FileName: String;
  Description: string;
  SelFrequencyId = 9; //Monthly
  constructor(private util: Util, private dialog: MatDialog, private winref: WindowRef,
    private dialogRef: MatDialogRef<ShopDocmentUploadComponent>,
    private http: HttpClient, @Inject(MAT_DIALOG_DATA) data: {RefDate: Moment, ParentShopDocumentId: number | null, FrequencyId: number | null} ) {
    this.ParentShopDocumentId = data.ParentShopDocumentId;
    this.SelFrequencyId = data.FrequencyId;
    this.ReferenceDate = data.RefDate;
   }

  ngOnInit(): void {
  }

  SaveDocument(fileObj: HTMLInputElement) {
    const files = fileObj.files;
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      token
    });
    headers['Content-Type'] = undefined;
    const uploadFormData = new FormData();

    if (this.ReferenceDate == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the reference date', null);
      return;
    }

    if (this.SelFrequencyId == null || this.SelFrequencyId == 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the frequency', null);
      return;
    }

    if (files == null || files.length <= 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the file', null);
      return;
    }

    if (this.Description == null || this.Description.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the description', null);
      return;
    }

    if (this.ParentShopDocumentId != null && this.ParentShopDocumentId > 0)
      uploadFormData.append('parentDocumentId', this.ParentShopDocumentId.toString());
    uploadFormData.append('sDate', this.ReferenceDate.format('MM/DD/YYYY'));
    uploadFormData.append('frequencyId', this.SelFrequencyId.toString());
    uploadFormData.append('description', this.Description);
    uploadFormData.append('file', files[0], files[0].name);
    this.http.post(this.util.GetServerUrl() + '/UpdateShopDocument', uploadFormData,
      { headers, reportProgress: true, observe: 'events' }).subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            const progress = Math.round(100 * event.loaded / event.total);
          } else if (event.type === HttpEventType.Response) {
            const message = 'Upload success.';
            this.dialogRef.close(true);
          }
        });
  }

  Close() {
    this.dialogRef.close(false);
  }

}
