import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { Moment } from 'moment';
import { GroupedKeyValueIntString, InchargeConsolidated, InchargeConsolidatedRow, KeyValueIntString } from '../models/Poco';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';

@Component({
  selector: 'app-incharge-consolidated',
  templateUrl: './incharge-consolidated.component.html',
  styleUrls: ['./incharge-consolidated.component.css']
})
export class InchargeConsolidatedComponent implements OnInit, AfterViewInit {
  fromDate: Moment;
  toDate: Moment;
  dateSet = false;
  IsStickable = true;
  dataSource: InchargeConsolidatedRow[];
  tableValues: InchargeConsolidated[];
  CustomerName = '';

  addndisplayedColumns: string[];
  displayedColumns: string[];
  CustomerWise = false;

  Schemes: KeyValueIntString[] = [];
  Shops: GroupedKeyValueIntString[] = [];
  Products: GroupedKeyValueIntString[] = [];

  SelSchemesCount = 0;
  SelShopsCount = 0;
  SelProductsCount = 0;

  @ViewChild('details', { static: false }) details: MatTable<InchargeConsolidated>;

  constructor(private http: HttpClient, private util: Util,
              private dialog: MatDialog, private winref: WindowRef) { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
  }

  setDate(): void {
    this.dateSet = true;
    this.loadSchemes();
    this.SelSchemesCount = 0;
    this.SelShopsCount = 0;
    this.SelProductsCount = 0;
  }

  resetDate(): void {
    this.dateSet = false;
    this.CustomerWise = false;
    this.CustomerName = '';
    this.dataSource = [];
  }

  loadReport(): void {
    const randomstring = new Date().toISOString();
    const selFromDate = this.fromDate.format('MM/DD/YYYY');
    const selToDate = this.toDate.format('MM/DD/YYYY');
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    let p = new HttpParams()
      .set('sFromDate', selFromDate)
      .set('sToDate', selToDate)
      .set('CustomerWise', this.CustomerWise ? 'true' : 'false');
    if (this.CustomerWise) {
      p = p.set('CustomerName', this.CustomerName);
    }
    if (this.SelSchemesCount > 0) {
      const schemeIds = this.Schemes.filter(x => x.IsSelected).map(x => x.Key);
      p = p.set('SchemeIds', schemeIds.join(','));
    }
    if (this.SelShopsCount > 0) {
      const ShopIds: number[] = [];
      this.Shops.forEach(x => {
        x.Values.forEach(y => {
        if (y.IsSelected) {
          ShopIds.push(y.Key);
        }
        });
      });
      p = p.set('ShopIds', ShopIds.join(','));
    }
    if (this.SelProductsCount > 0) {
      const productIds: number[] = [];
      this.Products.forEach(x => {
        x.Values.forEach(y => {
        if (y.IsSelected) {
          productIds.push(y.Key);
        }
        });
      });
      p = p.set('ProductIds', productIds.join(','));
    }
    this.http.get<InchargeConsolidated>(
      this.util.GetServerUrl() + '/ConsolidatedReport',
      { headers, params: p, observe: 'response' }).subscribe(res => {
        const dist = res.body.Rows.filter(x => x.Adjustments != null && x.Adjustments).map(x => x.Adjustments.map(y => y.Key));
        this.addndisplayedColumns = res.body.AdjsutmentColumns; // this.findDistinct([].concat.apply([], dist));
        if (this.CustomerWise) {
          this.displayedColumns = ['TransactionDate', 'SubUnitName', 'Scheme', 'CustomerName', 'Product',
          'SaleQty', 'SaleAmt', 'ReturnQty', 'ReturnAmt'];
        } else {
          this.displayedColumns = ['TransactionDate', 'SubUnitName', 'Scheme', 'Product',
          'SaleQty', 'SaleAmt', 'ReturnQty', 'ReturnAmt', 'PurchaseQty', 'PurchaseAmt']
          .concat(this.addndisplayedColumns);
        }

        // this.tableValues = res.body;
        this.dataSource = res.body.Rows; // new MatTableDataSource<InchargeConsolidated>(this.tableValues);
        // this.dataSource.paginator = this.paginator;

        this.details.renderRows();

      }, err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
      }
      );
  }

  loadSchemes(): void {
    const randomstring = new Date().toISOString();
    const selFromDate = this.fromDate.format('MM/DD/YYYY');
    const selToDate = this.toDate.format('MM/DD/YYYY');
    const p = new HttpParams()
    .set('sFromDate', selFromDate)
    .set('sToDate', selToDate);

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<KeyValueIntString[]>(
      this.util.GetServerUrl() + '/GetIncSchemes',
      { headers, params: p, observe: 'response' }).subscribe(res => {
        this.Schemes = res.body;
        this.SelSchemesCount = 0;
        this.Shops = [];
        this.SelShopsCount = 0;
        this.Products = [];
        this.SelProductsCount = 0;
      }, err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
      }
      );
  }

  loadShops(): void {
    const randomstring = new Date().toISOString();
    const selFromDate = this.fromDate.format('MM/DD/YYYY');
    const selToDate = this.toDate.format('MM/DD/YYYY');
    let p = new HttpParams()
    .set('sFromDate', selFromDate)
    .set('sToDate', selToDate);

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    if (this.Schemes.filter(x => x.IsSelected).length > 0) {
      const schemeIds = this.Schemes.filter(x => x.IsSelected).map(x => x.Key);
      if (schemeIds.length === 0) {
        this.Shops = [];
        return;
      }
      p = p.set('SchemeIds', schemeIds.join(','));
    } else {
      this.Shops = [];
      return;
    }

    this.http.get<GroupedKeyValueIntString[]>(
      this.util.GetServerUrl() + '/GetIncShops',
      { headers, params: p, observe: 'response' }).subscribe(res => {
        this.Shops = res.body;
        this.SelShopsCount = 0;
        this.Products = [];
        this.SelProductsCount = 0;
      }, err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
      }
      );
  }

  loadProducts(): void {
    const randomstring = new Date().toISOString();
    const selFromDate = this.fromDate.format('MM/DD/YYYY');
    const selToDate = this.toDate.format('MM/DD/YYYY');
    let p = new HttpParams()
    .set('sFromDate', selFromDate)
    .set('sToDate', selToDate);

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    if (this.SelShopsCount > 0) {

      const shopIds: number[] = [];
      this.Shops.forEach(x => {
        x.Values.forEach(y => {
        if (y.IsSelected) {
          shopIds.push(y.Key);
        }
        });
      });

      if (shopIds.length === 0) {
        this.Products = [];
        return;
      }
      p = p.set('ShopIds', shopIds.join(','));
    } else {
      this.Products = [];
      return;
    }

    this.http.get<GroupedKeyValueIntString[]>(
      this.util.GetServerUrl() + '/GetIncProducts',
      { headers, params: p, observe: 'response' }).subscribe(res => {
        this.Products = res.body;
      }, err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
      }
      );
  }

  findDistinct(inp: string[]): string[] {
    let tmp: any;
    const output: string[] = [];
    tmp = new Object();
    inp.forEach(x => {
      if (tmp[x]) {
        // Do nothing
      } else {
        output.push(x);
        tmp[x] = 1;
      }
    });
    return output;
  }

  SelectUnSelectScheme(evnt: Event): void {
    this.SelSchemesCount = this.Schemes.filter(x => x.IsSelected).length;
    this.loadShops();
  }

  SelectUnSelectShop(evnt: Event): void {
    let count = 0;
    this.Shops.forEach(x => {
      x.Values.forEach(y => {
      if (y.IsSelected) {
        count++;
      }
      });
    });
    this.SelShopsCount = count;
    this.loadProducts();
  }

  SelectUnSelectProduct(evnt: Event): void {
    let count = 0;
    this.Products.forEach(x => {
      x.Values.forEach(y => {
      if (y.IsSelected) {
        count++;
      }
      });
    });
    this.SelProductsCount = count;
  }

}
