import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Moment } from 'moment';
import { BoatBulkBillDetail } from '../models/Poco';
import { Util } from '../models/Util';

@Component({
  selector: 'app-bulk-boat-bill-history',
  templateUrl: './bulk-boat-bill-history.component.html',
  styleUrls: ['./bulk-boat-bill-history.component.css']
})
export class BulkBoatBillHistoryComponent implements OnInit {

  BillDate: Date;
  fromDate: Moment;
  toDate: Moment;
  maxDate: Moment;
  BoatRegNo: string;
  displayedColumns = ['ProductName', 'BillDate', 'Qty'];
  billDetails: BoatBulkBillDetail[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) data: Date,
    private dialogRef: MatDialogRef<BulkBoatBillHistoryComponent>,
    private http: HttpClient, private util: Util) { 
      this.BillDate = data;
      this.toDate = moment(data);
      this.maxDate = moment(data);
      const fromDate = new Date(data);
      fromDate.setDate(1);
      this.fromDate = moment(fromDate);
    }

  ngOnInit(): void {
    
  }

  loadHistory() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const selFromDate = this.fromDate.format('MM/DD/YYYY');
    const selToDate = this.toDate.format('MM/DD/YYYY');
    this.http.post<BoatBulkBillDetail[]>(
      this.util.GetServerUrl() + '/boatbulkbillhistory', { 'RegNo' : this.BoatRegNo.trim(), 'sFromDate': selFromDate, 'sToDate': selToDate},
      { headers, observe: 'response' }).subscribe(res => {
        this.billDetails = res.body;
      }
      );
  }

  doCancel() {
    this.dialogRef.close(false);
  }

  getTotalQty(details: BoatBulkBillDetail[]) {
    return details == null || details.length == 0 ? 0 :
    details.map(x=> x.Qty).reduce((x,y)=> x+y);
  }

  getMaxQtyPres(details: BoatBulkBillDetail[]) {
    return details == null || details.length == 0 ? 0 :
    details.map(x=> x.QtyPres).reduce((x,y)=> x > y ? x : y );
  }

}
