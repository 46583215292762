import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuditEntry, AuditProduct, StockAudit, Quota, MyShops, Shop } from '../models/Poco';
import { MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';

@Component({
  templateUrl: './audit-entry.component.html',
  styleUrls: ['./audit-entry.component.css'],
  providers: [
    WindowRef
  ]
})
export class AuditEntryComponent implements OnInit {

  @ViewChild('imgCustomer', { static: true }) imgCustomer: ElementRef;

  shops: Shop[];
  ShopId = 0;
  SelShop: Shop;

  products: AuditProduct[];
  auditEntries: AuditEntry[] = new Array();
  SelProduct: AuditProduct;
  Qty: number;

  displayedColumns: string[] = ['ProductName', 'Unit', 'Qty', 'Action'];
  totalColumns: string[] = ['ProductName'];

  @ViewChild('details', { static: false }) details: MatTable<AuditEntry>;
  @ViewChild('qty', { static: false }) inpQty: ElementRef;

  IsFullAudit = false;

  Quotas: Quota[];
  SelQuota: Quota;

  constructor(private http: HttpClient, private util: Util, private dialog: MatDialog, private winref: WindowRef) {
  }

  ngOnInit(): void {
    this.loadShops();
  }

  Proceed(): void {
    if (this.SelShop != null) {
      if (this.SelShop.IsShopOpen) {
        this.ShopId = this.SelShop.ShopId;
        this.loadProducts();
      } else {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null,
        'Shop is not having open transaction date', null);
      }
    }
  }

  loadShops(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<MyShops>(
      this.util.GetServerUrl() + '/myshops', { headers, observe: 'response' }).subscribe(res => {
        this.shops = res.body.Shops;
      }
      );
  }

  loadProducts(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<AuditProduct[]>(
      this.util.GetServerUrl() + '/auditproducts/' + this.ShopId,
      { headers, observe: 'response' }).subscribe(res => {
        this.products = res.body;
        this.loadQuotas();
      }
      );
  }

  loadQuotas(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<Quota[]>(
      this.util.GetServerUrl() + '/shopquotas/' + this.ShopId,
      { headers, observe: 'response' }).subscribe(res => {
        this.Quotas = res.body;
      }
      );
  }

  addEntry(): void {
    this.auditEntries.push(new AuditEntry(this.SelProduct.ProductId, this.SelProduct.ProductName,
      this.SelProduct.ProductDisplayName, this.SelProduct.UomTypeName, this.Qty,
      this.SelQuota != null ? this.SelQuota.QuotaId : null, this.SelQuota != null ? this.SelQuota.QuotaName : null));
    this.Qty = null;
    this.details.renderRows();
  }

  fullAuditSelected(evnt: MatCheckboxChange): void {
    if ( evnt.checked) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null,
      'Product which are not entered will be treated as not available', null);
    }
  }

  doSave(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const stockAudit = new StockAudit();
    stockAudit.ShopId = this.ShopId;
    stockAudit.AuditEntries = this.auditEntries;
    stockAudit.IsComplete = this.IsFullAudit;

    this.http.post(
      this.util.GetServerUrl() + '/makeaudit',
      stockAudit, { headers, observe: 'response' }).subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
          this.auditEntries = [];
          this.IsFullAudit = false;
        });
      }, err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
      }
      );
  }

  doCancel(): void {
    this.auditEntries.length = 0;
    this.details.renderRows();
    this.IsFullAudit = false;
  }

  removeItem(rowItem: AuditEntry): void {
    const idx = this.auditEntries.indexOf(rowItem);
    this.auditEntries.splice(idx, 1);
    this.details.renderRows();
  }

  moveToQty(curVal: any): void {
    this.SelQuota = null;
    // this.inpQty.nativeElement.focus();
  }
}
