import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WindowRef } from '../service/window-ref';
import { Util } from '../models/Util';
import { StktransferService } from '../service/stktransfer.service';
import { StktransferDataSource } from '../datasource/stktransfer.datasource';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { StktransferDetail, StktransferSummary } from '../models/Poco';
import { Moment } from 'moment';

@Component({
  templateUrl: './stktransfer.component.html',
  styleUrls: ['./stktransfer.component.css'],
  providers: [StktransferService]
})
export class StktransferComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['InitiatedDate', 'TargetShopName', 'TransferNo', 'Description', 'Detail', 'TransferStatus', 'SentDate', 'ReceivedDate', 'Action'];
  pageSize = 10;
  totalRecords: number;
  dataSource: StktransferDataSource;
  currentShopId: number;
  pendingTransfer = false;
  fromDate: Moment;
  toDate: Moment;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private stktransferService: StktransferService,
              private http: HttpClient, private util: Util,
              public router: Router, private dialog: MatDialog, private winref: WindowRef) {
  }

  ngOnInit() {
    this.dataSource = new StktransferDataSource(this.stktransferService);
    this.totalRecords = this.dataSource.totalRecords;
    this.dataSource.loadStktransfer(0, 10, 'FromDate', 'asc', false, this.fromDate, this.toDate);
    this.currentShopId = this.util.GetShopSelected();
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadStktransfer())
      )
      .subscribe();
  }

  loadStktransfer() {
    //debugger;
    this.dataSource.loadStktransfer(this.paginator.pageIndex, this.paginator.pageSize, 'FromDate', 'asc', this.pendingTransfer, this.fromDate, this.toDate);
  }

  rangeChanged(evnt: any) {
    if (this.fromDate != null && this.toDate != null ) {
      this.paginator.pageIndex = 0;
      this.loadStktransfer();
    }
  }

  pendingChanged() {
    this.paginator.pageIndex = 0;
    this.loadStktransfer();
  }

  StktransferAction(summaryId: number, action: string) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const inp = { action };

    let promptMessage = '';

    if (inp.action === 'SendToShop') {
      promptMessage = 'Sent the products?';
    } else if (inp.action === 'Accept') {
      promptMessage = 'Received the products?';
    }else if (inp.action === 'Cancel') {
      promptMessage = 'Cancel the transfer?';
    }else if (inp.action === 'Return') {
      promptMessage = 'Sent back the products?';
    }

    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', promptMessage, promtVal => {
      if (promtVal === 1) {
        this.http.post<boolean>(
          this.util.GetServerUrl() + '/managestktransfer/' + summaryId, inp,
          { headers, observe: 'response' }).subscribe(res => {
              this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Done', opt => {
                if (opt === 1) {
                  this.loadStktransfer();
                }
              });
          }, err => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
          }
          );
      }
    });
  }

  getTotal(lst: StktransferDetail[]): number {
    if (lst.length === 0) {
      return 0;
    }
    return lst.map(x => x.Qty).reduce( (x, y) => x + y, 0);
  }

  getMaxPres(lst: StktransferDetail[]): number {
    if (lst.length === 0) {
      return 3;
    }
    return lst.map(x => x.QtyPres).reduce( (x, y) => x > y ? x : y);
  }

}
