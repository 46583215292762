import { Component, OnInit, Inject } from '@angular/core';
import { AmountDetail, LkType, PaymentModeAmount, Constant, ResalePending } from '../models/Poco';
import { WindowRef } from '../service/window-ref';
import { Util } from '../models/Util';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-bill-payment',
  templateUrl: './bill-payment.component.html',
  styleUrls: ['./bill-payment.component.css'],
  providers: [
    WindowRef,
  ]
})
export class BillPaymentComponent implements OnInit {

  PaymentModeAmounts: PaymentModeAmount[] = [];
  BillAmount: number;
  PaymentType: string;
  Mode: string;
  SummaryId: number;
  SoldQuantities: ResalePending[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) data: { TotalAmount: number, Mode: string, PaymentMode: string, SummaryId: number},
              public dialogRef: MatDialogRef<BillPaymentComponent>,
              private util: Util, private dialog: MatDialog, private winref: WindowRef,
              private http: HttpClient) {
    this.BillAmount = data.TotalAmount;
    this.Mode = data.Mode;
    this.PaymentType = data.PaymentMode;
    this.SummaryId = data.SummaryId;
  }

  ngOnInit() {
    this.loadPaymentModes();
  }

  getTotal() {
    return this.PaymentModeAmounts.reduce((acc, cur) => acc + (cur.Amount ? parseFloat(cur.Amount + '') : 0), 0);
  }

  getQtyTotal() {
    return this.SoldQuantities.reduce((acc, cur) => acc + (cur.Qty ? parseFloat(cur.Qty + '') : 0), 0);
  }

  loadPaymentModes() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/paymentmodes',
      { headers, observe: 'response' }).subscribe(res => {
        this.PaymentModeAmounts = res.body.map(x => new PaymentModeAmount(x));
        if (this.PaymentType === Constant.PayOnSalePayment) {
          this.loadPaidToPaidStatus(this.SummaryId);
        }
      }
      );
  }

  loadPaidToPaidStatus(summaryId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<ResalePending[]>(
      this.util.GetServerUrl() + '/getresalestatus/' + summaryId,
      { headers, observe: 'response' }).subscribe(res => {
        this.SoldQuantities = res.body;
      }
      );
  }

  OkClicked() {

    const total = this.PaymentModeAmounts.reduce((acc, cur) => acc + parseFloat(cur.Amount + ''), 0);

    if (this.Mode === 'FullPayment') {
      if (Math.abs(parseFloat(this.BillAmount.toFixed(5)) - parseFloat(total.toFixed(5))) > 0.01) {
        this.util.ShowMessageBox(this.dialog, this.winref, 'Ok', null, 'Please check the de-nomination', null);
        return;
      }
    } else if (this.Mode === 'AddnPayment') {
      if (parseFloat(this.BillAmount.toFixed(5)) < parseFloat(total.toFixed(5))) {
        this.util.ShowMessageBox(this.dialog, this.winref, 'Ok', null, 'Please check the de-nomination', null);
        return;
      }
    }

    const paymentDetail = new AmountDetail(null, null, false);
    paymentDetail.PaymentModeAmounts = this.PaymentModeAmounts.filter(x => x.Amount > 0);
    paymentDetail.ReSoldItems = this.SoldQuantities.filter(x => x.Qty > 0);
    if (this.PaymentModeAmounts.length > 0) {
      const payingAmount = this.PaymentModeAmounts.filter(x => x.Amount > 0).map(x => x.Amount).reduce( (x, y) => x + y, 0);
      if (payingAmount === this.BillAmount) {
        if (this.SoldQuantities.filter(x => x.Purchased - x.Returned - x.Sold !== x.Qty).length !== 0) {
          this.util.ShowMessageBox(this.dialog, this.winref, 'Ok', null, 'Amount paid fully. Please check the sold items', null);
          return;
        }
      }
    }

    this.dialogRef.close(paymentDetail);
  }

  CancelClicked() {
    this.dialogRef.close(null);
  }

}
