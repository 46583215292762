import { ExpenseSummary, ExpensePaged, UserTask, TaskPaged, TempLedgerEntryPaged, TempLedgerEntrySummary } from '../models/Poco';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, Observer } from 'rxjs';
import { ExpenseService } from '../Service/Expense.service';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserTaskService } from '../service/user-task.service';
import { TempLedgerEntryService } from '../service/temp-ledger-entry.service';

// https://blog.angular-university.io/angular-material-data-table/

export class TempLedgerEntryDataSource implements DataSource<TempLedgerEntrySummary> {

    private LedgerSubject = new BehaviorSubject<TempLedgerEntryPaged>(new TempLedgerEntryPaged());
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    totalRecords = 0;

    constructor(private taskService: TempLedgerEntryService) { }

    connect(collectionViewer: CollectionViewer): Observable<TempLedgerEntrySummary[]> {
        return this.LedgerSubject.asObservable()
            .pipe(map((x) => {
                // console.log(this.totalRecords);
                this.totalRecords = x.TotalRecords; return x.Summary;
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.LedgerSubject.complete();
        this.loadingSubject.complete();
    }

    subscribeForList(): Observable<TempLedgerEntryPaged> {
       return this.LedgerSubject.asObservable();
    }

    loadEntries(pageIndex: number, pageSize: number, sortKey = 'CreatedAt', sortDir = 'asc') {
        this.loadingSubject.next(true);
        this.taskService.listTempLedgerEntry(pageIndex, pageSize, sortKey, sortDir)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((taskPaged: TempLedgerEntryPaged) => {
                this.totalRecords = taskPaged.TotalRecords;
                this.LedgerSubject.next(taskPaged);
            }
            );
    }
}
