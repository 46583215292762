import { Component, OnInit, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CustomProperty, ShopPump } from '../models/Poco';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditCustomPropertyComponent } from '../edit-custom-property/edit-custom-property.component';

@Component({
  selector: 'app-custom-propertyp',
  templateUrl: './custom-property.component.html',
  styleUrls: ['./custom-property.component.css'],
  providers: [WindowRef]
})
export class CustomPropertyComponent implements OnInit {

  ParentProductId: number;
  Properties: CustomProperty[];
  displayedColumns = ['PropertyText', 'PropertyValue', 'Action'];
  constructor(@Inject(MAT_DIALOG_DATA) data: number, private http: HttpClient, private util: Util,
              private dialogRef: MatDialogRef<CustomPropertyComponent>, private dialog: MatDialog,
              private winref: WindowRef) {
    this.ParentProductId = data;
  }

  ngOnInit() {
    this.LoadPropertyList(this.ParentProductId);
  }

  doCancel() {
    this.dialogRef.close(false);
  }

  LoadPropertyList(parentProductId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<CustomProperty[]>(
      this.util.GetServerUrl() + '/customproperty/' + parentProductId, { headers, observe: 'response' })
      .subscribe(res => {
        this.Properties = res.body;
      }
      );
  }

  newProperty() {
      const dialogRef = this.dialog.open(EditCustomPropertyComponent, {
        data: { ParentProductId: this.ParentProductId },
      });
      dialogRef.afterClosed().subscribe(x=> {
        this.LoadPropertyList(this.ParentProductId);
      })
  }

  EditProperty(customProperty: CustomProperty) {
    const dialogRef = this.dialog.open(EditCustomPropertyComponent, {
      data: customProperty,
    });
    dialogRef.afterClosed().subscribe(x=> {
      this.LoadPropertyList(this.ParentProductId);
    })
}

  Delete(customProprtyId: number): void {
    this.LoadPropertyList(this.ParentProductId);
  }

}
