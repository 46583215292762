import { Component, OnInit, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { PumpNozzle } from '../models/Poco';
import { Util } from '../models/Util';
import { EditNozzleComponent } from '../edit-nozzle/edit-nozzle.component';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-nozzle-list',
  templateUrl: './nozzle-list.component.html',
  styleUrls: ['./nozzle-list.component.css']
})
export class NozzleListComponent implements OnInit {

  displayedColumns: string[] = ['NozzleNo', 'Action'];
  totalColumns: string[] = [];

  Nozzles: PumpNozzle[];
  PumpId: number;

  constructor(private dialog: MatDialog, private dialogRef: MatDialogRef<NozzleListComponent>,
              @Inject(MAT_DIALOG_DATA) data: number, private http: HttpClient,
              private util: Util) {
    this.PumpId = data as number;
  }

  ngOnInit() {
    this.loadPumpNozzles(this.PumpId);
  }

  loadPumpNozzles(pumpId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<PumpNozzle[]>(
      this.util.GetServerUrl() + '/bumpnozzles/' + pumpId, { headers, observe: 'response' })
      .subscribe(res => {
        this.Nozzles = res.body;
      }
      );
  }


  addNozzle() {
    const nozzle = new PumpNozzle();
    nozzle.ShopPumpId = this.PumpId;
    nozzle.PumpNozzleId = 0;
    this.editNozzle(nozzle);
  }

  editNozzle(nozzle: PumpNozzle) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = nozzle;
    const dialogRef = this.dialog.open(EditNozzleComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadPumpNozzles(this.PumpId);
    }
    );
  }

  deleteNozzle(nozzleId: number) {

  }


  doSave() {
    /*
        const excluded = this.ShopProducts.filter(x => !x.Included && x.ShopProductId != null)
          .map(x => x.ShopProductId);
        const updated = this.ShopProducts.filter(x => x.Included);
        const updateObj = new ShopProductUpdate();
        updateObj.ShopId = this.ShopId;
        updateObj.UpdateProducts = updated;
        updateObj.DeleteShopProductIds = excluded;
    
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });
    
        this.http.post(
          this.util.GetServerUrl() + '/updateshopproduct', updateObj, { headers, observe: 'response' }).subscribe(res => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Updated', (x) => {
              this.dialogRef.close(true);
            });
          },
            err => {
              this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
            }
          ); */
  }

  doCancel() {
    this.dialogRef.close(false);
  }

}
