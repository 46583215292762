import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ParentProduct, Shop, ShopDocument, ShopIdName } from '../models/Poco';
import { Util } from '../models/Util';

@Component({
  selector: 'app-incharge-dashboard',
  templateUrl: './incharge-dashboard.component.html',
  styleUrls: ['./incharge-dashboard.component.css']
})
export class InchargeDashboardComponent implements OnInit {

  selectedShop: ShopIdName;
  shops: ShopIdName[];
  selectedProduct: ParentProduct;
  products: ParentProduct[];
  dashboardData: DashboardView;

  PurchaseIndentOption: any;

  constructor(private http: HttpClient, private util: Util) { }

  ngOnInit(): void {
    this.loadShop();
  }

  loadShop(): void   {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ShopIdName[]>(
      this.util.GetServerUrl() + '/mysubsidyshops', { headers, observe: 'response' }).subscribe(res => {
        // console.log(res);
        this.shops = res.body;
        if (this.shops.length > 0){
          this.selectedShop = this.shops[0];
          this.ListProducts(this.selectedShop.ShopId);
        }
      }
    );
  }

  ShopSelected(curVal: MatSelectChange): void {
    this.dashboardData = null;
    this.ListProducts(this.selectedShop.ShopId)
  }

  ListProducts(shopId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ParentProduct[]>(
      this.util.GetServerUrl() + '/subsidyproducts?shopId='+shopId, { headers, observe: 'response' }).subscribe(res => {
        console.log(res);
        this.products = res.body;
        if (this.shops.length > 0){
          this.selectedProduct = this.products[0];
          this.LoadView(this.selectedShop.ShopId, this.selectedProduct.ParentProductId);
        }
      }
    );
  }

  ProductSelected(curVal: MatSelectChange): void {
    this.dashboardData = null;
    this.LoadView(this.selectedShop.ShopId, this.selectedProduct.ParentProductId)
  }

  LoadView(shopId: number, parentProductId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<DashboardView>(
      this.util.GetServerUrl() + '/dashboardview?shopId='+shopId+'&parentProductId='+parentProductId, { headers, observe: 'response' }).subscribe(res => {
        this.dashboardData = res.body;

        this.PurchaseIndentOption = {
          series: [
            {
              type: 'gauge',
              progress: {
                show: true,
                width: 18
              },
              axisLine: {
                lineStyle: {
                  width: 18
                }
              },
              axisTick: {
                show: false
              },
              splitLine: {
                length: 15,
                lineStyle: {
                  width: 2,
                  color: '#999'
                }
              },
              axisLabel: {
                distance: 25,
                color: '#999',
                fontSize: 20
              },
              anchor: {
                show: true,
                showAbove: true,
                size: 25,
                itemStyle: {
                  borderWidth: 10
                }
              },
              title: {
                show: false
              },
              detail: {
                valueAnimation: true,
                fontSize: 80,
                offsetCenter: [0, '70%']
              },
              data: [
                {
                  value: this.dashboardData.PurchaseIndent.PurchaseQty
                }
              ]
            }
          ]
        };

        console.log(res);
        //this.products = res.body;
      }
    );
  }

  downloadFile(guid: string, fileName: number) {
    window.open(this.util.GetServerUrl() + '/downloadview/'+guid);
  }

}

export interface DashboardView
{
    ShopName: string;
    ViewDate: Date;
    PurchaseIndent: DashboardViewPurchase;
    SaleIndents: DashboardViewSale[]; 
    Rates: DashboardViewRate[];
    SubsidyRegister: DashboardViewRegister[];
    NonSubsidyRegister: DashboardViewRegister[];
    CashBook: DashboardViewCashbook[];
    BankBook: DashboardViewBankbook[];
    Documents: ShopDocument[];
}

export interface DashboardViewPurchase
{
    IndentQty: number;
    PurchaseQty: number;
}

export interface DashboardViewSale
{
    BoatTypeName: string;
    BoatTypeDisplayName: string; 
    IndentQty: number;
    SaleQty: number;
}

export interface DashboardViewRate
{
    TransactionDate: Date;
    SubsidySellingRate: number;
    NonSubsidySellingRate: number;
    SubsidyPurchaseRate: number;
    NonSubsidyPurchaseRate: number;
}

export interface DashboardViewRegister
{
    TransactionDate: Date;
    OpeningBalance: number;
    Purchase: number;
    Sales: number;
    ClosingBalance: number;
}

export interface DashboardViewCashbook
{
    TransactionDate: Date;
    OpeningBalance: number;
    TotalSaleAmount: number;
    CashSaleAmount: number;
    BankRemittance: number;
    ClosingBalance: number;
}

export interface DashboardViewBankbook
{
    Entries: DashboardViewBankbookEntry[];
    AccountNumber: string; 
    Bank: string; 
    Branch: string; 
    Address: string; 
}

export interface DashboardViewBankbookEntry
{
    TransactionDate: Date;
    OpeningBalance: number;
    AllCredit: number;
    TransferToHeadOffice: number;
    Purchase: number;
    OtherDebit: number;
    ClosingBalance: number;
}

