import { Component, OnInit } from '@angular/core';
import { ResalePendingBill } from '../models/Poco';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Util } from '../models/Util';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-resale-all-pending',
  templateUrl: './resale-all-pending.component.html',
  styleUrls: ['./resale-all-pending.component.css']
})
export class ResaleAllPendingComponent implements OnInit {

  displayedColumns: string[] = ['BillDate', 'CustomerName', 'BillNo', 'BillRefNo', 'ProductName', 'Purchased', 'Returned',
  'Sold', 'Balance', 'Amount'];
  BillDetails: ResalePendingBill[];
  SortValue = 'BillDate|Desc';
  constructor(private http: HttpClient, private util: Util) { }

  ngOnInit() {
    this.loadBills();
  }

  loadBills() {
    const p = new HttpParams()
      .set('sortName',  this.SortValue.substr(0, this.SortValue.indexOf('|')))
      .set('sortDir', this.SortValue.substring(this.SortValue.indexOf('|') + 1));
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ResalePendingBill[]>(
      this.util.GetServerUrl() + '/resalependingbills', { headers, params: p, observe: 'response' }).subscribe(res => {
        this.BillDetails = res.body;
      }
      );
  }

  SortOptionChanged(evnt: MatSelectChange) {
    this.SortValue = evnt.value;
    this.loadBills();
  }

}
