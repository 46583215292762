import { number } from "echarts";
import { Moment } from "moment";

export interface TokenInfo {
  RoleName: string;
  ShopId: number;
}

export interface MyShops {
  DefaultShopId: number;
  Shops: Shop[];
}

export interface Shop {
  ShopId: number;
  ShopName: string;
  BoatCustomerBill: boolean;
  DirectCustomerBill: boolean;
  BulkBill: boolean;
  Address: string;
  ContactNo: string;
  GstNo: string;
  Title1: string;
  Title2: string;
  Footer1: string;
  Footer2: string;
  IsShopOpen: boolean;
}

export class ShopSupplierShortInfo {
  ShopId: number;
  ShopName: string;
  ShopSupplierId: number;
  IsSelected: boolean;
}

export class Constant {
  public static BoatCustomer = 'BoatCustomer';
  public static DirectCustomer = 'DirectCustomer';

  public static SupplierAccount = 'SupplierAccount';
  public static AdvisorRole = 'Advisor';
  public static PaymentRequest = 'PaymentRequest';

  public static CashPayment = 'Cash';
  public static CreditPayment = 'Credit';
  public static OnLineCreditPayment = 'OnLineCredit';
  public static PayLaterPayment = 'PayLater';
  public static PayOnSalePayment = 'PayOnSale';
  public static PaymentOwnUse = 'Own';

  public static NotPaid = 'NotPaid';
  public static PartiallyPaid = 'PartiallyPaid';
}

export interface ShopIdName {
  ShopId: number;
  ShopName: string;
}

export class ShopShortInfo {
  ShopId: number;
  ShopName: string;
  IsSelected = false;
  constructor(shopId: number, shopName: string) {
    this.ShopId = shopId;
    this.ShopName = shopName;
    this.IsSelected = false;
  }
}

export class UserShopShortInfo extends ShopShortInfo {
  UserShopId: number;
}

export interface Role {
  RoleId: number;
  RoleName: string;
}

export interface Unit {
  UnitId: number;
  UnitName: string;
}

export interface AdOffice {
  AdOfficeId: number;
  OfficeName: string;
}

export class BillPayment {
  SummaryId: number;
  PaymentModeAmounts: PaymentModeAmount[];
  ReSoldItems: ResalePending[];
}


export class Product {
  ProductId: number;
  ProductDisplayName: string;
  SGstPerc: number;
  CGstPerc: number;
  IGstPerc: number;
  IsConcessional: boolean;
  IsDummy: boolean;
  constructor(productId: number, productDisplayName: string) {
    this.ProductId = productId;
    this.ProductDisplayName = productDisplayName;
  }
}

export class ArchiveProduct {
  ShopProductId: number;
  Archived: boolean;
  constructor(shopProductId: number, archived: boolean) {
    this.ShopProductId = shopProductId;
    this.Archived = archived;
  }
}

export class CloneProduct {
  ShopIds: number[];
  ProductId: number;
  SellingPrice: number;
  BatchNo: string;
}

export class ChildProduct {
  ProductId: number;
  ProductDisplayName: string;
  SellingPrice: number;
  BatchNo: string;
  IsArchived: boolean;
  CreatedAt: Date;
  IsStocked: boolean;
}

export interface QuotaBalance {
  ShopName: string;
  Alloted: number;
  PurchasedQty: number;
  SoldQty: number;
  QuotaName: string;
  ActiveOpenDate: Date;
}

export class ResalePending {
  BillDetailId: number;
  ProductName: string;
  Purchased: number;
  Returned: number;
  Sold: number;
  Qty = 0;
}

export class InchargeSalePurchase {
  TransactionDate: Date;
  Scheme: string;
  SubUnitId: number;
  SubUnitName: string;
  UnitName: string;
  CustomerName: string;
  ProductId: number;
  Product: string;
  SaleQty: number;
  SaleAmt: number;
  PurchaseQty: number;
  PurchaseAmt: number;
}

export class InchargeAdjustment {
    TransactionDate: Date;
    ShopId: number;
    AdjustmentTypeId: number;
    Qty: number;
}

export class InchargeConsolidated {
    Rows: InchargeConsolidatedRow[];
    AdjsutmentColumns: string[];
}

export class InchargeConsolidatedRow {
  TransactionDate: Date;
  Scheme: string;
  SubUnitId: number;
  SubUnitName: string;
  UnitName: string;
  CustomerId: number;
  CustomerName: string;
  ProductId: number;
  ProductName: string;
  SaleQty: number;
  SaleAmt: number;
  PurchaseQty: number;
  PurchaseAmt: number;
  ReturnQty: number;
  ReturnAmt: number;
  Adjustments: KeyValueIntString[];
  AdjustmentVales: any;
}

export class KeyValueIntString {
  Key: number;
  Value: string;
  IsSelected = false;
}
export class GroupedKeyValueIntString {
  GroupName: string;
  Values: KeyValueIntString[];
}

export class ResoldHistory {
  PendingStatus: ResalePending[];
  Summaries: ResoldSummary[];
}

export class ResalePendingBill extends ResalePending {
  BillNo: string;
  BillRefNo: string;
  CustomerName: string;
  BillDate: Date;
}

export class ResoldSummary {
  EnteredBy: string;
  EnteredAt: Date;
  Details: ResoldDetail[];
}

export class ResoldDetail {
  ProductName: string;
  QuotaName: string;
  Qty: number;
}

export class PaymentRequestFile {
    FileName: string;
    FileDate: Date;
}

export class LkType {
  TypeId: number;
  TypeName: string;
  TypeDisplayName: string;
  WarningText: string;
  constructor(typeId: number, typeName: string, typeDisplayName: string) {
    this.TypeId = typeId;
    this.TypeName = typeName;
    this.TypeDisplayName = typeDisplayName;
  }
}

export interface LkStatus {
  StatusId: number;
  StatusName: string;
  StatusDisplayName: string;
}

export interface ShopDocument
{
    ShopDocumentId: number;
    ParentShopDocumentId: number;
    FrequencyId: number;
    //1 for daily, 2:8 for weekly, 9 for monthly, 10 for quarterly, 11 for half yearly (Jan-Jun/Jul-Dec), 12 for half yearly (Apr-Sep, Oct-Mar), 13 for yearly 
    FrequencyDate: Date;
    FileName: string;
    Description: string;
    FileDate: Date;
    FileUniqId: string;
    IsActive : boolean;
}

export class TaskTypeField {
  FieldName: string;
  FieldDisplayName: string;
  Prompt: string;
  Pattern: string;
  IsRequired: boolean;
  DefaultAnswer: string;
  IsReadOnly: boolean;
  DisplaySequence: number;
}

export class TaskTypeFieldValue extends TaskTypeField {
  FieldValue: string;
  FieldValues: any;
  RequestValue: string;
}

export class TaskTypeFieldReqResp extends TaskTypeFieldValue {
  RequestValue: string;
}

export class ExceptionSummary {
  AppErrorId: number;
  ErrorMessage: string;
  StackTrace: string;
  IpAddress: string;
  HostName: string;
  UserName: string;
  CreatedAt: Date;
}

export class ExceptionPaged {
  TotalRecords: number;
  Summary: ExceptionSummary[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class TempLedgerEntryPaged {
  TotalRecords: number;
  Summary: TempLedgerEntrySummary[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class BillingPaged {
  TotalRecords: number;
  Summary: BillingSummary[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class StktransferPaged {
  TotalRecords: number;
  Summary: StktransferSummary[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class StktransferSummary {
  StktransferSummaryId: number;
  StktransferNo: string;
  StktransferDate: Date;
  TargetShopId: number;
  ShopName: string;
  Description: string;
  AdjustedAt: Date;
  AdjustedByName: string;
  // Details: StktransferDetail[];
  constructor(stktransferDate: Date, description: string, targetShopId: number) {
    this.StktransferDate = stktransferDate;
    this.Description = description;
    this.TargetShopId = targetShopId;
  }
}

export class StktransferDetail {
  ProductId: number;
  ProductName: string;
  Qty: number;
  QtyPres: number;
  QuotaId: number;
  QuotaName: string;
  constructor(productId: number, productName: string, qty: number, qtyPres: number, quotaId: number, quotaName: string) {
    this.ProductId = productId;
    this.ProductName = productName;
    this.Qty = qty;
    this.QtyPres = qtyPres;
    this.QuotaId = quotaId;
    this.QuotaName = quotaName;
  }
}

export class Stktransfer {
  Summary: StktransferSummary;
  StktransferDetails: StktransferDetail[];
  constructor(summary: StktransferSummary, stktransferDetails: StktransferDetail[]) {
    this.Summary = summary;
    this.StktransferDetails = stktransferDetails;
  }
}

export class AdjustmentPaged {
  TotalRecords: number;
  Summary: AdjustmentSummary[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class AdjustmentSummary {
  AdjustmentSummaryId: number;
  AdjustmentNo: string;
  AdjustmentDate: Date;
  AdjustmentTypeId: number;
  AdjustmentType: string;
  Description: string;
  AdjustedAt: Date;
  AdjustedByName: string;
  Details: AdjustmentDetail[];
  constructor(adjustmentDate: Date, description: string, adjustmentTypeId: number) {
    this.AdjustmentDate = adjustmentDate;
    this.Description = description;
    this.AdjustmentTypeId = adjustmentTypeId;
  }
}

export interface TempLedgerEntrySummary {
  TempLedgerEntryId: number;
  LedegerTypeId: number;
  LedegerTypeName: string;
  ReferenceDate?: Date;
  Amount: number;
  Description: string;
  EntryDateTime: Date;
}

export class BillingSummary {
  BoatId: number;
  BoatRegNo: string;
  BillTypeId: number;
  BillingSummaryId: number;
  BillDate: Date;
  BilledAt: Date;
  Qty: number;
  FinalAmount: number;
  RoundOff: number;
  Discount: number;
  ShortDetails: BillingShortDetail[];
  GstNo: string;
  BillNo: string;
  BillRefNo: string;
  BarcodeImageSrc: string;
  DirectCustomerId: number;
  PaymentTypeId: number;
  PaymentStatus: string;
  PaymentStatusDisplay: string;
  PendingAmount: number;

  constructor(boatId: number, billTypeId: number, directCustomerId: number,
    billDate: Date, qty: number, finalAmount: number, roundOff: number,
    discount: number, paymentTypeId: number, billRefNo: string) {
    this.BoatId = boatId;
    this.BillTypeId = billTypeId;
    this.BillDate = billDate;
    this.Qty = qty;
    this.FinalAmount = finalAmount;
    this.RoundOff = roundOff;
    this.Discount = discount;
    this.DirectCustomerId = directCustomerId;
    this.PaymentTypeId = paymentTypeId;
    this.BillRefNo = billRefNo;
  }

}

export class BillingShortDetail {
  ProductName: string;
  Qty: number;
  QtyPres: number;
  Rate: number;
  WFRate: number;
  BoatTypeDisplay: string;
}

export class AdjustmentDetail {
  ProductId: number;
  ProductName: string;
  Qty: number;
  QtyPres: number;
  QuotaId: number;
  QuotaName: string;
  constructor(productId: number, productName: string, qty: number, qtyPres: number, quotaId: number, quotaName: string) {
    this.ProductId = productId;
    this.ProductName = productName;
    this.Qty = qty;
    this.QtyPres = qtyPres;
    this.QuotaId = quotaId;
    this.QuotaName = quotaName;
  }
}

export class Adjustment {
  Summary: AdjustmentSummary;
  AdjustmentDetails: AdjustmentDetail[];
  constructor(summary: AdjustmentSummary, adjustmentDetails: AdjustmentDetail[]) {
    this.Summary = summary;
    this.AdjustmentDetails = adjustmentDetails;
  }
}


export class BillingDetail {
  ProductId: number;
  ProductName: string;
  invoiceFrom: number;
  invoiceTo: number;
  BoatTypeId: number;
  BoatTypeName: string;
  PaymentTypeId: number;
  PaymentTypeName: string;
  QuotaId: number;
  QuotaName: string;
  Rate: number;
  PrdDiscount: number;
  SGst: number;
  CGst: number;
  IGst: number;
  WFRate: number;
  DiscRate: number;
  Qty: number;
  Amount: number;
  SysRate: number;
  BoatQtys: BulkBoatBillEntryRequest[];

  constructor(productId: number, productName: string,
              boatTypeId: number, boatTypeName: string,
              paymentTypeId: number, paymentTypeName: string,
              quotaId: number, quotaName: string, invoiceFrom: number,
              invoiceTo: number, rate: number, prdDiscount: number, sGst: number, cGst: number, iGst: number,
              wfRate: number, discRate: number, qty: number, sysrate: number) {
    this.ProductId = productId;
    this.ProductName = productName;
    this.BoatTypeId = boatTypeId;
    this.BoatTypeName = boatTypeName;
    this.PaymentTypeId = paymentTypeId;
    this.PaymentTypeName = paymentTypeName;
    this.QuotaId = quotaId;
    this.QuotaName = quotaName;
    this.invoiceFrom = invoiceFrom;
    this.invoiceTo = invoiceTo;
    this.WFRate = wfRate;
    this.DiscRate = discRate;
    this.SysRate = sysrate;
    this.Rate = rate;
    this.PrdDiscount = prdDiscount;
    this.SGst = sGst;
    this.CGst = cGst;
    this.IGst = iGst;
    this.Qty = qty;
    this.Amount = rate * qty;
  }
}

export class _BulkBoatQty
{
    BoatCustomerId: number;
    RegNo: string;
    Qty: number;
}

export class BillingScreenData {
  SelectedBoatCustomer: string;
  ShowBillType = false;
  Quotas: QuotaShortInfo[];
  DiscountApplicable = false;
  SelProduct: Product;
  SelBoatType: LkType;
  SelPaymentType: LkType;
  SelBillType: LkType;
  SelQuota: QuotaShortInfo;
  SelBoat: Boat;
  BillDetailAmount: AmountDetail;
  SelDirectCustomer: DirectCustomer;
  DirectCustomers: DirectCustomer[] = [];
  products: Product[];
  BoatTypes: LkType[];
  PaymentTypes: LkType[];
  BillTypes: LkType[];
  BillingDetails: BillingDetail[] = [];
  BillDate: Date;
  PayOnSalePaymentType: LkType = null;
}

export class TaskPaged {
  TotalRecords: number;
  Summary: UserTask[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class PdfViewData {
  PdfTitle: string;
  PdfUrl: string;
}

export class PaymentTransfer {
  UserTaskIds: number[];
  FileType = 'pdf';
}

export class UserTask {
  UserTaskId: number;
  TaskTypeId: number;
  TaskTypeName: string;
  TaskDesc: string;
  TaskOwnerId: number;
  TaskOwnerName: string;
  TaskStatusId: number;
  TaskStatusName: string;
  ProposedDate: Date;
  CreatedAt: Date;
  Assignments: TaskAssignment[];
  FieldValues: TaskTypeFieldValue[];
  IsSelected: boolean;
}

export class UserTaskAction extends UserTask {
  Action: string;
}

export class AnyPaged<T> {
  TotalRecords: number;
  Summary: T[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export interface NoParamConstructor<T> {
  new(): T;
}

export class ExpenseActions {
  ExpenseIds: number[];
  Action: string;
  BatchNo: string;
  constructor(expenseIds: number[], action: string, batchNo: string) {
    this.ExpenseIds = expenseIds;
    this.Action = action;
    this.BatchNo = batchNo;
  }
}

export class ExpenseAction {
  ExpenseId: number;
  Action: string;
  constructor(expenseId: number, action: string) {
    this.ExpenseId = expenseId;
    this.Action = action;
  }
}

export class ExpensePaged {
  TotalRecords: number;
  Summary: ExpenseSummary[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class ServiceProvider {
  ServiceProviderId: number;
  GstNumber: string;
  PanNumber: string;
  PartyName: string;
}

export class QuotaTransfer {
  ProductId: number;
  FromQuotaId: number;
  ToQuotaId: number;
  constructor(productId: number, fromQuotaId: number, toQuotaId: number) {
    this.ProductId = productId;
    this.FromQuotaId = fromQuotaId;
    this.ToQuotaId = toQuotaId;
  }
}

export class CurrentStock {
  ProductId: number;
  ProductName: string;
  QuotaId: number;
  QuotaName: string;
  Qty: number;
  QtyPres: number;
  NextQuotas: Quota[];
  SelNewQuota: Quota;
}

export class ExpenseSummary {
  ExpenseId: number;
  ShopName: string;
  ExpenseTypeId: number;
  ExpenseTypeName: string;
  ExpenseDate: Date;
  OtherExpense: string;
  Amount: number;
  ServiceProviderId: number;
  GstNumber: string;
  PanNumber: string;
  PartyName: string;
  Sgst: number;
  Cgst: number;
  Igst: number;
  InvoiceDate: Date;
  InvoiceNo: string;
  VoucherTypeId: number;
  VoucherNo: string;
  TDS: number;
  TDS2: number;
  RoundOff: number;
  Qty: number;
  RatePerQty: number;
  AdditionalInfo: string;
  CreatedByUserName: string;
  CreatedAt: Date;
  ExpenseStatus: string;
  IsSelected = false;

  BankDetail: string;
  ChequeNo: string;
  ChequeClrDate?: Date;
  FileNo: string;

  PurchaseSummaryId: number;
  SupplierName: string;

  TargetShopId?: number;
  ForOtherShop: boolean;

  BankAccountId?: number

  constructor(expenseTypeId: number, expenseTypeName: string, expenseDate: Date, otherExpense: string,
              amount: number,
              serviceProviderId: number, gstNumber: string, panNumber: string,
              partyName: string, sgst: number, cgst: number, igst: number,
              invoiceDate: Date, invoiceNo: string, voucherTypeId: number, voucherNo: string, tds: number, tds2: number,
              roundOff: number, qty: number, ratePerQty: number,
              additionalInfo: string, bankDetail: string, chequeNo: string, ChequeClrDate: Date | null, 
              fileNo: string, targetShopId?: number, bankAccountId?: number) {
    this.ExpenseTypeId = expenseTypeId;
    this.ExpenseTypeName = expenseTypeName;
    this.ExpenseDate = expenseDate;
    this.OtherExpense = otherExpense;
    this.Amount = amount;
    this.ServiceProviderId = serviceProviderId;
    this.GstNumber = gstNumber;
    this.PanNumber = panNumber;
    this.PartyName = partyName;
    this.Sgst = sgst;
    this.Cgst = cgst;
    this.Igst = igst;
    this.InvoiceDate = invoiceDate;
    this.InvoiceNo = invoiceNo;
    this.VoucherTypeId = voucherTypeId;
    this.VoucherNo = voucherNo;
    this.TDS = tds;
    this.TDS2 = tds2;
    this.RoundOff = roundOff;
    this.Qty = qty;
    this.RatePerQty = ratePerQty;
    this.AdditionalInfo = additionalInfo;
    this.BankDetail = bankDetail;
    this.ChequeNo = chequeNo;
    this.ChequeClrDate = ChequeClrDate; 
    this.FileNo = fileNo;
    this.TargetShopId = targetShopId;
    this.BankAccountId = bankAccountId;
  }
}

export class ShopNozzle {
  ShopNozzleId: number;
  PumpNo: string;
  NozzleNo: string;
}

export class ShopPump {
  ShopId: number;
  ShopPumpId: number;
  PumpNo: string;
}

export class PumpNozzle {
  ShopPumpId: number;
  PumpNozzleId: number;
  NozzleNo: string;
}

export class CustomProperty {
    CustomProprtyId: number;
    ParentProductId: number;
    PropertyText: string;
    PropertyValue: string;
}

export class ShopNozzleQty extends ShopNozzle {
  Qty: number;
}

export class SettlementConfirmation {
  CashbookSummaryId: number;
  IsConfirmed: boolean;
  Amount: number;
  constructor(cashbookSummaryId: number, isConfirmed: boolean, amount: number) {
    this.CashbookSummaryId = cashbookSummaryId;
    this.IsConfirmed = isConfirmed;
    this.Amount = amount;
  }
}

export class BankReceipts {
  SettlementId: number;
  Receipts: SettlementReceipt[];
  IsConfirmed: boolean;
  constructor(settlementId: number, receipts: SettlementReceipt[], isConfirmed: boolean) {
    this.SettlementId = settlementId;
    this.Receipts = receipts;
    this.IsConfirmed = isConfirmed;
  }
}

export class Billing {
  Summary: BillingSummary;
  BillingDetails: BillingDetail[];
  AmountDetail: AmountDetail;
  constructor(summary: BillingSummary, billingDetails: BillingDetail[], billDetailAmount: AmountDetail) {
    this.BillingDetails = billingDetails;
    this.Summary = summary;
    this.AmountDetail = billDetailAmount;
  }

}

export class PurchasePaged {
  TotalRecords: number;
  Summary: PurchaseSummary[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class BoatPaged {
  TotalRecords: number;
  Summary: Boat[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class SettlementPaged {
  TotalRecords: number;
  Summary: CashbookEntry[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class ShopPaged {
  TotalRecords: number;
  Summary: ShopSummary[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class SupplierPaged {
  TotalRecords: number;
  Summary: Supplier[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class DirectCustomerPaged {
  TotalRecords: number;
  Summary: DirectCustomer[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class UserPaged {
  TotalRecords: number;
  Summary: UserSummary[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class MasterProductPaged {
  TotalRecords: number;
  Summary: MasterProduct[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class LedgerPaged {
  TotalRecords: number;
  Summary: LedgerEntry[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class LedgerEntry {
  LedgerEntryId: number;
  ShopName: string;
  Description: string;
  IsCredit: boolean;
  Amount: number;
  OpenBalance: number;
  CloseBalance: number;
  EntryDateTime: Date;
}

export class MiscEntry {
  Description: string;
  LedgerTypeId: number;
  Amount: number;
  BankAccountId: number;
  TempLedgerEntryId?: number;
  TransactionDate: Date;
  ReferenceDate?: Date;
  constructor(bankAccountId: number, transactionDate: Date, description: string, ledgerTypeId: number, amount: number, tempLedgerEntryId?: number, referenceDate?: Date) {
    this.BankAccountId = bankAccountId;
    this.Description = description;
    this.LedgerTypeId = ledgerTypeId;
    this.Amount = amount;
    this.TransactionDate = transactionDate;
    this.TempLedgerEntryId = tempLedgerEntryId;
    this.ReferenceDate = referenceDate;
  }
}

export class BankIfscCode {
  Bank: string;
  IfscCode: string;
  Branch: string;
  Address: string;
  Contact: string;
  City: string;
  District: string;
  State: string;
}

export class BankAccountPaged {
  TotalRecords: number;
  Summary: BankAccount[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class UpdateUserShop {
  UserShops: UserShopShortInfo[];
  UserId: number;
}

export class UpdateShopSupplier {
  SupplierShops: ShopSupplierShortInfo[];
  SupplierId: number;
}

export class BankAccount extends BankIfscCode {
  BankAccountId: number;
  AccountNumber: string;
  SupplierId: number;
  ShopSupplierId: number;
  AccountForId: number;
  AccountTypeId: number;
  ContactNo: number;
  Description: string;
  HolderAccountNo: string;
}

export class BankAccountShopInfo {
  ShopBankAccountId: number;
  BankAccountId: number;
  AccountDesc: string;
}

export class ShopSummary {
  ShopId: number;
  ShopName: string;
  ShopTypeId: number;
  ShopTypeName: string;
  ShopTypeDisplayName: string;
  ParentShopId: number;
  ParentShopName: string;
  ShopCode: string;
  BoatCustomerBill: boolean;
  DirectCustomerBill: boolean;
  BulkBill: boolean;
  Title1: string;
  Title2: string;
  Footer1: string;
  Footer2: string;
  Address: string;
  ContactNo: string;
  GstNo: string;
  PurchBankAccountId: number;
  SaleBankAccountId: number;
  ShopUnitId: number;
  AdOfficeId: number;
  ShopUnitName: string;
  ActiveOpenDate: Date;
  QuotaApplicable: boolean;
  PurchExpenseAssd: boolean;
  SchemeTypeId: number;

  PayOptionCash: boolean;
  PayOptionCredit: boolean;
  PayOptionOnlineCredit: boolean;
  PayOptionOwn: boolean;
  PayOptionPayOnSale: boolean;
  PayOptionPayLater: boolean;
}

export class DirectCustomer {
  DirectCustomerId: number;
  CustomerName: string;
  Address: string;
  MobileNo: string;
  IsReseller: boolean;
  CustomerTypeId: number;
  IsOwn: boolean;
  ContactPerson: string;
}

export class ParentProduct {
  ParentProductId: number;
  ParentProductName: string;
  Uom: string;
  RateEditable: boolean;
  Categories: string[];
}

export class Supplier {
  SupplierId: number;
  SupplierName: string;
  SupplierShortName: string;
  Address: string;
  ContactNo: string;
  SupplierGstNo: string;
  ShopId: number;
  ShopName: string;
  IsInternalSupplier: boolean;
  ShopIds: number[];
  IsSelected: boolean;
}

export class UserSummary {
  UserId: number;
  UserName: string;
  Password: string;
  FullName: string;
  RoleId: number;
  RoleName: string;
  EmailId: string;
  NewEmailId: string;
  EditEmailId: boolean;
  MobileNo: string;
  IsActive: boolean;
  UserShops: UserShop[];
  SessionCount: number;
  LastLoggedIn: Date;
}

export class MasterProduct {
    ParentProductId: number;
    ParentProductName: string;
    UomTypeId: number;
    UomTypeName: string;
    FillTypeId: number;
    FillTypeName: string;
    SGst: number;
    CGst: number;
    IGst: number;
    IncludingTax: boolean;
    StockCheck: boolean;
    DailyRate: boolean;
    constructor(parentProductId: number, parentProductName: string, uomTypeId: number,
                fillTypeId: number, SGst: number, CGst: number, IGst: number,
                includingTax: boolean, stockCheck: boolean, dailyRate: boolean) {
                  this.ParentProductId = parentProductId;
                  this.ParentProductName = parentProductName;
                  this.UomTypeId = uomTypeId;
                  this.FillTypeId = fillTypeId;
                  this.SGst = SGst;
                  this.CGst = CGst;
                  this.IGst = IGst;
                  this.IncludingTax = includingTax;
                  this.StockCheck = stockCheck;
                  this.DailyRate = dailyRate;
                }
}

export class UserShop {
  UserShopId: number;
  ShopId: number;
  ShopName: string;
  constructor(shopId: number, shopName: string) {
    this.ShopId = shopId;
    this.ShopName = shopName;
  }
}

export class CashbookEntry {
  TransactionDate: Date;
  UserName: string;
  SettlementDates: Date[];
  Settlement: SettlementDetail[];
  IsConfirmed: boolean;
  IsDenominated: boolean;
  Reason: string;
  CashbookSummaryId: number;
  IsCashbookSkipped: boolean;
  ShopId: number;
  ShopName: string;
}

export class SettlementDetail {
  PaymentMode: string;
  Amount: number;
}

export class CommonPagedBase<T> {
  TotalRecords: number;
  Summary: T[];
}

export class CommentPaged {
  TotalRecords: number;
  Summary: CommentSummary[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class CommentSummary {
  TransactionDate: Date;
  UserName: string;
  Comment: string;
  DateForComment: Date;
}

export class BoatCustomerPaged {
  TotalRecords: number;
  Summary: BoatCustomer[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class QuotaPaged {
  TotalRecords: number;
  Summary: Quota[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class QuotaShortInfo {
  QuotaId: number;
  QuotaName: string;
  Discount: number;
  SubsidyRate: number;
  constructor(quotaId: number, quotaName: string, discount: number) {
    this.QuotaId = quotaId;
    this.QuotaName = quotaName;
    this.Discount = this.Discount;
  }
}

export class Quota {
  QuotaId: number;
  QuotaName: string;
  ValidFrom: Date;
  ValidUpto: Date;
  QuotaTypeId: number;
  QuotaTypeName: string;
  IsActive: boolean;

  constructor(quotaId: number, quotaName: string, validFrom: Date, validUpto: Date, quotaTypeId: number,
              quotaTypeName: string, isActive: boolean) {
    this.QuotaId = quotaId;
    this.QuotaName = quotaName;
    this.ValidFrom = validFrom;
    this.ValidUpto = validUpto;
    this.QuotaTypeId = quotaTypeId;
    this.QuotaTypeName = quotaTypeName;
    this.IsActive = isActive;
  }

}

export class PendingPaymentTransfer
{
    PaymentTransferId: number;
    ShopName: string;
    Amount: number;
    ForwardDate: Date;
    IsSelected = false;
}

export class CurrentUser {
  UserId: number;
  UserName: string;
  Name: string;
  RoleName: string;
  ShopId: number;
  ParentShopId: number;
  ChildShopIds: number[];
  ShopName: string;
  PurchaseTaxFlag: boolean;
  PendingTaskCount: number;
  QuotaApplicable: boolean;
  PurchExpenseAssd: boolean;
  DiscountApplicable: boolean;
  PayOptionCash: boolean;
  PayOptionCredit: boolean;
  PayOptionOnlineCredit: boolean;
  PayOptionOwn: boolean;
  PayOptionPayOnSale: boolean;
  PayOptionPayLater: boolean;
  NonValidityProdFound: boolean;
  OthersExpense: boolean;
}

export class SelectedShop {
  ShopId: number;
  constructor(shopId: number) {
    this.ShopId = shopId;
  }
}

export class PurchaseSummary {
  PurchaseSummaryId: number;
  PurchaseDate: Date;
  CarrierNo: string;
  InvoiceNo: string;
  InvoiceAmount: number;
  RoundOff: number;
  SupplierId: number;
  constructor(purchaseDate: Date, carrierNo: string, invoiceNo: string, invoiceAmount: number, roundOff: number, supplierId: number) {
    this.PurchaseDate = purchaseDate;
    this.CarrierNo = carrierNo;
    this.InvoiceNo = invoiceNo;
    this.InvoiceAmount = invoiceAmount;
    this.RoundOff = roundOff;
    this.SupplierId = supplierId;
  }
}

export class PurchaseDetail {
  ProductId: number;
  ProductName: string;
  PurchaseAmount: number;
  Rate: number;
  Qty: number;
  Amount: number;
  Sgst: number;
  Cgst: number;
  Igst: number;
  Vat: number;
  Tcs: number;
  QuotaId: number;
  QuotaName: string;
  SellingRate: number;

  constructor(productId: number, productName: string, qty: number,
    sgst: number, cgst: number, igst: number,
    vat: number, tcs: number, amount: number,
    sellingRate: number,
    quotaId: number, 
    quotaName: string) {
    this.ProductId = productId;
    this.ProductName = productName;
    this.Qty = qty;
    this.Amount = amount;
    this.SellingRate = sellingRate;
    this.Sgst = sgst;
    this.Cgst = cgst;
    this.Igst = igst;
    this.Vat = vat;
    this.Tcs = tcs;
    this.QuotaId = quotaId;
    this.QuotaName = quotaName;
  }
}


export class Purchase {
  Summary: PurchaseSummary;
  PurchaseDetails: PurchaseDetail[];

  constructor(summary: PurchaseSummary, billingDetails: PurchaseDetail[]) {
    this.PurchaseDetails = billingDetails;
    this.Summary = summary;
  }

}

export class UserSession {
  TokenId: number;
  IpAddress: string;
  LoggedInTime: Date;
}

export class ShopProduct {
  ShopProductId: number;
  ProductId: number;
  ProductName: string;
  SubsidyBill: boolean;
  NonSubsidyBill: boolean;
  Included: boolean;
}

export class ShopCategory {
  ShopCategoryId: number;
  CategoryId: number;
  CategoryName: string;
  Included: boolean;
}

export class ProductCategory {
  ProductCategoryId: number;
  CategoryId: number;
  CategoryName: string;
  Included: boolean;
}

export class ShopProductUpdate {
  ShopId: number;
  UpdateProducts: ShopProduct[];
  DeleteShopProductIds: number[];
}

export class ShopCategoryUpdate {
  ShopId: number;
  UpdateCategories: ShopCategory[];
  DeleteShopCategoryIds: number[];
}

export class ProductCategoryUpdate {
  ProductId: number;
  UpdateCategories: ProductCategory[];
  DeleteProductCategoryIds: number[];
}

export class AuditProduct {
  ProductId: number;
  ProductName: string;
  ProductDisplayName: string;
  QtyPresc: number;
  UomTypeName: string;
  IsQuotaBased: boolean;
}
export class AuditEntry extends AuditProduct {
  Qty: number;
  QuotaId: number;
  QuotaName: string;
  constructor(productId: number, productName: string, productDisplayName: string, uomTypeName: string,
              qty: number, quotaId: number, quotaName: string) {
    super();
    this.ProductId = productId;
    this.ProductName = productName;
    this.ProductDisplayName = productDisplayName;
    this.UomTypeName = uomTypeName;
    this.Qty = qty;
    this.QuotaId = quotaId;
    this.QuotaName = quotaName;
  }
}

export class StockAudit {
  IsComplete: boolean;
  ShopId: number;
  AuditEntries: AuditEntry[];
  constructor() { }
}

export class ShopQuota {
  QuotaId: number;
  ProductId: number;
  Qty: number;
  ShopName: string;
  ProductName: string;
}

export class ShopQuotaFilter {
  QuotaId: number;
  BoatTypeId: number;
  ProductId: number;
  constructor(quotaId: number, boatTypeId: number, productId: number) {
    this.QuotaId = quotaId;
    this.BoatTypeId = boatTypeId;
    this.ProductId = productId;
  }
}

export class ShopQuotaUpdate {
  Filter: ShopQuotaFilter;
  ShopQuotas: ShopQuota[];
  constructor(filter: ShopQuotaFilter, quotas: ShopQuota[]) {
    this.Filter = filter;
    this.ShopQuotas = quotas;
  }
}

export class BoatQuotaFilter {
  QuotaId: number;
  ProductId: number;
  constructor(quotaId: number, productId: number) {
    this.QuotaId = quotaId;
    this.ProductId = productId;
  }
}

export class BoatQuotaUpdate {
  Filter: BoatQuotaFilter;
  BoatQuotas: ShopQuota[];
  constructor(filter: BoatQuotaFilter, quotas: ShopQuota[]) {
    this.Filter = filter;
    this.BoatQuotas = quotas;
  }
}

export class DateBulkRate {
  ShopProductId: number;
  ProductName: string;
  NMinus2Rate: number;
  NMinus1Rate: number;
  DateRate: number;
  NewDateRate: number;
  IsSelected = false;
  StockQty: number;
}

export class BulkRateUpdate {
  ShopProductId: number;
  DateRate: number;
  constructor(shopProductId: number, dateRate: number) {
    this.ShopProductId = shopProductId;
    this.DateRate = dateRate;
  }
}

export class ProductRate {
  QuotaId: number;
  ProductId: number;
  Rate: number;
  Rate_1: number;
  Rate_2: number;
  SGstPerc: number;
  CGstPerc: number;
  IGstPerc: number;
  SubsidyRate: number;
  SubsidyRate_1: number;
  SubsidyRate_2: number;
  Discount: number;
  WFRate: number;
  ShopName: string;
  ProductName: string;
  RateEditable: boolean;
  IncludingTax: boolean;
  Quotas: QuotaShortInfo[];
  SubsidyRateDate_1: Date;
  SubsidyRateDate_2: Date;
}

export class ProductRateFilter {
  RateDate: string;
  ConcessionTypeId: number;
  BoatTypeId: number;
  ProductId: number;
  QuotaId: number;

  constructor(rateDate: string, productId: number, quotaId: number) {
    this.RateDate = rateDate;
    this.ProductId = productId;
    this.QuotaId = quotaId;
  }

}

export class ProductRateUpdate {
  filter: ProductRateFilter;
  productRates: ProductRate[];
  constructor(filter: ProductRateFilter, productRates: ProductRate[]) {
    this.filter = filter;
    this.productRates = productRates;
  }
}

export interface MyShopSale {
  Product: string;
  ConcessionTypeDisplayName: string;
  BoatTypeDisplayName: string;
  PaymentTypeDisplayName: string;
  PaymentTypeName: string;
  SoldQty: number;
  QtyPres: number;
  SoldAmount: number;
}

export interface MyShopPurchase {
  Product: string;
  ConcessionTypeDisplayName: string;
  PurchasedQty: number;
  QtyPres: number;
  PurchasedAmount: number;
}

export interface MyShopSalesPurchase {
  SalePurchaseDate: Date;
  Sales: MyShopSale[];
  Purchases: MyShopPurchase[];
}

export class OpenCloseStatusRequest {
  ShopId: number;
  StatusDate: string;
  constructor(shopId: number, statusDate: string) {
    this.ShopId = shopId;
    this.StatusDate = statusDate;
  }
}

export class SalesPurchasePaged {
  TotalRecords: number;
  Summary: SalesPurchaseHistory[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class SalesPurchaseHistory {
  TransactionDate: Date;
  ProductId: number;
  ProductName: string;
  IsSubsidy: boolean;
  OpeningQty: number;
  PurchaseQty: number;
  SalesQty: number;
  AdjustmentQty: number;
  AuditQty: number;
  ClosingQty: number;
}

export class StockHistoryPaged {
  TotalRecords: number;
  Summary: StockChangeHistory[];
  constructor() {
    this.TotalRecords = 0;
    this.Summary = [];
  }
}

export class StockChangeHistory {
  ActivityTypeId: number;
  ActivityType: string;
  ActivityTime: string;
  RefNo: string;
  Qty: number;
}

export interface OpenCloseStatusResponse {
  EntryFound: boolean;
  TransactionDate: Date;
  OpenTime: Date;
  CloseTime: Date;
  OpenedBy: string;
  ClosedBy: string;
  BoatBulkBilling: boolean;
}

export interface BoatBulkBillDetail {
  ProductName: string;
  BillDate: Date;
  Qty: number;
  QtyPres: number;
}

export class BillDateAmount {
  BillDate: Date;
  CreditAmount: number;
  Amount: number;
  IsClosed: boolean;
}

export class PaymentModeAmount extends LkType {
  Amount: number;
  PaymentDate: Date;
  constructor(lkType: LkType) {
    super(lkType.TypeId, lkType.TypeName, lkType.TypeDisplayName);
    this.Amount = 0;
  }
}

export class DayAmount {
  BillDate: Date;
  SaleAmount: number;
}

export class PendingPayment {
  BillDate?: Date;
  PaymentModeAmounts: PaymentModeAmount[];
  DayAmounts: DayAmount[];
  CreditSaleAmt: number;
  CreditSaleRecdAmt: number;
  OnLineSaleAmt: number;
  OnLineSaleRecdAmt: number;
  OwnSaleAmt: number;
  OwnSaleRecdAmt: number;
  PayLaterSaleAmt: number;
  PayLaterSaleRecdAmt: number;
  PayOnSaleAmt: number;
  PayOnSaleRecdAmt: number; 
  CashSaleAmt: number;
  CashSaleRecdAmt: number;
  IsUpdated = false;
}

export class AmountDetail {
  BillDate: Date;
  BillAmount: number;
  PaymentModeAmounts: PaymentModeAmount[];
  ReSoldItems: ResalePending[];
  IsSelected: boolean;
  IsClosed: boolean;
  DebtorId: number;
  constructor(billDate: Date, billAmount: number, isClosed: boolean) {
    this.BillDate = billDate;
    this.BillAmount = billAmount;
    this.IsSelected = false;
    this.IsClosed = isClosed;
  }
}

export class SkipPayment {
  ShopOpenCloseId: number;
  CommentDate: Date;
  Comment: string;
  constructor(shopOpenCloseId: number, commentDate: Date, comment: string) {
    this.ShopOpenCloseId = shopOpenCloseId;
    this.CommentDate = commentDate;
    this.Comment = comment;
  }
}

export class DepositEntry {
  ShopOpenCloseId: number;
  //BillFromDate: Date;
  //BillEndDate: Date;
  TransactionDate: Date;
  Denomination: Denomination;
  Amount = 0;
  IsCompleted = false;
  CashbookDate: Date;
  constructor(shopOpenCloseId: number, transactionDate: Date) {
    this.ShopOpenCloseId = shopOpenCloseId;
    this.TransactionDate = transactionDate;
  }
}

export class Denomination {
  Rs1: number;
  Rs2: number;
  Rs5: number;
  Rs10: number;
  Rs20: number;
  Rs50: number;
  Rs100: number;
  Rs200: number;
  Rs500: number;
  Rs1000: number;
  Rs2000: number;
  // DenoDate: Date;
  constructor(rs1: number, rs2: number, rs5: number, rs10: number,
              rs20: number, rs50: number, rs100: number, rs200: number,
              rs500: number, rs1000: number, rs2000: number) {
    this.Rs1 = rs1;
    this.Rs2 = rs2;
    this.Rs5 = rs5;
    this.Rs10 = rs10;
    this.Rs20 = rs20;
    this.Rs50 = rs50;
    this.Rs100 = rs100;
    this.Rs200 = rs200;
    this.Rs500 = rs500;
    this.Rs1000 = rs1000;
    this.Rs2000 = rs2000;
  }
}

export class SettlementDenomination {
  SettlementSummaryId: number;
  Rs1: number;
  Rs2: number;
  Rs5: number;
  Rs10: number;
  Rs20: number;
  Rs50: number;
  Rs100: number;
  Rs200: number;
  Rs500: number;
  Rs1000: number;
  Rs2000: number;
  // DenoDate: Date;
  constructor(summaryId: number, denomination: Denomination) {
    this.Rs1 = denomination.Rs1;
    this.Rs2 = denomination.Rs2;
    this.Rs5 = denomination.Rs5;
    this.Rs10 = denomination.Rs10;
    this.Rs20 = denomination.Rs20;
    this.Rs50 = denomination.Rs50;
    this.Rs100 = denomination.Rs100;
    this.Rs200 = denomination.Rs200;
    this.Rs500 = denomination.Rs500;
    this.Rs1000 = denomination.Rs1000;
    this.Rs2000 = denomination.Rs2000;
    // this.DenoDate = denomination.DenoDate;
    this.SettlementSummaryId = summaryId;
  }
}

export class BillTotalAmount {
  BillDate: Date;
  PaymentModeAmounts: PaymentModeAmount[];
  DebtorId: number;
  constructor() {
  }
}

export class TaskAction {
  Task: UserTask;
  Action: string;
}

export class ExpenseCancel {
  ExpenseId: number;
}


export class BillCancel {
  SummaryId: number;
}

export class PurchaseCancel {
  SummaryId: number;
}

export interface MonthlySale {
  MonthName: string;
  SubsidySale: number;
  NonSubsidySale: number;
}

export interface MonthlySalePurchase {
  MonthName: string;
  SubsidySale: number;
  NonSubsidySale: number;
  SubsidyPurchase: number;
  NonSubsidyPurchase: number;
}

export interface DailySale {
  DateName: string;
  SubsidySale: number;
  NonSubsidySale: number;
}

export class ClearDay {
  ShopId: number;
  ClearDate: Date;
}

export class BoatCustomer {
  BoatCustomerId: number;
  CustomerName: string;
  FatherOrHusbandName: string;
  PhotoFileUniqueId: string;
  Address: string;
  Pincode: string;
  MobileNo: string;
  SocietyName: string;
  SocietyMemberNo: string;
  BiometricIdNo: string;
  AadhaarNo: string;
  BankAccNo: string;
  BankIfscCode: string;
  BankName: string;
  BankBranch: string;
}

export class BillPrint {
  BillInfo: Billing;
  BoatInfo: Boat;
  ShopInfo: Shop;
  PaymentModes: PaymentMode[];
}

export class PaymentMode {
  Mode: string;
  Amount: number;
  AddnInfo: string;
}
export class SettlementReceipt {
  ReceiptId: number;
  SummaryId: number;
  ReceiptFileName: string;
  ReceiptUniqId: string;
  ActionByName: string;
  ActionDateTime: Date;
  Description: string;
}

export class Boat extends BoatCustomer {
  BoatId: number;
  BoatTypeId: number;
  BoatTypeName: string;
  BoatRegNo: string;
  BoatCerttificateFileUniqId: string;
  ShopId: number;
  ShopName: string;
  StatusId: number;
  StatusName: string;
  StatusDisplayName: string;
  QuotaBalanceText: string;
}

export class MessageBoxCall {
  OkText: string;
  CancelText: string;
  Msg: string;
  Callback: (x: number) => void;
  constructor(okText: string, cancelText: string, msg: string) {
    this.OkText = okText;
    this.CancelText = cancelText;
    this.Msg = msg;
  }
}

export class TaskAssignment {
  TaskAssignmentId: number;
  UserCategory: string;
  CategoryId: number;
  CategoryObjectName: string;
}

export class BillDetailForReturn {
  BillDetailId: number;
  ProductId: number;
  ProductName: number;
  Rate: number;
  BillQty: number;
  Returned: number;
  Qty = 0;
}

export class BillReturn {
  BillSummaryId: number;
  ReturnDetails: BillDetailForReturn[];
  PaymentDetail: AmountDetail;
}

export interface BulkUploadBoatShortInfo
{
    CustomerId: string;
    CustomerName: string;
    PhoneNo: string;
    BoatRegNo: string;
    BoatType: string;
}

export class BulkBoatBillEntryRequest
{
  BoatCustomerId: number;
  RegNo: string;
  Qty: number;
}

export class BulkBoatBillEntryResponse
{
  CustomerId: string;
  CustomerName: string;
  PhoneNo: string;
  BoatRegNo: string;
  BoatType: string;
  Qty: number;
}

export class PaperSetting {
  SettingName: string;
  Width: number;
  Height: number;
  UnitType: string;
  constructor(settingName: string, width: number, height: number, unitType: string) {
    this.SettingName = settingName;
    this.Width = width;
    this.Height = height;
    this.UnitType = unitType;
  }
}

export class PageSetting {
  Width: number;
  Height: number;
  Left: number;
  Right: number;
  Top: number;
  Bottom: number;
  UnitType: string;
  Orientation: string;
  constructor(width: number, height: number,
    left: number, right: number, top: number, bottom: number, unitType: string, orientation: string) {
    this.Width = width;
    this.Height = height;
    this.Left = left;
    this.Right = right;
    this.Top = top;
    this.Bottom = bottom;
    this.UnitType = unitType;
    this.Orientation = orientation;
  }
}