import { Component, OnInit, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Util } from '../models/Util';
import { UserShopShortInfo, UpdateUserShop } from '../models/Poco';
import { WindowRef } from '../service/window-ref';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-shop',
  templateUrl: './user-shop.component.html',
  styleUrls: ['./user-shop.component.css'],
  providers: [WindowRef]
})
export class UserShopComponent implements OnInit {

  userId: number;
  shops: UserShopShortInfo[] = [];
  displayedColumns: string[] = ['IsSelected', 'ShopName'];
  constructor(@Inject(MAT_DIALOG_DATA) data: number, private util: Util, private http: HttpClient,
              private dialogRef: MatDialogRef<UserShopComponent>, private dialog: MatDialog,
              private winref: WindowRef
  ) {
    this.userId = data;
    this.ShowShops(data);
  }

  ngOnInit() {
  }

  ShowShops(userId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<UserShopShortInfo[]>(
      this.util.GetServerUrl() + '/allusershops/' + userId, { headers, observe: 'response' }).subscribe(res => {
        this.shops = res.body;
      }
      );
  }

  doSave() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const updateUserShop = new UpdateUserShop();
    updateUserShop.UserShops = this.shops.filter(x => x.UserShopId != null || x.IsSelected);
    updateUserShop.UserId = this.userId;

    this.http.post(
      this.util.GetServerUrl() + '/updateusershop', updateUserShop, { headers, observe: 'response' }).subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
          this.dialogRef.close(true);
        });
      }
      );
  }

  doCancel() {
    this.dialogRef.close();
  }

}
