import { Component, OnInit, Input, Inject } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Util } from '../models/Util';
import { UserTask, UserSummary, TaskAssignment,
  TaskTypeFieldValue, UserTaskAction, LkType } from '../models/Poco';
import { Router } from '@angular/router';
import { WindowRef } from '../service/window-ref';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { UserSearchService } from '../service/user-search.service';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
  selector: 'app-task-action',
  templateUrl: './task-action.component.html',
  styleUrls: ['./task-action.component.css'],
  providers: [
    WindowRef
  ]
})
export class TaskActionComponent implements OnInit {
  TaskId = 0;
  TaskTypes: LkType[] = [];
  SelTaskType: LkType;
  TaskDesc: string;
  TaskDescRegEx: string;
  proposedDate: Moment;
  sProposedDate: string;
  TaskAddnFields: TaskTypeFieldValue[] = [];

  AssignedObjects: TaskAssignment[] = [];

  filterUser = new FormControl();
  filteredUsers: Observable<UserSummary[]>;

  UserVal: string;
  filterRole: string;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(@Inject(MAT_DIALOG_DATA) data: number, private http: HttpClient, private util: Util, private dialog: MatDialog,
              private winref: WindowRef, private router: Router, private dialogRef: MatDialogRef<TaskActionComponent>,
              private userSearchService: UserSearchService,
              private snackBar: MatSnackBar) {
    this.TaskId = data;
  }

  ngOnInit(): void {
    this.filteredUsers = this.filterUser.valueChanges
    .pipe(
      debounceTime(300),
      switchMap(value =>
        this.userSearchService.search(this.filterRole, value as string)
      )
    );
    this.loadTaskTypes();
  }

  loadTaskTypes(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/tasktypes',
      { headers, observe: 'response' }).subscribe(res => {
        this.TaskTypes = res.body;
        if (this.TaskId > 0) {
          this.showDetail(this.TaskId);
        }

      }
      );
  }

  loadTaskAdditionFields(taskTypeId: number): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<TaskTypeFieldValue[]>(
      this.util.GetServerUrl() + '/tasktypefield/' + taskTypeId,
      { headers, observe: 'response' }).subscribe(res => {
        this.TaskAddnFields = res.body;
      }
      );
  }

  TaskTypeChanged(curVal: any): void {
    if (this.TaskId == null || this.TaskId === 0) {
      this.loadTaskAdditionFields(this.SelTaskType.TypeId);
    }
  }

  displayUserFn(user: UserSummary): string {
    if (user) { return user.FullName; }
    return null;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    if (input) {
      input.value = '';
    }
  }

  remove(taskAssignment: TaskAssignment): void {
    const index = this.AssignedObjects.indexOf(taskAssignment);
    if (index >= 0) {
      this.AssignedObjects.splice(index, 1);
    }
  }


  setUser(evnt: any): void {
    const user = evnt.option.value as UserSummary;
    const assignment = new TaskAssignment();
    assignment.UserCategory = 'User';
    assignment.CategoryId = user.UserId;
    assignment.CategoryObjectName = user.FullName;
    this.AssignedObjects.push(assignment);
  }

  showDetail(taskId: number): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const p = new HttpParams().set('mode', 'action');

    this.http.get<UserTask>(
      this.util.GetServerUrl() + '/taskid/' + taskId, { headers, params: p, observe: 'response' })
      .subscribe(res => {
        const task = res.body as UserTask;
        this.SelTaskType = this.TaskTypes.filter(x => x.TypeId === task.TaskTypeId)[0];
        this.TaskDesc = task.TaskDesc;
        this.proposedDate = moment(task.ProposedDate);
        this.sProposedDate = this.proposedDate.format('DD/MM/YYYY');
        this.AssignedObjects = task.Assignments;
        this.TaskAddnFields = task.FieldValues;
      }
      );
  }

  taskAction(action: string): void {

    if (this.proposedDate == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the proposed date', null);
      return;
    }

    for (const element of this.TaskAddnFields) {
      if ( element.IsRequired && ( element.FieldValue == null || element.FieldValue.length === 0 ) ) {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the value for \'' + element.FieldName + '\'', null);
        return;
      }
      if ( element.Pattern != null && element.Pattern.length > 0 ) {
        const exp = new RegExp(element.Pattern);
        if (!exp.test(element.FieldValue)) {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the valid value for \'' + element.FieldName + '\'', null);
          return;
        }
      }
    }

    if (this.TaskDesc == null || this.TaskDesc.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the task detail', null);
      return;
    }

    const isPaymentRequest = this.SelTaskType.TypeName === 'PaymentRequest';

    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No',
    'Are you sure to ' + (action === 'Completed' ? ( isPaymentRequest ? 'accept' : 'complete' ) : 'Cancel')
    + ' the task ' + '?', (x: number) => {
      if (x === 1) {
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });

        const task = new UserTaskAction();
        task.UserTaskId = this.TaskId;
        task.TaskDesc = this.TaskDesc;
        task.FieldValues = this.TaskAddnFields;
        task.Action = (action === 'Completed' ? ( isPaymentRequest ? 'Accepted' : 'Completed' ) : 'Cancelled');
        this.http.post(
          this.util.GetServerUrl() + '/taskaction', task, { headers, observe: 'response' }).subscribe(res => {
            /*this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null,
            (action === 'Completed' ? ( isPaymentRequest ? 'Accepted' : 'Completed' ) : 'Cancelled'), () => {
              this.dialogRef.close(true);
            });*/
            this.dialogRef.close(true);
            this.snackBar.open((action === 'Completed' ? ( isPaymentRequest ? 'Accepted' : 'Completed' ) : 'Cancelled'), null, {
              duration: 1000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              panelClass: ['snackbar']
            });


          }, err => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
          }
          );

      }
    });

  }

  doCancel(): void {
    this.dialogRef.close(false);
  }
}
