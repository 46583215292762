import { Boat, BoatPaged } from '../models/Poco';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { BoatService } from '../service/boat.service';

// https://blog.angular-university.io/angular-material-data-table/

export class BoatDataSource implements DataSource<Boat> {

    private boatSubject = new BehaviorSubject<BoatPaged>(new BoatPaged());
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    totalRecords = 0;

    constructor(private boatService: BoatService) { }

    connect(collectionViewer: CollectionViewer): Observable<Boat[]> {
        return this.boatSubject.asObservable()
            .pipe(map((x) => {
                // console.log(this.totalRecords);
                this.totalRecords = x.TotalRecords; return x.Summary;
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.boatSubject.complete();
        this.loadingSubject.complete();
    }

    loadBoat(pageIndex: number, pageSize: number, sortKey = 'FromDate', sortDir = 'asc') {
        this.loadingSubject.next(true);
        this.boatService.listBoat(pageIndex, pageSize, sortKey, sortDir)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((boatPaged: BoatPaged) => {
                this.totalRecords = boatPaged.TotalRecords;
                this.boatSubject.next(boatPaged);
            }
            );
    }
}
