import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Moment } from 'moment';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';

@Component({
  selector: 'app-payment-comment',
  templateUrl: './payment-comment.component.html',
  styleUrls: ['./payment-comment.component.css'],
  providers: [
    WindowRef
  ]
})
export class PaymentCommentComponent implements OnInit {
  paymentComment: string;
  denoDate: Moment;
  constructor(public dialogRef: MatDialogRef<PaymentCommentComponent>,
              private util: Util,
              private dialog: MatDialog, private winref: WindowRef) { }

  ngOnInit() {
  }

  proceedComment() {
    if (this.paymentComment == null || this.paymentComment.trim().length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the comment', null);
    }
    this.dialogRef.close({ CommentDate: this.denoDate.utcOffset(0, true).toDate(), Comment: this.paymentComment });
  }

  cancelComment() {
      this.dialogRef.close(null);
    }
}

