import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Product, LkType, ProductRate, ProductRateFilter, ProductRateUpdate,
  OpenCloseStatusResponse, Quota, DateBulkRate, BulkRateUpdate } from '../models/Poco';
import { Util } from '../models/Util';

import { WindowRef } from '../service/window-ref';
import { MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-product-bulk-rate',
  templateUrl: './product-bulk-rate.component.html',
  styleUrls: ['./product-bulk-rate.component.css'],
  providers: [
    WindowRef
  ]
})
export class ProductBulkRateComponent implements OnInit {

  displayedColumns: string[] = ['ProductName', 'StockQty', 'DateRate', 'NMinus1Rate', 'NMinus2Rate'];
  selectedDate2: Date = new Date();
  selectedDate1: Date = new Date();
  selectedDate: Date = new Date();
  selectedCount = 0;
  @ViewChild('details', { static: true }) details: MatTable<DateBulkRate>;
  ProductDateRates: DateBulkRate[];
  constructor(private http: HttpClient, private util: Util, private dialog: MatDialog, private winref: WindowRef) { }

  ngOnInit(): void {
    this.loadBillingStatus();
  }

  loadBillingStatus(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const dateInddMMyyyy = this.util.GetddMMMyyyyDate(new Date());

    this.http.get<OpenCloseStatusResponse>(
      this.util.GetServerUrl() + '/GetOpenCloseStatus?StatusDate=' + dateInddMMyyyy,
      { headers, observe: 'response' }).subscribe(res => {

        const date = new Date(res.body.TransactionDate);
        const date1 = new Date(res.body.TransactionDate);
        const date2 = new Date(res.body.TransactionDate);

        date1.setDate(date1.getDate() - 1);
        date2.setDate(date2.getDate() - 2);

        this.selectedDate = date;
        this.selectedDate1 = date1;
        this.selectedDate2 = date2;
        this.loadProductRates();
      }
      );
  }

  loadProductRates(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<DateBulkRate[]>(
      this.util.GetServerUrl() + '/bulkrateproducts', { headers, observe: 'response' }).subscribe(res => {
        this.ProductDateRates = res.body;
        this.updateSelectionCount();
      }
      );
  }

  importData(): boolean {
    let cnt = 0;
    this.ProductDateRates.forEach(x => {
      if (x.NMinus1Rate !== null && (x.DateRate === undefined || x.DateRate === null || x.DateRate === 0)) {
        x.IsSelected = true;
        x.NewDateRate = x.NMinus1Rate;
        cnt++;
      }
    });
    this.updateSelectionCount();
    return false;
  }

  updateSelectionCount(): void {
    this.selectedCount = this.ProductDateRates.filter(x => x.IsSelected).length;
  }

  updateData(): void {
    const invalidCount = this.ProductDateRates.filter(x => x.IsSelected)
        .filter(x => x.NewDateRate === undefined || x.NewDateRate <= 0).length;
    if ( invalidCount > 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Check the value', null);
    }
    else {
      const rates = this.ProductDateRates.filter(x => x.IsSelected)
        .map(x => new BulkRateUpdate(x.ShopProductId, x.NewDateRate));

      const token = sessionStorage.getItem('Token') ?? '';
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        token
      });

      this.http.post(this.util.GetServerUrl() + '/updatebulkrate?rateDate=' + moment(this.selectedDate).format('MM/DD/YYYY'),
      rates, { headers, observe: 'response' })
      .subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
          this.loadProductRates();
        });
      }
      );
  }

  }
}
