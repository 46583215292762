import { CashbookEntry, SettlementPaged } from '../models/Poco';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { SettlementService } from '../service/settlement.service';

// https://blog.angular-university.io/angular-material-data-table/

export class SettlementDataSource implements DataSource<CashbookEntry> {

    private settlementSubject = new BehaviorSubject<SettlementPaged>(new SettlementPaged());
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    totalRecords = 0;

    constructor(private settlementService: SettlementService) { }

    connect(collectionViewer: CollectionViewer): Observable<CashbookEntry[]> {
        return this.settlementSubject.asObservable()
            .pipe(map((x) => {
                // console.log(this.totalRecords);
                this.totalRecords = x.TotalRecords; return x.Summary;
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.settlementSubject.complete();
        this.loadingSubject.complete();
    }

    loadSettlements(pageIndex: number, pageSize: number, sortKey = 'FromDate', sortDir = 'asc') {
        this.loadingSubject.next(true);
        this.settlementService.listSettlement(pageIndex, pageSize, sortKey, sortDir)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((settlementPaged: SettlementPaged) => {
                this.totalRecords = settlementPaged.TotalRecords;
                this.settlementSubject.next(settlementPaged);
            }
            );
    }
}
