import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Boat } from '../models/Poco';

@Component({
  selector: 'app-confirm-boat',
  templateUrl: './confirm-boat.component.html',
  styleUrls: ['./confirm-boat.component.css']
})
export class ConfirmBoatComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: Boat, public matDialogRef: MatDialogRef<ConfirmBoatComponent>
  ) {
    matDialogRef.backdropClick().subscribe( x => {
      this.matDialogRef.close(null);
    });
    matDialogRef.afterClosed().subscribe( x => {
      this.matDialogRef.close(null);
    });
  }

  ngOnInit() {
  }

  Confirmed() {
    this.matDialogRef.close(this.data);
  }

  Cancelled() {
    this.matDialogRef.close(null);
  }

}
