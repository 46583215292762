import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

import { tap } from 'rxjs/operators';
import { merge, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { WindowRef } from '../service/window-ref';
import { Util } from '../models/Util';
import { BillCancel, SettlementReceipt, BankReceipts, SettlementConfirmation, SettlementDenomination, Denomination, SkipPayment, DepositEntry, PendingPaymentTransfer } from '../models/Poco';
import { SettlementService } from '../service/settlement.service';
import { SettlementDataSource } from '../datasource/settlement.datasource';
import { ContextService } from '../service/context-change';
import { BankReceiptsComponent } from '../bank-receipts/bank-receipts.component';
import { DenominationPageComponent } from '../settlement/denomination-page/denomination-page.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PaymentCommentComponent } from '../payment-comment/payment-comment.component';
import { DepositEntryConfirmComponent } from '../deposit-entry-confirm/deposit-entry-confirm.component';
import { Moment } from 'moment';
import { AcceptAmountComponent } from '../accept-amount/accept-amount.component';

@Component({
  selector: 'app-settlement-list',
  templateUrl: './settlement-list.component.html',
  styleUrls: ['./settlement-list.component.css'],
  providers: [SettlementService],
  entryComponents: [BankReceiptsComponent, DepositEntryConfirmComponent]
})
export class SettlementListComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['ShopName', 'TransactionDate', 'CashOnHand', 'CardPayment',
    'FundTransfer', 'PaperCheque', 'OtherCredit', 'TotalAmount', 'RecordedBy', 'Reason', 'AccCashSaleAmt', 'RemittanceAmount', 'Action'];
  pageSize = 10;
  totalRecords: number;
  dataSource: SettlementDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  contextSubscriber: Subscription;
  currentShopId: number;

  constructor(private settlementService: SettlementService, private contextChange: ContextService,
    private http: HttpClient, private util: Util,
    public router: Router, private dialog: MatDialog, private winref: WindowRef) {
    this.contextSubscriber = contextChange.getObservable().subscribe(x => {
      this.loadSettlements();
    });
  }

  ngOnInit() {
    this.currentShopId = parseInt(sessionStorage.getItem('BillerShopId'));
    this.dataSource = new SettlementDataSource(this.settlementService);
    this.totalRecords = this.dataSource.totalRecords;
    this.dataSource.loadSettlements(0, 10, 'FromDate', 'asc');
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadSettlements())
      )
      .subscribe();
  }

  loadSettlements() {
    this.dataSource.loadSettlements(this.paginator.pageIndex, this.paginator.pageSize);
  }

  editBilling(summaryId: number) {
    this.router.navigate(['../BillingDetail', summaryId]);
  }

  completeDeposit(openCloseId: number) {

    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Payment will be completed. Are you sure?', (x: number) => {
      if (x === 1) {

        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });

        this.http.post(
          this.util.GetServerUrl() + '/CompleteDepositTransfer/' + openCloseId, null, { headers, observe: 'response' }).subscribe(res => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Completed', () => {
              this.loadSettlements();
            });
          }, err => {
            debugger;
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message ? err.error.Message : err.error, null);
          }
          );

      }
    });

  }

  ShowAcceptDeposit(shopOpenCloseId: number, transactionDate: Date) {

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<PendingPaymentTransfer[]>(
      this.util.GetServerUrl() + '/ListPendingPaymentTransfers/' + shopOpenCloseId,
      { headers, observe: 'response' }).subscribe(res => {


        if (res.body.length == 0) {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'No pendings', x => {
          });
        }
        else {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data = { ShopOpenCloseId: shopOpenCloseId, TransactionDate: transactionDate, PendingList: res.body };
          const dialogRef = this.dialog.open(AcceptAmountComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(() => {
            this.loadSettlements();
          });
        }

      }, err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error, x => {
        });
      }

      );


  }

  _AcceptDeposit(paymentTransferId: number, amount: number) {
    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Do you want to accept the amount - Rs. ' + amount + ' ?', (x: number) => {
      if (x === 1) {
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });

        this.http.post<boolean>(
          this.util.GetServerUrl() + '/ConfirmDepositFwd/' + paymentTransferId, null,
          { headers, observe: 'response' }).subscribe(res => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Accepted', null);
            this.loadSettlements();
          }, err => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error, null);
          }
          );
      }
    });

  }

  showBillPayment(cashbookSummaryId: number, isConfirmed: boolean, amount: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = new SettlementConfirmation(cashbookSummaryId, isConfirmed, amount);
    const dialogRef = this.dialog.open(BankReceiptsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.loadSettlements();
    });
  }


  skipPayment(shopOpenCloseId: number) {
    const dialogRef = this.dialog.open(PaymentCommentComponent,
      {
        width: '350px',
        data: null
      });


    dialogRef.afterClosed().subscribe((result: {CommentDate: Date, Comment: string}) => {
      if (result) {
        console.log(result);

        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });

        const paymentComment = new SkipPayment(shopOpenCloseId, result.CommentDate, result.Comment);

        this.http.post<boolean>(
          this.util.GetServerUrl() + '/SkipBillPayment', paymentComment,
          { headers, observe: 'response' }).subscribe(res => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Updated', null);
            this.loadSettlements();
          }, err => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error, null);
          }
          );
      }
    });
  }

  showDepositEntry(shopOpenCloseId: number, transactionDate: Date, IsDepositEntry: boolean) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token: token
    });

    const depositEntry = new DepositEntry(shopOpenCloseId, transactionDate);
    this.http.get<DepositEntry>(
      this.util.GetServerUrl() + '/loaddepositentry/' + shopOpenCloseId + '?isDeposit=' + (IsDepositEntry ? 'true' : 'false'),
      { headers: headers, observe: 'response' }).subscribe(res => {
        debugger;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = { isDepositEntry: IsDepositEntry, data: res.body, ShopOpenCloseId: shopOpenCloseId };
        const dialogRef = this.dialog.open(DepositEntryConfirmComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(() => {
          this.loadSettlements();
        });
      }, err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error, null);
      }
      );
  }


  ShowDenomination(settlementId: number, amount: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<Denomination>(
      this.util.GetServerUrl() + '/getdenomination/' + settlementId,
      { headers, observe: 'response' }).subscribe(res => {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = { Amount: Math.ceil(amount), Denomination: res.body, ReadOnly: true };
        const dialogRef = this.dialog.open(DenominationPageComponent, dialogConfig);
        // dialogRef.afterClosed().subscribe(() => { });
      }
      );
  }

  GetDenomination(settlementId: number, amount: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { Amount: Math.ceil(amount), Denomination: null, ReadOnly: false, DateRequired: true, MisMatchAllowed: false };
    const dialogRef = this.dialog.open(DenominationPageComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((denomination: Denomination) => {
      const token = sessionStorage.getItem('Token') ?? '';
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        token
      });

      const settlementDenomination = new SettlementDenomination(settlementId, denomination);

      this.http.post<number>(
        this.util.GetServerUrl() + '/updatedenomination', settlementDenomination,
        { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.loadSettlements();
          });
        }
        );
    });
  }
}
