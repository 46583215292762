import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MiscBankLedgerEntryComponent } from '../misc-bank-ledger-entry/misc-bank-ledger-entry.component';
import { MiscEntry, BankAccount, BankAccountShopInfo } from '../models/Poco';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Util } from '../models/Util';
import { LedgerDataSource } from '../datasource/ledger.datasource';
import { LedgerService } from '../service/ledger.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TempLedgerEntryComponent } from '../temp-ledger-entry/temp-ledger-entry.component';
import { WindowRef } from '../service/window-ref';

@Component({
  selector: 'app-bank-ledger',
  templateUrl: './bank-ledger.component.html',
  styleUrls: ['./bank-ledger.component.css'],
  entryComponents: [],
  providers: [LedgerService],
})
export class BankLedgerComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['TransactionDate', 'LedgerType', 'Description', 'OpenBalance', 'Credit', 'Debit',
    'CloseBalance', 'EntryBy', 'ShopName', 'Action'];
  settlementList = [];

  BankAccounts: BankAccountShopInfo[];
  SelBankAccount: BankAccountShopInfo;

  pageSize = 10;
  totalRecords: number;
  dataSource: LedgerDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private ledgerService: LedgerService, private dialog: MatDialog,
    private winref: WindowRef, private http: HttpClient, private util: Util) {

  }

  ngOnInit() {
    this.dataSource = new LedgerDataSource(this.ledgerService);
    this.totalRecords = this.dataSource.totalRecords;
    this.loadMyBankAccounts();
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadLedgers())
      )
      .subscribe();
  }

  loadLedgers() {
    this.dataSource.loadLedgers(this.SelBankAccount.BankAccountId, this.paginator.pageIndex, this.paginator.pageSize);
  }

  newEntry() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    const miscEntry = new MiscEntry(this.SelBankAccount.BankAccountId, null, null, null, null, null);
    dialogConfig.data = miscEntry;
    const dialogRef = this.dialog.open(MiscBankLedgerEntryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      const newentry = result as MiscEntry;
      if (result != null) {
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });
        this.http.post<boolean>(
          this.util.GetServerUrl() + '/makeMiscEntry', newentry, { headers, observe: 'response' }).subscribe(res => {
            this.dataSource.loadLedgers(this.SelBankAccount.BankAccountId, 0, 10, 'EntryDateTime', 'asc');
          });
      }
    }
    );
  }

  deleteBankLedgerEntry(bankLedgerEntryId: number) {
    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Are you sure?', (x: number) => {
      if (x === 1) {
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });
        this.http.post<boolean>(
          this.util.GetServerUrl() + '/deleteBankLedgerEntry/'+bankLedgerEntryId, null, { headers, observe: 'response' }).subscribe(res => {
            this.dataSource.loadLedgers(this.SelBankAccount.BankAccountId, 0, this.paginator.pageSize);
          }, err=> {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
          });
      }
    });
  }

  ShowEntry() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    //const miscEntry = new MiscEntry(this.SelBankAccount.BankAccountId, null, null, null);
    dialogConfig.data = this.SelBankAccount.BankAccountId;
    const dialogRef = this.dialog.open(TempLedgerEntryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.dataSource.loadLedgers(this.SelBankAccount.BankAccountId, 0, 10, 'EntryDateTime', 'asc');
    }
    );
  }

  loadMyBankAccounts() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<BankAccountShopInfo[]>(
      this.util.GetServerUrl() + '/mybankaccounts',
      { headers, observe: 'response' }).subscribe(res => {
        this.BankAccounts = res.body;
        if (this.BankAccounts.length > 0) {
          this.SelBankAccount = this.BankAccounts[0];
          this.dataSource.loadLedgers(this.SelBankAccount.BankAccountId, 0, 10, 'EntryDateTime', 'asc');
        }
      }
      );
  }

  accountChanged(curVal: any) {
    this.dataSource.loadLedgers(this.SelBankAccount.BankAccountId, 0, 10, 'EntryDateTime', 'asc');
  }
}
