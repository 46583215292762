import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { WindowRef } from '../service/window-ref';
import { Util } from '../models/Util';
import { AdjustmentService } from '../service/adjustment.service';
import { AdjustmentDataSource } from '../datasource/adjustment.datasource';

@Component({
  templateUrl: './adjustment.component.html',
  styleUrls: ['./adjustment.component.css'],
  providers: [AdjustmentService]
})
export class AdjustmentComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['AdjustmentDate', 'AdjustmentNo', 'AdjustmentType', 'Description', 'Detail', 'Action'];
  pageSize = 10;
  totalRecords: number;
  dataSource: AdjustmentDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private adjustmentService: AdjustmentService, public router: Router) {
  }

  ngOnInit(): void {
    this.dataSource = new AdjustmentDataSource(this.adjustmentService);
    this.totalRecords = this.dataSource.totalRecords;
    this.dataSource.loadAdjustment(0, 10, 'FromDate', 'asc');
  }

  ngAfterViewInit(): void {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadAdjustment())
      )
      .subscribe();
  }

  loadAdjustment(): void {
    this.dataSource.loadAdjustment(this.paginator.pageIndex, this.paginator.pageSize);
  }

}
