import { Component, OnInit, Input, ChangeDetectorRef, Inject } from '@angular/core';
import { BillPrint } from '../models/Poco';
import { Util } from '../models/Util';
import {DomSanitizer} from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-single-bill-print',
  templateUrl: './single-bill-print.component.html',
  styleUrls: ['./single-bill-print.component.css']
})
export class SingleBillPrintComponent implements OnInit {

  BillData: BillPrint;
  TotalDiscAmt: number;

  constructor(private ref: ChangeDetectorRef, private dialogRef: MatDialogRef<SingleBillPrintComponent>,
              @Inject(MAT_DIALOG_DATA) data: any, private util: Util,
              public domSanitization: DomSanitizer) {
      this.BillData = data as BillPrint;
      let disc = 0;
      for (const detail of this.BillData.BillInfo.BillingDetails) {
        disc += (detail.Qty * detail.DiscRate);
      }
      this.TotalDiscAmt = disc;
  }

  ngOnInit() {
    this.ref.detectChanges();
  }

  close() {
    this.dialogRef.close();
  }

}
