import { Component, OnInit, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Util } from '../models/Util';
import { UserShopShortInfo, UpdateUserShop, ShopSupplierShortInfo, UpdateShopSupplier } from '../models/Poco';
import { WindowRef } from '../service/window-ref';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-supplier-shop',
  templateUrl: './supplier-shop.component.html',
  styleUrls: ['./supplier-shop.component.css'],
  providers: [WindowRef]
})
export class SupplierShopComponent implements OnInit {

  supplierId: number;
  shops: ShopSupplierShortInfo[] = [];
  displayedColumns: string[] = ['IsSelected', 'ShopName'];
  constructor(@Inject(MAT_DIALOG_DATA) data: number, private util: Util, private http: HttpClient,
              private dialogRef: MatDialogRef<SupplierShopComponent>, private dialog: MatDialog,
              private winref: WindowRef
  ) {
    this.supplierId = data;
    this.ShowShops(data);
  }

  ngOnInit() {
  }

  ShowShops(supplierId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ShopSupplierShortInfo[]>(
      this.util.GetServerUrl() + '/allsuppliershops/' + supplierId, { headers, observe: 'response' }).subscribe(res => {
        this.shops = res.body;
      }
      );
  }

  doSave() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const updateUserShop = new UpdateShopSupplier();
    updateUserShop.SupplierShops = this.shops.filter(x => x.ShopSupplierId != null || x.IsSelected);
    updateUserShop.SupplierId = this.supplierId;

    this.http.post(
      this.util.GetServerUrl() + '/updatesuppliershop', updateUserShop, { headers, observe: 'response' }).subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
          this.dialogRef.close(true);
        });
      }
      );
  }

  doCancel() {
    this.dialogRef.close();
  }

}
