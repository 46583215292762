import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageBoxCall } from '../models/Poco';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.css']
})
export class MessageBoxComponent implements OnInit {

  OkText: string;
  CancelText: string;
  MessageContent: string;
  Callback: (x: number) => void;
  constructor(@Inject(MAT_DIALOG_DATA) public data: MessageBoxCall, public dialogRef: MatDialogRef<MessageBoxComponent>) {
    this.MessageContent = data.Msg;
    this.OkText = data.OkText;
    this.CancelText = data.CancelText;
    this.Callback = data.Callback;
  }

  ngOnInit() {
  }

  OkClicked() {
    this.dialogRef.close(1);
  }

  CancelClicked() {
    this.dialogRef.close(2);
  }

}
