import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Shop } from '../models/Poco';

@Component({
  selector: 'app-shop-selection',
  templateUrl: './shop-selection.component.html',
  styleUrls: ['./shop-selection.component.css']
})
export class ShopSelectionComponent implements OnInit {

  SelShop: Shop;
  ShopList: Shop[];
  constructor(@Inject(MAT_DIALOG_DATA) shopList: Shop[], public matDialogRef: MatDialogRef<ShopSelectionComponent>) {
    this.ShopList = shopList;
    matDialogRef.backdropClick().subscribe( x => {
      // console.log('backdropClick');
      this.matDialogRef.close(null);
    });
    matDialogRef.afterClosed().subscribe( x => {
      // console.log('afterClosed');
      this.matDialogRef.close(null);
    });
  }
  ngOnInit() {
  }

  Confirmed() {
    console.log(this.SelShop.ShopId);
    this.matDialogRef.close(this.SelShop.ShopId);
  }

  Cancelled() {
    this.matDialogRef.close(null);
  }

}
