import { PurchaseSummary, PurchasePaged } from '../models/Poco';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { PurchaseService } from '../service/purchase.service';

// https://blog.angular-university.io/angular-material-data-table/

export class PurchaseDataSource implements DataSource<PurchaseSummary> {

    private purchaseSubject = new BehaviorSubject<PurchasePaged>(new PurchasePaged());
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    totalRecords = 0;

    constructor(private purchaseService: PurchaseService) { }

    connect(collectionViewer: CollectionViewer): Observable<PurchaseSummary[]> {
        return this.purchaseSubject.asObservable()
            .pipe(map((x) => { console.log(x.Summary); this.totalRecords = x.TotalRecords; return x.Summary; }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.purchaseSubject.complete();
        this.loadingSubject.complete();
    }

    loadPurchase(pageIndex: number, pageSize: number, sortKey = 'FromDate', sortDir = 'asc') {
        this.loadingSubject.next(true);
        this.purchaseService.listPurchase(pageIndex, pageSize, sortKey, sortDir)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((purchasePaged: PurchasePaged) => {
                this.totalRecords = purchasePaged.TotalRecords;
                // console.log(purchasePaged);
                this.purchaseSubject.next(purchasePaged);
            }
            );
    }
}
