import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Purchase, PurchaseCancel } from '../models/Poco';
import { Util } from '../models/Util';
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { PurchaseService } from '../service/purchase.service';
import { PurchaseDataSource } from '../datasource/purchase.datasource';
import { ViewPurchaseComponent } from '../view-purchase/view-purchase.component';
import { Router } from '@angular/router';
import { WindowRef } from '../service/window-ref';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

@Component({
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.css'],
  providers: [PurchaseService]
})
export class PurchaseComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['PurchaseDate', 'SupplierName', 'InvoiceNo', 'CarrierNo', 'Qty', 'Amount', 'Detail', 'Action'];
  PurchaseList = [];
  pageSize = 10;
  totalRecords: number;

  IsBillingOpen = false;
  IsBillingClosed = false;

  dataSource: PurchaseDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  newPurchaseBtnDisabled = false;

  constructor(private http: HttpClient, private purchaseService: PurchaseService, public dialog: MatDialog,
    private util: Util, public router: Router, private winref: WindowRef) {
  }

  ngOnInit() {
    this.dataSource = new PurchaseDataSource(this.purchaseService);
    this.totalRecords = this.dataSource.totalRecords;
    this.dataSource.loadPurchase(0, 10, 'PurchaseDate', 'asc');
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadPurchase())
      )
      .subscribe();
  }

  loadPurchase() {
    this.dataSource.loadPurchase(this.paginator.pageIndex, this.paginator.pageSize);
  }

  onRowClicked() {
    // row: MatRow;
    // console.log('Row clicked: ', row);
  }
  viewPurchase(summaryId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<Purchase>(
      this.util.GetServerUrl() + '/Purchaseid/' + summaryId, { headers: headers, observe: 'response' }).subscribe(res => {
        const dialogRef = this.dialog.open(ViewPurchaseComponent, {
          width: '80%',
          height: '90%',
          data: res.body
        });
        dialogRef.afterClosed().subscribe(result => {
          this.loadPurchase();
        });
      }
      );
  }

  cancelPurchase(summaryId: number) {

    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Are you sure to cancel?', (x: number) => {
      if (x === 1) {
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });

        const obj = new PurchaseCancel();
        obj.SummaryId = summaryId;

        this.http.post(
          this.util.GetServerUrl() + '/cancelpurchase', obj, { headers, observe: 'response' }).subscribe(res => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Cancelled', () => {
              this.loadPurchase();
            });
          }, err => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
          }
          );

      }
    });

  }
}
