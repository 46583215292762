import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './Service/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private route: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.IsAuthenticated().then(
      (authenticated: boolean) => {
        if (authenticated) {
          const expectedRoles: string[] = route.data.expectedRoles;
          if (expectedRoles && expectedRoles.length > 0) {
            const role = sessionStorage.getItem('Role');
            return expectedRoles.filter(x => x === role).length > 0;
          } else {
            // this.route.navigate(['/']);
            window.location.href = '/';
          }
        } else {
          // this.route.navigate(['/']);
          window.location.href = '/';
        }
      }
    );
  }
}
