import { Subject, Observable } from 'rxjs';

export class RequestResponse {
    private RequestCount = 0;
    private subject = new Subject<number>();
    Requested() {
        this.RequestCount = this.RequestCount + 1;
        this.subject.next(this.RequestCount);
    }
    Responded() {
        this.RequestCount = this.RequestCount - 1;
        this.subject.next(this.RequestCount);
    }

    getObservable(): Observable<any> {
        return this.subject.asObservable();
    }
}
