import { MessageBoxComponent } from '../message-box/message-box.component';
import { MessageBoxCall, PageSetting } from './Poco';
import { WindowRef } from '../service/window-ref';
import { environment } from 'src/environments/environment';
import { InformationBoxComponent } from '../information-box/information-box.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PageSettingsComponent } from '../reports/page-settings/page-settings.component';

export class Util {

    public SupplierAccount = 'SupplierAccount';
    // dialogRef: MatDialogRef<MessageBoxComponent>;

    public GetFormattedDate(date: Date) {
        const month = this.format(date.getMonth() + 1, 2);
        const day = this.format(date.getDate(), 2);
        const year = this.format(date.getFullYear(), 4);
        return day + '/' + month + '/' + year;
    }
    private format(num: number, cnt: number) {
        const s = Array(cnt + 1).join('0') + num.toString();
        return s.substr(s.length - cnt, cnt);
    }

    public GetddMMMyyyyDate(date: Date) {
        const month_names: string[] =
            ['Jan', 'Feb', 'Mar',
                'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep',
                'Oct', 'Nov', 'Dec'];
        const day = date.getDate();
        const month_index = date.getMonth();
        const year = date.getFullYear();
        return '' + day + '-' + month_names[month_index] + '-' + year;
    }

    OldQuotaExists(http: HttpClient): Observable<HttpResponse<boolean>> {
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        return http.post<boolean>(
            this.GetServerUrl() + '/OldQuotaExists', null,
            { headers, observe: 'response' });
    }

    public GetToday(): Date {
        const currentTime = new Date();
        const today = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());
        return today;
    }

    public _ShowMessageBox(dialog: MatDialog, okText: string, cancelText: string, msg: string, callback: (x: number) => void) {
        const dialogRef = dialog.open(MessageBoxComponent, {
            data: new MessageBoxCall(okText, cancelText, msg),
            // disableClose: true,
        });
        if (callback) {
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    callback(result);
                } else {
                    callback(0);
                }
            });
        }
    }

    public ShowMessageBox(dialog: MatDialog, winref: WindowRef, okText: string, cancelText: string,
        msg: string, callback: (x: number) => void) {
        /*
            if (false && winref && winref.nativeWindow.navigator && winref.nativeWindow.navigator.notification) {
            if (cancelText === null) {
                winref.nativeWindow.navigator.notification.alert(msg, callback, 'TNFDC', [okText]);
            } else {
                winref.nativeWindow.navigator.notification.confirm(msg, callback, 'TNFDC', [okText, cancelText]);
            }
        } else */
        {
            const dialogRef = dialog.open(MessageBoxComponent, {
                data: new MessageBoxCall(okText, cancelText, msg),
                autoFocus: cancelText == null // disableClose: true,
            });
            if (callback) {
                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        callback(result);
                    } else {
                        callback(0);
                    }
                });
            }
        }
    }

    public ShowInformationBox(dialog: MatDialog, msg: string): Observable<any> {
        const dialogRef = dialog.open(InformationBoxComponent, {
            data: msg
        });
        return dialogRef.afterClosed()
    }

    public IsNullOrEmpty(inp: string) {
        return inp === undefined || inp === null || inp === '';
    }

    public GetServerUrl() {
        return environment.apiUrl;
    }
    public GetAuthToken() {
        return sessionStorage.getItem('Token') ?? '';
    }

    public SetShopSelected(shopId: number) {
        return sessionStorage.setItem('SelectedShopId', shopId + '');
    }

    public GetShopSelected(): number {
        return parseInt(sessionStorage.getItem('SelectedShopId'), 10);
    }

    public PassworStrong(pwd: string): boolean {
        const hasNumber = /\d/.test(pwd);
        const hasUpper = /[A-Z]/.test(pwd);
        const hasLower = /[a-z]/.test(pwd);
        const valid = hasNumber && hasUpper && hasLower;
        return valid && pwd.length >= 8;
    }

    public DeserializePageSetting(data: string): PageSetting {
        if (data && data.length > 0) {
            let SettingName = '';
            let Width = 0;
            let Height = 0;
            let Left = 0;
            let Right = 0;
            let Top = 0;
            let Bottom = 0;
            let UnitType = '';
            let Orientation = 'portrait'
            const arr = data.split(',');
            arr.forEach(x => {
                const c = x.split(':');
                if (c.length > 0) {
                    switch (c[0]) {
                        case 'SettingName': {
                            SettingName = c[1];
                        }
                        case 'Width': {
                            Width = parseFloat(c[1]);
                        }
                        case 'Height': {
                            Height = parseFloat(c[1]);
                        }
                        case 'Left': {
                            Left = parseFloat(c[1]);
                        }
                        case 'Right': {
                            Right = parseFloat(c[1]);
                        }
                        case 'Top': {
                            Top = parseFloat(c[1]);
                        }
                        case 'Bottom': {
                            Bottom = parseFloat(c[1]);
                        }
                        case 'UnitType': {
                            UnitType = c[1];
                        }
                        case 'Orientation': {
                            Orientation = c[1];
                        }
                    }

                }
            })
            return new PageSetting(Width, Height, Left, Right, Top, Bottom, UnitType, Orientation);
        }
        return null;
    }

    public SerializePageSetting(settingName: string, data: PageSetting): string {
        return 'SettingName:' + settingName + ',Width:' + data.Width + ',Height:' + data.Height + ',Left:' + data.Left + ',Right:' + data.Right + ',Top:' + data.Top + ',Bottom:' + data.Bottom + ',UnitType:' + data.UnitType + ',Orientation:' + data.Orientation;
    }

    public ShowPageSettings(keyName: string, dialog: MatDialog): Observable<any> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        dialogConfig.width = '400px';
        if (keyName != null && localStorage.getItem(keyName) != null) {
            const keyVal = localStorage.getItem(keyName);
            dialogConfig.data = { settingName: keyName, pageSetting: this.DeserializePageSetting(keyVal) } ;
        } else {
            dialogConfig.data = { settingName: keyName, pageSetting: null } ;
        }
        const dialogRef = dialog.open(PageSettingsComponent, dialogConfig);
        return dialogRef.afterClosed();
    }
}

export const CustomerStatusActive = 'Active';

export const BillingRole = 'Billing';
