import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { WindowRef } from '../service/window-ref';
import { Util } from '../models/Util';
import { LkType, UserTask, PaymentTransfer, PdfViewData, LkStatus, PaymentRequestFile } from '../models/Poco';
import { UserTaskService } from '../service/user-task.service';
import { UserTaskDataSource } from '../datasource/user-task.datasource';
import { EditTaskComponent } from '../edit-task/edit-task.component';
import { TaskActionComponent } from '../task-action/task-action.component';
import { PdfViewComponent } from '../pdf-view/pdf-view.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  templateUrl: './user-task.component.html',
  styleUrls: ['./user-task.component.css'],
  providers: [UserTaskService]
})
export class UserTaskComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['TaskTypeName', 'TaskRequest', 'TaskResponse', 'ProposedDate', 'TaskStatusName',
    'CreatedAt', 'Action'];
  // TaskList = [];
  pageSize = 10;
  totalRecords: number;
  dataSource: UserTaskDataSource;
  lastDownloadUrl: string;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  IsMobile = false;


  TaskTypes: LkType[] = [];
  SelTaskType: LkType;

  TaskStatuses: LkStatus[] = [];
  SelTaskStatus: LkStatus;

  IsMyTask = false;
  IsAllSelected = false;

  RequestFiles: PaymentRequestFile[] = [];

  SelectedTaskIds: { UserTaskId: number, TaskStatus: string }[] = [];

  innerWidth: number;

  isMgmt = false;
  isAdvsr = false;

  currentTaskList: UserTask[] = [];

  constructor(private taskService: UserTaskService,
    private http: HttpClient, private util: Util,
    public router: Router, private dialog: MatDialog, private winref: WindowRef) {
  }

  ngOnInit() {

    const role = sessionStorage.getItem('Role');
    this.isMgmt = role === 'Mgmt';
    this.isAdvsr = role === 'Advisor';

    if (window.screen.width === 360) { // 768px portrait
      this.IsMobile = true;
    }

    this.dataSource = new UserTaskDataSource(this.taskService);
    this.dataSource.subscribeForList().subscribe(res => {
      this.currentTaskList = res.Summary;
      let allSelected: boolean = null;
      this.currentTaskList.forEach(x => {
        if (x.TaskStatusName === 'Completed' || x.TaskStatusName === 'Accepted') {
          if (this.SelectedTaskIds.filter(y => y.UserTaskId === x.UserTaskId).length > 0) {
            x.IsSelected = true;
            if (allSelected === null) {
              allSelected = true;
            }
          } else {
            x.IsSelected = false;
            allSelected = false;
          }
        }
      });
      this.IsAllSelected = allSelected;
    }, err => {
    });
    this.loadRequestfiles();
    this.totalRecords = this.dataSource.totalRecords;
    this.loadTaskTypes();
    this.dataSource.loadTask(null, null, false, 0, 10, 'CreatedAt', 'asc');
    this.innerWidth = window.innerWidth;
  }

  taskTypeChanged(evnt: any) {
    this.SelectedTaskIds = [];
    this.loadTasks();
    if (this.SelTaskType != null && this.SelTaskType.TypeName === 'PaymentRequest') {
      this.displayedColumns = ['TaskSelection', 'TaskTypeName', 'TaskRequest', 'TaskResponse', 'ProposedDate', 'TaskStatusName',
        'CreatedAt', 'Action'];
    } else {
      this.displayedColumns = ['TaskTypeName', 'TaskRequest', 'TaskResponse', 'ProposedDate', 'TaskStatusName',
        'CreatedAt', 'Action'];
    }
  }

  taskStatusChanged(evnt: any) {
    this.SelectedTaskIds = [];
    this.loadTasks();
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.loadTasks();
        })
      )
      .subscribe();
  }

  loadTasks() {
    this.dataSource.loadTask(
      this.SelTaskType != null ? this.SelTaskType.TypeId : null,
      this.SelTaskStatus != null ? this.SelTaskStatus.StatusId : null,
      this.IsMyTask,
      this.paginator.pageIndex, this.paginator.pageSize);
  }

  loadTaskTypes() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/tasktypes',
      { headers, observe: 'response' }).subscribe(res => {
        this.TaskTypes = res.body;
        this.loadTaskStatuses();
      }
      );
  }

  loadTaskStatuses() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<LkStatus[]>(
      this.util.GetServerUrl() + '/taskstatuses',
      { headers, observe: 'response' }).subscribe(res => {
        this.TaskStatuses = res.body;
      }
      );
  }

  myTaskSelected(evnt: any) {
    this.SelectedTaskIds = [];
    this.loadTasks();
  }


  editUserTask(taskId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = taskId;
    const dialogRef = this.dialog.open(EditTaskComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.SelectedTaskIds = [];
      this.loadTasks();
    }
    );
  }

  newUserTask() {
    this.editUserTask(0);
  }

  viewUserTask(taskId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = -taskId;
    const dialogRef = this.dialog.open(EditTaskComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.SelectedTaskIds = [];
      this.loadTasks();
    }
    );
  }

  showTaskAction(taskId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.position = {
      top: '0'
    };
    dialogConfig.data = taskId;
    const dialogRef = this.dialog.open(TaskActionComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.SelectedTaskIds = [];
      this.loadTasks();
    }
    );
  }

  onStatusChange(usertask: UserTask, evnt: MatCheckboxChange) {
    if (evnt.checked) {
      this.SelectedTaskIds.push({ UserTaskId: usertask.UserTaskId, TaskStatus: usertask.TaskStatusName });
      if (this.currentTaskList.filter(x => !x.IsSelected &&
        (x.TaskStatusName === 'Completed' || x.TaskStatusName === 'Accepted')).length === 0) {
        this.IsAllSelected = true;
      }
    } else {
      this.SelectedTaskIds = this.SelectedTaskIds.filter(x => x.UserTaskId !== usertask.UserTaskId);
      if (this.currentTaskList.filter(x => !x.IsSelected).length > 0) {
        this.IsAllSelected = false;
      }
    }

  }

  doPreparePaymentReport() {

    if (this.SelectedTaskIds.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'None of the tasks selected', null);
      return;
    }

    const transfer = new PaymentTransfer();
    transfer.UserTaskIds = this.SelectedTaskIds.map(x => x.UserTaskId);

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.post<string>(
      this.util.GetServerUrl() + '/generaterequest', transfer, { headers, observe: 'response' }).subscribe(res => {
        const paymentRequestUrl = this.util.GetServerUrl() + '/downtemp/' + res.body;
        this.lastDownloadUrl = res.body;
        // this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Generated', null);
        this.openPdfView('Payment Transfer Request Form', paymentRequestUrl, false);

      }, err => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
      });
  }

  openPdfView(title: string, url: string, isOld: boolean) {

    const viewData = new PdfViewData();
    viewData.PdfTitle = title;
    viewData.PdfUrl = url;

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    let relativeWidth = (this.innerWidth * 80) / 100; // take up to 80% of the screen size
    if (this.innerWidth > 1500) {
      relativeWidth = (1500 * 80) / 100;
    } else {
      relativeWidth = (this.innerWidth * 80) / 100;
    }

    /*
    const relativeHeight = (relativeWidth * 9) / 16 + 120; // 16:9 to which we add 120 px for the dialog action buttons ("close")
    dialogConfig.width = relativeWidth + 'px';
    dialogConfig.height = relativeHeight + 'px';
    */

    dialogConfig.data = viewData;

    const dialogRef = this.dialog.open(PdfViewComponent, dialogConfig);
    if (!isOld) {
      dialogRef.afterClosed().subscribe(() => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Do you want to complete selected tasks?', x => {
          if (x === 1) {
            const token = sessionStorage.getItem('Token') ?? '';
            const headers = new HttpHeaders({
              'Content-Type': 'application/json',
              token
            });
            this.http.post<string>(
              this.util.GetServerUrl() + '/completetask?url=' + this.lastDownloadUrl, this.SelectedTaskIds.map(y => y.UserTaskId),
              { headers, observe: 'response' }).subscribe(res => {
                this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Completed', y => {
                  this.loadTasks();
                  this.loadRequestfiles();
                });
              }, err => {
                this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
              });
          }
        });
      }
      );
    }
  }

  SelectUnSelectAll(evnt: MatCheckboxChange) {
    if (evnt.checked) {
      this.currentTaskList.forEach(x => {
        if (x.TaskStatusName === 'Accepted') {
          x.IsSelected = true;
          if (this.SelectedTaskIds.filter(y => y.UserTaskId === x.UserTaskId).length === 0) {
            this.SelectedTaskIds.push({ UserTaskId: x.UserTaskId, TaskStatus: x.TaskStatusName });
          }
        }
      }
      );
    } else {
      this.currentTaskList.forEach(x => {
        if (x.TaskStatusName === 'Completed' || x.TaskStatusName === 'Accepted') {
          x.IsSelected = false;
          this.SelectedTaskIds = this.SelectedTaskIds.filter(y => y.UserTaskId !== x.UserTaskId);
        }
      }
      );
    }
  }

  loadRequestfiles() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<PaymentRequestFile[]>(
      this.util.GetServerUrl() + '/requestfiles',
      { headers, observe: 'response' }).subscribe(res => {
        this.RequestFiles = res.body;
      }
      );
  }

  ShowRequest(fileName: string) {
    const uId = sessionStorage.getItem('curuserId')
    const paymentRequestUrl = this.util.GetServerUrl() + '/downtemp/' + fileName + '?archive=true&uId=' + uId;
    this.openPdfView('Payment Transfer Request Form', paymentRequestUrl, true);
  }

}

