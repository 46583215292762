import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {
  BillingDetail, OpenCloseStatusResponse, StktransferDetail, Stktransfer, StktransferSummary, Quota, AuditProduct, LkType, ShopShortInfo
} from '../models/Poco';

import { Util } from '../models/Util';
import { Router, ActivatedRoute } from '@angular/router';
import { WindowRef } from '../service/window-ref';
import { MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

declare var cordova: any;

@Component({
  selector: 'app-new-stktransfer',
  templateUrl: './new-stktransfer.component.html',
  styleUrls: ['./new-stktransfer.component.css'],
  providers: [
    WindowRef
  ]
})
export class NewStktransferComponent implements OnInit {

  StktransferSummaryId: number;
  Description: string = null;
  Quotas: Quota[];
  SelQuota: Quota;
  Qty: number = null;

  SelShop: ShopShortInfo;
  ActiveShops: ShopShortInfo[];

  SelProduct: AuditProduct;
  products: AuditProduct[];
  stktransferDetails: StktransferDetail[] = [];
  stktransferDate: Date;

  displayedColumns: string[] = ['ProductName', 'Qty', 'Action'];
  totalColumns: string[] = ['ProductName', 'Qty'];

  @ViewChild('details', { static: true }) details: MatTable<StktransferDetail>;

  constructor(private http: HttpClient, private util: Util, private router: Router,
              private route: ActivatedRoute, private messageDialog: MatDialog,
              private dialog: MatDialog, private winref: WindowRef,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.loadBillingStatus();
  }

  loadProducts(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<AuditProduct[]>(
      this.util.GetServerUrl() + '/currentauditproducts',
      { headers, observe: 'response' }).subscribe(res => {
        this.products = res.body;
        this.loadQuotas();
      }
      );
  }

  StktransferTypeChanged(event: any): void {
  }

  loadQuotas(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<Quota[]>(
      this.util.GetServerUrl() + '/currentquotas',
      { headers, observe: 'response' }).subscribe(res => {
        this.Quotas = res.body;
        this.initGrid();
      }
      );
  }

  initGrid(): void {
    const id = this.route.snapshot.params.Id as number;
    if (id > 0) {
      this.updateQuotaColumn();
      this.StktransferSummaryId = id;
      const token = sessionStorage.getItem('Token') ?? '';
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        token
      });
      this.http.get<Stktransfer>(
        this.util.GetServerUrl() + '/Stktransferid/' + id, { headers, observe: 'response' }).subscribe(res => {
          const billing = res.body;
          this.loadStktransfer(billing);
        }
        );
    } else {
      this.StktransferSummaryId = 0;
      this.updateQuotaColumn();
    }
  }

  loadBillingStatus(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const dateInddMMyyyy = this.util.GetddMMMyyyyDate(new Date());

    this.http.get<OpenCloseStatusResponse>(
      this.util.GetServerUrl() + '/GetOpenCloseStatus?StatusDate=' + dateInddMMyyyy,
      { headers, observe: 'response' }).subscribe(res => {
        this.stktransferDate = new Date(res.body.TransactionDate);
        this.loadShops();
      }
      );
  }

  loadShops(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ShopShortInfo[]>(
      this.util.GetServerUrl() + '/activeshops', { headers, observe: 'response' }).subscribe(res => {
        const currentShopId = this.util.GetShopSelected();
        let activeShops = res.body.filter(x => x.ShopId !== currentShopId);
        let _activeShops: ShopShortInfo[] = [];
        const _parentShopId = sessionStorage.getItem('ParentShopId');
        if ( _parentShopId && _parentShopId.length > 0 && activeShops.filter( x=> x.ShopId == parseInt(_parentShopId) ).length > 0) {
          _activeShops = _activeShops.concat(activeShops.filter( x=> x.ShopId == parseInt(_parentShopId)));
        }
        const _childShopIds = sessionStorage.getItem('ChildShopIds');
        if ( _childShopIds && _childShopIds.length > 0) {
          let _2 = _childShopIds.split(',').map(x=> parseInt(x));
          _activeShops = _activeShops.concat(activeShops.filter( x=> _2.filter(y=> y == x.ShopId).length > 0 ));
        }

        _activeShops.forEach(x=> {
          x.ShopName = "*" + x.ShopName;
        })

        activeShops = _activeShops.concat(activeShops.filter( x=> _activeShops.filter(y=> y.ShopId == x.ShopId).length == 0 ));

        this.ActiveShops = activeShops;
        this.loadProducts();
      }
      );
  }

  addStktransferDetail(): void {

    if (this.SelProduct == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Product is not selected', null);
      return;
    }
    if (this.Qty == null || this.Qty === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Qty is not entered', null);
      return;
    }

    if (this.Qty < 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Qty is not valid', null);
      return;
    }

    this.stktransferDetails.push(new StktransferDetail(this.SelProduct.ProductId, this.SelProduct.ProductDisplayName,
      this.Qty, this.SelProduct.QtyPresc,
      (this.SelQuota != null ? this.SelQuota.QuotaId : null), this.SelQuota != null ? this.SelQuota.QuotaName : null));

    this.updateQuotaColumn();

    this.Description = null;
    this.SelProduct = null;
    this.SelQuota = null;
    this.Qty = null;
    this.details.renderRows();
  }

  loadStktransfer(stktransfer: Stktransfer): void {
    this.stktransferDetails = stktransfer.StktransferDetails;
    this.stktransferDate = new Date(stktransfer.Summary.StktransferDate);
  }

  updateQuotaColumn(): void {
    if (this.stktransferDetails.filter(x => x.QuotaId != null).length > 0) {
      if (this.displayedColumns.length !== 4){
        this.displayedColumns = ['ProductName', 'QuotaName', 'Qty', 'Action'];
        this.totalColumns = ['ProductName', 'QuotaName', 'Qty'];
      }
    } else {
      if (this.displayedColumns.length !== 3){
        this.displayedColumns = ['ProductName', 'Qty', 'Action'];
        this.totalColumns = ['ProductName', 'Qty'];
      }
    }
  }

  doSave(): void {

    if (this.SelShop == null) {
      this.util.ShowMessageBox(this.messageDialog, this.winref, 'OK', null, 'Select the shop to transfer', null);
      return;
    }
    this.save();

  }

  save(): void {
    if (this.stktransferDetails === undefined || this.stktransferDetails === null || this.stktransferDetails.length === 0) {
      this.util.ShowMessageBox(this.messageDialog, this.winref, 'OK', null, 'Add the products to proceed', null);
      return;
    }

    if (this.Description === undefined || this.Description === null) {
      this.util.ShowMessageBox(this.messageDialog, this.winref, 'OK', null, 'Enter the description', null);
      return;
    }

    let token = sessionStorage.getItem('Token') ?? '';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const stktransferSummary = new StktransferSummary(this.stktransferDate, this.Description, this.SelShop.ShopId);
    const stktransfer = new Stktransfer(stktransferSummary, this.stktransferDetails);

    if (this.StktransferSummaryId > 0) {
      this.http.post(
        this.util.GetServerUrl() + '/updatebilling', stktransfer, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => { this.router.navigate(['../Billing']); });
        }
        );
    } else {
      this.http.post(
        this.util.GetServerUrl() + '/makeStktransfer', stktransfer, { headers, observe: 'response' }).subscribe(res => {
          const billSummaryId = res.body;
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            token = sessionStorage.getItem('Token') ?? '';
            headers = new HttpHeaders({
              'Content-Type': 'application/json',
              token
            });
            this.router.navigate(['../Stktransfer']);
          });
        }, err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
        );
    }
  }

  doCancel(): void {
    this.router.navigate(['../Stktransfer']);
  }

  editBillingDetail(billDetail: BillingDetail): void {


  }

  removeItem(rowItem: StktransferDetail): void {
    const idx = this.stktransferDetails.indexOf(rowItem);
    // console.log(idx);
    this.stktransferDetails.splice(idx, 1);
    this.updateQuotaColumn();
    this.details.renderRows();
  }

  getTotal(): number {
    if (this.stktransferDetails.length === 0) {
      return 0;
    }
    return this.stktransferDetails.map(x => x.Qty).reduce( (x, y) => x + y, 0);
  }

  getMaxPres(): number {
    if (this.stktransferDetails.length === 0) {
      return 3;
    }
    return this.stktransferDetails.map(x => x.QtyPres).reduce( (x, y) => x > y ? x : y);
  }
}
