import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  IsMobile = false;
  IsMgmt = false;
  IsIncharge = false;

  constructor(private route: Router) { }

  ngOnInit() {
    this.IsMobile = environment.appMode === 'M';
    const role = sessionStorage.getItem('Role');
    this.IsMgmt = role === 'Mgmt';
    this.IsIncharge = role === 'Incharge';
    if (this.IsMobile && this.IsMgmt) {
      this.route.navigate(['Dashboard', 'InchargeConsolidated']);
    } else {
      this.route.navigate(['Dashboard', 'QuotaBalance']);
    }
  }

  navigate(rptName: string) {
    switch (rptName) {
      case 'QuotaBalance':
        this.route.navigate(['Dashboard', 'QuotaBalance']);
        break;
      case 'Sales':
        this.route.navigate(['Dashboard', 'Sales']);
        break;
      case 'StockReport':
        this.route.navigate(['Dashboard', 'StockReport']);
        break;
      case 'StockHistory':
        this.route.navigate(['Dashboard', 'StockHistory']);
        break;
      case 'SalesPurchase':
        this.route.navigate(['Dashboard', 'SalesPurchase']);
        break;
      case 'SalesDaily':
        this.route.navigate(['Dashboard', 'SalesDaily']);
        break;
      case 'ReSalesPending':
        this.route.navigate(['Dashboard', 'PendingSaleBill']);
        break;
      case 'Consolidated':
        this.route.navigate(['Dashboard', 'InchargeConsolidated']);
        break;
      default:

    }
  }

  indianRupeeFormat(val: number) {
    return Number(val).toLocaleString('en-IN');
  }

}
