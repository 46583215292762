import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { BillingDataSource } from '../datasource/billing.datasource';
import { BillingService } from '../Service/billing.service';
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { WindowRef } from '../service/window-ref';
import { Util } from '../models/Util';
import { BillCancel, BillPrint, Constant, AmountDetail, BillPayment, BillingShortDetail, BulkBoatBillEntryResponse } from '../models/Poco';
import { SingleBillPrintComponent } from '../single-bill-print/single-bill-print.component';
import { BillReturnComponent } from '../bill-return/bill-return.component';
import { BillPaymentComponent } from '../bill-payment/bill-payment.component';
import { ResoldDetailComponent } from '../resold-detail/resold-detail.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SingleFileUploadComponent } from '../single-file-upload/single-file-upload.component';
import { BulkCustomerListPreviewComponent } from '../bulk-customer-list-preview/bulk-customer-list-preview.component';
import { ShopDocmentFilesComponent } from '../shop-docment-files/shop-docment-files.component';
import * as moment from 'moment';

@Component({
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css'],
  providers: [BillingService]
})
export class BillingComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['SlNo', 'BillDate', 'BillNo', 'Qty', 'Amount', 'Detail', 'PaymentStatus', 'Action'];
  billingList = [];
  pageSize = 10;
  totalRecords: number;
  dataSource: BillingDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  CustName: string;
  TobePaidOnly = false;

  constructor(private billingService: BillingService,
              private http: HttpClient, private util: Util,
              public router: Router, private dialog: MatDialog, private winref: WindowRef) {
  }

  ngOnInit() {
    this.dataSource = new BillingDataSource(this.billingService);
    this.totalRecords = this.dataSource.totalRecords;
    this.dataSource.loadBilling(0, 10, 'FromDate', 'asc', this.CustName, this.TobePaidOnly);
  }


  Search() {
    this.dataSource.loadBilling(0, 10, 'FromDate', 'asc', this.CustName, this.TobePaidOnly);
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadBilling())
      )
      .subscribe();
  }

  loadBilling() {
    this.dataSource.loadBilling(this.paginator.pageIndex, this.paginator.pageSize, 'FromDate', 'asc', this.CustName, this.TobePaidOnly);
  }

  getMaxPres(billdetail: BillingShortDetail[]): number {
    if (billdetail.length === 0) {
      return 3;
    }
    return billdetail.map(x => x.QtyPres).reduce( (x, y) => x > y ? x : y);
  }

  IsNotPaid(paymentStatus: string) {
    return paymentStatus === Constant.NotPaid || paymentStatus === Constant.PartiallyPaid;
  }

  payBill(summaryId: number, pendingAmt: number, paymentMode: string) {
    const dialogRef = this.dialog.open(BillPaymentComponent, {
      data: {
        TotalAmount: pendingAmt,
        Mode: 'AddnPayment',
        PaymentMode: paymentMode,
        SummaryId: summaryId
      },
    });
    dialogRef.afterClosed().subscribe((result: AmountDetail) => {
      if (result) {
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });

        const obj = new BillPayment();
        obj.SummaryId = summaryId;
        obj.PaymentModeAmounts = result.PaymentModeAmounts;
        obj.ReSoldItems = result.ReSoldItems;

        this.http.post(
          this.util.GetServerUrl() + '/makepayment', obj, { headers, observe: 'response' }).subscribe(res => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Paid', () => {
              this.loadBilling();
            });
          }, err => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
          }
          );
      }
    });
  }

  printBill(summaryId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<BillPrint>(
      this.util.GetServerUrl() + '/printBill/' + summaryId, { headers, observe: 'response' }).subscribe(res => {
        const billing = res.body;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        dialogConfig.data = billing;
        const dialogRef = this.dialog.open(SingleBillPrintComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(() => {
        }
        );
      }
      );
  }

  editBilling(summaryId: number) {
    this.router.navigate(['../BillingDetail', summaryId]);
  }

  billReturn(summaryId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = summaryId;
    const dialogRef = this.dialog.open(BillReturnComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
    }
    );
  }

  showResoldItems(summaryId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.data = summaryId;
    const dialogRef = this.dialog.open(ResoldDetailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
    }
    );
  }

  editUsers(billDetailId:number, qty: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { Type: 'UpdCustomerQty' } ;
    const dialogRef = this.dialog.open(SingleFileUploadComponent, dialogConfig);
    dialogRef.afterClosed().subscribe( (result:BulkBoatBillEntryResponse[]) => {
      if (result){
        const _dialogConfig = new MatDialogConfig();
        _dialogConfig.disableClose = true;
        _dialogConfig.autoFocus = true;
        _dialogConfig.data = { refId: billDetailId, dataType: 'UpdCustomerQty', list: result } ;
        const _dialogRef = this.dialog.open(BulkCustomerListPreviewComponent, _dialogConfig);
        _dialogRef.afterClosed().subscribe( res => {
        });
      }
    });
  }

  cancelBilling(summaryId: number) {

    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Do you want to cancel the bill?', (x: number) => {
      if (x === 1) {
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });

        const obj = new BillCancel();
        obj.SummaryId = summaryId;

        this.http.post(
          this.util.GetServerUrl() + '/cancelbilling', obj, { headers, observe: 'response' }).subscribe(res => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Cancelled', () => {
              this.loadBilling();
            });
          }, err => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
          }
          );

      }
    });

  }
}
