import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Shop, MyShops, Product, KeyValueIntString } from '../models/Poco';
import { StockHistoryService } from '../service/stock-history.service';
import { StockHistoryDataSource } from '../datasource/stock-history.datasource';
import { Util } from '../models/Util';
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
  templateUrl: './stock-history.component.html',
  styleUrls: ['./stock-history.component.css'],
  providers: [StockHistoryService]
})

export class StockHistoryComponent implements OnInit, AfterViewInit {

  shops: Shop[] = [];
  selectedShopId: number = null;
  fromDate: Moment;
  toDate: Moment;
  ParentProducts: Product[] = null;
  selectedParentProduct: Product = null;
  Quotas: KeyValueIntString[] = [];
  selQuota: KeyValueIntString;

  displayedColumns: string[] = ['ActivityTime', 'Qty', 'ActivityTypeDisplayName', 'RefNo'];

  pageSize = 10;
  totalRecords: number;
  dataSource: StockHistoryDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private http: HttpClient, private stockHistoryService: StockHistoryService,
              private util: Util) { }

  ngOnInit(): void {
    this.dataSource = new StockHistoryDataSource(this.stockHistoryService);
    this.totalRecords = this.dataSource.totalRecords;
    const d = new Date();
    d.setDate(1);
    this.fromDate = moment(d).utcOffset(0, true);
    this.toDate = moment().utcOffset(0, true);
    this.loadProducts();
    this.loadShops();
  }

  loadProducts(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    const p = new HttpParams()
      .set('context', 'SR');
    this.http.get<Product[]>(
      this.util.GetServerUrl() + '/allproducts',
      { headers, params: p, observe: 'response' }).subscribe(res => {
        const liParentProducts: Product[] = res.body;
        console.log(liParentProducts.length);
        if (liParentProducts.length !== 1) {
          const totalItems = liParentProducts.length;
          for (let i = totalItems; i > 0; i--) {
            liParentProducts[i] = liParentProducts[i - 1];
          }
          liParentProducts[0] = new Product(null, '--Select--');
        }
        this.ParentProducts = liParentProducts;
        this.selectedParentProduct = liParentProducts[0];
      }
      );
  }

  loadShops(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<MyShops>(
      this.util.GetServerUrl() + '/myshops', { headers, observe: 'response' }).subscribe(res => {
        const myShops = res.body;
        this.shops = myShops.Shops;
        if (myShops.Shops.length > 0) {
          this.selectedShopId = this.util.GetShopSelected(); // myShops.Shops[0].ShopId;
          this.loadQuotas(this.selectedShopId);
        }
      }
      );
  }

  loadQuotas(shopId: number): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<KeyValueIntString[]>(
      this.util.GetServerUrl() + '/GetShopStockQuota/' + shopId, { headers, observe: 'response' }).subscribe(res => {
        this.Quotas = res.body;
      }
      );
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.loadReport();
        })
      )
      .subscribe();
  }

  loadReport(): void {
    this.dataSource.loadHistory(this.selectedShopId, this.fromDate.utcOffset(0, true).toDate(),
                                this.toDate.utcOffset(0, true).toDate(),
                                this.selectedParentProduct.ProductId, this.selQuota != null ? this.selQuota.Key : null,
      this.paginator.pageIndex, this.paginator.pageSize);
  }

  _loadPageData(event?: PageEvent): void {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.loadReport();
  }

  reLoadReport(): void {
    this.paginator.pageIndex = 0;
    this.loadReport();
  }

  changeShop(): void {
    this.loadQuotas(this.selectedShopId);
  }

}
