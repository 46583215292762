import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { merge, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { WindowRef } from '../service/window-ref';
import { Util } from '../models/Util';
import { UserService } from '../service/user.service';
import { UserDataSource } from '../datasource/user.datasource';
import { ContextService } from '../service/context-change';
import { NewUserComponent } from '../new-user/new-user.component';
import { UserShopComponent } from '../user-shop/user-shop.component';
import { UserSessionsComponent } from '../user-sessions/user-sessions.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProductMasterService } from '../service/product-master.service';
import { ProductMasterDataSource } from '../datasource/product-master.datasource';
import { ProductCategoryAssociationComponent } from '../product-catagory-association/product-catagory-association.component';
import { EditProductComponent } from '../edit-product/edit-product.component';
import { CustomPropertyComponent } from '../custom-property/custom-property.component';

@Component({
  selector: 'app-product-master',
  templateUrl: './product-master.component.html',
  styleUrls: ['./product-master.component.css'],
  providers: [ProductMasterService],
})
export class ProductMasterComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['ParentProductName', 'UomTypeName', 'FillTypeName',
  'SGst', 'CGst', 'IGst', 'IncludingTax', 'StockCheck', 'Action'];
  pageSize = 10;
  totalRecords: number;
  dataSource: ProductMasterDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  contextSubscriber: Subscription;

  constructor(private productMasterService: ProductMasterService, private contextChange: ContextService,
              private http: HttpClient, private util: Util,
              public router: Router, private dialog: MatDialog, private winref: WindowRef) {
    this.contextSubscriber = contextChange.getObservable().subscribe(x => {
      this.dataSource.loadProductMasters(0, 10, 'UserName', 'asc');
    });
  }

  ngOnInit(): void {
    this.dataSource = new ProductMasterDataSource(this.productMasterService);
    this.totalRecords = this.dataSource.totalRecords;
    this.dataSource.loadProductMasters(0, 10, 'UserName', 'asc');
  }

  ShowCustomProperty(parentProductId: number) {
    const dialogRef = this.dialog.open(CustomPropertyComponent, {
      data: parentProductId,
    });
  }

  productCategories(parentProductId: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = parentProductId;
    const dialogRef = this.dialog.open(ProductCategoryAssociationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadParentProducts();
      }
    }
    );
  }

  ngAfterViewInit(): void {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadParentProducts())
      )
      .subscribe();
  }

  loadParentProducts(): void {
    this.dataSource.loadProductMasters(this.paginator.pageIndex, this.paginator.pageSize);
  }

  editParentProduct(parentProductId?: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = parentProductId;
    const dialogRef = this.dialog.open(EditProductComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadParentProducts();
    }
    );
  }
}
