import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Unit, ShopSummary, AdOffice, BankAccount, LkType, Shop, ShopShortInfo } from '../models/Poco';
import { Util } from '../models/Util';
import { Router, ActivatedRoute } from '@angular/router';
import { WindowRef } from '../service/window-ref';
import { EditBankAccountComponent } from '../edit-bank-account/edit-bank-account.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-shop',
  templateUrl: './edit-shop.component.html',
  styleUrls: ['./edit-shop.component.css']
})
export class EditShopComponent implements OnInit {

  Units: Unit[];
  AdOffices: AdOffice[];
  ActiveShops: ShopShortInfo[];
  ShopId: number;
  SelShop: ShopShortInfo;
  ShopName: string;
  ShopCode: string;
  Title1: string;
  Title2: string;
  Footer1: string;
  Footer2: string;
  SelUnit: Unit;
  SelAdOffice: AdOffice;
  Address: string;
  ContactNo: string;
  GstNo: string;
  BoatCustomerBill: boolean;
  DirectCustomerBill: boolean;
  BulkBill: boolean;
  CategoryCode: string;
  activeOpenDate: Moment;
  sActiveOpenDate: string;
  QuotaApplicable: boolean;
  PurchExpenseAssd: boolean;

  PayOptionCash = false;
  PayOptionCredit = false;
  PayOptionOnlineCredit = false;
  PayOptionOwn = false;
  PayOptionPayOnSale = false;
  PayOptionPayLater = false;

  PurchBankAccounts: BankAccount[];
  SelPurchBankAccount: BankAccount;

  SaleBankAccounts: BankAccount[];
  SelSaleBankAccount: BankAccount;

  ShopTypes: LkType[];
  SelShopType: LkType;

  SchemeTypes: LkType[];
  SelSchemeType: LkType;

  AccountForTypes: LkType[];

  constructor(private http: HttpClient, private util: Util, private dialog: MatDialog,
              private router: Router, private route: ActivatedRoute, private winref: WindowRef) { }

  ngOnInit(): void {
    this.ShopId = this.route.snapshot.params.Id as number;
    this.loadShopTypes();
  }

  loadShopTypes(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/shoptypes',
      { headers, observe: 'response' }).subscribe(res => {
        this.ShopTypes = res.body;
        this.loadShops();
      });
  }

  loadSchemeTypes(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/schemetypes',
      { headers, observe: 'response' }).subscribe(res => {
        this.SchemeTypes = res.body;
        this.loadAccountForTypes();
      });
  }

  loadAccountForTypes(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/accountfortypes',
      { headers, observe: 'response' }).subscribe(res => {
        this.AccountForTypes = res.body;
        this.loadAccounts(true);

      });
  }


  loadAccounts(init: boolean): void {
      const token = sessionStorage.getItem('Token') ?? '';
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        token
      });
      this.http.get<BankAccount[]>(
        this.util.GetServerUrl() + '/allshopaccounts', { headers, observe: 'response' }).subscribe(res => {
          this.PurchBankAccounts = res.body;
          this.SaleBankAccounts = res.body;
          if (init) {
            this.loadAdOffices();
          }
        }
        );
  }

  loadAdOffices(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<AdOffice[]>(
      this.util.GetServerUrl() + '/listadoffices', { headers, observe: 'response' }).subscribe(res => {
        this.AdOffices = res.body;
        this.loadUnits();
      }
      );
  }

  loadUnits(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<Unit[]>(
      this.util.GetServerUrl() + '/listunits', { headers, observe: 'response' }).subscribe(res => {
        this.Units = res.body;
        if (this.ShopId > 0 ) {
          this.showShopInfo(this.ShopId);
        }
      }
      );
  }

  newBankAccount(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    const accountType = this.AccountForTypes.filter(x => x.TypeName === 'ShopAccount')[0];

    dialogConfig.data = {
      accountForTypeId: accountType.TypeId,
      accountForTypeName: accountType.TypeName,
      bankAccountId: 0 };
    const dialogRef = this.dialog.open(EditBankAccountComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadAccounts(false);
    }
    );
  }

  doSave(): void {

    if (this.ShopName == null || this.ShopName.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the shop name', null);
      return;
    }
    if (this.ShopCode == null || this.ShopCode.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the shop code', null);
      return;
    }
    if (this.SelUnit == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the unit', null);
      return;
    }
    if (this.SelAdOffice == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the administrative office', null);
      return;
    }

    const shop = new ShopSummary();
    shop.ShopId = this.ShopId;
    if (this.SelShop != null) {
      shop.ParentShopId = this.SelShop.ShopId;
    } else {
      shop.ParentShopId = null;
    }
    shop.ShopName = this.ShopName;
    shop.ShopCode = this.ShopCode;
    shop.BoatCustomerBill = this.BoatCustomerBill;
    shop.DirectCustomerBill = this.DirectCustomerBill;
    shop.BulkBill = this.BulkBill;
    shop.Title1 = this.Title1;
    shop.Title2 = this.Title2;
    shop.Footer1 = this.Footer1;
    shop.Footer2 = this.Footer2;
    shop.Address = this.Address;
    shop.ContactNo = this.ContactNo;
    shop.GstNo = this.GstNo;
    shop.QuotaApplicable = this.QuotaApplicable;
    shop.PurchExpenseAssd = this.PurchExpenseAssd;

    shop.PayOptionCash = this.PayOptionCash;
    shop.PayOptionCredit = this.PayOptionCredit;
    shop.PayOptionOnlineCredit = this.PayOptionOnlineCredit;
    shop.PayOptionOwn = this.PayOptionOwn;
    shop.PayOptionPayOnSale = this.PayOptionPayOnSale;
    shop.PayOptionPayLater = this.PayOptionPayLater;

    shop.ActiveOpenDate = this.activeOpenDate.utcOffset(0, true).toDate();

    if (this.SelPurchBankAccount != null) {
      shop.PurchBankAccountId = this.SelPurchBankAccount.BankAccountId;
    }

    if (this.SelSaleBankAccount != null) {
      shop.SaleBankAccountId = this.SelSaleBankAccount.BankAccountId;
    }

    if (this.SelUnit != null) {
      shop.ShopUnitId = this.SelUnit.UnitId;
    }
    if (this.SelAdOffice != null) {
      shop.AdOfficeId = this.SelAdOffice.AdOfficeId;
    }

    if (this.SelSchemeType != null) {
      shop.SchemeTypeId = this.SelSchemeType.TypeId;
    }

    shop.ShopTypeId = this.SelShopType.TypeId;

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    if (this.ShopId > 0) {
      this.http.post(
        this.util.GetServerUrl() + '/updateshop', shop, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.router.navigate(['../Shops']); // this.router.navigate(['EditShop/' + res.body]);
          });
        },
        err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
        );
    } else {
      this.http.post(
        this.util.GetServerUrl() + '/addshop', shop, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.router.navigate(['../Shops']); // this.router.navigate(['EditShop/' + res.body]);
          });
        },
        err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
        );
    }
  }

  loadShops(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ShopShortInfo[]>(
      this.util.GetServerUrl() + '/activeshops', { headers, observe: 'response' }).subscribe(res => {
        this.ActiveShops = res.body.filter(x => x.ShopId !== +this.ShopId);
        this.loadSchemeTypes();
      }
      );
  }

  showShopInfo(shopId: number): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ShopSummary>(
      this.util.GetServerUrl() + '/getshop/' + shopId, { headers, observe: 'response' })
      .subscribe(res => {
        const shop = res.body;
        this.ShopName = shop.ShopName;
        this.ShopCode = shop.ShopCode;
        this.SelShopType = this.ShopTypes.filter(x=> x.TypeId == shop.ShopTypeId)[0];
        if (shop.ParentShopId != null) {
          this.SelShop = this.ActiveShops.filter(x => x.ShopId === +shop.ParentShopId)[0];
        }
        this.BoatCustomerBill = shop.BoatCustomerBill;
        this.DirectCustomerBill = shop.DirectCustomerBill;
        this.BulkBill = shop.BulkBill;
        this.Title1 = shop.Title1;
        this.Title2 = shop.Title2;
        this.Footer1 = shop.Footer1;
        this.Footer2 = shop.Footer2;
        this.Address = shop.Address;
        this.ContactNo = shop.ContactNo;
        this.GstNo = shop.GstNo;
        this.QuotaApplicable = shop.QuotaApplicable;
        this.PurchExpenseAssd = shop.PurchExpenseAssd;
        if (shop.ActiveOpenDate != null) {
          const activeOpenDate = new Date(shop.ActiveOpenDate);
          this.activeOpenDate = moment(activeOpenDate);
          this.sActiveOpenDate = this.activeOpenDate.format('DD/MM/YYYY');
        }
        if (shop.PurchBankAccountId != null) {
          this.SelPurchBankAccount = this.PurchBankAccounts.filter(x => x.BankAccountId === shop.PurchBankAccountId)[0];
        } else {
          this.SelPurchBankAccount = null;
        }

        if (shop.SaleBankAccountId != null) {
          this.SelSaleBankAccount = this.SaleBankAccounts.filter(x => x.BankAccountId === shop.SaleBankAccountId)[0];
        } else {
          this.SelSaleBankAccount = null;
        }

        if (shop.ShopUnitId != null) {
          this.SelUnit = this.Units.filter(x => x.UnitId === shop.ShopUnitId)[0];
        } else {
          this.SelUnit = null;
        }
        if (shop.AdOfficeId != null) {
          this.SelAdOffice = this.AdOffices.filter(x => x.AdOfficeId === shop.AdOfficeId)[0];
        } else {
          this.SelAdOffice = null;
        }

        if (shop.SchemeTypeId != null) {
          this.SelSchemeType = this.SchemeTypes.filter(x => x.TypeId === shop.SchemeTypeId)[0];
        } else {
          this.SelSchemeType = null;
        }
        this.PayOptionCash = shop.PayOptionCash;
        this.PayOptionCredit = shop.PayOptionCredit;
        this.PayOptionOnlineCredit = shop.PayOptionOnlineCredit;
        this.PayOptionOwn = shop.PayOptionOwn;
        this.PayOptionPayOnSale = shop.PayOptionPayOnSale;
        this.PayOptionPayLater = shop.PayOptionPayLater;
      }
      );
  }

  doCancel(): void {
    if (this.ShopId == null || this.ShopId === 0) {
      this.router.navigate(['../Shops']);
    } else {
      // this.showShopInfo(this.ShopId);
      this.router.navigate(['../Shops']);
    }
  }

}
