import { ExceptionSummary, ExceptionPaged } from '../models/Poco';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { ExceptionService } from '../Service/Exception.service';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';

// https://blog.angular-university.io/angular-material-data-table/

export class ExceptionDataSource implements DataSource<ExceptionSummary> {

    private ExceptionSubject = new BehaviorSubject<ExceptionPaged>(new ExceptionPaged());
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    totalRecords = 0;

    constructor(private exceptionService: ExceptionService) { }

    connect(collectionViewer: CollectionViewer): Observable<ExceptionSummary[]> {
        return this.ExceptionSubject.asObservable()
            .pipe(map((x) => {
                // console.log(this.totalRecords);
                this.totalRecords = x.TotalRecords; return x.Summary;
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.ExceptionSubject.complete();
        this.loadingSubject.complete();
    }

    loadException(pageIndex: number, pageSize: number, sortKey = 'FromDate', sortDir = 'asc') {
        this.loadingSubject.next(true);
        this.exceptionService.listException(pageIndex, pageSize, sortKey, sortDir)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((exceptionPaged: ExceptionPaged) => {
                this.totalRecords = exceptionPaged.TotalRecords;
                this.ExceptionSubject.next(exceptionPaged);
            }
            );
    }
}
