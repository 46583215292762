import { Component, OnInit } from '@angular/core';
import { Product, CloneProduct, ShopShortInfo, ChildProduct, ArchiveProduct, MyShops } from '../models/Poco';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';
import { MatDialog } from '@angular/material/dialog';
import { CustomPropertyComponent } from '../custom-property/custom-property.component';

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.css']
})
export class NewProductComponent implements OnInit {

  ParentProducts: Product[] = [];
  SelParentProduct: Product = null;
  ActiveShops: ShopShortInfo[] = [];
  SellingPrice: number;
  BatchNo: string;

  ChildProducts: ChildProduct[] = [];
  IncludeArchived = false;

  isBilling = false;
  isMgmt = false;
  IsIncharge = false;

  displayedColumns = ['ShopName', 'ProductDisplayName', 'SellingPrice', 'BatchNo', 'IsStocked', 'CreatedAt', 'IsArchived', 'Action'];

  constructor(private http: HttpClient, private util: Util, private dialog: MatDialog,
              private winref: WindowRef) { }

  ngOnInit() {
    const role = sessionStorage.getItem('Role');
    this.isBilling = role === 'Billing';
    this.isMgmt = role === 'Mgmt';
    this.isMgmt = role === 'Mgmt';
    this.IsIncharge = role === 'Incharge';

    if (this.isBilling) {
      this.ActiveShops = [];
      this.loadProducts();
    } else if (this.isMgmt) {
      this.loadShops();
    } else if (this.IsIncharge) {
      this.loadMyShops();
    }

  }

  ShowCustomProperty(parentProductId: number) {
    const dialogRef = this.dialog.open(CustomPropertyComponent, {
      data: parentProductId,
    });
  }

  loadShops() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ShopShortInfo[]>(
      this.util.GetServerUrl() + '/activeshops', { headers, observe: 'response' }).subscribe(res => {
        this.ActiveShops = res.body;
        this.loadProducts();
      }
      );
  }

  loadMyShops() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<MyShops>(
      this.util.GetServerUrl() + '/myshops', { headers, observe: 'response' }).subscribe(res => {
        this.ActiveShops = res.body.Shops.map(x => new ShopShortInfo(x.ShopId, x.ShopName));
        this.loadProducts();
      }
      );
  }

  loadProducts() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    const p = new HttpParams()
      .set('context', 'NP');
    this.http.get<Product[]>(
      this.util.GetServerUrl() + '/allproducts',
      { headers, params: p, observe: 'response' }).subscribe(res => {
        this.ParentProducts = res.body;
      }
      );
  }


  productChanged() {
    // if (this.isBilling)
    {
      this.loadChildProducts();
    }
  }


  loadChildProducts() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const p = new HttpParams().set('parentProductId', this.SelParentProduct.ProductId + '')
      .set('includeArchived', this.IncludeArchived ? 'true' : 'false');
    this.http.get<ChildProduct[]>(
      this.util.GetServerUrl() + '/childproducts',
      { headers, observe: 'response', params: p }).subscribe(res => {
        this.ChildProducts = res.body;
      }
      );
  }

  doClone() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const prod = new CloneProduct();
    prod.ProductId = this.SelParentProduct.ProductId;
    prod.SellingPrice = this.SellingPrice;
    prod.BatchNo = this.BatchNo;
    prod.ShopIds = this.ActiveShops.filter(x => x.IsSelected).map(x => x.ShopId);

    this.http.post(
      this.util.GetServerUrl() + '/cloneproduct', prod, { headers, observe: 'response' }).subscribe(
        res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'New product added', x => {
            this.doClear();
            this.loadChildProducts();
          });
        },
        err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
      );
  }

  doClear() {
    this.SellingPrice = null;
    this.BatchNo = null;
    this.ActiveShops.forEach(element => {
      element.IsSelected = false;
    });
  }

  Archive(shopProductId: number, archived: boolean) {
    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Do you want to ' + (archived ? 'Archive' : 'resume') + '?', x => {
      if (x === 1) {
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });
        const archiveProduct = new ArchiveProduct(shopProductId, archived);
        this.http.post<boolean>(
          this.util.GetServerUrl() + '/setarchived', archiveProduct,
          { headers, observe: 'response' }).subscribe(res => {
            this.loadChildProducts();
          }, err => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
          });
      }
    }
    );
  }

}
