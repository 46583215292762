import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ExpenseDataSource } from '../datasource/Expense.datasource';
import { ExpenseService } from '../Service/Expense.service';
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { WindowRef } from '../service/window-ref';
import { Util } from '../models/Util';
import { LkStatus, ExpenseCancel, ExpenseSummary, ExpenseActions, ShopShortInfo } from '../models/Poco';
import { EditExpenseComponent } from '../edit-expense/edit-expense.component';
import { ExceptionService } from '../Service/Exception.service';
import { ExceptionDataSource } from '../datasource/exception.datasource';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

@Component({
  templateUrl: './exception.component.html',
  styleUrls: ['./exception.component.css'],
  providers: [ExceptionService]
})
export class ExceptionComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['ErrorMessage', 'StackTrace', 'HostName', 'IpAddress', 'UserName', 'CreatedAt'];
  pageSize = 10;
  totalRecords: number;
  dataSource: ExceptionDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private expenseService: ExceptionService,
              private http: HttpClient, private util: Util,
              public router: Router, private dialog: MatDialog, private winref: WindowRef) {
  }

  ngOnInit() {
    this.dataSource = new ExceptionDataSource(this.expenseService);
    this.totalRecords = this.dataSource.totalRecords;
    this.dataSource.loadException(0, 10);
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadExpense())
      )
      .subscribe();
  }

  loadExpense() {
    this.dataSource.loadException(
      this.paginator.pageIndex, this.paginator.pageSize);
  }

}
