import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { QuotaService } from '../service/quota.service';
import { QuotaDataSource } from '../datasource/quota.datasource';
import { EditQuotaComponent } from '../edit-quota/edit-quota.component';
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-quota-master',
  templateUrl: './quota-master.component.html',
  styleUrls: ['./quota-master.component.css'],
  providers: [QuotaService]
})
export class QuotaMasterComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['QuotaName', 'ValidFrom', 'ValidUpto', 'QuotaTypeName', 'Action'];
  pageSize = 10;
  totalRecords: number;

  dataSource: QuotaDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private quotaService: QuotaService, private dialog: MatDialog) { }

  ngOnInit() {
    this.dataSource = new QuotaDataSource(this.quotaService);
    this.totalRecords = this.dataSource.totalRecords;
    this.dataSource.loadQuota(0, 10, 'QuotaName', 'asc');
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadQuota())
      )
      .subscribe();
  }

  loadQuota() {
    this.dataSource.loadQuota(this.paginator.pageIndex, this.paginator.pageSize);
  }

  addQuota() {
    this.editQuota(0);
  }

  editQuota(quotaId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = quotaId;
    const dialogRef = this.dialog.open(EditQuotaComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadQuota();
    }
    );
  }

}
