import { Component, OnInit, Inject } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';
import { BankAccount, BankIfscCode, LkType, Supplier, Constant } from '../models/Poco';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-bank-account',
  templateUrl: './edit-bank-account.component.html',
  styleUrls: ['./edit-bank-account.component.css'],
  providers: [WindowRef]
})
export class EditBankAccountComponent implements OnInit {

  BankAccountId: number;
  AccountNumber: string;
  Description: string;
  Bank: string;
  IfscCode: string;
  Branch: string;
  Address: string;
  Contact: string;
  City: string;
  District: string;
  State: string;
  HolderAccountNo: string;

  ShowSupplier = false;

  AccountForTypeId: number;
  AccountForTypeName: string;

  AccountTypes: LkType[];
  SelAccountType: LkType;

  /*
  Suppliers: Supplier[];
  SelSupplier: Supplier;
  */

  ShopSupplierId: number;

  constructor(private dialogRef: MatDialogRef<EditBankAccountComponent>, private http: HttpClient,
              @Inject(MAT_DIALOG_DATA) data: { accountForTypeId: number, accountForTypeName: string,
                bankAccountId: number, ShopSupplierId: number},
              private dialog: MatDialog, private util: Util, private winref: WindowRef) {

    this.AccountForTypeId = data.accountForTypeId;
    this.AccountForTypeName = data.accountForTypeName;
    this.BankAccountId = data.bankAccountId;
    if (data.ShopSupplierId) {
      this.ShopSupplierId = data.ShopSupplierId;
    }
  }

  ngOnInit() {
    this.loadAccountTypes();
  }

  loadIfscInfo() {
    const ifscCode = this.IfscCode;
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<BankIfscCode>(
      this.util.GetServerUrl() + '/getifsccode/' + ifscCode,
      { headers, observe: 'response' }).subscribe(res => {
        this.Bank = res.body.Bank;
        this.IfscCode = res.body.IfscCode;
        this.Branch = res.body.Branch;
        this.Address = res.body.Address;
        this.Contact = res.body.Contact;
        this.City = res.body.City;
        this.District = res.body.District;
        this.State = res.body.State;
      }
      );
  }

  doSave() {
    const bankAccount = new BankAccount();
    bankAccount.IfscCode = this.IfscCode;
    bankAccount.AccountNumber = this.AccountNumber;
    bankAccount.BankAccountId = this.BankAccountId;
    bankAccount.Description = this.Description;
    bankAccount.AccountForId = this.AccountForTypeId;
    bankAccount.AccountTypeId = this.SelAccountType.TypeId;
    /* bankAccount.SupplierId = this.SelSupplier != null ? this.SelSupplier.SupplierId : null; */
    bankAccount.HolderAccountNo = this.HolderAccountNo;

    if (this.ShopSupplierId) {
      bankAccount.ShopSupplierId = this.ShopSupplierId;
    }

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    if (this.BankAccountId > 0) {
      this.http.post(
        this.util.GetServerUrl() + '/updatebankaccount', bankAccount, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.dialogRef.close(true);
          });
        }
        );
    } else {
      this.http.post(
        this.util.GetServerUrl() + '/addbankaccount', bankAccount, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.dialogRef.close(true);
          });
        }, err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
        );
    }
  }

  /*
  loadSuppliers() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const p = new HttpParams()
      .set('context', 'M');

    this.http.get<Supplier[]>(
      this.util.GetServerUrl() + '/mysuppliers', { headers, params: p, observe: 'response' }).subscribe(res => {
        this.Suppliers = res.body;
      }
      );
  }
  */

  loadAccountTypes() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/accounttypes',
      { headers, observe: 'response' }).subscribe(res => {
        this.AccountTypes = res.body;
        this.ShowSupplier = this.AccountForTypeName === this.util.SupplierAccount;
        /*
        if (this.ShowSupplier) {
          this.loadSuppliers();
        }
        */
      }
      );
  }

  doCancel() {
    this.dialogRef.close(false);
  }

}
