import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ExpenseDataSource } from '../datasource/Expense.datasource';
import { ExpenseService } from '../Service/Expense.service';
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { WindowRef } from '../service/window-ref';
import { Util } from '../models/Util';
import { LkStatus, ExpenseCancel, ExpenseSummary, ExpenseActions, ShopShortInfo } from '../models/Poco';
import { EditExpenseComponent } from '../edit-expense/edit-expense.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.css'],
  providers: [ExpenseService]
})
export class ExpenseComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['ExpenseSelection', 'ExpenseDate', 'ExpenseType', 'PartyName', 'AdditionalInfo', 'Amount',
    'GstNo', 'PanNo', 'Sgst', 'Cgst', 'Igst', 'TDS', 'TDS2', 'ExpenseStatus', 'CreatedByUserName', 'BatchNumber', 'Action'];
  pageSize = 10;
  totalRecords: number;
  dataSource: ExpenseDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  isBilling = false;
  IsAdvisor = false;

  BatchNo = '';

  ExpenseStatuses: LkStatus[] = [];
  SelExpenseStatus: LkStatus;
  ActiveShops: ShopShortInfo[];
  SelShop: ShopShortInfo;


  SelectedExpenseIds: { ExpenseId: number, ExpenseStatus: string }[] = [];

  currentExpenseList: ExpenseSummary[] = [];
  IsAllSelected = false;

  constructor(private expenseService: ExpenseService,
    private http: HttpClient, private util: Util,
    public router: Router, private dialog: MatDialog, private winref: WindowRef) {
  }

  ngOnInit() {
    const role = sessionStorage.getItem('Role');
    this.isBilling = role === 'Billing';
    this.IsAdvisor = role === 'Advisor';

    if (this.isBilling) {
      this.displayedColumns = ['ExpenseSelection', 'ExpenseDate', 'ExpenseType', 'PartyName', 'AdditionalInfo', 'Amount',
        'GstNo', 'PanNo', 'Sgst', 'Cgst', 'Igst', 'TDS', 'TDS2', 'ExpenseStatus', 'CreatedByUserName', 'BatchNumber', 'Action'];
    } else {
      this.displayedColumns = ['ExpenseDate', 'ExpenseType', 'PartyName', 'AdditionalInfo', 'Amount',
        'GstNo', 'PanNo', 'Sgst', 'Cgst', 'Igst', 'TDS', 'TDS2', 'ExpenseStatus', 'CreatedByUserName', 'BatchNumber', 'Action'];
    }

    this.dataSource = new ExpenseDataSource(this.expenseService);

    this.dataSource.subscribeForList().subscribe(res => {
      this.currentExpenseList = res.Summary;
      let allSelected: boolean = null;
      this.currentExpenseList.forEach(x => {
        if (x.ExpenseStatus === 'Draft' || x.ExpenseStatus === 'Refused') {
          if (this.SelectedExpenseIds.filter(y => y.ExpenseId === x.ExpenseId).length > 0) {
            x.IsSelected = true;
            if (allSelected === null) {
              allSelected = true;
            }
          } else {
            x.IsSelected = false;
            allSelected = false;
          }
        }
      });
      this.IsAllSelected = allSelected;
    }, err => {
    });

    this.totalRecords = this.dataSource.totalRecords;

    if (this.isBilling) {
      this.loadExpenseStatuses();
    } else {
      this.loadShops();
    }
  }

  loadExpenseStatuses() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<LkStatus[]>(
      this.util.GetServerUrl() + '/expensestatuses',
      { headers, observe: 'response' }).subscribe(res => {
        this.ExpenseStatuses = res.body;
        this.dataSource.loadExpense(null, null, 0, 10, 'CreatedAt', 'asc');
      }
      );
  }

  loadShops() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ShopShortInfo[]>(
      this.util.GetServerUrl() + '/activeshops', { headers, observe: 'response' }).subscribe(res => {
        this.ActiveShops = res.body;
        this.loadExpenseStatuses();
      }
      );
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadExpense())
      )
      .subscribe();
  }

  loadExpense() {
    this.dataSource.loadExpense(
      this.SelExpenseStatus != null ? this.SelExpenseStatus.StatusId : null,
      this.SelShop != null ? this.SelShop.ShopId : null,
      this.paginator.pageIndex, this.paginator.pageSize);
  }

  editExpense(summaryId: number, editMode: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { expenseId: summaryId, mode: editMode };
    dialogConfig.width = "90vw";
    dialogConfig.panelClass = 'full-width-dialog';
    const dialogRef = this.dialog.open(EditExpenseComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadExpense();
    }
    );
  }

  newExpense() {
    this.editExpense(0, 'add');
  }

  shopChanged() {
    this.SelectedExpenseIds = [];
    this.paginator.pageIndex = 0;
    this.loadExpense();
  }

  statusChanged() {
    this.SelectedExpenseIds = [];
    this.paginator.pageIndex = 0;
    this.loadExpense();
  }

  cancelExpense(expenseId: number) {

    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Are you sure to want to cancel?', (x: number) => {
      if (x === 1) {

        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });

        const obj = new ExpenseCancel();
        obj.ExpenseId = expenseId;

        this.http.post(
          this.util.GetServerUrl() + '/cancelExpense', obj, { headers, observe: 'response' }).subscribe(res => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Cancelled', () => {
              this.loadExpense();
            });
          }, err => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
          }
          );

      }
    });

  }

  makeRequest() {

    if (this.BatchNo == null || this.BatchNo.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the batch number', null);
      return;
    }

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const expAction = new ExpenseActions(this.SelectedExpenseIds.map(x => x.ExpenseId), 'Requested', this.BatchNo);

    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Are you sure?', (x: number) => {
      if (x === 1) {
        this.http.post(
          this.util.GetServerUrl() + '/expenseactions', expAction, { headers, observe: 'response' }).subscribe(res => {
            this.BatchNo = '';
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Requested', () => {
              this.SelectedExpenseIds = [];
              this.loadExpense();
            });
          }, err => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
            this.BatchNo = '';
          }
          );
      }
    });

  }

  onStatusChange(expense: ExpenseSummary, evnt: MatCheckboxChange) {
    if (evnt.checked) {
      this.SelectedExpenseIds.push({ ExpenseId: expense.ExpenseId, ExpenseStatus: expense.ExpenseStatus });
      if (this.currentExpenseList.filter(x => !x.IsSelected &&
        (x.ExpenseStatus === 'Draft' || x.ExpenseStatus === 'Refused')).length === 0) {
        this.IsAllSelected = true;
      }
    } else {
      this.SelectedExpenseIds = this.SelectedExpenseIds.filter(x => x.ExpenseId !== expense.ExpenseId);
      if (this.currentExpenseList.filter(x => !x.IsSelected).length > 0) {
        this.IsAllSelected = false;
      }
    }

  }

  SelectUnSelectAll(evnt: MatCheckboxChange) {
    if (evnt.checked) {
      this.currentExpenseList.forEach(x => {
        if (x.ExpenseStatus === 'Draft' || x.ExpenseStatus === 'Refused') {
          x.IsSelected = true;
          if (this.SelectedExpenseIds.filter(y => y.ExpenseId === x.ExpenseId).length === 0) {
            this.SelectedExpenseIds.push({ ExpenseId: x.ExpenseId, ExpenseStatus: x.ExpenseStatus });
          }
        }
      }
      );
    } else {
      this.currentExpenseList.forEach(x => {
        if (x.ExpenseStatus === 'Draft' || x.ExpenseStatus === 'Refused') {
          x.IsSelected = false;
          this.SelectedExpenseIds = this.SelectedExpenseIds.filter(y => y.ExpenseId !== x.ExpenseId);
        }
      }
      );
    }
  }

}
