import { BankAccountPaged, BankAccount } from '../models/Poco';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { ShopService } from '../service/shop.service';
import { BankAccountService } from '../service/bank-account.service';

// https://blog.angular-university.io/angular-material-data-table/

export class BankAccountDataSource implements DataSource<BankAccount> {

    private shopSubject = new BehaviorSubject<BankAccountPaged>(new BankAccountPaged());
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    totalRecords = 0;

    constructor(private bankAccountService: BankAccountService) { }

    connect(collectionViewer: CollectionViewer): Observable<BankAccount[]> {
        return this.shopSubject.asObservable()
            .pipe(map((x) => {
                // console.log(this.totalRecords);
                this.totalRecords = x.TotalRecords; return x.Summary;
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.shopSubject.complete();
        this.loadingSubject.complete();
    }

    loadBankAccounts(accountForTypeId: number, pageIndex: number, pageSize: number, sortKey = 'FromDate', sortDir = 'asc') {
        this.loadingSubject.next(true);
        this.bankAccountService.listBankAccount(accountForTypeId, pageIndex, pageSize, sortKey, sortDir)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((bankAccountPaged: BankAccountPaged) => {
                this.totalRecords = bankAccountPaged.TotalRecords;
                this.shopSubject.next(bankAccountPaged);
            }
            );
    }
}
