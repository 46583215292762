import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Product, LkType, ProductRate, ProductRateFilter, ProductRateUpdate, OpenCloseStatusResponse, Quota } from '../models/Poco';
import { Util } from '../models/Util';

import { WindowRef } from '../service/window-ref';
import { MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-product-rate',
  templateUrl: './product-rate.component.html',
  styleUrls: ['./product-rate.component.css'],
  providers: [
    WindowRef
  ]
})
export class ProductRateComponent implements OnInit {

  displayedColumns: string[] = ['ShopName', 'RateWithoutDiscount', 'RateWithDiscount', 'Discount'];

  alertType = 'success';

  selectedDate: Date = new Date();
  Product: Product;
  products: Product[];
  boatTypes: LkType[];
  productRateListFound = false;
  productRateList: ProductRate[];

  quotas: Quota[];
  SelectedQuota: Quota;

  @ViewChild('details', { static: true }) details: MatTable<ProductRate>;

  constructor(private http: HttpClient, private util: Util, private dialog: MatDialog, private winref: WindowRef) { }

  ngOnInit() {
    this.loadQuotas();
  }

  _ngOnInit() {
    this.loadProduct();
  }

  loadBillingStatus() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const dateInddMMyyyy = this.util.GetddMMMyyyyDate(new Date());

    this.http.get<OpenCloseStatusResponse>(
      this.util.GetServerUrl() + '/GetOpenCloseStatus?StatusDate=' + dateInddMMyyyy,
      { headers, observe: 'response' }).subscribe(res => {
        this.selectedDate = new Date(res.body.TransactionDate);
        this._ngOnInit();
      }
      );
  }

  loadQuotas() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<Quota[]>(
      this.util.GetServerUrl() + '/currentquotas',
      { headers, observe: 'response' }).subscribe(res => {
        this.quotas = res.body;
        // if (res.body.length > 0) {
          // this.SelectedQuota = this.quotas[0];
        // }
        this.loadBillingStatus();
      }
      );
  }

  loadProduct() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const p = new HttpParams()
      .set('context', 'RT');

    this.http.get<Product[]>(
      this.util.GetServerUrl() + '/allproducts', { headers, params: p, observe: 'response' }).subscribe(res => {
        this.products = res.body.filter(x => !x.IsDummy);
      }
      );
  }

  loadBoatType() {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/AllBoatTypes', { headers, observe: 'response' }).subscribe(res => {
        this.boatTypes = res.body;
      }
      );
  }

  _loadProductRates(evt: any) {
    this.loadProductRates(this.Product);
  }

  loadProductRates(product: Product) {
    const selDate = this.util.GetddMMMyyyyDate(this.selectedDate);
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ProductRate[]>(
      this.util.GetServerUrl() + '/listproductrate?RateDate=' + selDate
      + '&ProductId=' + product.ProductId + ( this.SelectedQuota != null ? '&QuotaId=' + this.SelectedQuota.QuotaId : '' )
      , { headers, observe: 'response' }).subscribe(res => {
        this.productRateList = res.body;
        this.productRateListFound = (this.productRateList.length > 0);
        this.loadProductRates_1(product);
      }
      );
  }

  loadProductRates_1(product: Product) {
    const selectedDate = new Date(this.selectedDate);
    selectedDate.setDate(selectedDate.getDate()-1);
    const selDate = this.util.GetddMMMyyyyDate(selectedDate);
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ProductRate[]>(
      this.util.GetServerUrl() + '/listproductrate?RateDate=' + selDate
      + '&ProductId=' + product.ProductId + ( this.SelectedQuota != null ? '&QuotaId=' + this.SelectedQuota.QuotaId : '' )
      , { headers, observe: 'response' }).subscribe(res => {
        res.body.forEach(y=> {
          const res = this.productRateList.filter(z=> z.ProductId == y.ProductId);
          if ( res.length > 0){
            res[0].SubsidyRateDate_1 = selectedDate;
            res[0].Rate_1 = y.Rate;
            res[0].SubsidyRate_1 = y.SubsidyRate;
          }
          else 
          {
            res[0].SubsidyRateDate_1 = null;
            res[0].Rate_1 = null;
            res[0].SubsidyRate_1 = null;
          }
        });
        this.loadProductRates_2(product);
      }
      );
  }

  loadProductRates_2(product: Product) {
    const selectedDate = new Date(this.selectedDate);
    selectedDate.setDate(selectedDate.getDate()-2);
    const selDate = this.util.GetddMMMyyyyDate(selectedDate);
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ProductRate[]>(
      this.util.GetServerUrl() + '/listproductrate?RateDate=' + selDate
      + '&ProductId=' + product.ProductId + ( this.SelectedQuota != null ? '&QuotaId=' + this.SelectedQuota.QuotaId : '' )
      , { headers, observe: 'response' }).subscribe(res => {
        res.body.forEach(y=> {
          const res = this.productRateList.filter(z=> z.ProductId == y.ProductId);
          if ( res.length > 0){
            res[0].SubsidyRateDate_2 = selectedDate;
            res[0].Rate_2 = y.Rate;
            res[0].SubsidyRate_2 = y.SubsidyRate;
          }
          else 
          {
            res[0].SubsidyRateDate_2 = null;
            res[0].Rate_2 = null;
            res[0].SubsidyRate_2 = null;
          }
        });
      }
      );
  }


  updateProductRates(product: Product) {

    const selDate = ('0' + (this.selectedDate.getMonth() + 1)).slice(-2) + '/' +
      ('0' + this.selectedDate.getDate()).slice(-2) + '/' + ('0000' + this.selectedDate.getFullYear()).slice(-4);

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    /*
    for (const r of this.productRateList) {
      if (r.Rate == null || r.SubsidyRate == null) {
        r.Discount = null;
      } else {
        r.Discount = r.Rate - r.SubsidyRate;
      }
    }
    */

    const filter = new ProductRateFilter(selDate, product.ProductId, this.SelectedQuota != null ? this.SelectedQuota.QuotaId : null );
    const productUpdate = new ProductRateUpdate(filter, this.productRateList);

    this.http.post(
      this.util.GetServerUrl() + '/updateproductrate', productUpdate, { headers, observe: 'response' }).subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'updated', null);
        this.loadProductRates(product);
      }
      );
  }
}
