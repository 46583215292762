import { Component, OnInit, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ArchiveProduct, ShopProduct, ShopProductUpdate } from '../models/Poco';
import { Util } from '../models/Util';
import { WindowRef } from '../service/window-ref';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-shop-product',
  templateUrl: './shop-product.component.html',
  styleUrls: ['./shop-product.component.css'],
  providers: [WindowRef]
})
export class ShopProductComponent implements OnInit {

  ShopId: number;
  ShopProducts: ShopProduct[];

  displayedColumns: string[] = ['Included', 'ProductName', 'SubsidyBill', 'NonSubsidyBill', 'Action'];
  totalColumns: string[] = [];

  constructor(private dialogRef: MatDialogRef<ShopProductComponent>, @Inject(MAT_DIALOG_DATA) data: any,
              private http: HttpClient, private util: Util, private dialog: MatDialog,
              private winref: WindowRef) {
    this.ShopId = data as number;
  }

  ngOnInit() {
    this.loadShopProducts(this.ShopId);
  }

  doSave() {

    const excluded = this.ShopProducts.filter(x => !x.Included && x.ShopProductId != null)
      .map(x => x.ShopProductId);
    const updated = this.ShopProducts.filter(x => x.Included);
    const updateObj = new ShopProductUpdate();
    updateObj.ShopId = this.ShopId;
    updateObj.UpdateProducts = updated;
    updateObj.DeleteShopProductIds = excluded;

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    this.http.post(
      this.util.GetServerUrl() + '/updateshopproduct', updateObj, { headers, observe: 'response' }).subscribe(res => {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Updated', (x) => {
          this.dialogRef.close(true);
        });
      },
        err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
      );
  }

  doCancel() {
    this.dialogRef.close(false);
  }

  loadShopProducts(shopId: number) {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<ShopProduct[]>(
      this.util.GetServerUrl() + '/shopproducts/' + shopId, { headers, observe: 'response' })
      .subscribe(res => {
        this.ShopProducts = res.body;
      }
      );
  }

  Archive(shopProductId: number, archived: boolean) {
    this.util.ShowMessageBox(this.dialog, this.winref, 'Yes', 'No', 'Do you want to ' + (archived ? 'Archive' : 'resume') + '?', x => {
      if (x === 1) {
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          token
        });
        const archiveProduct = new ArchiveProduct(shopProductId, archived);
        this.http.post<boolean>(
          this.util.GetServerUrl() + '/setarchived', archiveProduct,
          { headers, observe: 'response' }).subscribe(res => {
            this.loadShopProducts(this.ShopId);
          }, err => {
            this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
          });
      }
    }
    );
  }

  includeChanged(change: MatCheckboxChange, shopProduct: ShopProduct) {
    if (!change.checked) {
      shopProduct.SubsidyBill = false;
      shopProduct.NonSubsidyBill = false;
    }
  }

}
