import { Component, OnInit, Inject } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Util } from '../models/Util';
import { UserTask, UserSummary, TaskAssignment, TaskTypeFieldValue, LkType, Constant } from '../models/Poco';
import { Router } from '@angular/router';
import { WindowRef } from '../service/window-ref';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { UserSearchService } from '../service/user-search.service';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-task',
  templateUrl: './edit-task.component.html',
  styleUrls: ['./edit-task.component.css'],
  providers: [
    WindowRef
  ]
})
export class EditTaskComponent implements OnInit {
  TaskId = 0;
  TaskTypes: LkType[] = [];
  SelTaskType: LkType;
  TaskDesc: string;
  TaskDescRegEx: string;
  proposedDate: Moment;
  sProposedDate: string;
  selectedShopCode: string;
  TaskAddnFields: TaskTypeFieldValue[] = [];

  AssignedObjects: TaskAssignment[] = [];

  filterUser = new FormControl();
  filteredUsers: Observable<UserSummary[]>;

  UserVal: string;
  filterRole: string;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  ViewOnly = false;

  constructor(@Inject(MAT_DIALOG_DATA) data: number, private http: HttpClient, private util: Util, private dialog: MatDialog,
              private winref: WindowRef, private dialogRef: MatDialogRef<EditTaskComponent>,
              private userSearchService: UserSearchService) {
                this.ViewOnly = data < 0;
                this.TaskId = data > 0 ? data : -data;
  }

  ngOnInit(): void {
    this.filteredUsers = this.filterUser.valueChanges
    .pipe(
      debounceTime(300),
      switchMap(value =>
        this.userSearchService.search(this.filterRole, value as string)
      )
    );
    this.loadTaskTypes();
  }

  loadTaskTypes(): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<LkType[]>(
      this.util.GetServerUrl() + '/tasktypes',
      { headers, observe: 'response' }).subscribe(res => {
        this.TaskTypes = res.body;
        if (this.TaskId > 0) {
          this.showDetail(this.TaskId);
        }

      }
      );
  }

  loadTaskAdditionFields(taskTypeId: number): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });
    this.http.get<TaskTypeFieldValue[]>(
      this.util.GetServerUrl() + '/tasktypefield/' + taskTypeId,
      { headers, observe: 'response' }).subscribe(res => {
        const fields = res.body;
        fields.forEach(element => {
          element.FieldValue = element.DefaultAnswer;
        });
        this.TaskAddnFields = fields;
      }
      );
  }

  TaskTypeChanged(curVal: any): void {
    if (this.TaskId == null || this.TaskId === 0) {
      if (this.SelTaskType.TypeName === Constant.PaymentRequest) {
        this.filterRole = Constant.AdvisorRole;
      } else {
        this.filterRole = '';
      }
      this.loadTaskAdditionFields(this.SelTaskType.TypeId);
    }
  }

  displayUserFn(user: UserSummary): string {
    if (user) { return user.FullName; }
    return '';
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    if (input) {
      input.value = '';
    }
  }

  remove(taskAssignment: TaskAssignment): void {
    const index = this.AssignedObjects.indexOf(taskAssignment);
    if (index >= 0) {
      this.AssignedObjects.splice(index, 1);
    }
  }


  setUser(evnt: any): void {
    const user = evnt.option.value as UserSummary;
    const assignment = new TaskAssignment();
    assignment.UserCategory = 'User';
    assignment.CategoryId = user.UserId;
    assignment.CategoryObjectName = user.FullName;
    this.AssignedObjects.push(assignment);
  }

  showDetail(taskId: number): void {
    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const p = new HttpParams().set('mode', this.ViewOnly ? 'action' : 'edit');

    this.http.get<UserTask>(
      this.util.GetServerUrl() + '/taskid/' + taskId, { headers, params: p, observe: 'response' })
      .subscribe(res => {
        const task = res.body as UserTask;
        this.SelTaskType = this.TaskTypes.filter(x => x.TypeId === task.TaskTypeId)[0];
        this.TaskDesc = task.TaskDesc;
        const proposedDate = new Date(task.ProposedDate);
        this.proposedDate = moment(proposedDate);
        this.sProposedDate = this.proposedDate.format('DD/MM/YYYY');
        this.AssignedObjects = task.Assignments;
        this.TaskAddnFields = task.FieldValues;
      }
      );
  }

  SaveOrUpdate(): void {

    if (this.SelTaskType == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the task type', null);
      return;
    }

    if (this.proposedDate == null) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Select the proposed date', null);
      return;
    }

    for (const element of this.TaskAddnFields) {
      if ( element.IsRequired && ( element.FieldValue == null || element.FieldValue.length === 0
        || element.FieldName === 'ShopShpAcc' && element.FieldValue === '[SHOPSHPACC]' ) ) {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the value for \''
        + (element.FieldName === 'ShopShpAcc' ? 'Account' : element.FieldName) + '\'', null);
        return;
      }
      if ( element.Pattern != null && element.Pattern.length > 0 ) {
        const exp = new RegExp(element.Pattern);
        if (!exp.test(element.FieldValue)) {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the valid value for \'' + element.FieldName + '\'', null);
          return;
        }
      }
    }

    if (this.TaskDesc == null || this.TaskDesc.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Enter the task detail', null);
      return;
    }

    if (this.AssignedObjects == null || this.AssignedObjects.length === 0) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Assign the task', null);
      return;
    }

    const token = sessionStorage.getItem('Token') ?? '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token
    });

    const task = new UserTask();
    task.UserTaskId = this.TaskId;
    task.TaskTypeId = this.SelTaskType.TypeId;
    task.TaskDesc = this.TaskDesc;
    task.ProposedDate = this.proposedDate.utcOffset(0, true).toDate();
    task.Assignments = this.AssignedObjects;
    task.FieldValues = this.TaskAddnFields;

    if (this.TaskDescRegEx != null) {
      const regex = new RegExp(this.TaskDescRegEx);
      const isValid = regex.test(task.TaskDesc);
      if (!isValid) {
        this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Amount description is invalid', null);
        return;
      }
    }

    if (this.TaskId > 0) {
      this.http.post(
        this.util.GetServerUrl() + '/updatetask', task, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.dialogRef.close(true);
          });
        }
        );
    } else {
      this.http.post(
        this.util.GetServerUrl() + '/createtask', task, { headers, observe: 'response' }).subscribe(res => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Saved', x => {
            this.dialogRef.close(true);
          });
        }, err => {
          this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, err.error.Message, null);
        }
        );
    }
  }

  doCancel(): void {
    this.dialogRef.close(false);
  }

  shopcodeSelected(curVal: any): void {
    this.selectedShopCode = curVal.value;
  }
}
