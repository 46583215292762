import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ShopCodeAccount',
    pure: false
})
export class ShopCodeAccountFilter implements PipeTransform {
    transform(items: any[], filter: any): any {
        if (!items || !filter) {
            return items;
        }
        return items.filter(item => item.Tag === filter);
    }
}