import { Component, OnInit, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Util } from '../models/Util';
import { TokenInfo } from '../models/Poco';
import { environment } from 'src/environments/environment';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-not-logged-in',
  templateUrl: './not-logged-in.component.html',
  styleUrls: ['./not-logged-in.component.css']
})
export class NotLoggedInComponent implements OnInit {

  alertVisible = false;
  alertMessage: string;
  AppVersion: string;

  username: string;
  password: string;

  LoginInprogress = false;

  scrHeight: number;

  @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;

  @Output() LogggedIn = new EventEmitter<null>();
  constructor(private http: HttpClient, private util: Util, private dialog: MatDialog) {
    this.getScreenSize();
    //this.util.ShowInformationBox(this.dialog, 'Profile has been updated');
  }

  @HostListener('document:keyup', ['$event']) onKeyUp(evnt: KeyboardEvent): void {
    if(evnt.code == 'CapsLock' && evnt.getModifierState("CapsLock"))
    {
      const obj: any =  evnt.target;
      if (obj.type && obj.type === 'password')
      {
        this.util.ShowInformationBox(this.dialog, 'CapsLock key is On !.').subscribe(x=> {
          obj.focus();
        })
      }
    }
  }

  onFocus(evnt: any) {
    
    console.log(evnt);
    //console.log(evnt.getModifierState('CapsLock'))
    //KeyboardEvent.getModifierState('CapsLock');
  }

  goFullscreen() {
    document.documentElement.requestFullscreen();

    //var element: any = document.getElementById("main");
    //if (element.mozRequestFullScreen) {
    //  element.mozRequestFullScreen();
    //} else if (element.webkitRequestFullScreen) {
    //  element.webkitRequestFullScreen();
    //}
  }

  existFullscreen() {
    document.exitFullscreen();
    //var element: any = document.getElementById("main");
    //if (element.mozRequestFullScreen) {
    //  element.mozRequestFullScreen();
    //} else if (element.webkitRequestFullScreen) {
    //  element.webkitRequestFullScreen();
    //}
  }

  ngOnInit() {
    this.username = environment.username;
    this.password = environment.password;
    this.AppVersion = environment.version;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }

  toggle(control,evnt) {
    if (control.type == 'password') {
      control.type = 'text'
    } else {
      control.type = 'password'
    }
  }

  doLogin(userName: string, password: string) {
    this.LoginInprogress = true;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + userName + ':' + password,
      Version: environment.version
    });
    this.http.post(
      this.util.GetServerUrl() + '/login', {
    }, { headers, observe: 'response' }).subscribe(res => {
      if (environment.production) {
        this.goFullscreen();
      }
      this.LoginInprogress = false;
      sessionStorage.setItem('dateViolationPromptDone', '0');
      const token = res.headers.get('Token');
      sessionStorage.setItem('Token', token);
      console.log(sessionStorage.getItem('Token') ?? '');
      const body = res.body as TokenInfo;
      sessionStorage.setItem('PwdStrong', this.util.PassworStrong(password) ? '1' : '0');
      sessionStorage.setItem('Role', body.RoleName);
      sessionStorage.setItem('ShopId', body.ShopId ? body.ShopId + '' : '');
      this.LogggedIn.emit();
    }, err => {
      this.LoginInprogress = false;
    }
    );
  }

  @HostListener('window:resize', ['$event']) getScreenSize(event?): void {
    this.scrHeight = window.innerHeight * 0.80;
  }

}
