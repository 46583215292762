import { Component, OnInit, Inject } from '@angular/core';
import { Denomination } from 'src/app/models/Poco';
import { WindowRef } from 'src/app/service/window-ref';
import { Util } from 'src/app/models/Util';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Moment } from 'moment';

@Component({
  selector: 'app-denomination-page',
  templateUrl: './denomination-page.component.html',
  styleUrls: ['./denomination-page.component.css'],
  providers: [WindowRef]
})
export class DenominationPageComponent implements OnInit {
  // denoDate: Moment;
  // sDenoDate: string;
  DenomincationAmount: number;
  RsExcess: number;
  Rs1: number;
  Rs2: number;
  Rs5: number;
  Rs10: number;
  Rs20: number;
  Rs50: number;
  Rs100: number;
  Rs200: number;
  Rs500: number;
  Rs1000: number;
  Rs2000: number;
  IsReadOnly: boolean;
  MisMatchAllowed: boolean;
  // IsDateRequired: boolean;
  constructor(public dialogRef: MatDialogRef<DenominationPageComponent>, private winref: WindowRef,
              private util: Util, private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: { Amount: number, Denomination: Denomination,
                ReadOnly: boolean, MisMatchAllowed: boolean
                // , DateRequired: boolean 
              }) {
                this.MisMatchAllowed = data.MisMatchAllowed;
    this.DenomincationAmount = data.Amount;
    if (data.Denomination != null) {
      this.Rs1 = data.Denomination.Rs1;
      this.Rs2 = data.Denomination.Rs2;
      this.Rs5 = data.Denomination.Rs5;
      this.Rs10 = data.Denomination.Rs10;
      this.Rs20 = data.Denomination.Rs20;
      this.Rs50 = data.Denomination.Rs50;
      this.Rs100 = data.Denomination.Rs100;
      this.Rs200 = data.Denomination.Rs200;
      this.Rs500 = data.Denomination.Rs500;
      this.Rs1000 = data.Denomination.Rs1000;
      this.Rs2000 = data.Denomination.Rs2000;
    }
    //this.IsDateRequired = data.DateRequired;
    //if (data.DateRequired) {
    //  this.denoDate = null;
    //}
    this.IsReadOnly = data.ReadOnly;
  }

  ngOnInit(): void {
  }

  onOkClick(): void {

    //if (this.IsDateRequired && (!this.denoDate)) {
    //  this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null,
    //    'Choose the date', null);
    //  return;
    //}

    const totalAmount =
      (+(this.Rs2000 === undefined ? 0 : this.Rs2000 * 2000) +
        +(this.Rs1000 === undefined ? 0 : this.Rs1000 * 1000) +
        +(this.Rs500 === undefined ? 0 : this.Rs500 * 500) +
        +(this.Rs200 === undefined ? 0 : this.Rs200 * 200) +
        +(this.Rs100 === undefined ? 0 : this.Rs100 * 100) +
        +(this.Rs50 === undefined ? 0 : this.Rs50 * 50) +
        +(this.Rs20 === undefined ? 0 : this.Rs20 * 20) +
        +(this.Rs10 === undefined ? 0 : this.Rs10 * 10) +
        +(this.Rs5 === undefined ? 0 : this.Rs5 * 5) +
        +(this.Rs2 === undefined ? 0 : this.Rs2 * 2) +
        +(this.Rs1 === undefined ? 0 : this.Rs1 * 1));

    if ( !this.MisMatchAllowed && Math.abs(totalAmount - this.DenomincationAmount) >= 1) {
      this.util.ShowMessageBox(this.dialog, this.winref, 'OK', null, 'Mismatch in denomination', null);
      return;
    }

    const retVal = new Denomination(
      this.Rs1,
      this.Rs2,
      this.Rs5,
      this.Rs10,
      this.Rs20,
      this.Rs50,
      this.Rs100,
      this.Rs200,
      this.Rs500,
      this.Rs1000,
      this.Rs2000);

 //   if (this.IsDateRequired) {
 //     retVal.DenoDate = this.denoDate.utcOffset(0, true).toDate();
 //   }

    this.dialogRef.close(retVal);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
