import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ServiceProvider } from '../models/Poco';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Util } from '../models/Util';

@Injectable()
export class GstAddInfoService {

    constructor(private http: HttpClient, private util: Util) { }

    search(expTypeId: number, name: string): Observable<string[]> {
        const token = sessionStorage.getItem('Token') ?? '';
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        let p = new HttpParams()
            .set('startWith', name);
        if (expTypeId && expTypeId > 0) {
            p = p.set('expTypeId', expTypeId + '');
        }

        return this.http.get<string[]>(this.util.GetServerUrl() + '/matchaddinfo', { headers, params: p })
            .pipe(
                tap((response: string[]) => {
                    return response;
                })
            );
    }
}
