import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BillPrint } from '../models/Poco';

@Component({
  selector: 'app-bill-print',
  templateUrl: './bill-print.component.html',
  styleUrls: ['./bill-print.component.css']
})
export class BillPrintComponent implements OnInit {

  BillData: BillPrint;
  TotalDiscAmt: number;

  constructor(private ref: ChangeDetectorRef, private dialogRef: MatDialogRef<BillPrintComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
      this.BillData = data as BillPrint;
      let disc = 0;
      for (const detail of this.BillData.BillInfo.BillingDetails) {
        disc += (detail.Qty * detail.DiscRate);
      }
      this.TotalDiscAmt = disc;
  }

  ngOnInit() {
    this.ref.detectChanges();
  }

  close() {
    this.dialogRef.close();
  }

}
