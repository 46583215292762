import { UserSummary, UserPaged, MasterProductPaged, MasterProduct } from '../models/Poco';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { ProductMasterService } from '../service/product-master.service';

// https://blog.angular-university.io/angular-material-data-table/

export class ProductMasterDataSource implements DataSource<MasterProduct> {

    private masterProductSubject = new BehaviorSubject<MasterProductPaged>(new MasterProductPaged());
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    totalRecords = 0;

    constructor(private productMasterService: ProductMasterService) { }

    connect(collectionViewer: CollectionViewer): Observable<MasterProduct[]> {
        return this.masterProductSubject.asObservable()
            .pipe(map((x) => {
                // console.log(this.totalRecords);
                this.totalRecords = x.TotalRecords; return x.Summary;
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.masterProductSubject.complete();
        this.loadingSubject.complete();
    }

    loadProductMasters(pageIndex: number, pageSize: number, sortKey = 'FromDate', sortDir = 'asc'): void {
        this.loadingSubject.next(true);
        this.productMasterService.listProductMaster(pageIndex, pageSize, sortKey, sortDir)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            ).subscribe((masterProductPaged: MasterProductPaged) => {
                this.totalRecords = masterProductPaged.TotalRecords;
                this.masterProductSubject.next(masterProductPaged);
            }
            );
    }
}
